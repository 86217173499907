import { FugFile, UploadedFile } from '@campfire/file-upload-gallery';
import { difference } from 'lodash';
import {
  array as YupArray,
  bool as YupBool,
  date as YupDate,
  number as YupNumber,
  object as YupObject,
  Schema,
  string as YupString,
  mixed as YupMixed,
} from 'yup';
import { TaskItemsDeserialize } from '../../../../screens/admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';

type SchemaDependencyType = { optionId: string; taskFieldId: string; optionType: string; };

const requiredManualAddress = {
  lineOne: YupString().required('Address required'),
  lineTwo: YupString(),
  country: YupString().required('Country required'),
  postcode: YupString().required('Postcode required'),
  suburb: YupString().required('Suburb required'),
  state: YupString().required('State required'),
};

const manualAddress = {
  lineOne: YupString(),
  lineTwo: YupString(),
  country: YupString(),
  postcode: YupString(),
  suburb: YupString(),
};

const addressValidator = (optional: boolean) =>
  YupObject().shape({
    address: YupObject().shape(
      {
        placesAddress: YupString().when('manualAddress', {
          is: '' && !optional,
          then: YupString().required(),
          otherwise: YupString().nullable(),
        }),
        manualAddress: YupObject()
          .shape(manualAddress)
          .when('placesAddress', {
            is: (val) => !val && !optional,
            then: YupObject()
              .shape(requiredManualAddress)
              .required(),
            otherwise: YupObject()
              .shape(manualAddress)
              .nullable(),
          }),
      },
      [['placesAddress', 'manualAddress']]
    ),
  });

const multiChoiceValidator = (optional: boolean) =>
  YupObject().shape({
    multipleChoiceOptionId: optional ? YupString().nullable() : YupString().required('Please select an option'),
    otherOptionSelected: YupBool(),
    otherText: YupString().when('otherOptionSelected', {
      is: (otherOptionSelected) => otherOptionSelected,
      then: YupString().required('Required'),
      otherwise: YupString().nullable(),
    }),
  });

const quizValidator = (optional: boolean) =>
  YupObject().shape({
    quizTaskFieldOptionId: optional ? YupString().nullable() : YupString().required('Please select an answer'),
    otherTaskFieldOptionSelected: YupBool(),
    otherText: YupString().when('otherTaskFieldOptionSelected', {
      is: (otherTaskFieldOptionSelected) => otherTaskFieldOptionSelected,
      then: YupString().required('Required'),
      otherwise: YupString().nullable(),
    }),
    isCorrect: YupBool().when('allowRetry', {
      is: (allowRetry) => allowRetry,
      then: YupBool().oneOf([true], 'Incorrect! Try selecting another option'),
      otherwise: YupBool().nullable(),
    }),
  });

export const phoneNumberValidator = (optional: boolean) =>
  YupString()
    .nullable(optional)
    .test('validate', 'Invalid phone number', (value) => {
      if (optional && !value) return true;
      return value?.replace(/\D/g, '').length >= 10;
    });

export const dateValidator = (optional: boolean) => {
  const schema = YupDate()
    .max('2100-01-01', 'Date is after max limit')
    .min('1900-01-01', 'Date is before min limit')
    .typeError('Invalid date');
  return optional ? schema.nullable() : YupDate().required('Required');
};

const dependenciesWrapper = (schema: any, dependencies?: SchemaDependencyType) => {
  if (dependencies) {
    const { optionId, optionType, taskFieldId } = dependencies;
    switch (optionType) {
      case 'checkbox':
        return schema.when(`${taskFieldId}`, {
          is: (value: string[]) => {
            return value.includes(optionId);
          },
          then: (innerSchema: any) => innerSchema,
          otherwise: () => {
            return YupMixed().test('non-pass', 'Passed as do not depended', () => true);
          },
        });
      case 'dropdown':
        return schema.when(`${taskFieldId}`, {
          is: (value: string) => {
            return value === optionId;
          },
          then: (innerSchema: any) => innerSchema,
          otherwise: () => {
            return YupMixed().test('non-pass', 'Passed as do not depended', () => true);
          },
        });
      case 'quiz':
        return schema.when(`${taskFieldId}.quizTaskFieldOptionId`, {
          is: (value: string) => {
            return value === optionId;
          },
          then: (innerSchema: any) => innerSchema,
          otherwise: () => {
            return YupMixed().test('non-pass', 'Passed as do not depended', () => true);
          },
        });
      case 'multipleChoice':
        return schema.when(`${taskFieldId}.multipleChoiceOptionId`, {
          is: (value: string) => {
            return value === optionId;
          },
          then: (innerSchema: any) => innerSchema,
          otherwise: () => {
            return YupMixed().test('non-pass', 'Passed as do not depended', () => true);
          },
        });
      default:
        return schema;
    }
  }
  return schema;
};

const taskItemValidators = (optional: boolean, __typename: string, dependencies?: SchemaDependencyType) => {
  const validators: { [key: string]: any } = {
    VOLUNTEER_DateTaskFieldType: dependenciesWrapper(dateValidator(optional), dependencies),
    VOLUNTEER_EmailTaskFieldType: dependenciesWrapper(
      optional
        ? YupString()
          .nullable()
          .email('Invalid email')
        : YupString()
          .email('Invalid email')
          .required('Required'),
      dependencies
    ),
    VOLUNTEER_LongTextTaskFieldType: dependenciesWrapper(
      optional ? YupString().nullable() : YupString().required('Required'),
      dependencies
    ),
    VOLUNTEER_NumberTaskFieldType: dependenciesWrapper(
      optional ? YupNumber().nullable() : YupString().required('Required'),
      dependencies
    ),
    VOLUNTEER_PhoneTaskFieldType: dependenciesWrapper(
      optional ? phoneNumberValidator(optional) : phoneNumberValidator(optional).required('Required'),
      dependencies
    ),
    VOLUNTEER_ShortTextTaskFieldType: dependenciesWrapper(
      optional ? YupString().nullable() : YupString().required('Required'),
      dependencies
    ),
    VOLUNTEER_MultipleChoiceTaskFieldType: dependenciesWrapper(multiChoiceValidator(optional), dependencies),
    VOLUNTEER_QuizTaskFieldType: dependenciesWrapper(quizValidator(optional), dependencies),
    VOLUNTEER_DropdownTaskFieldType: dependenciesWrapper(
      optional ? YupString().nullable() : YupString().required('Required'),
      dependencies
    ),
    VOLUNTEER_AttachmentTaskFieldType: dependenciesWrapper(
      optional
        ? YupObject().nullable()
        : YupObject()
          .test(
            'validate',
            'No files attached',
            (value) => {
              const allAttachedIds = [
                ...value?.addedAttachments.map((file: FugFile) => file.id),
                ...(value?.attachments || []).map((attachment: UploadedFile) => attachment.fileId)
              ];
              return difference(allAttachedIds, value.removedAttachmentIds || []).length > 0;
            }
          )
          .typeError('Please attach a file'),
      dependencies
    ),
    VOLUNTEER_CheckboxTaskFieldType: dependenciesWrapper(
      optional ? YupArray().nullable() : YupArray().min(1, 'Please select an option'),
      dependencies
    ),
    VOLUNTEER_AddressTaskFieldType: dependenciesWrapper(addressValidator(optional), dependencies),
  };

  return validators[__typename];
};

const generateYupSchema = (taskItems: TaskItemsDeserialize) => {
  return taskItems
    .allIds
    .reduce((schema: { [key: string]: any }, itemId) => {
      const taskItem = taskItems.byId[itemId];
      if (taskItem.__typename !== 'VOLUNTEER_TaskItemFieldType') return schema;
      const updatedSchema: { [key: string]: any } = schema;
      const { taskFieldId, __typename } = taskItem.field;

      const optionId = taskItems.byTriggerOption[itemId];

      if (optionId && !taskItems.byTrigger[optionId]) {
        return updatedSchema;
      }

      if (optionId) {
        updatedSchema[taskFieldId] = taskItemValidators(taskItem.optional, __typename, {
          optionId,
          ...taskItems.byTrigger[optionId]
        })
      } else {
        updatedSchema[taskFieldId] = taskItemValidators(taskItem.optional, __typename)
      }
      return updatedSchema;
    }, {});
};

export const dynamicValidationSchema = (taskItems: TaskItemsDeserialize, schema?: { [key: string]: Schema<any> }) => {
  return YupObject().shape({
    ...schema,
    ...generateYupSchema(taskItems),
  });
};
