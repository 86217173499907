import React, { useEffect, useState } from 'react';
import { Box, Button, Theme, Typography } from '@material-ui/core';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';
import { CheckCircle, ExpandMore, Lock, PhonelinkLock } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { TooltipMenu } from '../../../../../common/tooltip-menu';
import { AddAuthenticatorAppDialog } from './AddAuthenticatorAppDialog';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

type totpStatusDate = {
  totpActive: boolean | null;
  totpAdded: any;
};

interface Props {
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    edit: {
      color: theme.alert.blue.extraLight,
      display: 'flex',
      alignItems: 'center',
      paddingTop: '10px',
      justifyContent: 'center',
      '& p': {
        fontSize: '14px',
      },
      '& svg': {
        fontSize: '16px',
      },
      textTransform: 'none',
    },
  };
});

export const AuthenticationTableWidget = (props: Props) => {
  const { onClose } = props;
  const apiUrl = useApiUrl();
  const { theme } = useCampfireTheme();
  const buttonClasses = useStyles();
  const [addAuthOpen, setAddAuthOpen] = React.useState(false);
  const [totpExists, setTotpExists] = React.useState(false);
  const [addAuthAppDialog, setAddAuthAppDialog] = React.useState(false);
  const { run } = useCampfireFetch({ defer: true });

  const createData = (id: number, method: string, lastUpdated: string, action: string) => {
    return {
      id,
      method,
      lastUpdated,
      action,
    };
  };

  const [rows, setRows] = useState([createData(2, 'SMS', 'n/a', ''), createData(3, 'Password', '', '')]);

  const totpStatus = () => {
    run({
      method: 'get',
      url: `${apiUrl}/im/user/totp/status`,
    }).then((response) => {
      if (response.ok) {
        const data = response.data.data as totpStatusDate;
        if (data.totpActive) {
          setRows(rows.concat(createData(1, 'Authenticator App', '', '')));
          setTotpExists(true);
        }
      }
    });
  };

  useEffect(() => {
    totpStatus();
  }, []);

  const columns: GridColumns = [
    {
      field: 'method',
      headerName: 'Method',
      flex: 1,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <Box style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <CheckCircle style={{ color: theme.alert.green.light, fontSize: '14px' }} />
            <Typography style={{ fontWeight: 500, fontSize: '14px', padding: '0 6px' }}>{row.method}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      flex: 1,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      renderCell: ({ row }: any) => {
        return (
          <Typography style={{ fontWeight: 400, fontSize: '12px', padding: '0 6px', opacity: '.8' }}>
            {row.lastUpdated}
          </Typography>
        );
      },
    },
    {
      field: 'action',
      headerName: ' ',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      disableReorder: true,
      renderHeader: () => {
        return (
          <Box style={{ marginLeft: 'auto' }} hidden={totpExists}>
            <TooltipMenu
              open={addAuthOpen}
              onClose={() => {
                setAddAuthOpen(false);
              }}
              title={
                <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ padding: '0 5px' }}>
                  {!totpExists && (
                    <Button
                      startIcon={<PhonelinkLock />}
                      className={buttonClasses.edit}
                      onClick={() => {
                        setAddAuthAppDialog(true);
                        setAddAuthOpen(false);
                      }}
                    >
                      Authenticator App
                    </Button>
                  )}
                  {/* <Button startIcon={<VpnKey />} className={buttonClasses.edit} onClick={() => {}}>
                    One-Time Password
                  </Button> */}
                </Box>
              }
            >
              <TabletButton
                size='small'
                variant='contained'
                color='primary'
                aria-controls='options-menu'
                aria-haspopup='true'
                onClick={() => setAddAuthOpen(true)}
                startIcon={<Lock />}
                endIcon={<ExpandMore />}
                style={{
                  height: '100%',
                  border: '1px solid transparent',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                  padding: '5px 10px',
                  marginRight: '10px',
                  color: '#fff',
                  marginLeft: 'auto',
                }}
              >
                Add
              </TabletButton>
            </TooltipMenu>
          </Box>
        );
      },
    },
  ];

  return (
    <Box width='100%' height='100%'>
      <DataGrid
        autoHeight
        columns={columns}
        rows={rows}
        loading={false}
        disableSelectionOnClick
        hideFooter
        disableColumnFilter
      />
      {addAuthAppDialog && (
        <AddAuthenticatorAppDialog
          open={addAuthAppDialog}
          handleClose={() => setAddAuthAppDialog(false)}
          onSuccess={() => onClose()}
          orgForce
        />
      )}
    </Box>
  );
};
