import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { Suspense, useEffect } from 'react';
import { Route, useLocation } from 'react-router';
import { CampfireSwitch } from './content-blocks/common/CampfireSwitch';
import { Intercom } from './global/analytics/Segment';
import { RedirectProvider } from './global/app-shell/RedirectContext';
import { SessionProvider } from './global/auth/SessionContext';
import { unpackToken } from './global/auth/unpack-token';
import { useOrgInfo } from './global/auth/use-org-info/use-org-info';
import { useSession } from './global/auth/useSession';
import { ApiProvider } from './global/config/ApiContext';
import { CampfireApolloProvider } from './global/config/ApolloContext';
import { CampfireVersionContext, CampfireVersionProvider } from './global/config/CampfireVersionContext';
import { CampfireVersionSnackbar } from './global/config/CampfireVersionSnackbar';
import { SnackbarProvider } from './global/config/SnackbarContext';
import { OnboardingProvider } from './global/onboarding/OnboardingContext';
import { PageNotFoundProvider } from './global/page-not-found/PageNotFoundContext';
import { PageVisibilityProvider } from './global/page-visibility/PageVisibilityContext';
import { ProgramsContextProvider } from './global/programs-sell/ProgramsContext';
import { VerifyEmailShell } from './global/public-shell/VerifyEmailShell';
import { ScreenControlProvider } from './global/screen-control/ScreenControlContext';
import { TenantDeactivatedShell } from './global/tenant-deactivated-shell/TenantDeactivatedShell';
import { TenantUndefinedShell } from './global/tenant-undefined-shell/TenantUndefinedShell';
import LegacyErrorBoundary from './LegacyErrorBoundary';

const PublicShell = React.lazy(() => import('./global/public-shell/PublicShell'));
const AppShell = React.lazy(() => import('./global/app-shell/AppShell'));
const StaticShell = React.lazy(() => import('./global/static-shell/StaticShell'));
const ApplicantShell = React.lazy(() => import('./global/applicant-shell/ApplicantShell'));

const getIsTokenExpired = (token: string): boolean => {
  const currentUnixTimestamp = Math.floor(new Date().getTime() / 1000);
  const { exp } = unpackToken(token);
  return currentUnixTimestamp > exp;
};

const useStyles = makeStyles(() =>
  createStyles({
    appStyle: {
      backgroundColor: '#F6F8F9',
      boxSizing: 'border-box',
    },
  })
);

const App = () => {
  return (
    <CampfireVersionProvider>
      <CampfireVersionContext.Consumer>
        {(versionContext) => (
          <LegacyErrorBoundary versionContext={versionContext}>
            <PageNotFoundProvider>
              <PageVisibilityProvider>
                <ApiProvider>
                  <CampfireApolloProvider>
                    <ShellGateway />
                  </CampfireApolloProvider>
                </ApiProvider>
              </PageVisibilityProvider>
            </PageNotFoundProvider>
          </LegacyErrorBoundary>
        )}
      </CampfireVersionContext.Consumer>
    </CampfireVersionProvider>
  );
};

const ShellGateway = () => {
  const classes = useStyles();

  return (
    <Suspense fallback={<CircularProgressOverlay spinnerColor='white' opacity={0} isLoading transitionDelay={150} />}>
      <SnackbarProvider>
        <Box className={classes.appStyle}>
          <CampfireSwitch>
            <Route path='/-' render={() => <StaticShell />} />
            <Route
              path='*'
              render={() => (
                <SessionProvider>
                  <RedirectProvider>
                    <CampfireVersionSnackbar />
                    <Gateway />
                  </RedirectProvider>
                </SessionProvider>
              )}
            />
          </CampfireSwitch>
        </Box>
      </SnackbarProvider>
    </Suspense>
  );
};

const Gateway = () => {
  const orgInfo = useOrgInfo();
  const { user, isLoadingFromStorage, logout } = useSession();
  const location = useLocation();

  // Initialise Segment Intercom
  useEffect(() => {
    if (orgInfo?.tenantId !== '5fbb9c2c-c282-4048-b9ca-3c651f9a4346') {
      const segmentIntercom = new Intercom();
      segmentIntercom.init(user, user?.orgInfo);
    }
  }, [location.pathname, user]);

  // Token is still loading from localforage
  if (isLoadingFromStorage) {
    return <CircularProgressOverlay opacity={0} isLoading />;
  }

  // Tenant is deactivated
  if (orgInfo?.isDeactivated) {
    return <TenantDeactivatedShell />;
  }

  // Tenant is undefined
  if (orgInfo && orgInfo?.name === undefined) {
    return <TenantUndefinedShell />;
  }

  // User is not signed in
  if (!user) {
    return <PublicShell redirectUrl={location.pathname} />;
  }

  const isStrictlyApplicant = user.userIdentityService.isApplicant && !user.userIdentityService.isVolunteer;

  if (!user.emailVerified) {
    return <VerifyEmailShell />;
  }

  if (isStrictlyApplicant) {
    return (
      <CampfireApolloProvider token={user.token}>
        <ApplicantShell />
      </CampfireApolloProvider>
    );
  }

  if (user.emailVerified) {
    const isTokenExpired = getIsTokenExpired(user.token);
    return (
      <CampfireApolloProvider token={user.token}>
        <OnboardingProvider>
          <ProgramsContextProvider>
            <ScreenControlProvider>
              <AppShell isTokenExpired={isTokenExpired} />
            </ScreenControlProvider>
          </ProgramsContextProvider>
        </OnboardingProvider>
      </CampfireApolloProvider>
    );
  }

  logout();
  return null;
};

export { App };
