import { Grid, Typography } from '@material-ui/core';
import { Security } from '@material-ui/icons';
import React from 'react';
import { AuthenticationTableWidget } from './authentication/AuthenticationTable';

interface Props {
  onClose: () => void;
}

const MultiFactorPanel = (props: Props) => {
  const { onClose } = props;

  return (
    <>
      <Grid container spacing={2} direction='column' style={{ position: 'relative' }}>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <Typography style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
            <Security style={{ fontSize: '14px', paddingRight: '5px' }} /> Authentication
          </Typography>
          <Typography variant='body2' style={{ color: '#464646' }} align='left'>
            Increase the security of your Volaby account by using additional authentication methods.
            <br />
            <br />
          </Typography>
          <AuthenticationTableWidget onClose={onClose} />
        </Grid>
      </Grid>
    </>
  );
};

export { MultiFactorPanel };
