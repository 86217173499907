import classnames from 'classnames';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { memo, ReactNode } from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useUser } from '../../../../../global/auth/useUser';
import { GetIncomingVolunteerAllTasks_vm_application_relevantTasks_roles as RoleType } from '../__generated__/GetIncomingVolunteerAllTasks';
import { RoleIcon } from '../../../../../common/icons/RoleIconList';

type IncomingVolunteerTaskListItemType = {
  taskId: string;
  icon: ReactNode;
  taskTitle: string;
  taskSubText?: string;
  taskRecentActivityStatus?: string;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
  adminOnly: boolean;
  roleReq?: RoleType[];
};

const useStyles = () => {
  const { theme, isMobile } = useCampfireTheme();
  return makeStyles({
    listItem: {
      padding: isMobile ? '8px 20px' : '6px 25px',
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    taskListItem: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    taskListItemHover: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
    },
    taskListDisabled: {
      opacity: '0.5',
      cursor: 'default',
    },
    taskListItemSelected: {
      backgroundColor: theme.palette.grey[200],
    },
    taskListSubItem: {
      padding: isMobile ? '8px 40px' : '8px 64px',
    },
    iconContainer: {
      marginRight: isMobile ? 16 : 8,
    },
    borderBottom: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    borderRight: {
      borderRight: `1px solid ${theme.color.grey.border}`,
    },
  })();
};

type IncomingVolunteerTaskListItemProps = IncomingVolunteerTaskListItemType & { locked?: boolean };

const IncomingVolunteerTaskListItem = memo(
  ({
    icon,
    taskTitle,
    taskSubText,
    taskRecentActivityStatus,
    onClick,
    locked,
    disabled,
    selected,
    adminOnly,
    roleReq,
  }: IncomingVolunteerTaskListItemProps) => {
    const classes = useStyles();
    const {
      user: {
        userIdentityService: { isVmAdmin },
      },
    } = useUser();

    const onlyAdminDisabledClick = !isVmAdmin && adminOnly;
    const { theme } = useCampfireTheme();

    return (
      <Box
        display='flex'
        alignContent='center'
        alignItems='center'
        width={1}
        paddingX={1}
        className={`${classes.taskListItem} ${
          disabled || locked ? classes.taskListDisabled : classes.taskListItemHover
        } ${selected ? classes.taskListItemSelected : ''}`}
        onClick={() => (!locked && !disabled && !onlyAdminDisabledClick && onClick ? onClick() : null)}
      >
        <Box
          display='flex'
          alignContent='center'
          alignItems='center'
          flex={2}
          className={classes.borderRight}
          paddingY={2}
        >
          <Box display='flex' paddingX={2}>
            {icon}
          </Box>
          <Box>
            <span>
              <Typography display='inline' variant='body2' style={{ marginRight: 12, display: 'flex' }}>
                {taskTitle}
                {roleReq
                  ? roleReq.map((role) => {
                      return (
                        <Box
                          style={{
                            backgroundColor: '#e7e7e7',
                            padding: '3px 5px',
                            borderRadius: '3px',
                            minHeight: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '7px',
                          }}
                        >
                          {role?.icon ? (
                            <RoleIcon
                              iconName={role?.icon}
                              color={theme.color.grey.neutral300}
                              style={{ height: '12px' }}
                            />
                          ) : (
                            ''
                          )}
                          <Typography style={{ fontWeight: 600, fontSize: '11px', color: '#444', paddingLeft: '3px' }}>
                            {role?.name}
                          </Typography>
                        </Box>
                      );
                    })
                  : undefined}
              </Typography>
              {taskSubText && (
                <Typography display='inline' variant='caption' color='textSecondary'>
                  {taskSubText}
                </Typography>
              )}
            </span>
          </Box>
        </Box>
        <Box display='flex' alignContent='center' alignItems='center' flex={1} paddingLeft={2}>
          <Typography variant='caption' color='textSecondary'>
            {taskRecentActivityStatus}
          </Typography>
        </Box>
      </Box>
    );
  }
);

const IncomingVolunteerTaskListItemMobile = memo(
  ({
    icon,
    taskTitle,
    taskSubText,
    taskRecentActivityStatus,
    onClick,
    locked,
    adminOnly,
  }: {
    icon: ReactNode;
    taskTitle: string;
    taskSubText?: string;
    taskRecentActivityStatus?: string;
    onClick?: () => void;
    locked?: boolean;
    adminOnly: boolean;
  }) => {
    const classes = useStyles();
    const {
      user: {
        userIdentityService: { isVmAdmin },
      },
    } = useUser();

    const onlyAdminDisabledClick = !isVmAdmin && adminOnly;
    return (
      <Grid
        item
        container
        wrap='nowrap'
        alignItems='center'
        className={classnames(classes.taskListItem, classes.taskListSubItem)}
        onClick={() => (!locked && !onlyAdminDisabledClick && onClick ? onClick() : null)}
      >
        <Grid item className={classes.iconContainer}>
          {icon}
        </Grid>
        <Grid item container direction='column'>
          <Typography variant='body2'>{taskTitle}</Typography>
          {taskSubText && (
            <Grid item style={{ flex: 1 }}>
              <Typography variant='caption' color='textSecondary'>
                {taskSubText}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Typography variant='caption' color='textSecondary'>
              {taskRecentActivityStatus}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export const IncomingVolunteerTaskList = ({
  header,
  locked,
  items,
}: {
  header: string;
  locked?: boolean;
  items: Array<IncomingVolunteerTaskListItemType>;
}) => {
  const classes = useStyles();
  const { isMobile } = useCampfireTheme();
  return (
    <Grid container style={{ opacity: locked ? 0.5 : 1 }}>
      <Grid item container alignItems='center' className={classes.listItem}>
        <Grid item>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography
                style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center', padding: '.6em 0' }}
              >
                {header}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {items.map((item) =>
        isMobile ? (
          <IncomingVolunteerTaskListItemMobile
            key={item.taskId}
            icon={item.icon}
            taskTitle={item.taskTitle}
            taskSubText={item.taskSubText}
            taskRecentActivityStatus={item.taskRecentActivityStatus}
            onClick={item.onClick}
            locked={locked}
            adminOnly={item.adminOnly}
          />
        ) : (
          <IncomingVolunteerTaskListItem
            key={item.taskId}
            icon={item.icon}
            taskId={item.taskId}
            taskTitle={item.taskTitle}
            taskSubText={item.taskSubText}
            taskRecentActivityStatus={item.taskRecentActivityStatus}
            onClick={item.onClick}
            locked={locked}
            disabled={item.disabled}
            selected={item.selected}
            adminOnly={item.adminOnly}
            roleReq={item.roleReq}
          />
        )
      )}
    </Grid>
  );
};
