import React from 'react';
import { Box, Button, Theme, Typography, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TabletButton } from '@campfire/tablet-button';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Delete, Edit, ExpandMore, FileCopy, KeyboardArrowDown, KeyboardArrowUp, SwapHoriz } from '@material-ui/icons';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ADMIN_CONSOLE_GET_SINGLE_VOLUNTEER_PROFILE_TASK } from '../../admin-console/admin-console-content-pages/admin-console-volunteer-profile/admin-console-volunteer-profile.gql';
import {
  AdminConsoleGetSingleVolunteerProfileTask,
  AdminConsoleGetSingleVolunteerProfileTaskVariables,
} from '../../admin-console/admin-console-content-pages/admin-console-volunteer-profile/__generated__/AdminConsoleGetSingleVolunteerProfileTask';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { TaskItemField } from './TaskItemField';
import { deserializeTaskItems } from '../utils';
import { MoveToDialog } from './MoveToDialog';
import { TooltipMenu } from '../../../../common/tooltip-menu';

const TaskDetailSkeleton = () => {
  return (
    <Box>
      <Box width='100%' mb='16px'>
        <Skeleton variant='text' width='180px' height='44px' style={{ borderRadius: '4px', marginBottom: '4px' }} />
        <Divider />
      </Box>
      <Box width='100%'>
        <Skeleton variant='rect' width='100%' height='20px' style={{ borderRadius: '4px', marginBottom: '12px' }} />
        <Skeleton variant='rect' width='100%' height='20px' style={{ borderRadius: '4px', marginBottom: '12px' }} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '40px',
    },
    field: {
      '& .header': {
        display: 'flex',
        borderBottom: `1px solid ${theme.color.grey.neutralBrand200}`,
        paddingBottom: '12px',
        alignItems: 'center',
      },
      '& .title': {
        fontWeight: 900,
        fontSize: '20px',
        color: theme.color.grey.neutralBrand800,
        flex: 1,
      },
      '& .subTitle': {
        fontWeight: 400,
        fontSize: '16px',
        color: theme.color.grey.neutralBrand500,
        marginTop: '20px',
      },
      '& .subTitle2': {
        fontWeight: 400,
        fontSize: '14px',
        color: theme.color.grey.neutralBrand500,
        marginTop: '20px',
      },
      '& .button': {
        textTransform: 'none',
        fontSize: '14px',
        fontWeight: 700,
        padding: '8px',
        minWidth: 'unset',
        minHeight: 'unset',
        height: '30px',
      },
      '& .buttonGroup': {
        display: 'flex',
        gap: '12px',
      },
    },
    visibilityField: {
      marginTop: 36,
      '& .title': {
        fontWeight: 900,
        fontSize: 15,
        color: theme.color.grey.neutralBrand800,
      },
      '& .item': {
        fontSize: '9px',
        fontWeight: 600,
        padding: 10,
        borderRadius: 3,
        backgroundColor: '#D9D9D9',
        color: theme.color.grey.neutral400,
      },
      '& .items': {
        marginTop: '5px',
        display: 'flex',
        gap: 10,
      },
    },
    showMoreButton: {
      textTransform: 'none',
      borderRadius: '8px',
    },
    optionItem: {
      color: theme.alert.blue.extraLight,
      display: 'flex',
      alignItems: 'center',
      paddingTop: '10px',
      justifyContent: 'center',
      '& p': {
        fontSize: '14px',
      },
      '& svg': {
        fontSize: '16px',
      },
      textTransform: 'none',
    },
  })
);

interface TaskDetailsProps {
  taskId?: string | null;
  onEdit: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
  onMove: (newTask: any, handleSuccess: () => void) => void;
  cakes: Array<{ cakeId: string; title: string }>;
}

export function TaskDetails({ taskId, onEdit, onDuplicate, onDelete, cakes, onMove }: TaskDetailsProps) {
  const [taskQuery, { data, loading }] = useCampfireLazyQuery<
    AdminConsoleGetSingleVolunteerProfileTask,
    AdminConsoleGetSingleVolunteerProfileTaskVariables
  >(ADMIN_CONSOLE_GET_SINGLE_VOLUNTEER_PROFILE_TASK);
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const [openMoveToDialog, setOpenMoveToDialog] = React.useState<boolean>(false);
  const { theme } = useCampfireTheme();

  React.useEffect(() => {
    if (taskId) {
      taskQuery({ variables: { taskId } });
    }
  }, [taskId]);

  const { task } = data?.vm || {};
  const isFixedTask = task?.order === -1;
  const cakeOptions = cakes.filter((cake) => cake.cakeId !== task?.cake.cakeId);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onOption = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onOptionClose = () => setAnchorEl(null);

  const classes = useStyles();

  const parseTaskItems = deserializeTaskItems(task?.taskItems);
  const programText = React.useMemo(() => {
    const programNumber = task?.programs?.length;
    switch (programNumber) {
      case 0:
        return 'all programs';
      case 1:
        return task?.programs[0].name;
      default:
        return `${programNumber} programs`;
    }
  }, [task]);

  const roleText = React.useMemo(() => {
    const roleNumber = task?.roles.length;
    switch (roleNumber) {
      case 0:
        return 'all roles';
      case 1:
        return `the ${task?.roles[0].name} role`;
      default:
        return `${roleNumber} roles`;
    }
  }, [task]);

  const rootTaskIds = parseTaskItems.allIds
    .filter((taskItemId) => !parseTaskItems.byTriggerOption[taskItemId])
    .filter((taskItemId) => parseTaskItems.byId[taskItemId].__typename === 'VOLUNTEER_TaskItemFieldType');

  return (
    <Box className={classes.container}>
      {loading ? (
        <TaskDetailSkeleton />
      ) : (
        <Box className={classes.field}>
          <Box className='header'>
            <Typography className='title'>{task?.title}</Typography>
            <Box className='buttonGroup'>
              <TabletButton
                // data-track='actCnl-overview-status-dropdown'
                size='small'
                variant='outlined'
                color='primary'
                aria-controls='task-status-menu'
                aria-haspopup='true'
                onClick={() => onEdit()}
                startIcon={<Edit />}
                style={{
                  border: '1px solid #9e9e9e',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                  padding: '2px 7px',
                  color: theme.color.grey.neutral300,
                  marginTop: '5px',
                }}
              >
                {'Edit'}
              </TabletButton>
              {isFixedTask ? null : (
                <Box display='flex'>
                  <TooltipMenu
                    open={Boolean(anchorEl)}
                    onClose={onOptionClose}
                    title={
                      <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ padding: '0 5px' }}>
                        <Button
                          className={classes.optionItem}
                          startIcon={<FileCopy />}
                          onClick={onDuplicate}
                          style={{ display: 'none' }}
                        >
                          Duplicate
                        </Button>

                        {cakeOptions.length && (
                          <Button
                            onClick={() => {
                              setOpenMoveToDialog(true);
                              onOptionClose();
                            }}
                            className={classes.optionItem}
                            startIcon={<SwapHoriz />}
                          >
                            Move Category
                          </Button>
                        )}

                        <Button
                          className={classes.optionItem}
                          style={{ color: '#E5B3B3' }}
                          startIcon={<Delete />}
                          onClick={onDelete}
                        >
                          Delete
                        </Button>
                      </Box>
                    }
                  >
                    <TabletButton
                      size='small'
                      variant='outlined'
                      color='primary'
                      aria-controls='task-status-menu'
                      aria-haspopup='true'
                      onClick={(e) => onOption(e)}
                      endIcon={<ExpandMore />}
                      style={{
                        border: '1px solid #9e9e9e',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        padding: '2px 7px',
                        color: theme.color.grey.neutral300,
                        marginRight: '8px',
                        marginTop: '5px',
                      }}
                    >
                      {'Options'}
                    </TabletButton>
                  </TooltipMenu>
                </Box>
              )}
            </Box>
          </Box>
          <Typography className={'subTitle'}>{task?.description}</Typography>
        </Box>
      )}

      {loading ? (
        <TaskDetailSkeleton />
      ) : (
        <Box className={classes.field}>
          <Box className='header' style={{ marginBottom: '20px' }}>
            <Typography className='title'>Fields</Typography>
          </Box>
          <Box display='flex' flexDirection='column' style={{ gap: '28px' }}>
            {(showAll ? rootTaskIds : rootTaskIds.slice(0, 3)).map((taskItemId) => (
              <TaskItemField key={taskItemId} items={parseTaskItems} taskItemId={taskItemId} />
            ))}
            {rootTaskIds.length > 3 && (
              <Box alignSelf='stretch' display='flex' alignItems='center' flexDirection='colmn' justifyContent='center'>
                <Button
                  endIcon={showAll ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  onClick={() => setShowAll(!showAll)}
                  className={classes.showMoreButton}
                  variant='outlined'
                  disableElevation
                >
                  {showAll ? 'Show Less' : 'Show More'}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {loading ? (
        <TaskDetailSkeleton />
      ) : (
        <Box className={classes.field}>
          <Box className='header'>
            <Typography className='title'>Visibility</Typography>
          </Box>
          <Typography className={'subTitle2'}>
            This task is visible to <strong>{task?.applicantsOnly ? 'new applicants' : 'volunteers'}</strong> in{' '}
            <strong>{programText}</strong>, for <strong>{roleText}</strong>. To see a task, a volunteer must satisfy
            each condition.{' '}
            {task?.adminOnly ? (
              <span>
                Task submissions are <strong>only</strong> visible to <strong>Admins</strong>.
              </span>
            ) : (
              ''
            )}
          </Typography>
          <Box className={classes.visibilityField}>
            <Typography className='title'>Programs</Typography>
            <Box className='items'>
              {task?.programs.length === 0 ? (
                <Typography style={{ fontSize: '14px', color: theme.color.grey.neutralBrand500 }}>
                  This task is visible to all programs
                </Typography>
              ) : null}
              {task?.programs.map((program) => (
                <Box className='item'>{program.name}</Box>
              ))}
            </Box>
          </Box>
          {/* <Box className={classes.visibilityField}>
          <Typography className='title'>Activities</Typography>
          {task.activities.length === 0 ? <Typography style={{ fontSize: '14px', color: theme.color.grey.neutralBrand500 }}>This task is visible to all programs</Typography> : null}
            {task.activities.map((program) => (
              <Box className='item'>{program.name}</Box>
            ))}
        </Box> */}
          <Box className={classes.visibilityField}>
            <Typography className='title'>Roles</Typography>
            <Box className='items'>
              {task?.roles.map((role) => (
                <Box className='item'>{role.name}</Box>
              ))}
              <Box className='items'>
                {task?.roles.length === 0 ? (
                  <Typography style={{ fontSize: '14px', color: theme.color.grey.neutralBrand500 }}>
                    This task is visible to all roles
                  </Typography>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {openMoveToDialog && task && (
        <MoveToDialog
          task={task}
          cakeOptions={cakeOptions}
          onMove={onMove}
          isOpen={openMoveToDialog}
          handleClose={() => {
            setOpenMoveToDialog(false);
          }}
        />
      )}
    </Box>
  );
}
