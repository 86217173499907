import { Box, Switch, Theme, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Security } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AlertCard } from '../../../../../common/cards/alert-card/AlertCard';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import {
  GET_ADMIN_CONSOLE_MFA_ADMIN_SETTINGS,
  GET_ADMIN_CONSOLE_MFA_AL_SETTINGS,
  GET_ADMIN_CONSOLE_MFA_PM_SETTINGS,
  GET_ADMIN_CONSOLE_MFA_VOL_SETTINGS,
} from './admin-console-mfa-settings.gql';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetAdminConsoleMFAAdminSettings } from './__generated__/GetAdminConsoleMFAAdminSettings';
import { GetAdminConsoleMFAALSettings } from './__generated__/GetAdminConsoleMFAALSettings';
import { GetAdminConsoleMFAVolSettings } from './__generated__/GetAdminConsoleMFAVolSettings';
import { useSnackbar } from '../../../../../global/config/useSnackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      backgroundColor: '#F9F9F9',
      minHeight: 'calc(100% - 20px)',
    },
    contentContainer: {
      background: 'white',
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
      paddingLeft: '1.875rem',
      marginTop: '2.25rem',
    },
    heading: {
      alignItems: 'center',
      paddingBottom: '8px',
      borderBottom: '1px solid #efefef',
    },
    headingTitle: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 600,
      paddingBottom: '5px',
      fontSize: 20,
    },
    infoBody: {
      color: theme.alert.blue.dark,
      fontSize: '14px',
      fontWeight: 400,
    },
  })
);

interface ConfigSubmit {
  value: boolean;
  url: string;
}

const AdminConsoleSecurityPanel = () => {
  const classes = useStyles();
  const { isSm } = useCampfireTheme();
  const { setSnackbar } = useSnackbar();
  const [enforceMfaAdmin, setEnforceMfaAdmin] = React.useState(false);
  const [enforceMfaPm, setEnforceMfaPm] = React.useState(false);
  const [enforceMfaAl, setEnforceMfaAl] = React.useState(false);
  const [enforceMfaVol, setEnforceMfaVol] = React.useState(false);
  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });

  const { data: mfaAdmin, refetch: mfaAdminRefetch } = useCampfireQuery<GetAdminConsoleMFAAdminSettings, undefined>(
    GET_ADMIN_CONSOLE_MFA_ADMIN_SETTINGS
  );
  const { data: mfaPM, refetch: mfaPMRefetch } = useCampfireQuery<GetAdminConsoleMFAAdminSettings, undefined>(
    GET_ADMIN_CONSOLE_MFA_PM_SETTINGS
  );
  const { data: mfaAL, refetch: mfaALRefetch } = useCampfireQuery<GetAdminConsoleMFAALSettings, undefined>(
    GET_ADMIN_CONSOLE_MFA_AL_SETTINGS
  );
  const { data: mfaVol, refetch: mfaVolRefetch } = useCampfireQuery<GetAdminConsoleMFAVolSettings, undefined>(
    GET_ADMIN_CONSOLE_MFA_VOL_SETTINGS
  );

  useEffect(() => {
    setEnforceMfaAdmin(mfaAdmin?.vm.configItem?.configItemValue?.flagValue ?? false);
    setEnforceMfaPm(mfaPM?.vm.configItem?.configItemValue?.flagValue ?? false);
    setEnforceMfaAl(mfaAL?.vm.configItem?.configItemValue?.flagValue ?? false);
    setEnforceMfaVol(mfaVol?.vm.configItem?.configItemValue?.flagValue ?? false);
  }, [mfaAdmin, mfaPM, mfaAL, mfaVol]);

  const onAdminChange = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/admin-console/security/enforce-mfa`,
        method: 'post',
        data: {
          value: !enforceMfaAdmin,
          type: 'admin',
        },
      })
      .then(() => {
        if (mfaAdminRefetch) {
          setTimeout(mfaAdminRefetch, 5);
        }
        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Settings updated',
        });
      });

    setEnforceMfaAdmin(!enforceMfaAdmin);
  };

  const onPMChange = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/admin-console/security/enforce-mfa`,
        method: 'post',
        data: {
          value: !enforceMfaPm,
          type: 'pm',
        },
      })
      .then(() => {
        if (mfaPMRefetch) {
          setTimeout(mfaPMRefetch, 5);
        }
        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Settings updated',
        });
      });

    setEnforceMfaPm(!enforceMfaPm);
  };

  const onALChange = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/admin-console/security/enforce-mfa`,
        method: 'post',
        data: {
          value: !enforceMfaAl,
          type: 'al',
        },
      })
      .then(() => {
        if (mfaALRefetch) {
          setTimeout(mfaALRefetch, 5);
        }
        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Settings updated',
        });
      });

    setEnforceMfaAl(!enforceMfaAl);
  };

  const onVolChange = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/admin-console/security/enforce-mfa`,
        method: 'post',
        data: {
          value: !enforceMfaVol,
          type: 'vol',
        },
      })
      .then(() => {
        if (mfaVolRefetch) {
          setTimeout(mfaVolRefetch, 5);
        }
        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Settings updated',
        });
      });

    setEnforceMfaVol(!enforceMfaVol);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.heading}>
        <Typography variant='h2' className={classes.headingTitle}>
          Security
        </Typography>
        <Typography variant={'body2'}>{'Manage security settings for your organisation'}</Typography>
      </Box>
      <Box style={{ marginTop: '25px' }} className={classes.contentContainer}>
        <Box
          display='flex'
          flexDirection={isSm ? 'column' : 'row'}
          alignItems={isSm ? 'left' : 'center'}
          justifyContent='space-between'
          pr='1.25rem'
          pt={isSm ? '1rem' : 0}
        >
          <Box>
            <Typography style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
              <Security style={{ fontSize: '14px', paddingRight: '5px' }} /> Multi-Factor Authentication
            </Typography>
            <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
              {'Secure user accounts with mandatory multi-factor authentication.'}
            </Typography>
            <Box marginTop='1.5rem'>
              <AlertCard variant='info' title='What is Multi-Factor Authentication?'>
                <Typography className={classes.infoBody}>
                  Multi-factor authentication (MFA) is one of the most effective ways to protect your
                  organisation&apos;s information and accounts against unauthorised access. The settings below relate to
                  enforcing login methods that require two or more proofs of identity to grant you access.
                  <br />
                  <br />
                  Whilst users can setup additional authentication at anytime, toggling the below configuration settings
                  will enforce and require an additional authentication method to be setup on the next login.
                </Typography>
              </AlertCard>
              <Box mt={2}>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Enforce MFA for:
                </Typography>
                <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080', paddingBottom: '1em' }}>
                  {'Users will be required to setup an authenticator app on their next login'}
                </Typography>
                <Box display='flex' alignItems={'center'}>
                  <Switch
                    checked={enforceMfaAdmin}
                    onChange={onAdminChange}
                    color='primary'
                    inputProps={{ 'aria-label': 'allow comments' }}
                  />
                  <Box ml={1}>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#333',
                      }}
                    >
                      Admins
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' alignItems={'center'}>
                  <Switch
                    checked={enforceMfaPm}
                    onChange={onPMChange}
                    color='primary'
                    inputProps={{ 'aria-label': 'allow comments' }}
                  />
                  <Box ml={1}>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#333',
                      }}
                    >
                      Program Managers
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' alignItems={'center'}>
                  <Switch
                    checked={enforceMfaAl}
                    onChange={onALChange}
                    color='primary'
                    inputProps={{ 'aria-label': 'allow comments' }}
                  />
                  <Box ml={1}>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#333',
                      }}
                    >
                      Activity Leaders
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex' alignItems={'center'}>
                  <Switch
                    checked={enforceMfaVol}
                    onChange={onVolChange}
                    color='primary'
                    inputProps={{ 'aria-label': 'allow comments' }}
                  />
                  <Box ml={1}>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#333',
                      }}
                    >
                      Volunteers
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { AdminConsoleSecurityPanel };
