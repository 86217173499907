import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { RosterContext } from '../bulk-roster/RosterContext';
import { useApiUrl } from '../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';
import { useSnackbar } from '../../../../global/config/useSnackbar';

export function BulkRosterReminderDialog() {
  const { activity, volunteers, showBulkReminderDialog, onBulkReminder } = React.useContext(RosterContext);
  const apiUrl = useApiUrl();
  const { setSnackbar } = useSnackbar();
  const campfireMutation = useCampfireFetch({ defer: true });

  const submitVolunteers = volunteers.map((volunteer) => {
    return volunteer.volunteerId;
  });

  const onSubmit = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity/roster/bulk-reminder`,
        method: 'post',
        data: {
          volunteerIds: submitVolunteers,
          activityId: activity?.activityId,
        },
      })
      .then(() => {
        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Bulk reminder sent',
        });
        onBulkReminder(false);
      });
  };

  return (
    <Dialog
      open={showBulkReminderDialog}
      fullWidth
      maxWidth='sm'
      onClose={() => {
        onBulkReminder(false);
      }}
      style={{ zIndex: 2000 }}
    >
      <DialogTitle style={{ padding: '1.6em 2.5em 0.15em 2.5em' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display={'flex'}>
            <Typography variant='h4' style={{ fontWeight: 500, fontSize: '1.15em' }}>
              Send Bulk Reminder
            </Typography>
          </Box>
          <IconButton onClick={() => onBulkReminder(false)}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ padding: '0 2.5em 2.5em 2.5em' }}>
        <Typography style={{ color: '#00000099', fontWeight: 400 }}>
          Remind your volunteers about all upcoming rosterings for this activity.
          <br />
          <br />
          By pressing <strong>Send</strong>, you will remind <strong>{volunteers.length} volunteers</strong> of all
          upcoming rosters.
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: '0 2.5rem 2.5rem 2.5rem' }}>
        <Button variant='text' onClick={() => onBulkReminder(false)} style={{ textTransform: 'none' }}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          onClick={() => onSubmit()}
          style={{ textTransform: 'none' }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
