import React, { ReactNode, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { useUser } from '../../global/auth/useUser';
import { User } from '../../global/auth/SessionContext';

const hideIntercom = (hide: boolean, user: User) =>
  window.analytics.identify(
    user.userId,
    {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    },
    {
      Intercom: { hideDefaultLauncher: hide },
    }
  );

export interface Props {
  close: () => void;
  title: string;
  subtitle?: string;
  fullScreen?: boolean;
  dialogActions?: () => ReactNode;
  dialogHeaderContent?: ReactNode;
  dialogHeaderOption?: ReactNode;
  hasBorderActions?: boolean;
  noPadding?: boolean;
  mobileActionButton?: boolean;
  mobileAction?: () => void;
}

export const FullscreenDialog = (props: DialogProps & Props) => {
  const {
    children,
    dialogActions,
    dialogHeaderContent,
    close,
    title,
    subtitle,
    dialogHeaderOption,
    hasBorderActions = true,
    noPadding,
    mobileActionButton,
    mobileAction,
    ...rest
  } = props;
  const { theme } = useCampfireTheme();
  const { user } = useUser();

  useEffect(() => {
    if (props.fullScreen && props.open) hideIntercom(true, user);
  }, [props.fullScreen, props.open]);

  const handleClose = () => {
    hideIntercom(false, user);
    close();
  };

  return (
    <Dialog {...rest} onBackdropClick={close}>
      <DialogTitle
        style={{
          borderBottom: `1px solid ${theme.color.grey.border}`,
          padding: `8px 24px ${dialogHeaderContent ? '0' : '8px'} 24px`,
        }}
      >
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
            {mobileActionButton && (
              <Button
                style={{ justifyContent: 'center', display: 'flex', padding: 0, margin: 0, alignItems: 'flex-start' }}
                onClick={mobileAction}
              >
                <Menu style={{ color: '#777' }} />
              </Button>
            )}
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
              <Typography variant='subtitle1' color='textSecondary'>
                {subtitle}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <Box>{dialogHeaderOption}</Box>
              <IconButton key='close' aria-label='Close' color='inherit' onClick={handleClose}>
                <CloseIcon color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        {dialogHeaderContent}
      </DialogTitle>
      <DialogContent style={noPadding ? { padding: 0 } : {}}>
        {children}
        <Grid container justify='flex-end' alignItems='flex-end'>
          <Grid item>{props.fullScreen && dialogActions ? dialogActions() : null}</Grid>
        </Grid>
      </DialogContent>
      {!props.fullScreen && dialogActions ? (
        <DialogActions
          style={{ borderTop: hasBorderActions ? `1px solid ${theme.color.grey.border}` : 'none', padding: 16 }}
        >
          {dialogActions()}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};
