import React from 'react';
import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = (variant: string) =>
  makeStyles((theme: Theme) => {
    const { color } = theme;
    const { primary } = color.background;
    let backgroundColor = color.success[500];

    switch (variant) {
      case 'success':
        break;
      case 'default':
        backgroundColor = '#999';
        break;
      case 'warning':
        // eslint-disable-next-line
        backgroundColor = primary[900];
        break;
      default:
        break;
    }

    return createStyles({
      container: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        color: '#FCFCFC',
        fontWeight: 900,
        fontSize: 10,
        backgroundColor,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      },
    });
  });

export function TaskBadge({ number, variant }: { number: number; variant: string }) {
  if (number === 0) {
    return null;
  }

  const classes = useStyles(variant)();

  return <Box className={classes.container}>{number}</Box>;
}
