import { RosteredStatus } from '../../../../activities-v2/ActivityTypes';
import { GetVolDashboardMyElementsRostersData_vm_volunteer_activityEnrolments_activity as ActivityType } from '../../__generated__/GetVolDashboardMyElementsRostersData';

export type ActivityStatusType =
  | 'unavailable'
  | 'available'
  | 'rostered'
  | 'unsure'
  | 'cancelled'
  | 'not on team'
  | 'waitlisted'
  | 'applied';
export interface RosterType extends ActivityType {
  activityDate: string;
  rosterStatus: RosteredStatus;
}

export const ACTIVITYLEGENDSTATUSES = ['unavailable', 'available', 'rostered', 'unsure', 'cancelled'];

export type MyElementsMyActivityType = {
  activityId: string;
  activityName: string;
  startTime: string;
  endTime: string;
  activityDate: string;
  activityLocation: string | null | undefined;
  hasOpenRoster: boolean;
  numSessions: number;
  programName: string;
};
