import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { EditorState, convertToRaw } from 'draft-js';
import { Add } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { EmptyRoles } from './EmptyRoles';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { ADMIN_CONSOLE_ROLE_QUERY } from './AdminConsoleRolesQuery/AdminConsoleRolesQuery.gql';
import { VOLUNTEER_ROLES_QUERY } from './VolunteerRoleListQuery/VolunteerRoleListQuery.gql';
import { GetVolunteerRoles } from './VolunteerRoleListQuery/__generated__/GetVolunteerRoles';
import { AdminConsoleRole } from './AdminConsoleRolesQuery/__generated__/AdminConsoleRole';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { TemplateSnackbar, SnackBarOptions } from '../../../../../common/snackbars/TemplateSnackbar';
import { GetAdminConsoleRoles } from './AdminConsoleRolesQuery/__generated__/GetAdminConsoleRoles';
import { RolesList } from './RolesList';
import { NewRole, ValueType } from './NewRole';
import { EditRole } from './EditRole';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      backgroundColor: '#F9F9F9',
      minHeight: 'calc(100% - 20px)',
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '8px',
      borderBottom: '1px solid #efefef',
    },
    headingTitle: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 600,
      fontSize: 20,
    },
    infoBody: {
      color: theme.alert.blue.dark,
      fontSize: '14px',
      fontWeight: 400,
    },
  })
);

type VolunteerRoleType = { [key: string]: { [key: string]: boolean } };

export function AdminConsoleRoles() {
  const classes = useStyles();
  const [snackbar, setSnackBar] = React.useState<SnackBarOptions>();
  const { data, refetch } = useCampfireQuery<GetAdminConsoleRoles, {}>(ADMIN_CONSOLE_ROLE_QUERY);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [name, setName] = React.useState('');
  const [icon, setIcon] = React.useState('');
  const [canApply, setCanApply] = React.useState<boolean>(false);
  const [showRoleDialog, setShowRoleDialog] = React.useState(false);
  const [role, setRole] = React.useState<AdminConsoleRole>();
  const apiUrl = useApiUrl();

  const { data: volunteerData, loading: getRoleLoading } = useCampfireQuery<GetVolunteerRoles, {}>(
    VOLUNTEER_ROLES_QUERY
  );

  const onChange = ({ name: newName, icon: newIcon, description, canApply: roleCanApply }: ValueType) => {
    setName(newName);
    setIcon(newIcon);
    setEditorState(description);
    setCanApply(roleCanApply);
  };

  const campfireMutation = useCampfireFetch({ defer: true });

  const onCreateNewRole = (event: any) => {
    event.preventDefault();
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity-roles/save`,
        method: 'post',
        data: {
          name,
          icon,
          description: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
          canApply,
        },
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'A new role have been created!',
        });
        setShowRoleDialog(false);
      });
  };

  const onSave = (updatingRole: any) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity-roles/save`,
        method: 'post',
        data: {
          name: updatingRole.name,
          icon: updatingRole.icon,
          description: JSON.stringify(convertToRaw(updatingRole.description.getCurrentContent())),
          activityRoleId: updatingRole.activityRoleId,
          canApply: updatingRole.canApply,
        },
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setRole(undefined);
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'Role updated!',
        });
      });
  };

  const onEdit = setRole;
  const onDelete = (deletingRole: any) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity-roles/remove`,
        method: 'post',
        data: {
          activityRoleId: deletingRole.activityRoleId,
        },
      })
      .then(() => {
        if (refetch) {
          setTimeout(refetch, 0);
        }
        setSnackBar({
          variant: 'success',
          open: true,
          message: 'Delete role successfully!',
        });
      });
  };

  const roles =
    data?.vm.activityRoles.map((activityRole) => ({
      ...activityRole,
      volunteerCount: (volunteerData?.vm.volunteers || []).filter((volunteer) =>
        volunteer.activityRoles.some((volunteerRole) => volunteerRole.activityRoleId === activityRole.activityRoleId)
      ).length,
    })) || [];

  console.log(roles);
  return (
    <Box className={classes.container}>
      <Box className={classes.heading}>
        <Typography variant='h2' className={classes.headingTitle}>
          Roles
        </Typography>
        <TabletButton endIcon={<Add />} variant='contained' color='primary' onClick={() => setShowRoleDialog(true)}>
          Add New Role
        </TabletButton>
      </Box>
      {roles?.length ? (
        <RolesList roles={roles} onEdit={onEdit} onDelete={onDelete} isLoading={getRoleLoading} />
      ) : (
        <EmptyRoles />
      )}
      {showRoleDialog && (
        <NewRole
          close={() => setShowRoleDialog(false)}
          value={{ name, icon, description: editorState, canApply }}
          onChange={onChange}
          onCancel={() => setShowRoleDialog(false)}
          onSave={onCreateNewRole}
        />
      )}
      {role && (
        <EditRole role={role} onCancel={() => setRole(undefined)} close={() => setRole(undefined)} onSave={onSave} />
      )}
      {snackbar && <TemplateSnackbar {...snackbar} onClose={() => setSnackBar(undefined)} />}
    </Box>
  );
}
