import React, { useState } from 'react';
import { Box, Typography, Theme, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { ActivityHeader } from './ActivityHeader';
import { VolunteerProfile } from '../../../../common/AvatarName';
import { PublishedRoster_activeRosterings as ActiveRosterings, PublishedRoster } from './__generated__/PublishedRoster';
import { ActivityTeamInformation as ActivityEnrolment } from './__generated__/ActivityTeamInformation';
import { sortVolunteer } from '../ActivityUtils';
import { sharedStyles } from '../ActivityStyles/shared';
import { GetActivityDetails_vm_activity_publishedRoster_activeRosterings_sessionRosterings as SessionRosterings } from '../ActivityQueries/__generated__/GetActivityDetails';
import { GET_ADMIN_CONSOLE_OPS_SESSION_VISIBILITY } from '../../../admin/admin-console/admin-console-content-pages/admin-console-operations/admin-console-operations.gql';
import { GetAdminConsoleOpsSessionVisibility } from '../../../admin/admin-console/admin-console-content-pages/admin-console-operations/__generated__/GetAdminConsoleOpsSessionVisibility';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';

const useTeamStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.color.grey.neutral400,
    },
    text: {
      textAlign: 'center',
      color: theme.color.grey.neutral400,
      fontSize: '13px',
      paddingTop: '12px',
    },
  })
);

interface ActivityMemberType {
  sessions?: SessionRosterings[];
}

function ActivityMember(props: (ActiveRosterings | ActivityEnrolment) & ActivityMemberType) {
  const {
    volunteer: {
      profile: { firstName, lastName, avatarUrl, profileId, userId },
    },
    sessions,
  } = props;
  const displayName = `${firstName} ${lastName}`;
  const sessionsCompressed = sessions?.length === 1 ? sessions[0].session.name : `${sessions?.length} sessions`;
  const [extraInfo, setExtraInfo] = useState<boolean>(false);
  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        paddingTop='0.75rem'
        paddingBottom='0.75rem'
      >
        <VolunteerProfile
          avatarUrl={avatarUrl}
          name={displayName}
          noTrim
          profileId={profileId}
          userId={userId}
          lastName={lastName}
          preferredName={firstName}
        />
        {sessions && (
          <Box display='flex' alignItems='center' style={{ gap: '5px' }}>
            {sessionsCompressed ? (
              <Box
                style={{
                  border: '1px solid transparent',
                  boxSizing: 'border-box',
                  backgroundColor: '#ecebeb',
                  borderRadius: '4px',
                  padding: '5px 10px',
                  fontWeight: 500,
                  fontSize: '12px',
                  color: '#646464',
                }}
              >
                {sessionsCompressed}
              </Box>
            ) : (
              ''
            )}
            <Box onClick={() => setExtraInfo(!extraInfo)}>
              {!extraInfo ? <ExpandMore style={{ color: '#646464' }} /> : <ExpandLess style={{ color: '#646464' }} />}
            </Box>
          </Box>
        )}
      </Box>
      {sessions && (
        <Box>
          {extraInfo ? (
            <Box marginBottom={'1em'}>
              {sessions?.map((session) => {
                return (
                  <Box style={{ padding: '.25em 1em .5em 1em', marginLeft: '1em', borderLeft: '.5px solid #444' }}>
                    <Typography style={{ fontWeight: 500, fontSize: '11px', color: '#696969' }}>Session</Typography>
                    <Typography style={{ fontWeight: 700, fontSize: '14px', color: '#444' }}>
                      {session.session.name}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
}
interface ActivityRosteredInformationProps {
  isErolled: boolean;
  publishedRoster: PublishedRoster | null;
}

export function ActivityRosteredInformation({ publishedRoster, isErolled }: ActivityRosteredInformationProps) {
  const teams = (publishedRoster?.activeRosterings || []).sort(sortVolunteer);
  const [showFull, setShowFull] = React.useState(false);
  const { data: opsSessionVisibility } = useCampfireQuery<GetAdminConsoleOpsSessionVisibility, undefined>(
    GET_ADMIN_CONSOLE_OPS_SESSION_VISIBILITY
  );

  const showingTeams = showFull ? teams : teams.slice(0, 3);
  const buttonClasses = sharedStyles.button();
  const classes = useTeamStyles();

  if (!isErolled) {
    return null;
  }

  if (!publishedRoster) {
    return (
      <Box marginTop='2rem'>
        <ActivityHeader title='Rostered Team' />
        <Typography className={classes.text}>No published roster for this date</Typography>
      </Box>
    );
  }

  if (teams.length === 0) {
    return (
      <Box marginTop='2rem'>
        <ActivityHeader title='Rostered Team' />
        <Typography className={classes.text}>No volunteers rostered for this date</Typography>
      </Box>
    );
  }

  return (
    <Box marginTop='2rem'>
      <ActivityHeader title='Rostered Team' />
      <Box>
        {showingTeams?.map((activityMember) => (
          <ActivityMember
            key={activityMember.volunteer.volunteerId}
            {...activityMember}
            sessions={
              opsSessionVisibility?.vm.configItem?.configItemValue?.flagValue
                ? activityMember.sessionRosterings
                : undefined
            }
          />
        ))}
      </Box>
      {teams.length > 3 && (
        <Box display='flex' justifyContent='center' marginTop='12px'>
          {showFull ? (
            <Button
              variant='outlined'
              onClick={() => setShowFull(false)}
              endIcon={<ExpandLess />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show less
            </Button>
          ) : (
            <Button
              variant='outlined'
              onClick={() => setShowFull(true)}
              endIcon={<ExpandMore />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show more
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}

interface ActivityTeamInformationProps {
  isErolled: boolean;
  activityEnrolments: ActivityEnrolment[];
}

export function ActivityTeamInformation({ isErolled, activityEnrolments }: ActivityTeamInformationProps) {
  const teams = (activityEnrolments || []).sort(sortVolunteer);

  const [showFull, setShowFull] = React.useState(false);
  const showingTeams = showFull ? teams : teams.slice(0, 3);
  const buttonClasses = sharedStyles.button();
  const classes = useTeamStyles();

  if (!isErolled) {
    return null;
  }

  return (
    <Box marginTop='2rem'>
      <ActivityHeader title='Team Information' />
      <Box>
        {showingTeams?.map((activityMember) => (
          <ActivityMember key={activityMember.volunteer.volunteerId} {...activityMember} />
        ))}
      </Box>
      {teams.length > 3 && (
        <Box display='flex' justifyContent='center' marginTop='12px'>
          {showFull ? (
            <Button
              variant='outlined'
              onClick={() => setShowFull(false)}
              endIcon={<ExpandLess />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show less
            </Button>
          ) : (
            <Button
              variant='outlined'
              onClick={() => setShowFull(true)}
              endIcon={<ExpandMore />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show more
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
