import { gql } from '@apollo/client';

export const GET_ADMIN_CONSOLE_MFA_ADMIN_SETTINGS = gql `
  query GetAdminConsoleMFAAdminSettings {
    vm {
      configItem(key: "enforce-mfa-admin") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;

export const GET_ADMIN_CONSOLE_MFA_PM_SETTINGS = gql `
  query GetAdminConsoleMFAPmSettings {
    vm {
      configItem(key: "enforce-mfa-pm") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;

export const GET_ADMIN_CONSOLE_MFA_AL_SETTINGS = gql `
  query GetAdminConsoleMFAALSettings {
    vm {
      configItem(key: "enforce-mfa-al") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;

export const GET_ADMIN_CONSOLE_MFA_VOL_SETTINGS = gql `
  query GetAdminConsoleMFAVolSettings {
    vm {
      configItem(key: "enforce-mfa-vol") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;


