import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../../../../common/recurrence/recurrence-model.gql';

const INCOMING_VOLUNTEER_TASK_FRAGMENT = gql`
  fragment IncomingVolunteerTask on VOLUNTEER_ApplicantTaskType {
    taskId
    title
    description
    order
    adminOnly
    roles {
      activityRoleId
      name
      icon
    }
    cake {
      cakeId
      cakeType
    }
    taskItems {
      ... on VOLUNTEER_TaskItemHeadingType {
        taskItemId
      }
      ... on VOLUNTEER_TaskItemContentType {
        taskItemId
      }
      ... on VOLUNTEER_TaskItemFieldType {
        taskItemId
        optional
        field {
          taskFieldId
        }
      }
    }
    dateRemoved
    dateLastUpdated
    automateApproval
  }
`;

const INCOMING_VOLUNTEER_INVITEE_DETAIL_FRAGMENT = gql`
  fragment IncomingVolunteerInviteeDetail on VOLUNTEER_VolunteerInviteeType {
    __typename
    inviteeId
    email
    dateInvited
    dateRevoked
    invitedBy {
      profile {
        profileId
        preferredName
        lastName
        email
      }
    }
    dateAccepted
  }
`;

const INCOMING_VOLUNTEER_APPLICATION_DETAIL_FRAGMENT = gql`
  fragment IncomingVolunteerApplicationDetail on VOLUNTEER_ApplicationType {
    applicationId
    profile {
      profileId
      userId
    }
    status
    name
    email
    dateSubmitted
    mostRecentReminder {
      applicationReminderId
      remindedAt
    }
    relevantTasks {
      ...IncomingVolunteerTask
    }
    applicationRoles {
      applicationRoleId
      role {
        activityRoleId
        name
        icon
        description
        canApply
      }
      status
      dateActioned
      actionedBy {
        profile {
          profileId
          firstName
          preferredName
          lastName
        }
      }
    }
    applicationActivities {
      applicationActivityId
      activity {
        activityId
        name
      }
      status
      dateActioned
      actionedBy {
        profile {
          profileId
          firstName
          preferredName
          lastName
        }
      }
    }
    screeningAnswers {
      screeningAnswerId
      screeningQuestion {
        screeningQuestionId
        label
        order
        correctAnswer
      }
      answer
      isCorrect
    }
    invitee {
      ...IncomingVolunteerInviteeDetail
    }
  }
  ${INCOMING_VOLUNTEER_INVITEE_DETAIL_FRAGMENT}
  ${INCOMING_VOLUNTEER_TASK_FRAGMENT}
`;

const INCOMING_VOLUNTEER_COMPLETED_TASK_FRAGMENT = gql`
  fragment IncomingVolunteerCompletedTask on VOLUNTEER_CompletedTaskType {
    completedTaskId
    status
    dateLastUpdated
    task {
      __typename
      taskId
      cake {
        cakeType
      }
      dateLastUpdated
      dateRemoved
    }
  }
`;

export const GET_INCOMING_VOLUNTEER_PROFILE = gql`
  query GetIncomingVolunteerProfile($profileId: String!, $from: Date, $until: Date) {
    vm {
      profile(profileId: $profileId) {
        profileId
        userId
        preferredName
        lastName
        email
        isCompleted
        dateRemoved
        isProfileTaskApproved
        percentageComplete
        dateLastUpdated
        completedTasks {
          ...IncomingVolunteerCompletedTask
        }
        application {
          applicationId
    profile {
      profileId
      userId
    }
    status
    name
    email
    dateSubmitted
    mostRecentReminder {
      applicationReminderId
      remindedAt
    }
    relevantTasks {
      ...IncomingVolunteerTask
    }
    applicationRoles {
      applicationRoleId
      role {
        activityRoleId
        name
        icon
        description
        canApply
      }
      status
      dateActioned
      actionedBy {
        profile {
          profileId
          firstName
          preferredName
          lastName
        }
      }
    }
    applicationActivities {
      applicationActivityId
      activity {
        __typename
        activityId
        name
        startTime
        endTime
        startDate
        endDate
        description
        program {
          programId
          name
          allowCICO
        }
        sessions {
          sessionId
          name
          description
          minVolunteers
          maxVolunteers
          startTime
          endTime
          dateRemoved
        }
          nextX(x: 1)
        occurrencesBetween(from: $from, until: $until)
        activityLocation {
          activityLocationId
          placesAddress {
            placesId
            description
            formatted
            latLong {
              lat
              long
            }
          }
        }
        activityRemoteLocation {
          activityRemoteLocationId
          details
        }
        ... on VOLUNTEER_RecurringActivityType {
          schedule {
            recurrences {
              ...Recurrence
            }
          }
        }
        rosterTemplates {
          rosterDate
          placesAddress {
            formatted
          }
        }
        sessions {
          sessionId
          name
          description
          minVolunteers
          maxVolunteers
          startTime
          endTime
          dateRemoved
        }
      }
      status
      dateActioned
      availabilitySessions
      availabilityDates
      actionedBy {
        profile {
          profileId
          firstName
          preferredName
          lastName
        }
      }
    }
    screeningAnswers {
      screeningAnswerId
      screeningQuestion {
        screeningQuestionId
        label
        order
        correctAnswer
      }
      answer
      isCorrect
    }
    invitee {
      ...IncomingVolunteerInviteeDetail
    }
        }
      }
    }
  }
  ${INCOMING_VOLUNTEER_COMPLETED_TASK_FRAGMENT}
  ${INCOMING_VOLUNTEER_APPLICATION_DETAIL_FRAGMENT}
  ${RECURRENCE_FRAGMENT}
`;

export const GET_INCOMING_VOLUNTEER_ALL_TASKS = gql`
  query GetIncomingVolunteerAllTasks($userId: String!) {
    vm {
      application(userId: $userId) {
        relevantTasks {
          ...IncomingVolunteerTask
        }
      }
    }
  }

  ${INCOMING_VOLUNTEER_TASK_FRAGMENT}
`;

export const GET_INCOMING_VOLUNTEER_INVITEE_DETAIL = gql`
  query GetIncomingVolunteerInviteeDetail($inviteeId: String!) {
    vm {
      invitee(inviteeId: $inviteeId) {
        ...IncomingVolunteerInviteeDetail
      }
    }
  }

  ${INCOMING_VOLUNTEER_INVITEE_DETAIL_FRAGMENT}
`;

export const GET_INCOMING_VOLUNTEER_APPLICATION_DETAIL = gql`
  query GetIncomingVolunteerApplicationDetail($applicationId: String!) {
    vm {
      application(applicationId: $applicationId) {
        ...IncomingVolunteerApplicationDetail
      }
    }
  }

  ${INCOMING_VOLUNTEER_APPLICATION_DETAIL_FRAGMENT}
`;

export type LeftMenuItemType =
  | {
      inviteeId: string;
      type: 'VOLUNTEER_VolunteerInviteeType';
    }
  | { applicationId: string; type: 'VOLUNTEER_ApplicationType' }
  | {
      taskId: string;
      type: 'VOLUNTEER_TaskType' | 'VOLUNTEER_PermissionlessTaskType' | 'VOLUNTEER_ApplicantTaskType';
      completedTaskId?: string;
    }
  | {
    applicationRoleId: string;
    type: 'VOLUNTEER_ApplicationRoleType';
  }
  | {
    applicationActivityId: string;
    type: 'VOLUNTEER_ApplicationActivityType';
  } | {
    type: 'VOLUNTEER_SimpleAvailabilityType';
  };
