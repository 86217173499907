import { gql } from '@apollo/client';

export const ACTIVITIES_FILTERS_LIST = gql`
  query GetAllActivitiesForVolunteer($programIds: [String!]) {
    vm {
        programs(programIds: $programIds) {
          programId
          name
          activities(includeInactiveActivities: false) {
            activityId
            name
            startTime
            endTime
            startDate
            endDate
            program {
              programId
              name
            }
          }
        }
    }
  }
`;
