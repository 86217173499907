import { gql } from '@apollo/client';

export const SIMPLE_AVAILABILITY_QUERY = gql`
  query SimpleAvailabilityQuery($profileId: String!) {
    vm {
      profile(profileId: $profileId) {
        simpleAvailability {
            simpleAvailabilityId
            mondayAm
            mondayPm
            tuesdayAm
            tuesdayPm
            wednesdayAm
            wednesdayPm
            thursdayAm
            thursdayPm
            fridayAm
            fridayPm
            saturdayAm
            saturdayPm
            sundayAm
            sundayPm
            period
            notes
        }
      }
    }
  }
`;
