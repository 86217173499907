import { areSameDate, unpackToDate } from '@campfire/hot-date';
import { HoverLink } from '@campfire/hover-link';
import { ArrowPopper } from '@campfire/popper';
import { Box, ButtonBase, Grid, Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CalendarToday, ArrowForward } from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useState, useMemo } from 'react';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { VolunteerCommonProfileActivityAvailability } from '../../__generated__/VolunteerCommonProfileActivityAvailability';
import { VolunteerCommonProfileActivityEnrolment } from '../../__generated__/VolunteerCommonProfileActivityEnrolment';
import { VolunteerCommonProfileActivityUnavailability } from '../../__generated__/VolunteerCommonProfileActivityUnavailability';
import { useVolunteerCommonProfileContext } from '../../VolunteerCommonProfileContext';
import { RosterStatus } from '../../../rosters/main-section/roster/roster-types';
import { getRosterStatus } from './activity-enrolment-section-func';

type ActivityRosterForecast = {
  date: string;
  status: 'rostered' | 'unavailable' | 'available' | 'unsure' | 'cancelled';
  rosterStatus: RosterStatus;
  activityId: string;
  activityRoles: Array<{
    activityRoleId: string;
    name: string;
  }>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datesContainer: {
      background: theme.color.grey.neutralBrand100,
      borderRadius: 4,
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
    },
    dateGridItem: {
      background: theme.color.white.neutral50,
      padding: '6px',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      '&:hover': {
        background: theme.palette.grey[200],
      },
    },
  })
);

interface EnrolmentForecastProps {
  volunteerId: string;
  activityEnrolment: VolunteerCommonProfileActivityEnrolment;
  isLargeDialog?: true;
}

const EnrolmentForecast = React.memo((props: EnrolmentForecastProps) => {
  const { volunteerId, activityEnrolment, isLargeDialog } = props;
  const { activity } = activityEnrolment;
  const { publishedRosters, activityId } = activity;
  const {
    volunteerNameOptions: { preferredName },
  } = useVolunteerCommonProfileContext();
  const { theme } = useCampfireTheme();
  const classes = useStyles();

  const [activitiesForecast, setActivitiesForecast] = useState<ActivityRosterForecast[]>([]);
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorElement, setAnchorElement] = useState<EventTarget & HTMLButtonElement>();
  const [selectedActivity, setSelectedActivity] = useState<ActivityRosterForecast>();

  useEffect(() => {
    if (!volunteerId) return;
    const forecast: Array<ActivityRosterForecast> = activity.nextX.map((x) => {
      const rosterStatus = getRosterStatus(activity, x);
      const availableStatus = activityEnrolment.availabilities.find(
        (availability: VolunteerCommonProfileActivityAvailability) =>
          areSameDate(unpackToDate(availability.activityDate), unpackToDate(x))
      );

      const unavailableStatus = activityEnrolment.unavailabilities.find(
        (unavailability: VolunteerCommonProfileActivityUnavailability) =>
          areSameDate(unpackToDate(unavailability.activityDate), unpackToDate(x))
      );

      const targetRoster = publishedRosters.find((roster) =>
        areSameDate(unpackToDate(roster.activityDate), unpackToDate(x))
      );

      const isRostered =
        targetRoster && !!targetRoster.rosterings.find((rostering) => rostering.volunteer.volunteerId === volunteerId);

      const wasRostered = targetRoster && !!targetRoster.rosterings.find((rostering) => rostering.dateRemoved);

      const activityRoles = availableStatus ? availableStatus.activityRoles : [];

      const availabilityStatus = wasRostered
        ? 'unsure'
        : isRostered
        ? 'rostered'
        : unavailableStatus
        ? 'unavailable'
        : availableStatus
        ? 'available'
        : 'unsure';

      return {
        date: x,
        status: availabilityStatus,
        rosterStatus,
        activityId: activityId,
        activityRoles: activityRoles,
      };
    });

    setActivitiesForecast(forecast);
  }, [activityEnrolment, volunteerId, activity]);

  const linkUrl = useMemo(() => {
    if (selectedActivity) {
      const diffDays = DateTime.fromISO(selectedActivity.date)
        .diffNow(['days'])
        .toObject().days;
      const endDateParam = +Math.abs(diffDays || 0) > 29 ? `&endDate=${selectedActivity.date}` : '';
      return `/management/rosters?activityDate=${selectedActivity.date}&activityId=${activityId}&programId=all${endDateParam}`;
    }
    return '';
  }, [selectedActivity, activityId]);

  const visiableActivitiesForecast =
    window.innerWidth < 700 && !isLargeDialog ? activitiesForecast.slice(0, 6) : activitiesForecast;

  return volunteerId ? (
    <Box
      className={classes.datesContainer}
      padding={isLargeDialog ? '16px 8px' : '12px 8px 8px'}
      justifyContent={window.innerWidth < 700 && !isLargeDialog ? 'center' : 'unset'}
    >
      {visiableActivitiesForecast.map((activityForecast: ActivityRosterForecast) => {
        const color = theme.color.rosters.status[activityForecast.status];

        return (
          <Box key={activityForecast.date} margin='4px'>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Tooltip
                title={unpackToDate(activityForecast.date).toLocaleString(DateTime.DATE_FULL)}
                placement='top-start'
              >
                <ButtonBase
                  focusRipple
                  className={classes.dateGridItem}
                  data-track='fs-volPrf-enrolment-roster-date-item'
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedActivity(activityForecast);
                    setOpenPopper(true);
                    setAnchorElement(e.currentTarget);
                  }}
                  style={{
                    backgroundColor:
                      selectedActivity && activityForecast.date === selectedActivity.date
                        ? theme.palette.grey[300]
                        : undefined,

                    borderBottom: `8px solid ${color.primary}`,
                    minWidth: isLargeDialog ? '100px' : '87px',
                  }}
                >
                  <Typography variant='body2' style={{ fontSize: isLargeDialog ? '11px' : '10px', fontWeight: 700 }}>
                    {unpackToDate(activityForecast.date).toLocaleString(DateTime.DATE_MED)}
                  </Typography>
                </ButtonBase>
              </Tooltip>
            </Box>
          </Box>
        );
      })}

      {selectedActivity && (
        <ArrowPopper
          open={openPopper}
          close={() => {
            setOpenPopper(false);
            setSelectedActivity(undefined);
          }}
          anchorElement={anchorElement}
          placement='bottom'
        >
          <Grid container style={{ padding: 8 }}>
            <Grid item container direction='column'>
              <Grid item>
                <Typography variant='caption' color='textPrimary'>
                  {selectedActivity.status === 'rostered'
                    ? `${preferredName} is rostered onto this date`
                    : selectedActivity.status === 'available'
                    ? `${preferredName} is available for this date`
                    : selectedActivity.status === 'unavailable'
                    ? `${preferredName} is unavailable for this date`
                    : `${preferredName} has not indicated availability yet`}
                </Typography>
              </Grid>
              <HoverLink color='primary' to={linkUrl}>
                <Grid item style={{ marginTop: '4px' }}>
                  <ButtonBase style={{ padding: 0 }}>
                    <CalendarToday style={{ marginRight: '4px', fontSize: '16px' }} />
                    <Typography style={{ fontSize: '11px', fontWeight: 700 }}>{`View Roster`}</Typography>
                    <ArrowForward style={{ marginLeft: '4px', fontSize: '16px' }} />
                  </ButtonBase>
                </Grid>
              </HoverLink>
            </Grid>
          </Grid>
        </ArrowPopper>
      )}
    </Box>
  ) : null;
});

export { EnrolmentForecast };
