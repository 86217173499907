import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Assignment } from '@material-ui/icons';
import { useBetween } from 'use-between';
import { VolunteerTaskAddDialog } from './VolunteerTaskAddDialog';
import { VolunteerTaskItem } from './VolunteerTaskItem';
import { useVolunteerCommonProfileContext } from '../../VolunteerCommonProfileContext';

export interface AdditionalTask {
  taskId: string;
  title: string;
  adminOnly: boolean;
  cake: {
    cakeType: string;
  };
}

interface VolunteerTasksSectionProps {
  tasks: Array<AdditionalTask>;
  userId: string;
  profileId: string;
  refetch?: () => void;
}

const useVolunteerAdditionalTasksSectionState = () => {
  const [selectedAdditionalTask, setSelectedAdditionalTask] = useState<AdditionalTask | undefined>();
  const [refetchAdditionalTasks, setRefetchAdditionalTasks] = useState<boolean>(false);

  return {
    refetchAdditionalTasks,
    setRefetchAdditionalTasks,
    selectedAdditionalTask,
    setSelectedAdditionalTask,
  };
};

export const useSharedVolunteerAdditionalTasksSectionState = () => useBetween(useVolunteerAdditionalTasksSectionState);

export const VolunteerAdditionalTasksSection = (props: VolunteerTasksSectionProps) => {
  const { tasks, userId, profileId, refetch } = props;
  const { selectedAdditionalTask, setSelectedAdditionalTask } = useSharedVolunteerAdditionalTasksSectionState();
  const {
    volunteerNameOptions: { preferredName },
  } = useVolunteerCommonProfileContext();

  return (
    <Grid container spacing={2} direction='column' style={{ position: 'relative' }}>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <Typography style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center', color: '#333' }}>
          <Assignment style={{ fontSize: '14px', paddingRight: '5px' }} /> Incomplete Tasks
        </Typography>
        <Typography variant='body2' style={{ color: '#464646' }} align='left'>
          Tasks that {preferredName} has not yet completed
          <hr style={{ borderTop: '.5px solid #e7e7e7', margin: '20px 0' }} />
        </Typography>
        {tasks.map((task) => (
          <VolunteerTaskItem task={task} onClick={() => setSelectedAdditionalTask(task)} key={task.taskId} />
        ))}
        {tasks.length === 0 && (
          <Typography
            variant='body2'
            style={{ fontSize: '13px', color: '#494949', textAlign: 'center', padding: '2rem 0' }}
          >
            No results found
          </Typography>
        )}
      </Grid>

      {selectedAdditionalTask ? (
        <VolunteerTaskAddDialog
          open
          onClose={() => setSelectedAdditionalTask(undefined)}
          taskId={selectedAdditionalTask.taskId}
          userId={userId}
          profileId={profileId}
          refetch={refetch}
        />
      ) : null}
    </Grid>
  );
};
