import { Pane } from '@campfire/pane';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';
import { ChevronRight, Close, EmojiPeople, ExitToApp, ExpandMore } from '@material-ui/icons';
import { convertFromRaw, EditorState } from 'draft-js';
import {
  Box,
  Button,
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { Cake, CakeContent } from '@campfire/cake';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { INCOMING_ROLE_APPLY_QUERY } from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-roles/AdminConsoleRolesQuery/AdminConsoleRolesQuery.gql';
import {
  GetIncomingRoleApply,
  GetIncomingRoleApply_vm_activityRoles as RoleItem,
} from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-roles/AdminConsoleRolesQuery/__generated__/GetIncomingRoleApply';
import { RoleIcon } from '../../common/icons/RoleIconList';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { GET_ALL_APPLICANT_ROLES } from './model/applicant-role-application-query.gql';
import {
  GetAllApplicantRoles,
  GetAllApplicantRoles_vm_application_applicationRoles as AppliedRole,
  GetAllApplicantRolesVariables,
} from './model/__generated__/GetAllApplicantRoles';
import { useApiUrl } from '../config/useApiUrl';
import { useCampfireQuery } from '../network/useCampfireQuery';
import { useCampfireFetch } from '../network/useCampfireFetch';
import { useSnackbar } from '../config/useSnackbar';
import { useUser } from '../auth/useUser';

const useTaskListItemClasses = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      '&:hover': {
        cursor: 'pointer',
      },
      border: '.6px solid #00000010',
      padding: '15px 30px',
    },
    checkIcon: {
      fontSize: 29,
      color: '#00ed7e',
    },
    circleIcon: {
      fontSize: 29,
      color: theme.palette.grey[200],
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      padding: '0.3rem',
      borderRadius: '4px',
      cursor: 'pointer',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.75,
      },
    },
    menuItemText: {
      marginLeft: '0.75rem',
      fontSize: '14px',
    },
    tooltip: {
      borderRadius: '12px',
      paddingTop: '0.5rem',
      paddingLeft: '.7rem',
      paddingRight: '.7rem',
      paddingBottom: '0.5rem',
      backgroundColor: theme.color.tooltip.background,
    },
    tooltipArrow: {
      '&::before': {
        backgroundColor: theme.color.tooltip.background,
      },
    },
  })
);

const SUYPRoleApplication = () => {
  const { user } = useUser();
  const history = useHistory();
  const { data } = useCampfireQuery<GetIncomingRoleApply, {}>(INCOMING_ROLE_APPLY_QUERY);
  const { data: appliedRoleData, refetch, loading } = useCampfireQuery<
    GetAllApplicantRoles,
    GetAllApplicantRolesVariables
  >(GET_ALL_APPLICANT_ROLES, {
    options: {
      variables: {
        userId: user.userId,
      },
    },
  });

  const appliedRoles = appliedRoleData?.vm.application?.applicationRoles;
  const appliableRoles = data?.vm.activityRoles;

  return (
    <Pane style={{ width: '100%', height: '100%', backgroundColor: '#f1f1f1' }}>
      <Box
        overflow='hidden auto'
        bgcolor='#f1f1f1'
        display='flex'
        flex='1 1 auto'
        flexDirection='column'
        width={1}
        paddingTop={2}
        paddingBottom={8}
        paddingX={1}
        boxSizing='border-box'
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Box width={1} display='flex' justifyContent='center'>
          <Box width={1} maxWidth={720} marginBottom={3}>
            <Cake>
              <Box style={{ padding: '20px 30px 10px 30px' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '19px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#444',
                    }}
                  >
                    <EmojiPeople style={{ fontSize: '22px', paddingRight: '5px' }} /> Roles
                  </Typography>
                  <Button onClick={() => history.push('/')}>
                    <Close style={{ color: '#666' }} />
                  </Button>
                </Box>
                <Typography variant={'body2'} style={{ fontSize: '13px', color: '#808080' }}>
                  The roles below are available for you to apply to. <br />
                  <br />
                  Once you have applied to a role, return to the previous screen to complete any additional tasks that
                  may be required. You may return to this screen anytime to apply to additional roles or revoke any
                  applications.
                  <br />
                  <br />
                  You organisation will approve applications when they review your profile.
                </Typography>
              </Box>
              <CakeContent style={{ padding: '8px 0px 0px 0px' }}>
                {!loading ? (
                  <List style={{ paddingBottom: '0' }}>
                    {appliableRoles?.map((role) =>
                      appliedRoles?.length === 0 ? (
                        <RoleListItem role={role} refetch={refetch} />
                      ) : (
                        appliedRoles && (
                          <RoleListItem
                            role={role}
                            appliedRole={appliedRoles.find(
                              (aRole) => aRole.role.activityRoleId === role.activityRoleId
                            )}
                            refetch={refetch}
                          />
                        )
                      )
                    )}
                  </List>
                ) : (
                  <Box padding='1.5em'>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </Box>
                )}
              </CakeContent>
            </Cake>
          </Box>
        </Box>
      </Box>
    </Pane>
  );
};

interface RoleProps {
  role: RoleItem;
  appliedRole?: AppliedRole;
  refetch: () => void;
}

const RoleListItem = (props: RoleProps) => {
  const { user } = useUser();
  const taskListItemClasses = useTaskListItemClasses();
  const classes = useStyles();
  const { theme } = useCampfireTheme();
  const { role, appliedRole, refetch } = props;
  const editorState = React.useMemo(
    () => (role.description ? EditorState.createWithContent(convertFromRaw(JSON.parse(role.description))) : null),
    [role.description]
  );
  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  const { setSnackbar } = useSnackbar();
  const [withdrawOpen, setWithdrawOpen] = useState<boolean>(false);

  const applicationStatus = () => {
    if (appliedRole?.status === 'pending') {
      return 'unsure';
    }
    if (appliedRole?.status === 'approved') {
      return 'rostered';
    }
    if (appliedRole?.status === 'rejected') {
      return 'rejected';
    }
    return 'available';
  };

  const applicationStatusDesc = () => {
    if (appliedRole?.status === 'pending') {
      return 'Pending';
    }
    if (appliedRole?.status === 'approved') {
      return 'Approved';
    }
    if (appliedRole?.status === 'rejected') {
      return 'Rejected';
    }
    return 'Apply';
  };

  const applicationStatusIcon = () => {
    if (appliedRole?.status === 'pending') {
      return <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
    }
    if (appliedRole?.status === 'approved') {
      return <></>;
    }
    if (appliedRole?.status === 'rejected') {
      return <></>;
    }
    return <ChevronRight style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
  };

  const onRoleApply = (userId: string, activityRoleId: string) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity-roles/apply`,
        method: 'post',
        data: {
          activityRoleId: activityRoleId,
          userId: userId,
        },
      })
      .then((response) => {
        refetch();
        if (response.ok) {
          setSnackbar({
            variant: 'success',
            open: true,
            message: 'Applied',
          });
        } else {
          setSnackbar({
            variant: 'error',
            open: true,
            message: 'Error when applying',
          });
        }
      });
  };

  const onRoleWithdraw = (userId: string, activityRoleId: string) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity-roles/withdraw`,
        method: 'post',
        data: {
          applicationRoleId: activityRoleId,
          userId: userId,
        },
      })
      .then((response) => {
        refetch();
        if (response.ok) {
          setSnackbar({
            variant: 'success',
            open: true,
            message: 'Withdrawn role application',
          });
        } else {
          setSnackbar({
            variant: 'error',
            open: true,
            message: 'Error when withdrawing',
          });
        }
      });
  };

  const statusColor = applicationStatus();
  return (
    <ListItem className={taskListItemClasses.listItem}>
      <ListItemText>
        <Typography style={{ fontWeight: 600, fontSize: '15px', display: 'flex', alignItems: 'center', color: '#444' }}>
          {role?.icon ? (
            <Box
              style={{
                width: '1.125rem',
                height: '1.125rem',
                display: 'flex',
                alignItems: 'center',
                marginRight: '0.5rem',
              }}
            >
              <RoleIcon iconName={role.icon} color={theme.color.grey.neutral300} />
            </Box>
          ) : (
            ''
          )}
          {role.name}
        </Typography>
        <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
          {editorState && <CampfireRichEditor readOnly editorState={editorState} />}
        </Typography>
      </ListItemText>
      <ListItemIcon style={{ paddingLeft: '15px' }}>
        <ClickAwayListener onClickAway={() => setWithdrawOpen(false)}>
          <Tooltip
            arrow
            interactive
            title={
              <Box>
                <Box
                  className={classes.menuItem}
                  onClick={() => (appliedRole ? onRoleWithdraw(user.userId, appliedRole.applicationRoleId) : undefined)}
                >
                  <ExitToApp fontSize='small' />
                  <Typography className={classes.menuItemText}>Withdraw</Typography>
                </Box>
              </Box>
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement='bottom-end'
            open={withdrawOpen}
            onClose={() => setWithdrawOpen(false)}
            onOpen={() => setWithdrawOpen(true)}
            classes={{
              tooltip: classes.tooltip,
              arrow: classes.tooltipArrow,
            }}
          >
            <TabletButton
              data-track='resources-add-new-menu-button'
              variant='outlined'
              color='primary'
              aria-controls='add-new'
              aria-haspopup='true'
              disabled={appliedRole?.status === 'rejected' || appliedRole?.status === 'approved'}
              onClick={() =>
                appliedRole?.status === 'pending'
                  ? setWithdrawOpen(true)
                  : appliedRole?.status === 'rejected' || appliedRole?.status === 'approved'
                  ? null
                  : onRoleApply(user.userId, role.activityRoleId)
              }
              endIcon={applicationStatusIcon()}
              style={{ padding: '8px 12px', border: `1px solid #DCDCDC`, whiteSpace: 'nowrap' }}
            >
              <Box display='flex' alignItems='center'>
                <Box
                  height='10px'
                  width='10px'
                  borderRadius='50%'
                  marginRight='8px'
                  bgcolor={theme.color.rosters.status[statusColor].primary}
                />
                <Typography
                  variant='caption'
                  style={{
                    fontSize: '12px',
                    fontWeight: 700,
                    color: theme.color.grey.neutral400,
                    textTransform: 'uppercase',
                    lineHeight: '14px',
                    marginTop: '1px',
                  }}
                >
                  {applicationStatusDesc()}
                </Typography>
              </Box>
            </TabletButton>
          </Tooltip>
        </ClickAwayListener>
      </ListItemIcon>
    </ListItem>
  );
};

export { SUYPRoleApplication };
