import {
  Dialog,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Select } from '@campfire/select';
import { encodeDate } from '@campfire/hot-date';
import { CheckCircle, Close as CloseIcon, Info, RadioButtonUnchecked } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { date as YupDate, object as YupObject, string as YupString } from 'yup';
import { Form, Formik } from 'formik';
import { Field } from '@campfire/field';
import { DatePickerField } from '@campfire/date-picker';
import { DateTime } from 'luxon';
import { GetAddVolunteerPrograms_vm_programs as VolunteerPrograms } from './__generated__/GetAddVolunteerPrograms';
import { useApiUrl } from '../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../global/network/useCampfireFetch';
import { RoleIcon } from '../../../common/icons/RoleIconList';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { useSnackbar } from '../../../global/config/useSnackbar';

interface AddVolunteerArray {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dob: Date | undefined;
  programId: string;
  message?: string;
}

interface AddApplicantArray {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  dob?: Date | undefined;
  message?: string;
}

interface AddVolunteerValues {
  volunteer: {
    firstName: string;
    lastName: string;
    email: string;
    contactNumber: string;
    dob: string | undefined;
    programId: string;
  };
  message?: string;
}

interface AddApplicantValues {
  applicant: {
    firstName: string;
    lastName: string;
    email: string;
    contactNumber?: string | undefined;
    dob?: string | undefined;
  };
  message?: string;
}

interface AddVolunteerDialogProps {
  open: boolean;
  handleClose: () => void;
  onSuccess: () => void;
  programs: VolunteerPrograms[];
}

export const AddVolunteerDialog = (props: AddVolunteerDialogProps) => {
  const { open, handleClose, onSuccess, programs } = props;
  const { theme } = useCampfireTheme();
  const [step, setStep] = useState(0);
  const apiUrl = useApiUrl();
  const goBack = () => {
    setStep(step !== 0 ? step - 1 : step);
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dob: undefined,
    programId: programs[0].programId,
  };

  const [volType, setVolType] = useState<string | null>(null);

  const validationSchema = YupObject().shape({
    firstName: YupString().required('Please enter a First Name'),
    lastName: YupString().required('Please enter a Last Name'),
    phone: volType === 'volunteer' ? YupString().required('Please enter a Phone Number') : YupString(),
    email: YupString().required('Please enter an email'),
    programId: volType === 'volunteer' ? YupString().required('Please select a program') : YupString(),
    dob: volType === 'volunteer' ? YupDate().required('Please select a Date of Birth') : YupDate(),
    message: YupString(),
  });

  const prepareValuesAndSubmit = (values: any) => {
    if (volType === 'volunteer') {
      prepareValuesAndSubmitVolunteer(values);
    }
    if (volType === 'applicant') {
      prepareValuesAndSubmitApplicant(values);
    }
  };

  const prepareValuesAndSubmitVolunteer = (values: AddVolunteerArray) => {
    const coreValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      contactNumber: values.phone,
      dob: values.dob ? encodeDate(DateTime.fromJSDate(values.dob)) : undefined,
      programId: values.programId,
    };
    addVolunteer({ volunteer: coreValues, message: values.message });
  };

  const prepareValuesAndSubmitApplicant = (values: AddApplicantArray) => {
    const coreValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      contactNumber: values.phone === '' ? undefined : values.phone,
      dob: values.dob ? encodeDate(DateTime.fromJSDate(values.dob)) : undefined,
    };
    addApplicant({ applicant: coreValues, message: values.message });
  };

  const { run } = useCampfireFetch({ defer: true });
  const { setSnackbar } = useSnackbar();

  const addVolunteer = (data: AddVolunteerValues): Promise<any> => {
    return run({
      url: `${apiUrl}/vm/volunteer/add`,
      method: 'post',
      data: data,
    })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: `Error adding volunteer`,
            variant: 'error',
          });
          return;
        }
        setSnackbar({
          open: true,
          message: `Added new volunteer`,
          variant: 'success',
        });
        onSuccess();
        handleClose();
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: `Error adding volunteer`,
          variant: 'error',
        });
      });
  };
  const addApplicant = (data: AddApplicantValues): Promise<any> => {
    return run({
      url: `${apiUrl}/vm/application/add`,
      method: 'post',
      data: data,
    })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: `Error adding applicant`,
            variant: 'error',
          });
          return;
        }
        setSnackbar({
          open: true,
          message: `Added new applicant`,
          variant: 'success',
        });
        onSuccess();
        handleClose();
      })
      .catch(() => {
        setSnackbar({
          open: true,
          message: `Error adding applicant`,
          variant: 'error',
        });
      });
  };

  const orderedProgramItems = useMemo(
    () =>
      programs
        .filter((program) => program.dateSuspended === null)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((program) => {
          return {
            value: program.programId,
            label: program.name,
          };
        }),
    [programs]
  );

  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth='sm' fullWidth>
      <DialogTitle style={{ paddingBottom: 0 }}>
        <Grid container justify='space-between'>
          <Grid item>
            <Box alignItems='center'>
              <Typography
                style={{
                  fontSize: '23px',
                  fontWeight: 550,
                  marginRight: '16px',
                  color: theme.color.grey.neutral400,
                  paddingLeft: '5px',
                }}
              >
                Add{' '}
                {volType === 'applicant' && step === 1
                  ? 'Applicant'
                  : volType === 'volunteer' && step === 1
                  ? 'Volunteer'
                  : 'User'}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center'>
              <IconButton key='close' aria-label='Close' color='inherit' onClick={() => handleClose()}>
                <CloseIcon color='action' />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        {step === 0 && (
          <Box pr={3} mt={0}>
            <Typography style={{ fontSize: 14, paddingLeft: '5px', paddingRight: '10px' }} color='textSecondary'>
              Follow the prompts to add someone to your organisation.
              <br /> Hit &apos;Cancel&apos; to close this window.
            </Typography>
          </Box>
        )}
        {step === 1 && volType === 'applicant' && (
          <Box>
            <Typography
              style={{ fontSize: '13px', paddingLeft: '5px', lineHeight: '17px', color: theme.color.grey.neutral300 }}
            >
              Please complete the fields below to add an applicant. <br />
              Applicants will need to complete your organisation&apos;s onboarding tasks before enrolling in activities.
              <br />
              <br />
              <small>These users will appear in &apos;Incoming&apos;.</small>
            </Typography>
          </Box>
        )}
        {step === 1 && volType === 'volunteer' && (
          <Box>
            <Typography
              style={{ fontSize: '13px', paddingLeft: '5px', lineHeight: '17px', color: theme.color.grey.neutral300 }}
            >
              Please complete the fields below to add a volunteer. <br />
              Volunteers will be able to enrol in activities and are not blocked by onboarding tasks.
              <br />
              <br />
              <small>These users will appear in &apos;Database&apos;.</small>
            </Typography>
          </Box>
        )}
      </DialogTitle>
      <Formik
        onSubmit={(values) => {
          prepareValuesAndSubmit(values);
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ submitForm, setFieldValue, values }) => {
          return (
            <>
              <DialogContent id='dialog-content'>
                {step === 0 && (
                  <Box display={'flex'} style={{ gap: '15px', justifyContent: 'center' }}>
                    <Box
                      style={{
                        background: theme.color.white.neutral50,
                        border: '2px solid #DCDCDC',
                        borderRadius: '6px',
                        width: '220px',
                        height: '240px',
                        padding: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={() => setVolType(volType === 'applicant' ? null : 'applicant')}
                    >
                      <Box position={'absolute'}>
                        {volType === 'applicant' ? (
                          <CheckCircle style={{ marginRight: '1rem', borderRadius: 15 }} color='primary' />
                        ) : (
                          <RadioButtonUnchecked style={{ marginRight: '1rem', borderRadius: 15 }} color='disabled' />
                        )}
                      </Box>
                      <Box>
                        <Box display='flex' justifyContent='center' my={2}>
                          <RoleIcon iconName='account-school' color={theme.color.grey.neutral500} size={'30px'} />
                        </Box>
                        <Box display='flex' justifyContent='center' my={1}>
                          <Typography
                            style={{
                              fontWeight: 600,
                              fontSize: '18px',
                              lineHeight: '24px',
                              color: theme.color.grey.neutral500,
                            }}
                          >
                            Applicant
                          </Typography>
                        </Box>
                        <Box display='flex' justifyContent='center'>
                          <Typography
                            style={{
                              fontSize: '13px',
                              lineHeight: '17px',
                              color: theme.color.grey.neutral300,
                              textAlign: 'center',
                            }}
                          >
                            Applicants will need to complete your organisation&apos;s onboarding tasks before enrolling
                            in activities.
                            <br />
                            <br />
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '80%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                            >
                              <Info style={{ fontSize: '14px', paddingTop: '3px' }} />
                              <small style={{ lineHeight: '15px', paddingLeft: '4px' }}>
                                Requires: First & Last Name, Email Address
                              </small>
                            </Box>
                            <br />
                            <small>These users will appear in &apos;Incoming&apos;.</small>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        background: theme.color.white.neutral50,
                        border: '2px solid #DCDCDC',
                        borderRadius: '6px',
                        width: '220px',
                        height: '240px',
                        padding: '16px',
                        cursor: 'pointer',
                      }}
                      onClick={() => setVolType(volType === 'volunteer' ? null : 'volunteer')}
                    >
                      <Box position={'absolute'}>
                        {volType === 'volunteer' ? (
                          <CheckCircle style={{ marginRight: '1rem', borderRadius: 15 }} color='primary' />
                        ) : (
                          <RadioButtonUnchecked style={{ marginRight: '1rem' }} color='disabled' />
                        )}
                      </Box>
                      <Box>
                        <Box display='flex' justifyContent='center' my={2}>
                          <RoleIcon iconName='account' color={theme.color.grey.neutral500} size={'30px'} />
                        </Box>
                        <Box display='flex' justifyContent='center' my={1}>
                          <Typography
                            style={{
                              fontWeight: 600,
                              fontSize: '18px',
                              lineHeight: '24px',
                              color: theme.color.grey.neutral500,
                            }}
                          >
                            User/Volunteer
                          </Typography>
                        </Box>
                        <Box display='flex' justifyContent='center'>
                          <Typography
                            style={{
                              fontSize: '13px',
                              lineHeight: '17px',
                              color: theme.color.grey.neutral300,
                              textAlign: 'center',
                            }}
                          >
                            Users will be able to login and interact with Volaby like any other user in your
                            organisation.
                            <br />
                            <br />
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '80%',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                              }}
                            >
                              <Info style={{ fontSize: '14px', paddingTop: '3px' }} />
                              <small style={{ lineHeight: '15px', paddingLeft: '4px' }}>
                                Requires: First & Last Name, Email, Phone, DoB, Program
                              </small>
                            </Box>
                            <br />
                            <small>These users will appear in &apos;Database&apos;.</small>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                <Form
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1',
                    height: '100%',
                    overflow: 'hidden',
                    paddingTop: '5px',
                  }}
                >
                  {step === 1 && volType === 'applicant' && (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      style={{ gap: '15px', justifyContent: 'center', paddingLeft: '5px', paddingRight: '5px' }}
                    >
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          color: theme.color.grey.neutral400,
                          paddingTop: '5px',
                        }}
                      >
                        Required
                      </Typography>
                      <Grid container direction='row' spacing={2}>
                        <Grid item xs={6}>
                          <Field
                            name='firstName'
                            id='firstName'
                            label='First Name'
                            onChange={() => setFieldValue('firstName', values.firstName)}
                            variant='outlined'
                            helper
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field name='lastName' label='Last Name' variant='outlined' helper fullWidth required />
                        </Grid>
                      </Grid>
                      <Grid container direction='row' spacing={2}>
                        <Grid item xs={6}>
                          <Field name='email' label='Email' variant='outlined' helper fullWidth required />
                        </Grid>
                      </Grid>
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          color: theme.color.grey.neutral400,
                          paddingTop: '5px',
                        }}
                      >
                        Additional Information
                      </Typography>
                      <Grid container direction='row' spacing={2}>
                        <Grid item xs={6}>
                          <Field name='phone' label='Phone Number' variant='outlined' helper fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                          <DatePickerField
                            name='dob'
                            label='Date of Birth'
                            autoOk
                            fullWidth
                            inputVariant='outlined'
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name='message'
                          label='Message'
                          helperText="Inform this user about why they've been added to Volaby"
                          multiline
                          rows={2}
                          helper
                          variant='outlined'
                          fullWidth
                        />
                      </Grid>
                    </Box>
                  )}
                  {step === 1 && volType === 'volunteer' && (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      style={{ gap: '15px', justifyContent: 'center', paddingLeft: '5px', paddingRight: '5px' }}
                    >
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          color: theme.color.grey.neutral400,
                          paddingTop: '5px',
                        }}
                      >
                        Required
                      </Typography>
                      <Grid container direction='row' spacing={2}>
                        <Grid item xs={6}>
                          <Field name='firstName' label='First Name' variant='outlined' helper fullWidth required />
                        </Grid>
                        <Grid item xs={6}>
                          <Field name='lastName' label='Last Name' variant='outlined' helper fullWidth required />
                        </Grid>
                      </Grid>
                      <Grid container direction='row' spacing={2}>
                        <Grid item xs={6}>
                          <Field name='email' label='Email' variant='outlined' helper fullWidth required />
                        </Grid>
                        <Grid item xs={6}>
                          <Field name='phone' label='Phone Number' variant='outlined' helper fullWidth required />
                        </Grid>
                      </Grid>
                      <Grid container direction='row' spacing={2}>
                        <Grid item xs={6}>
                          <DatePickerField
                            name='dob'
                            label='Date of Birth'
                            required
                            autoOk
                            fullWidth
                            inputVariant='outlined'
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Select
                            style={{ width: '100%' }}
                            slow
                            native
                            fullWidth
                            required
                            variant='outlined'
                            defaultValue={orderedProgramItems[0].value}
                            name='programId'
                            label='Select program'
                            items={orderedProgramItems}
                            renderFunction={(item: { label: string; value: string }) => item.label}
                            valueFunction={(item: { label: string; value: string }) => item.value}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: 500,
                          color: theme.color.grey.neutral400,
                          paddingTop: '5px',
                        }}
                      >
                        Additional
                      </Typography>

                      <Grid container direction='row' spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name='message'
                            label='Message'
                            helperText="Inform this user about why they've been added to Volaby"
                            multiline
                            rows={2}
                            helper
                            variant='outlined'
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Form>
              </DialogContent>
              <DialogActions>
                <Box padding={2}>
                  {step === 0 ? (
                    <TabletButton
                      variant='text'
                      color='error'
                      onClick={() => {
                        handleClose();
                      }}
                      style={{ marginRight: 8 }}
                    >
                      {'Cancel'}
                    </TabletButton>
                  ) : (
                    <TabletButton
                      variant='text'
                      color='default'
                      onClick={() => {
                        goBack();
                      }}
                      style={{ marginRight: 8 }}
                    >
                      {'Back'}
                    </TabletButton>
                  )}
                  {step === 0 ? (
                    <TabletButton
                      data-track='actCnl-add-volunteers'
                      variant='contained'
                      disabled={step === 0 && volType === null}
                      color='primary'
                      type='submit'
                      onClick={() => setStep(step + 1)}
                    >
                      {'Next'}
                    </TabletButton>
                  ) : (
                    <TabletButton
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        submitForm();
                      }}
                      style={{ marginRight: 8 }}
                    >
                      {'Submit'}
                    </TabletButton>
                  )}
                </Box>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};
