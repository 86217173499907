import { Box, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import {
  GET_ADMIN_CONSOLE_APPLICANT_SCREENING_PANEL,
  GET_APPLICATION_AUTO_VERIFICATION,
} from './admin-console-applicant-screening.gql';
import { AutoVerificationEditForm } from './AutoVerificationEditForm';
import { ScreeningQuestionEditForm } from './ScreeningQuestionEditForm';
import { GetAdminConsoleApplicantScreeningPanel } from './__generated__/GetAdminConsoleApplicantScreeningPanel';
import { GetApplicationAutoVerification } from './__generated__/GetApplicationAutoVerification';
import { ActivitiesAndAvailabilitiesForm } from './ActivitiesAndAvailabilitiesForm';
import { RolesForm } from './RolesForm';
// import { PreApplicationEditForm } from './PreApplicationEditForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      backgroundColor: '#F9F9F9',
      minHeight: 'calc(100% - 20px)',
    },
    contentContainer: {
      background: 'white',
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
      paddingLeft: '1.875rem',
      paddingRight: '1.875rem',
      marginTop: '2.25rem',
    },
    heading: {
      alignItems: 'center',
      paddingBottom: '8px',
      borderBottom: '1px solid #efefef',
    },
    headingTitle: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 600,
      paddingBottom: '5px',
      fontSize: 20,
    },
    infoBody: {
      color: theme.alert.blue.dark,
      fontSize: '14px',
      fontWeight: 400,
    },
  })
);

const AdminConsoleOnboardingApplicationsPanel = () => {
  const classes = useStyles();
  const apiUrl = useApiUrl();
  const [autoVerification, setAutoVerification] = useState<boolean>();

  const { data, refetch: refetchQuestions } = useCampfireQuery<GetAdminConsoleApplicantScreeningPanel, undefined>(
    GET_ADMIN_CONSOLE_APPLICANT_SCREENING_PANEL
  );

  const initialScreeningQuestions = useMemo(() => {
    if (!data || !data.vm.screeningQuestions) return { screeningQuestions: [] };
    return {
      screeningQuestions: data.vm.screeningQuestions.map((screeningQuestion) => {
        return {
          screeningQuestionId: screeningQuestion.screeningQuestionId,
          correctAnswer: screeningQuestion.correctAnswer !== null ? screeningQuestion.correctAnswer : '',
          label: screeningQuestion.label,
          order: screeningQuestion.order,
        };
      }),
    };
  }, [data]);

  const { data: response, loading: verificationLoading } = useCampfireQuery<GetApplicationAutoVerification, undefined>(
    GET_APPLICATION_AUTO_VERIFICATION
  );

  const { run } = useCampfireFetch({ defer: true });

  useEffect(() => {
    if (response?.vm.applicationAutoVerification === undefined) return;
    setAutoVerification(response.vm.applicationAutoVerification);
  }, [response]);

  useEffect(() => {
    if (autoVerification === undefined) return;

    run({
      url: `${apiUrl}/vm/application/auto-verification/update`,
      method: 'post',
      data: {
        value: autoVerification,
      },
    });
  }, [autoVerification]);

  return (
    <Box className={classes.container}>
      <Box className={classes.heading}>
        <Typography variant='h2' className={classes.headingTitle}>
          Onboarding & Applications
        </Typography>
        <Typography variant={'body2'}>
          {'Manage settings and configuration for new users and their applications'}
        </Typography>
      </Box>
      <Box style={{ marginTop: '25px' }} className={classes.contentContainer}>
        <ScreeningQuestionEditForm initialValues={initialScreeningQuestions} refetchQuestions={refetchQuestions} />
      </Box>
      <Box style={{ marginTop: '25px' }} className={classes.contentContainer}>
        <AutoVerificationEditForm
          autoVerification={autoVerification}
          setAutoVerification={setAutoVerification}
          isLoading={verificationLoading}
        />
      </Box>
      {/* <Box style={{ marginTop: '25px' }} className={classes.contentContainer}>
        <PreApplicationEditForm />
      </Box> */}
      <Box style={{ marginTop: '25px' }} className={classes.contentContainer}>
        <ActivitiesAndAvailabilitiesForm />
      </Box>
      <Box style={{ marginTop: '25px' }} className={classes.contentContainer}>
        <RolesForm />
      </Box>
    </Box>
  );
};

export { AdminConsoleOnboardingApplicationsPanel };
