import { Box, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { ReactNode } from 'react';
import { SnackbarContextProps } from '../../../../../../global/config/SnackbarContext';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { LeftMenuItemType } from '../../incoming-volunteer-model.gql';
import { IncomingVolunteerApplication } from './application-view/IncomingVolunteerApplication';
import { IncomingVolunteerInvitation } from './invitation-view/IncomingVolunteerInvitation';
import { IncomingVolunteerTaskView } from './task-view/IncomingVolunteerTaskView';
import {
  GetIncomingVolunteerProfile_vm_profile_application_applicationRoles as ApplicationRoles,
  GetIncomingVolunteerProfile_vm_profile_application_applicationActivities as ApplicationActivities,
} from '../../__generated__/GetIncomingVolunteerProfile';
import { IncomingVolunteerRoleView } from './role-view/IncomingVolunteerRoleView';
import { IncomingVolunteerActivityView } from './activity-view/IncomingVolunteerActivityView';
import { SimpleAvailabilityView } from './simple-availability-view/SimpleAvailabilityView';

export const TaskMenuBarHeader = ({ children, onClose }: { children: ReactNode; onClose: () => void }) => {
  const { theme } = useCampfireTheme();
  return (
    <Box width={1}>
      <Box
        borderBottom={`1px solid ${theme.color.grey.border}`}
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        px={3}
        py={1}
      >
        <Box display='flex' alignItems='center' style={{ gap: '8px' }}>
          {children}
        </Box>
        <IconButton onClick={() => onClose()}>
          <CloseIcon color='action' />
        </IconButton>
      </Box>
    </Box>
  );
};

export const TaskMenuBarContentWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Box px={3} py={1}>
      {children}
    </Box>
  );
};

export const TaskSideMenuBar = ({
  onClose,
  leftMenuItem,
  reload,
  reloadList,
  selectedUserId,
  setSnackbar,
  roleApplications,
  activityApplications,
}: {
  onClose: () => void;
  leftMenuItem: LeftMenuItemType;
  reload?: () => void;
  reloadList?: () => void;
  selectedUserId?: string;
  setSnackbar?: (props: SnackbarContextProps | undefined) => void;
  roleApplications?: ApplicationRoles[];
  activityApplications?: ApplicationActivities[];
}) => {
  if (leftMenuItem.type === 'VOLUNTEER_VolunteerInviteeType') {
    return <IncomingVolunteerInvitation onClose={onClose} inviteeId={leftMenuItem.inviteeId} reload={reload} />;
  }

  if (leftMenuItem.type === 'VOLUNTEER_ApplicationType') {
    return (
      <IncomingVolunteerApplication onClose={onClose} applicationId={leftMenuItem.applicationId} reload={reload} />
    );
  }

  if (leftMenuItem.type === 'VOLUNTEER_ApplicantTaskType' && selectedUserId) {
    return (
      <IncomingVolunteerTaskView
        key={leftMenuItem.taskId}
        selectedUserId={selectedUserId}
        onClose={onClose}
        taskId={leftMenuItem.taskId}
        completedTaskId={leftMenuItem.completedTaskId}
        reload={reload}
        reloadList={reloadList}
        setSnackbar={setSnackbar}
      />
    );
  }

  if (leftMenuItem.type === 'VOLUNTEER_ApplicationRoleType') {
    return (
      <IncomingVolunteerRoleView
        onClose={onClose}
        applicationRoleId={leftMenuItem.applicationRoleId}
        reload={reload}
        setSnackbar={setSnackbar}
        roleApplication={
          roleApplications?.filter((role) => role.applicationRoleId === leftMenuItem.applicationRoleId)[0]
        }
      />
    );
  }

  if (leftMenuItem.type === 'VOLUNTEER_ApplicationActivityType') {
    return (
      <IncomingVolunteerActivityView
        onClose={onClose}
        applicationActivityId={leftMenuItem.applicationActivityId}
        reload={reload}
        setSnackbar={setSnackbar}
        activityApplication={
          activityApplications?.filter(
            (activity) => activity.applicationActivityId === leftMenuItem.applicationActivityId
          )[0]
        }
      />
    );
  }

  if (leftMenuItem.type === 'VOLUNTEER_SimpleAvailabilityType') {
    return <SimpleAvailabilityView onClose={onClose} />;
  }

  return null;
};
