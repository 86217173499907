import { Box, Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { TabletButton } from '@campfire/tablet-button';
import React, { useEffect, useState } from 'react';
import { useCampfireQuery } from '../../global/network/useCampfireQuery';
import { SIMPLE_AVAILABILITY_QUERY } from './simple-availability-query.gql';
import { SimpleAvailabilityQuery, SimpleAvailabilityQueryVariables } from './__generated__/SimpleAvailabilityQuery';
import { useApiUrl } from '../../global/config/useApiUrl';
import { useCampfireFetch } from '../../global/network/useCampfireFetch';
import { useSnackbar } from '../../global/config/useSnackbar';

const useStyles = makeStyles(() =>
  createStyles({
    days: {
      display: 'flex',
      gap: '15px',
      justifyContent: 'center',
      paddingBottom: '15px',
      paddingTop: '10px',
    },
    dayColumn: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      width: 'fit-content',
      alignItems: 'center',
    },
    dayName: {
      fontSize: '13px',
      color: '#444',
      fontWeight: 600,
    },
    selectAM: {
      display: 'flex',
      backgroundColor: '#FFE6D1',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '30px',
    },
    selectPM: {
      display: 'flex',
      backgroundColor: '#E5F5FC',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '30px',
    },
    selectInner: {
      fontSize: '11px',
      fontWeight: 650,
      padding: '10px 8px',
      textAlign: 'center',
      color: '#333 !important',
    },
  })
);

interface Props {
  profileId: string;
  editing: boolean;
}

export const SimpleAvailabilityComponent = (props: Props) => {
  const classes = useStyles();
  const { profileId, editing } = props;

  const { data, refetch } = useCampfireQuery<SimpleAvailabilityQuery, SimpleAvailabilityQueryVariables>(
    SIMPLE_AVAILABILITY_QUERY,
    {
      options: {
        variables: {
          profileId,
        },
      },
    }
  );

  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  const { setSnackbar } = useSnackbar();

  const onSave = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/volunteer/profile/simple-availability/save`,
        method: 'post',
        data: {
          profileId: profileId,
          mondayAm: mondayAM,
          mondayPm: mondayPM,
          tuesdayAm: tuesdayAM,
          tuesdayPm: tuesdayPM,
          wednesdayAm: wednesdayAM,
          wednesdayPm: wednesdayPM,
          thursdayAm: thursdayAM,
          thursdayPm: thursdayPM,
          fridayAm: fridayAM,
          fridayPm: fridayPM,
          saturdayAm: saturdayAM,
          saturdayPm: saturdayPM,
          sundayAm: sundayAM,
          sundayPm: sundayPM,
          period: period,
          notes: notes,
        },
      })
      .then((response) => {
        refetch();
        if (response.ok) {
          setSnackbar({
            variant: 'success',
            open: true,
            message: 'Availability Updated',
          });
        } else {
          setSnackbar({
            variant: 'error',
            open: true,
            message: 'Error updating',
          });
        }
      });
  };

  const [mondayAM, setMondayAM] = useState<boolean>(false);
  const [mondayPM, setMondayPM] = useState<boolean>(false);
  const [tuesdayAM, setTuesdayAM] = useState<boolean>(false);
  const [tuesdayPM, setTuesdayPM] = useState<boolean>(false);
  const [wednesdayAM, setWednesdayAM] = useState<boolean>(false);
  const [wednesdayPM, setWednesdayPM] = useState<boolean>(false);
  const [thursdayAM, setThursdayAM] = useState<boolean>(false);
  const [thursdayPM, setThursdayPM] = useState<boolean>(false);
  const [fridayAM, setFridayAM] = useState<boolean>(false);
  const [fridayPM, setFridayPM] = useState<boolean>(false);
  const [saturdayAM, setSaturdayAM] = useState<boolean>(false);
  const [saturdayPM, setSaturdayPM] = useState<boolean>(false);
  const [sundayAM, setSundayAM] = useState<boolean>(false);
  const [sundayPM, setSundayPM] = useState<boolean>(false);
  const [period, setPeriod] = useState<string>('');
  const [notes, setNotes] = useState<string>('');

  useEffect(() => {
    setMondayAM(data?.vm.profile?.simpleAvailability?.mondayAm ?? false);
    setMondayPM(data?.vm.profile?.simpleAvailability?.mondayPm ?? false);
    setTuesdayAM(data?.vm.profile?.simpleAvailability?.tuesdayAm ?? false);
    setTuesdayPM(data?.vm.profile?.simpleAvailability?.tuesdayPm ?? false);
    setWednesdayAM(data?.vm.profile?.simpleAvailability?.wednesdayAm ?? false);
    setWednesdayPM(data?.vm.profile?.simpleAvailability?.wednesdayPm ?? false);
    setThursdayAM(data?.vm.profile?.simpleAvailability?.thursdayAm ?? false);
    setThursdayPM(data?.vm.profile?.simpleAvailability?.thursdayPm ?? false);
    setFridayAM(data?.vm.profile?.simpleAvailability?.fridayAm ?? false);
    setFridayPM(data?.vm.profile?.simpleAvailability?.fridayPm ?? false);
    setSaturdayAM(data?.vm.profile?.simpleAvailability?.saturdayAm ?? false);
    setSaturdayPM(data?.vm.profile?.simpleAvailability?.saturdayPm ?? false);
    setSundayAM(data?.vm.profile?.simpleAvailability?.sundayAm ?? false);
    setSundayPM(data?.vm.profile?.simpleAvailability?.sundayPm ?? false);
    setPeriod(data?.vm.profile?.simpleAvailability?.period ?? '');
    setNotes(data?.vm.profile?.simpleAvailability?.notes ?? '');
  }, [data]);

  return (
    <Box>
      <Box className={classes.days}>
        <Box className={classes.dayColumn}>
          <Box className={classes.dayName}>Monday</Box>
          <Button
            disabled={!editing}
            className={classes.selectAM}
            style={mondayAM ? { backgroundColor: '#FFAE6B' } : {}}
            onClick={() => setMondayAM(!mondayAM)}
          >
            <Typography className={classes.selectInner}>AM</Typography>
          </Button>
          <Button
            disabled={!editing}
            className={classes.selectPM}
            style={mondayPM ? { backgroundColor: '#88DBFF' } : {}}
            onClick={() => setMondayPM(!mondayPM)}
          >
            <Typography className={classes.selectInner}>PM</Typography>
          </Button>
        </Box>
        <Box className={classes.dayColumn}>
          <Box className={classes.dayName}>Tuesday</Box>
          <Button
            disabled={!editing}
            className={classes.selectAM}
            style={tuesdayAM ? { backgroundColor: '#FFAE6B' } : {}}
            onClick={() => setTuesdayAM(!tuesdayAM)}
          >
            <Typography className={classes.selectInner}>AM</Typography>
          </Button>
          <Button
            disabled={!editing}
            className={classes.selectPM}
            style={tuesdayPM ? { backgroundColor: '#88DBFF' } : {}}
            onClick={() => setTuesdayPM(!tuesdayPM)}
          >
            <Typography className={classes.selectInner}>PM</Typography>
          </Button>
        </Box>
        <Box className={classes.dayColumn}>
          <Box className={classes.dayName}>Wednesday</Box>
          <Button
            disabled={!editing}
            className={classes.selectAM}
            style={wednesdayAM ? { backgroundColor: '#FFAE6B' } : {}}
            onClick={() => setWednesdayAM(!wednesdayAM)}
          >
            <Typography className={classes.selectInner}>AM</Typography>
          </Button>
          <Button
            disabled={!editing}
            className={classes.selectPM}
            style={wednesdayPM ? { backgroundColor: '#88DBFF' } : {}}
            onClick={() => setWednesdayPM(!wednesdayPM)}
          >
            <Typography className={classes.selectInner}>PM</Typography>
          </Button>
        </Box>
        <Box className={classes.dayColumn}>
          <Box className={classes.dayName}>Thursday</Box>
          <Button
            disabled={!editing}
            className={classes.selectAM}
            style={thursdayAM ? { backgroundColor: '#FFAE6B' } : {}}
            onClick={() => setThursdayAM(!thursdayAM)}
          >
            <Typography className={classes.selectInner}>AM</Typography>
          </Button>
          <Button
            disabled={!editing}
            className={classes.selectPM}
            style={thursdayPM ? { backgroundColor: '#88DBFF' } : {}}
            onClick={() => setThursdayPM(!thursdayPM)}
          >
            <Typography className={classes.selectInner}>PM</Typography>
          </Button>
        </Box>
        <Box className={classes.dayColumn}>
          <Box className={classes.dayName}>Friday</Box>
          <Button
            disabled={!editing}
            className={classes.selectAM}
            style={fridayAM ? { backgroundColor: '#FFAE6B' } : {}}
            onClick={() => setFridayAM(!fridayAM)}
          >
            <Typography className={classes.selectInner}>AM</Typography>
          </Button>
          <Button
            disabled={!editing}
            className={classes.selectPM}
            style={fridayPM ? { backgroundColor: '#88DBFF' } : {}}
            onClick={() => setFridayPM(!fridayPM)}
          >
            <Typography className={classes.selectInner}>PM</Typography>
          </Button>
        </Box>
        <Box className={classes.dayColumn}>
          <Box className={classes.dayName}>Saturday</Box>
          <Button
            disabled={!editing}
            className={classes.selectAM}
            style={saturdayAM ? { backgroundColor: '#FFAE6B' } : {}}
            onClick={() => setSaturdayAM(!saturdayAM)}
          >
            <Typography className={classes.selectInner}>AM</Typography>
          </Button>
          <Button
            disabled={!editing}
            className={classes.selectPM}
            style={saturdayPM ? { backgroundColor: '#88DBFF' } : {}}
            onClick={() => setSaturdayPM(!saturdayPM)}
          >
            <Typography className={classes.selectInner}>PM</Typography>
          </Button>
        </Box>
        <Box className={classes.dayColumn}>
          <Box className={classes.dayName}>Sunday</Box>
          <Button
            disabled={!editing}
            className={classes.selectAM}
            style={sundayAM ? { backgroundColor: '#FFAE6B' } : {}}
            onClick={() => setSundayAM(!sundayAM)}
          >
            <Typography className={classes.selectInner}>AM</Typography>
          </Button>
          <Button
            disabled={!editing}
            className={classes.selectPM}
            style={sundayPM ? { backgroundColor: '#88DBFF' } : {}}
            onClick={() => setSundayPM(!sundayPM)}
          >
            <Typography className={classes.selectInner}>PM</Typography>
          </Button>
        </Box>
      </Box>
      <Box className={classes.days}>
        <TabletButton
          onClick={() => setPeriod('weekly')}
          size='small'
          variant='contained'
          color='primary'
          disabled={!editing}
          aria-controls='options-menu'
          aria-haspopup='true'
          style={{
            border: '1px solid transparent',
            boxSizing: 'border-box',
            borderRadius: '4px',
            padding: '4px 8px',
            marginLeft: '10px',
            marginTop: '2px',
            color: period === 'weekly' ? '#fff' : '#222',
            backgroundColor: period === 'weekly' ? '#796fff' : '#e7e7e7',
          }}
        >
          Weekly
        </TabletButton>
        <TabletButton
          onClick={() => setPeriod('fortnightly')}
          size='small'
          variant='contained'
          color='primary'
          disabled={!editing}
          aria-controls='options-menu'
          aria-haspopup='true'
          style={{
            border: '1px solid transparent',
            boxSizing: 'border-box',
            borderRadius: '4px',
            padding: '4px 8px',
            marginLeft: '10px',
            marginTop: '2px',
            color: period === 'fortnightly' ? '#fff' : '#222',
            backgroundColor: period === 'fortnightly' ? '#796fff' : '#e7e7e7',
          }}
        >
          Fortnightly
        </TabletButton>
        <TabletButton
          onClick={() => setPeriod('monthly')}
          size='small'
          variant='contained'
          color='primary'
          disabled={!editing}
          aria-controls='options-menu'
          aria-haspopup='true'
          style={{
            border: '1px solid transparent',
            boxSizing: 'border-box',
            borderRadius: '4px',
            padding: '4px 8px',
            marginLeft: '10px',
            marginTop: '2px',
            color: period === 'monthly' ? '#fff' : '#222',
            backgroundColor: period === 'monthly' ? '#796fff' : '#e7e7e7',
          }}
        >
          Monthly
        </TabletButton>
      </Box>
      {editing && (
        <Box style={{ float: 'right', padding: '1em 3em 1em 0' }}>
          <TabletButton
            size='small'
            variant='contained'
            color='primary'
            aria-controls='options-menu'
            aria-haspopup='true'
            style={{
              border: '1px solid transparent',
              boxSizing: 'border-box',
              borderRadius: '4px',
              padding: '4px 8px',
              marginLeft: '10px',
              marginTop: '2px',
              color: '#fff',
            }}
            onClick={() => {
              onSave();
            }}
          >
            {'Save'}
          </TabletButton>
        </Box>
      )}
    </Box>
  );
};
