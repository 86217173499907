import { gql } from '@apollo/client';

export const VolunteerEnrolmnentFragments = gql`
  fragment CommonVolunteerEnrolments on VOLUNTEER_ActivityEnrolmentType {
    activityEnrolmentId
    activity {
      activityId
      hasOpenRoster
    }
    availabilities {
      activityAvailabilityId  
      activityDate
      sessionAvailabilities {
        session {
          sessionId
        }
        dateMarked
        dateRemoved
      }
      dateAdded
      dateRemoved
    }
    unavailabilities {
      activityUnavailabilityId
      activityDate
      dateRemoved
    }
  }
`;
