import React, { CSSProperties, RefObject } from 'react';
import Icon from '@mdi/react';
import {
  mdiAccount,
  mdiCardAccountDetails,
  mdiBadgeAccountHorizontal,
  mdiAccountSchool,
  mdiAccountHardHat,
  mdiAccountCowboyHat,
  mdiAccountKey,
  mdiAccountTieHat,
  mdiSubwayVariant,
  mdiBus,
  mdiFerry,
  mdiAirplane,
  mdiAmbulance,
  mdiBicycle,
  mdiSailBoat,
  mdiAtv,
  mdiCar,
  mdiForklift,
  mdiHelicopter,
  mdiVanUtility,
  mdiTruck,
  mdiSchool,
  mdiPalette,
  mdiCamera,
  mdiEmail,
  mdiPhone,
  mdiPhoneIncoming,
  mdiPhoneOutgoing,
  mdiVideoVintage,
  mdiBullhorn,
  mdiTea,
  mdiKeyVariant,
  mdiBugle,
  mdiController,
  mdiDatabase,
  mdiTshirtCrew,
  mdiDelete,
  mdiFireExtinguisher,
  mdiMovieOpen,
  mdiNeedle,
  mdiPoliceBadge,
  mdiRoutes,
  mdiRun,
  mdiHumanDolly,
  mdiHumanMaleBoardPoll,
  mdiSki,
  mdiBiathlon,
  mdiSkateboarding,
  mdiBed,
  mdiBathtub,
  mdiSwim,
  mdiSurfing,
  mdiHorseHuman,
  mdiKayaking,
  mdiBike,
  mdiWalk,
  mdiWheelchairAccessibility,
  mdiDogSide,
  mdiDolphin,
  mdiAlien,
  mdiCow,
  mdiFishbowl,
  mdiHorse,
  mdiRabbit,
  mdiShark,
  mdiCommentText,
  mdiStar,
  mdiChat,
  mdiDramaMasks,
  mdiFire,
  mdiHeart,
  mdiHome,
  mdiPowerPlug,
  mdiRadioactive,
  mdiRecycle,
  mdiSpeaker,
  mdiTrafficLight,
  mdiWheelchair,
  mdiBeach,
  mdiGlassWine,
  mdiGlassMugVariant,
  mdiFood,
  mdiFoodApple,
  mdiSilverwareForkKnife,
  mdiSilverwareVariant,
  mdiSnowflake,
  mdiWater,
  mdiWhiteBalanceSunny,
  mdiAccountInjury,
  mdiAccountWrench,
  mdiBabyBuggy,
  mdiBalloon,
  mdiBarley,
  mdiCarWrench,
  mdiCrown,
  mdiEarth,
  mdiPartyPopper,
  mdiEmoticonCoolOutline,
  mdiFilm,
  mdiFish,
  mdiFridge,
  mdiHomeFlood,
  mdiHomeGroup,
  mdiHook,
  mdiHorseVariant,
  mdiWashingMachine,
  mdiWrench,
  mdiBadgeAccountAlert,
  mdiAccountQuestion,
  mdiAccountChild,
  mdiAccountMusic,
  mdiAccountTie,
  mdiAccountVoice,
  mdiHomeAccount,
  mdiAccountCash,
  mdiAccountCreditCard,
  mdiAccountGroup,
  mdiAirHorn,
  mdiAlarmLight,
  mdiAlphaXBox,
  mdiArchive,
  mdiArchiveCog,
  mdiArmFlex,
  mdiAt,
  mdiAtm,
  mdiAttachment,
  mdiAutoFix,
  mdiAxe,
  mdiBabyCarriage,
  mdiBacteria,
  mdiBagPersonal,
  mdiBagSuitcase,
  mdiBandage,
  mdiBarn,
  mdiBeer,
  mdiBicycleElectric,
  mdiBikeFast,
  mdiBiohazard,
  mdiBlender,
  mdiBone,
  mdiBoombox,
  mdiBoomerang,
  mdiBriefcase,
  mdiBroom,
  mdiCalculator,
  mdiCandy,
  mdiCarElectric,
  mdiCarEmergency,
  mdiCarHatchback,
  mdiCarKey,
  mdiCarPickup,
  mdiCardsClub,
  mdiCardsHeart,
  mdiCardsSpade,
  mdiCardsDiamond,
  mdiCartVariant,
  mdiChurch,
  mdiDoctor,
  mdiBeaker,
  mdiBoomGate,
  mdiBowTie,
  mdiBrush,
  mdiCake,
  mdiConnection,
  mdiCompassRose,
  mdiDishwasher,
  mdiDolly,
  mdiExitRun,
  mdiFerrisWheel,
  mdiFootPrint,
  mdiForest,
  mdiFruitCherries,
  mdiFruitWatermelon,
  mdiGasStation,
  mdiGavel,
  mdiGift,
  mdiGrill,
  mdiHandHeart,
  mdiHandWater,
  mdiHandWash,
  mdiHardHat,
  mdiHead,
  mdiHeadHeart,
  mdiHeadLightbulb,
  mdiHeartPulse,
  mdiHiking,
  mdiHospital,
  mdiHospitalBox,
  mdiHospitalBuilding,
  mdiHumanCane,
  mdiHumanGreetingVariant,
  mdiKettle,
  mdiKnife,
  mdiKnifeMilitary,
  mdiLaptop,
  mdiLectern,
  mdiLightbulb,
  mdiLightbulbMultiple,
  mdiLiquor,
  mdiLotionPlus,
  mdiLotion,
  mdiLungs,
  mdiMailbox,
  mdiMap,
  mdiMarker,
  mdiMedal,
  mdiMedicalBag,
  mdiMicrophoneVariant,
  mdiMotherNurse,
  mdiIvBag,
  mdiMower,
  mdiMusic,
  mdiNaturePeople,
  mdiNumeric0Circle,
  mdiNumeric1Circle,
  mdiNumeric2Circle,
  mdiNumeric3Circle,
  mdiNumeric4Circle,
  mdiNumeric5Circle,
  mdiNumeric6Circle,
  mdiNumeric7Circle,
  mdiNumeric8Circle,
  mdiNumeric9Circle,
  mdiOffer,
  mdiOil,
  mdiPackage,
  mdiPassport,
  mdiPen,
  mdiPencil,
  mdiPencilRuler,
  mdiPiggyBankOutline,
  mdiPill,
  mdiPillMultiple,
  mdiPillOff,
  mdiPliers,
  mdiPresentation,
  mdiPrinter,
  mdiProjector,
  mdiPropaneTank,
  mdiPulse,
  mdiRacingHelmet,
  mdiRadioTower,
  mdiRadio,
  mdiRadiologyBox,
  mdiRoadVariant,
  mdiRocketLaunch,
  mdiRunFast,
  mdiRoomService,
  mdiSausage,
  mdiScale,
  mdiSeal,
  mdiMoonWaningCrescent,
  mdiSetSquare,
  mdiShapeOutline,
  mdiShieldCross,
  mdiShieldCrown,
  mdiShieldHome,
  mdiShieldKey,
  mdiShimmer,
  mdiSignDirection,
  mdiShoePrint,
  mdiShovel,
  mdiShower,
  mdiSignYield,
  mdiSilverwareClean,
  mdiSilverwareFork,
  mdiSilverwareSpoon,
  mdiSilverware,
  mdiSizeS,
  mdiSizeXxs,
  mdiSizeXs,
  mdiSizeM,
  mdiSizeL,
  mdiSizeXl,
  mdiSizeXxl,
  mdiSizeXxxl,
  mdiSkull,
  mdiSkullOutline,
  mdiSleep,
  mdiSpeakerBluetooth,
  mdiSmartCard,
  mdiSmoking,
  mdiSmokingOff,
  mdiSofa,
  mdiSpray,
  mdiSprayBottle,
  mdiSprout,
  mdiSqueegee,
  mdiStadium,
  mdiSunglasses,
  mdiStarShooting,
  mdiTag,
  mdiTeddyBear,
  mdiThermometer,
  mdiThermometerLines,
  mdiTelevisionClassic,
  mdiTicket,
  mdiTimer,
  mdiTooth,
  mdiToothbrush,
  mdiTorch,
  mdiTruckAlert,
  mdiTruckFast,
  mdiTruckPlus,
  mdiVirus,
  mdiVirusOff,
  mdiVote,
  mdiWhistle,
  mdiUsbFlashDrive,
} from '@mdi/js';

export const iconListPeople = {
  account: mdiAccount,
  'card-account-details': mdiCardAccountDetails,
  'badge-account-horizontal': mdiBadgeAccountHorizontal,
  'smart-card': mdiSmartCard,
  'badge-account-alert': mdiBadgeAccountAlert,
  'account-school': mdiAccountSchool,
  'account-hard-hat': mdiAccountCowboyHat,
  'account-cowboy-hat': mdiAccountHardHat,
  'account-key': mdiAccountKey,
  'account-injury': mdiAccountInjury,
  'account-wrench': mdiAccountWrench,
  'account-question': mdiAccountQuestion,
  'account-child': mdiAccountChild,
  'account-music': mdiAccountMusic,
  'account-tie-hat': mdiAccountTieHat,
  doctor: mdiDoctor,
  'account-tie': mdiAccountTie,
  'account-cash': mdiAccountCash,
  'account-credit-card': mdiAccountCreditCard,
  'account-group': mdiAccountGroup,
  'account-voice': mdiAccountVoice,
  'baby-buggy': mdiBabyBuggy,
  'baby-carriage': mdiBabyCarriage,
  'emoticon-cool-outline': mdiEmoticonCoolOutline,
  head: mdiHead,
  run: mdiRun,
  'run-fast': mdiRunFast,
  'humman-dolly': mdiHumanDolly,
  'humman-male-board-poll': mdiHumanMaleBoardPoll,
  ski: mdiSki,
  biathlon: mdiBiathlon,
  sketaboaring: mdiSkateboarding,
  swim: mdiSwim,
  surfing: mdiSurfing,
  'horse-human': mdiHorseHuman,
  kayaking: mdiKayaking,
  hiking: mdiHiking,
  bike: mdiBike,
  walk: mdiWalk,
  'human-cane': mdiHumanCane,
  'human-greeting-variant': mdiHumanGreetingVariant,
  'wheelchair-accessibility': mdiWheelchairAccessibility,
  wheelchair: mdiWheelchair,
  'arm-flex': mdiArmFlex,
  'mother-nurse': mdiMotherNurse,
  'nature-people': mdiNaturePeople,
  bed: mdiBed,
  sleep: mdiSleep,
  bathtub: mdiBathtub,
  footprint: mdiFootPrint,
  shoe: mdiShoePrint,
  lungs: mdiLungs,
  skull: mdiSkull,
  'skull-other': mdiSkullOutline,
  tooth: mdiTooth,
};

export const iconListCommunication = {
  email: mdiEmail,
  at: mdiAt,
  phone: mdiPhone,
  camera: mdiCamera,
  'phone-incoming': mdiPhoneIncoming,
  'phone-outgoing': mdiPhoneOutgoing,
  'video-vintage': mdiVideoVintage,
  film: mdiFilm,
  attachment: mdiAttachment,
  'comment-text': mdiCommentText,
  chat: mdiChat,
  mailbox: mdiMailbox,
  microphone: mdiMicrophoneVariant,
  radio: mdiRadio,
  speaker: mdiSpeaker,
  'speaker-bluetooth': mdiSpeakerBluetooth,
  television: mdiTelevisionClassic,
};

export const iconListVehicles = {
  bus: mdiBus,
  ferry: mdiFerry,
  airplane: mdiAirplane,
  ambulance: mdiAmbulance,
  'subway-variant': mdiSubwayVariant,
  bicycle: mdiBicycle,
  'bicycle-electric': mdiBicycleElectric,
  'bike-fast': mdiBikeFast,
  'sail-boat': mdiSailBoat,
  atv: mdiAtv,
  car: mdiCar,
  'car-electric': mdiCarElectric,
  'car-emergency': mdiCarEmergency,
  'car-hatchback': mdiCarHatchback,
  'car-key': mdiCarKey,
  'car-pickup': mdiCarPickup,
  forklift: mdiForklift,
  helicopter: mdiHelicopter,
  'van-utility': mdiVanUtility,
  truck: mdiTruck,
  'truck-alert': mdiTruckAlert,
  'truck-fast': mdiTruckFast,
  'truck-plus': mdiTruckPlus,
  'car-wrench': mdiCarWrench,
  boomgate: mdiBoomGate,
  mower: mdiMower,
  oil: mdiOil,
  road: mdiRoadVariant,
  rocket: mdiRocketLaunch,
  sailboat: mdiSailBoat,
};

export const iconListJobsRoles = {
  'police-badge': mdiPoliceBadge,
  'drama-marks': mdiDramaMasks,
  crown: mdiCrown,
  school: mdiSchool,
  bowtie: mdiBowTie,
  dolly: mdiDolly,
  gavel: mdiGavel,
  'hand-heart': mdiHandHeart,
  'hand-water': mdiHandWater,
  'hand-wash': mdiHandWash,
  'head-heart': mdiHeadHeart,
  'head-lightbulb': mdiHeadLightbulb,
  'heart-pulse': mdiHeartPulse,
  hardhat: mdiHardHat,
  'lotion-plus': mdiLotionPlus,
  'medical-bag': mdiMedicalBag,
  ivbag: mdiIvBag,
  offer: mdiOffer,
  passport: mdiPassport,
  'pencil-ruler': mdiPencilRuler,
  pill: mdiPill,
  'many-pills': mdiPillMultiple,
  'pill-off': mdiPillOff,
  pulse: mdiPulse,
  'racing-helment': mdiRacingHelmet,
  radiology: mdiRadiologyBox,
  'room-service': mdiRoomService,
  seal: mdiSeal,
  ticket: mdiTicket,
  timer: mdiTimer,
  vote: mdiVote,
  whistle: mdiWhistle,
};

export const iconListPlaces = {
  home: mdiHome,
  beach: mdiBeach,
  'home-flood': mdiHomeFlood,
  'home-group': mdiHomeGroup,
  'home-account': mdiHomeAccount,
  barn: mdiBarn,
  atm: mdiAtm,
  earth: mdiEarth,
  church: mdiChurch,
  ferriswheel: mdiFerrisWheel,
  forest: mdiForest,
  'gas-station': mdiGasStation,
  hospital: mdiHospital,
  'hospital-variant': mdiHospitalBox,
  'hospital-building': mdiHospitalBuilding,
  'radio-tower': mdiRadioTower,
  shower: mdiShower,
  stadium: mdiStadium,
};

export const iconListObjects = {
  'bag-personal': mdiBagPersonal,
  'bag-suitcase': mdiBagSuitcase,
  briefcase: mdiBriefcase,
  broom: mdiBroom,
  teddybear: mdiTeddyBear,
  thermometer: mdiThermometer,
  'thermometer-alt': mdiThermometerLines,
  'washing-machine': mdiWashingMachine,
  dishwasher: mdiDishwasher,
  bandage: mdiBandage,
  balloon: mdiBalloon,
  'party-popper': mdiPartyPopper,
  bullhorn: mdiBullhorn,
  'fire-extinguisher': mdiFireExtinguisher,
  't-shirt-crew': mdiTshirtCrew,
  needle: mdiNeedle,
  calculator: mdiCalculator,
  bugle: mdiBugle,
  palette: mdiPalette,
  sunglasses: mdiSunglasses,
  package: mdiPackage,
  'air-horn': mdiAirHorn,
  controller: mdiController,
  'power-plug': mdiPowerPlug,
  'traffic-light': mdiTrafficLight,
  hook: mdiHook,
  knifeB: mdiKnifeMilitary,
  wrench: mdiWrench,
  shovel: mdiShovel,
  toothbrush: mdiToothbrush,
  torch: mdiTorch,
  'alarm-light': mdiAlarmLight,
  archive: mdiArchive,
  'archive-cog': mdiArchiveCog,
  axe: mdiAxe,
  bone: mdiBone,
  boombox: mdiBoombox,
  boomerang: mdiBoomerang,
  cart: mdiCartVariant,
  'move-open': mdiMovieOpen,
  beaker: mdiBeaker,
  brush: mdiBrush,
  gift: mdiGift,
  laptop: mdiLaptop,
  lectern: mdiLectern,
  lightbulb: mdiLightbulb,
  'lightbulb-many': mdiLightbulbMultiple,
  lotion: mdiLotion,
  connection: mdiConnection,
  pen: mdiPen,
  pencil: mdiPencil,
  piggybank: mdiPiggyBankOutline,
  pliers: mdiPliers,
  printer: mdiPrinter,
  sofa: mdiSofa,
  usb: mdiUsbFlashDrive,
  squeege: mdiSqueegee,
  spray: mdiSpray,
  'spray-bottle': mdiSprayBottle,
  projector: mdiProjector,
  propane: mdiPropaneTank,
  scale: mdiScale,
};

export const iconListIcons = {
  radioactive: mdiRadioactive,
  biohazard: mdiBiohazard,
  recycle: mdiRecycle,
  fire: mdiFire,
  heart: mdiHeart,
  bacteria: mdiBacteria,
  virus: mdiVirus,
  'virus-alt': mdiVirusOff,
  traffic: mdiTrafficLight,
  'shooting-star': mdiStarShooting,
  'white-balance-sunny': mdiWhiteBalanceSunny,
  moon: mdiMoonWaningCrescent,
  star: mdiStar,
  snowflake: mdiSnowflake,
  presentation: mdiPresentation,
  delete: mdiDelete,
  'key-variant': mdiKeyVariant,
  database: mdiDatabase,
  routes: mdiRoutes,
  'alpha-x-box': mdiAlphaXBox,
  'auto-fix': mdiAutoFix,
  'cards-club': mdiCardsClub,
  'cards-heart': mdiCardsHeart,
  'cards-spade': mdiCardsSpade,
  'cards-diamond': mdiCardsDiamond,
  compass: mdiCompassRose,
  tag: mdiTag,
  exit: mdiExitRun,
  map: mdiMap,
  marker: mdiMarker,
  sprout: mdiSprout,
  'set-square': mdiSetSquare,
  'shape-outline': mdiShapeOutline,
  'shield-cross': mdiShieldCross,
  'shield-crown': mdiShieldCrown,
  'shield-home': mdiShieldHome,
  'shield-key': mdiShieldKey,
  shimmer: mdiShimmer,
  sign: mdiSignDirection,
  'sign-yield': mdiSignYield,
  medal: mdiMedal,
  music: mdiMusic,
  smoking: mdiSmoking,
  'smoking-off': mdiSmokingOff,
  'numeric-0-box': mdiNumeric0Circle,
  'numeric-1-box': mdiNumeric1Circle,
  'numeric-2-box': mdiNumeric2Circle,
  'numeric-3-box': mdiNumeric3Circle,
  'numeric-4-box': mdiNumeric4Circle,
  'numeric-5-box': mdiNumeric5Circle,
  'numeric-6-box': mdiNumeric6Circle,
  'numeric-7-box': mdiNumeric7Circle,
  'numeric-8-box': mdiNumeric8Circle,
  'numeric-9-box': mdiNumeric9Circle,
  'size-xxs': mdiSizeXxs,
  'size-xs': mdiSizeXs,
  'size-s': mdiSizeS,
  'size-m': mdiSizeM,
  'size-l': mdiSizeL,
  'size-xl': mdiSizeXl,
  'size-xxl': mdiSizeXxl,
  'size-xxxl': mdiSizeXxxl,
};

export const iconListAnimals = {
  'dog-side': mdiDogSide,
  dolphin: mdiDolphin,
  alien: mdiAlien,
  cow: mdiCow,
  fishbowl: mdiFishbowl,
  horse: mdiHorse,
  rabbit: mdiRabbit,
  'horse-variant': mdiHorseVariant,
  shark: mdiShark,
  fish: mdiFish,
};

export const iconListFoodDrink = {
  'glass-wine': mdiGlassWine,
  'glass-mug-variant': mdiGlassMugVariant,
  food: mdiFood,
  'food-apple': mdiFoodApple,
  'sliverware-fork-knife': mdiSilverwareForkKnife,
  'sliverware-variant': mdiSilverwareVariant,
  'silverware-clean': mdiSilverwareClean,
  'silverware-fork': mdiSilverwareFork,
  'silverware-spoon': mdiSilverwareSpoon,
  silverware: mdiSilverware,
  snowflake: mdiSnowflake,
  water: mdiWater,
  barley: mdiBarley,
  tea: mdiTea,
  beer: mdiBeer,
  blender: mdiBlender,
  candy: mdiCandy,
  cake: mdiCake,
  fruitCherries: mdiFruitCherries,
  watermelon: mdiFruitWatermelon,
  fridge: mdiFridge,
  grill: mdiGrill,
  kettle: mdiKettle,
  knife: mdiKnife,
  liquor: mdiLiquor,
  sausage: mdiSausage,
};

type IconList = Record<string, string>;

export const iconList: IconList = {
  ...iconListPeople,
  ...iconListJobsRoles,
  ...iconListCommunication,
  ...iconListVehicles,
  ...iconListPlaces,
  ...iconListObjects,
  ...iconListIcons,
  ...iconListAnimals,
  ...iconListFoodDrink,
};

interface RoleIconProps {
  iconName: string;
  id?: string;
  ref?: RefObject<SVGSVGElement>;
  title?: string | null;
  description?: string | null;
  size?: number | string | null;
  color?: string | null;
  horizontal?: boolean;
  vertical?: boolean;
  rotate?: number;
  spin?: boolean | number;
  style?: CSSProperties;
  inStack?: boolean;
}

export const RoleIcon = ({ iconName, ...otherProps }: RoleIconProps) => (
  <Icon path={iconList[iconName as keyof typeof iconList]} {...otherProps} />
);
