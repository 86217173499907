import React, { memo, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Add, Explore } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { useVolunteerCommonProfileContext } from '../VolunteerCommonProfileContext';
import { ProgramCardSimple } from '../../../../common/cards/ProgramCardSimple';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ProgramsContext } from '../../../../global/programs-sell/ProgramsContext';
import { ProgramFilters } from '../../../../global/program-filters/ProgramFilters';
import { useApiUrl } from '../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';
import { useSnackbar } from '../../../../global/config/useSnackbar';

export const VolunteerProgramsSection = memo(
  ({ programs }: { programs: Array<{ programId: string; name: string; description: string | null }> }) => {
    const {
      volunteerNameOptions: { preferredName },
      reloadNewData,
      volunteer,
    } = useVolunteerCommonProfileContext();
    const { theme } = useCampfireTheme();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const apiUrl = useApiUrl();
    const { setSnackbar } = useSnackbar();

    const { run } = useCampfireFetch({
      defer: true,
    });

    const setSelectedPrograms = (programList: string[]) => {
      run({
        method: 'post',
        data: {
          programIds: programList,
          volunteerId: volunteer?.volunteerId,
        },
        url: `${apiUrl}/vm/program/enrol`,
      }).then((response) => {
        if (response.ok) {
          setSnackbar({
            open: true,
            message: 'Volunteer enrolled',
            variant: 'success',
          });
        }
        if (!response.ok) {
          setSnackbar({
            open: true,
            message: 'Error while enrolling',
            variant: 'error',
          });
        }
      });
      reloadNewData();
    };

    const { getAuthorizedPrograms } = React.useContext(ProgramsContext);
    const programsList =
      getAuthorizedPrograms(true).filter(({ programId }) => !programs.some((e) => e.programId === programId)) || [];

    return (
      <Grid container spacing={2} direction='column' style={{ position: 'relative' }}>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box>
              <Typography
                style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center', color: '#333' }}
              >
                <Explore style={{ fontSize: '14px', paddingRight: '5px' }} /> Programs
              </Typography>
              <Typography variant='body2' style={{ color: '#464646' }} align='left'>
                All programs that {preferredName} is a part of
              </Typography>
            </Box>
            <TabletButton
              size='small'
              variant='contained'
              color='primary'
              aria-controls='options-menu'
              aria-haspopup='true'
              onClick={() => setDialogOpen(true)}
              startIcon={<Add />}
              style={{
                height: '100%',
                border: '1px solid transparent',
                boxSizing: 'border-box',
                borderRadius: '4px',
                padding: '6px 12px',
                marginRight: '10px',
                color: '#fff',
              }}
            >
              Add
            </TabletButton>
          </Box>
          <hr style={{ borderTop: '.5px solid #e7e7e7', margin: '20px 0' }} />
          {programs.map((program) => (
            <ProgramCardSimple program={program} color={theme.color.programs.status.joined} />
          ))}
          {programs.length === 0 && (
            <Typography
              variant='body2'
              style={{ fontSize: '13px', color: '#494949', textAlign: 'center', padding: '2rem 0' }}
            >
              No results found
            </Typography>
          )}
        </Grid>
        {dialogOpen && (
          <ProgramFilters
            programs={programsList}
            selectedPrograms={[]}
            setSelectedPrograms={setSelectedPrograms}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onCancel={() => setDialogOpen(false)}
            fullScreen={false}
            simple
            addVol
          />
        )}
      </Grid>
    );
  }
);
