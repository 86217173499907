import { PaneHeader, PaneWrapper } from '@campfire/pane';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Button, Grid, Input, MenuItem, Select, Tab, Tabs, Theme } from '@material-ui/core';
import { Email as EmailIcon, PersonAdd, ExpandMore } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { SearchField } from '../../../common/inputs/SearchField';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { InvitationDialog } from '../../admin/invitation/InvitationDialog';
import { useVolunteersContext } from './VolunteersContext';
import { TooltipMenu } from '../../../common/tooltip-menu';
import { AddVolunteerDialog } from '../../admin/add-volunteer/AddVolunteerDialog';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { GetAddVolunteerPrograms } from '../../admin/add-volunteer/__generated__/GetAddVolunteerPrograms';
import { GET_ADD_VOLUNTEER_PROGRAMS } from '../../admin/add-volunteer/add-volunteer-query.gql';

const useTabClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      minWidth: 72,
      textTransform: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  })
);

const TAB_ITEMS: Array<{ value: string; label: string }> = [
  { label: 'Database', value: '/management/volunteers/volunteers-database' },
  { label: 'Incoming', value: '/management/volunteers/incoming-volunteers' },
  { label: 'Activity Applications', value: '/management/volunteers/volunteers-applications' },
  { label: 'Activity Waitlistings', value: '/management/volunteers/waitlist-volunteers' },
  { label: 'Import', value: '/management/volunteers/volunteers-importing' },
];

const useStyles = makeStyles((theme: Theme) => {
  return {
    edit: {
      color: theme.alert.blue.extraLight,
      display: 'flex',
      alignItems: 'center',
      paddingTop: '10px',
      justifyContent: 'center',
      '& p': {
        fontSize: '14px',
      },
      '& svg': {
        fontSize: '16px',
      },
      textTransform: 'none',
    },
  };
});

const VolunteersTabs = () => {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const classes = useTabClasses();
  const buttonClasses = useStyles();
  const selectedTabIndex = TAB_ITEMS.findIndex((x) => x.value === routeMatch?.path);
  const [inviteDialog, setInviteDialog] = useState<boolean>(false);
  const [addVolunteerOpen, setAddVolunteerOpen] = React.useState(false);
  const [addVolunteerDialog, setVolunteerDialog] = React.useState(false);

  const { data: programData } = useCampfireQuery<GetAddVolunteerPrograms, undefined>(GET_ADD_VOLUNTEER_PROGRAMS);
  const programs = programData?.vm.programs;

  const { setSearchIncomingVolunteersInput, getIncomingVolunteersRefetch } = useVolunteersContext();

  const selectedTab = TAB_ITEMS[selectedTabIndex] ?? TAB_ITEMS[0];

  return selectedTab ? (
    <Box
      borderBottom='solid #dadada 1px'
      display='flex'
      justifyContent='space-between'
      alignContent='center'
      alignItems='center'
      width={1}
    >
      <Tabs indicatorColor='primary' value={selectedTabIndex} aria-label='volunteers tabs'>
        {TAB_ITEMS.map((x) => (
          <Tab
            disableRipple
            disableTouchRipple
            classes={classes}
            key={x.value}
            label={x.label}
            onClick={() => {
              history.push(x.value);
            }}
          />
        ))}
      </Tabs>
      <Box display='flex' justifyContent={'flex-end'} alignContent='center' alignItems='center'>
        {selectedTab.label === 'Incoming' && (
          <Box marginRight={1}>
            <SearchField
              placeholder='Search'
              onChange={(e) => setSearchIncomingVolunteersInput(e.target.value)}
              fullWidth
              height={32}
            />
          </Box>
        )}
        <Box display='flex'>
          <TooltipMenu
            open={addVolunteerOpen}
            onClose={() => {
              setAddVolunteerOpen(false);
            }}
            title={
              <Box display='flex' flexDirection='column' alignItems='flex-start' style={{ padding: '0 5px' }}>
                <Button
                  startIcon={<EmailIcon />}
                  className={buttonClasses.edit}
                  onClick={() => {
                    setInviteDialog(true);
                    setAddVolunteerOpen(false);
                  }}
                >
                  Invite
                </Button>
                <Button
                  startIcon={<PersonAdd />}
                  className={buttonClasses.edit}
                  onClick={() => {
                    setVolunteerDialog(true);
                    setAddVolunteerOpen(false);
                  }}
                >
                  Manual
                </Button>
              </Box>
            }
          >
            <TabletButton
              size='small'
              variant='contained'
              color='primary'
              aria-controls='options-menu'
              aria-haspopup='true'
              onClick={() => setAddVolunteerOpen(true)}
              startIcon={<PersonAdd />}
              endIcon={<ExpandMore />}
              style={{
                height: '100%',
                border: '1px solid transparent',
                boxSizing: 'border-box',
                borderRadius: '4px',
                padding: '5px 10px',
                marginRight: '10px',
                color: '#fff',
              }}
            >
              Add
            </TabletButton>
          </TooltipMenu>
        </Box>
      </Box>
      {inviteDialog && (
        <InvitationDialog
          open={inviteDialog}
          handleClose={() => setInviteDialog(false)}
          onSuccess={() => {
            setInviteDialog(false);
            if (getIncomingVolunteersRefetch) getIncomingVolunteersRefetch();
          }}
        />
      )}
      {addVolunteerDialog && programs && (
        <AddVolunteerDialog
          open={addVolunteerDialog}
          handleClose={() => setVolunteerDialog(false)}
          onSuccess={() => {
            if (selectedTab.label === 'Incoming' && getIncomingVolunteersRefetch) getIncomingVolunteersRefetch();
          }}
          programs={programs}
        />
      )}
    </Box>
  ) : null;
};

const useSelectInputStyles = makeStyles(() =>
  createStyles({
    root: {
      fontSize: 13,
    },
    input: {
      backgroundColor: '#ffffff',
      position: 'relative',
      fontSize: 16,
      padding: 4,
      paddingRight: 26,
      paddingLeft: 12,
      '&:focus': {
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
);
const VolunteersTabsMobile = () => {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const { theme } = useCampfireTheme();
  const [value, setValue] = useState(routeMatch ? routeMatch.path : TAB_ITEMS[0].value);
  const inputStyles = useSelectInputStyles();

  return routeMatch ? (
    <Grid
      item
      container
      alignItems='center'
      justify='space-between'
      style={{
        minHeight: 50,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        paddingLeft: 8,
        paddingRight: 2,
      }}
    >
      <Grid item>
        <Select
          MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
          value={value}
          onChange={(event) => {
            const selectedValue = event.target.value as string;
            history.push(selectedValue);
            setValue(selectedValue);
          }}
          input={<Input classes={inputStyles} />}
          renderValue={(selected) => {
            const selectedValue = selected as string;
            return TAB_ITEMS.find((x) => x.value === selectedValue)?.label;
          }}
        >
          {TAB_ITEMS.map((tabItem) => (
            <MenuItem key={tabItem.value} value={tabItem.value}>
              {tabItem.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  ) : null;
};

export const VolunteersTabsWrapper = ({ children }: { children: React.ReactNode }) => {
  const { isMobile } = useCampfireTheme();
  return (
    <PaneWrapper>
      <PaneHeader>{isMobile ? <VolunteersTabsMobile /> : <VolunteersTabs />}</PaneHeader>
      {children}
    </PaneWrapper>
  );
};
