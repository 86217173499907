import React, { memo } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { AssignmentTurnedIn, ExpandMore } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { VolunteerCompletedTaskItem } from './VolunteerCompletedTaskItem';
import { useVolunteerCommonProfileContext } from '../../VolunteerCommonProfileContext';

export interface CompletedTask {
  completedTaskId: string;
  dateSubmitted: string;
  status: string;
  task: {
    taskId: string;
    title: string;
    adminOnly: boolean;
  };
}

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      alignItems: 'center',
      '& > span': {
        textTransform: 'none',
      },
      borderRadius: 6,
      height: 38,
    },
  })
);

interface VolunteerTasksSectionProps {
  completedTasks: Array<CompletedTask>;
  userId: string;
  profileId: string;
  hasMore: boolean;
  loadMore: () => void;
  refetch: () => void;
}

export const VolunteerTasksSection = memo((props: VolunteerTasksSectionProps) => {
  const classes = useStyles();
  const { completedTasks, userId, profileId, hasMore, loadMore, refetch } = props;
  const {
    volunteerNameOptions: { preferredName },
  } = useVolunteerCommonProfileContext();

  return (
    <Grid container spacing={2} direction='column' style={{ position: 'relative' }}>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <Typography style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center', color: '#333' }}>
          <AssignmentTurnedIn style={{ fontSize: '14px', paddingRight: '5px' }} /> Complete Tasks
        </Typography>
        <Typography variant='body2' style={{ color: '#464646' }} align='left'>
          Tasks submitted by or for {preferredName}
          <hr style={{ borderTop: '.5px solid #e7e7e7', margin: '20px 0' }} />
        </Typography>
        {completedTasks.map((completedTask) => (
          <VolunteerCompletedTaskItem
            completedTask={completedTask}
            userId={userId}
            profileId={profileId}
            refetch={refetch}
          />
        ))}
        {completedTasks.length === 0 && (
          <Typography
            variant='body2'
            style={{ fontSize: '13px', color: '#494949', textAlign: 'center', padding: '2rem 0' }}
          >
            No results found
          </Typography>
        )}
        {hasMore && (
          <Button
            onClick={loadMore}
            style={{ alignSelf: 'center', marginTop: '1rem' }}
            className={classes.button}
            variant='outlined'
            disableElevation
            endIcon={<ExpandMore />}
          >
            Show more
          </Button>
        )}
      </Grid>
    </Grid>
  );
});
