import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { convertToEditorState } from '../../screens/general/activities-v2/AllPrograms/AllProgramsHelpers';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      width: '100%',
      height: 'fit-content',
      borderRadius: '8px',
      boxSizing: 'border-box',
      marginTop: '15px',
      // '&:hover': {
      //   cursor: 'pointer',
      //   boxShadow: `0px 0px 4px rgba(0, 0, 0, 0.25)`,
      // },
    },
  })
);

interface Props {
  color?: string;
  program: { name: string; description: string | null };
}

export const ProgramCardSimple = (props: Props) => {
  const { color, program } = props;
  const { theme, isSm, isMd } = useCampfireTheme();
  const classes = useStyles({ isSm });

  return (
    <Box
      className={classes.card}
      border={`1px solid ${theme.color.grey.border}`}
      borderBottom={`1px solid ${theme.color.grey.border}`}
      borderRight={`1px solid ${theme.color.grey.border}`}
      bgcolor={color ?? theme.color.grey.neutralBrand100}
    >
      <Box display='flex'>
        <Box minWidth='26px' width='26px' borderRadius={'0px 8px 8px 0px'}></Box>
        <Box
          display='flex'
          flexDirection='column'
          flexGrow={2}
          alignItems='flex-start'
          justifyContent='center'
          width={!isMd ? '300px' : '250px'}
          style={{ padding: '18px 18px', borderRadius: '0px 8px 8px 0px' }}
          overflow='hidden'
          bgcolor={theme.color.white.neutral50}
        >
          <Box display='flex' justifyContent='space-between' alignSelf='stretch'>
            <TitularTooltip title={program.name}>
              <Box
                display='flex'
                alignItems='center'
                style={{
                  width: '100%',
                  letterSpacing: '-0.02em',
                  overflow: 'hidden',
                  maxHeight: '3.5em',
                  textOverflow: 'ellipsis',
                }}
              >
                <Typography
                  variant='h6'
                  noWrap
                  style={{
                    color: theme.color.grey.neutral400,
                    fontWeight: 800,
                    width: '100%',
                    letterSpacing: '-0.02em',
                  }}
                >
                  {program.name}
                </Typography>
              </Box>
            </TitularTooltip>
          </Box>
          {program.description && (
            <Box maxHeight={40} height={40} mt={1} overflow='hidden'>
              <Typography
                style={{
                  color: theme.color.grey.neutral300,
                  fontWeight: 400,
                  fontSize: '0.75rem',
                }}
              >
                <CampfireRichEditor editorState={convertToEditorState(program.description)} readOnly />
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
