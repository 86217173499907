import React, { useState } from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { CampfireSwitch } from '../../../content-blocks/common/CampfireSwitch';
import { IncomingVolunteersScreen } from './incoming-volunteers/IncomingVolunteersScreen';
import { WaitlistVolunteersScreen } from './waitlist-volunteers/WaitlistVolunteersScreen';
import { VolunteersApplicationsScreen } from './volunteers-applications/VolunteersApplicationsScreen';
import { ImportingVolunteersScreen } from './volunteers-importing/ImportingVolunteersScreen';
import { VolunteersProvider } from './VolunteersContext';
import { VolunteersTabsWrapper } from './VolunteersTabsWrapper';
import { Page } from '../../../global/components';
import { VolunteersTutorialDialog } from './VolunteersTutorialDialog';
import VolunteerDatabaseScreen from './volunteer-database/VolunteerDatabaseScreen';

export const VolunteersScreen = withRouter(({ match }) => {
  const [tutorialDialogOpen, setTutorialDialogOpen] = useState<boolean>(false);

  return (
    <Page
      pageHelpOptions={{
        onClick: () => setTutorialDialogOpen(true),
      }}
    >
      <CampfireSwitch>
        <Route exact path={match.path} render={() => <Redirect to={`${match.path}/volunteers-database`} />} />
        <Route
          exact
          path={`${match.path}/volunteers-database`}
          render={() => (
            <VolunteersProvider>
              <VolunteersTabsWrapper>
                <VolunteerDatabaseScreen />
              </VolunteersTabsWrapper>
            </VolunteersProvider>
          )}
        />
        <Route
          exact
          path={`${match.path}/incoming-volunteers`}
          render={() => (
            <VolunteersProvider>
              <VolunteersTabsWrapper>
                <IncomingVolunteersScreen />
              </VolunteersTabsWrapper>
            </VolunteersProvider>
          )}
        />
        <Route
          exact
          path={`${match.path}/waitlist-volunteers`}
          render={() => (
            <VolunteersProvider>
              <VolunteersTabsWrapper>
                <WaitlistVolunteersScreen />
              </VolunteersTabsWrapper>
            </VolunteersProvider>
          )}
        />
        <Route
          exact
          path={`${match.path}/volunteers-applications`}
          render={() => (
            <VolunteersProvider>
              <VolunteersTabsWrapper>
                <VolunteersApplicationsScreen />
              </VolunteersTabsWrapper>
            </VolunteersProvider>
          )}
        />
        <Route
          exact
          path={`${match.path}/volunteers-importing`}
          render={() => (
            <VolunteersProvider>
              <VolunteersTabsWrapper>
                <ImportingVolunteersScreen />
              </VolunteersTabsWrapper>
            </VolunteersProvider>
          )}
        />
      </CampfireSwitch>
      <VolunteersTutorialDialog
        open={tutorialDialogOpen}
        onClose={() => {
          setTutorialDialogOpen(false);
        }}
      />
    </Page>
  );
});
