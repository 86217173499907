import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { EventAvailable } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 500,
    },
    subHeader: {
      fontSize: 14,
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingBottom: '10px',
    },
    toolBoxContainer: {
      color: theme.color.grey.neutralBrand800,
    },
  })
);

interface Props {
  header: string;
  subHeader: string;
  children: React.ReactElement;
}

export function AvailabilityHeader({ header, subHeader, children }: Props) {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.headerContainer}>
        <Box>
          <Typography style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center' }}>
            <EventAvailable style={{ fontSize: '16px', paddingRight: '5px', marginTop: '2px' }} /> {header}
          </Typography>
          <Typography className={classes.subHeader} color='textSecondary'>
            {subHeader}
          </Typography>
        </Box>
        <Box className={classes.toolBoxContainer}>{children}</Box>
      </Box>
    </Box>
  );
}
