import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import React from 'react';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { LeftMenuItemType } from '../../incoming-volunteer-model.gql';
import { GetIncomingVolunteerProfile_vm_profile_application_applicationRoles as ApplicationRoles } from '../../__generated__/GetIncomingVolunteerProfile';
import { TaskPendingReviewIcon } from '../../../../../../common/icons/TaskPendingReviewIcon';
import { TaskCompleteIcon } from '../../../../../../common/icons/TaskCompleteIcon';
import { RoleIcon } from '../../../../../../common/icons/RoleIconList';
import { TaskRejectedIcon } from '../../../../../../common/icons/TaskRejectedIcon';

interface Props {
  setLeftMenuItem: (x: LeftMenuItemType) => void;
  applicationRoles?: ApplicationRoles[];
}

const useStyles = () => {
  const { theme, isMobile } = useCampfireTheme();
  return makeStyles({
    listItem: {
      padding: isMobile ? '8px 20px' : '6px 25px',
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    taskListItem: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    taskListItemHover: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
    },
    taskListDisabled: {
      opacity: '0.5',
      cursor: 'default',
    },
    taskListItemSelected: {
      backgroundColor: theme.palette.grey[200],
    },
    taskListSubItem: {
      padding: isMobile ? '8px 40px' : '8px 64px',
    },
    iconContainer: {
      marginRight: isMobile ? 16 : 8,
    },
    borderBottom: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    borderRight: {
      borderRight: `1px solid ${theme.color.grey.border}`,
    },
  })();
};

const IncomingVolunteerRolesSection = (props: Props) => {
  const { setLeftMenuItem, applicationRoles } = props;
  const classes = useStyles();
  const { theme } = useCampfireTheme();

  const onClick = (role: ApplicationRoles) => {
    setLeftMenuItem({
      applicationRoleId: role.applicationRoleId,
      type: 'VOLUNTEER_ApplicationRoleType',
    });
  };

  return (
    <>
      <>
        <Grid container>
          <Grid item container alignItems='center' className={classes.listItem}>
            <Grid item>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '.6em 0',
                    }}
                  >
                    Role Applications
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {applicationRoles &&
            applicationRoles.map((role) => {
              return (
                <Box
                  display='flex'
                  alignContent='center'
                  alignItems='center'
                  width={1}
                  paddingX={1}
                  className={classes.taskListItem}
                  onClick={() => onClick(role)}
                >
                  <Box
                    display='flex'
                    alignContent='center'
                    alignItems='center'
                    flex={2}
                    className={classes.borderRight}
                    paddingY={2}
                  >
                    <Box display='flex' paddingX={2}>
                      {role.status === 'pending' ? (
                        <TaskPendingReviewIcon />
                      ) : role.status === 'approved' ? (
                        <TaskCompleteIcon isComplete={role.status === 'approved'} />
                      ) : (
                        <TaskRejectedIcon />
                      )}
                    </Box>
                    <Box>
                      <span>
                        <Typography variant='body2' style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}>
                          {role.role.icon ? (
                            <RoleIcon
                              iconName={role.role?.icon}
                              color={theme.color.grey.neutral300}
                              style={{ height: '17px', paddingRight: '7px' }}
                            />
                          ) : null}
                          {role.role.name}
                        </Typography>
                      </span>
                    </Box>
                  </Box>
                  <Box display='flex' alignContent='center' alignItems='center' flex={1} paddingLeft={2}>
                    <Typography variant='caption' color='textSecondary'>
                      {role.dateActioned
                        ? `Updated ${formatDistance(unpackToDateTime(role.dateActioned))}`
                        : 'Not reviewed'}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </Grid>
      </>
    </>
  );
};

export { IncomingVolunteerRolesSection };
