import { gql } from '@apollo/client';

export const GET_ADMIN_CONSOLE_APPLICANT_SCREENING_PANEL = gql`
  query GetAdminConsoleApplicantScreeningPanel {
    vm {
      screeningQuestions {
        screeningQuestionId
        label
        order
        correctAnswer
      }
    }
  }
`;

export const GET_APPLICATION_AUTO_VERIFICATION = gql`
  query GetApplicationAutoVerification {
    vm {
      applicationAutoVerification
    }
  }
`;

export const GET_APPLICATION_ENROL_ACTIVITIES = gql `
  query GetApplicationEnrolActivities {
    vm {
      configItem(key: "application-enrol-activity") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;

export const GET_APPLICATION_APPLY_ROLES = gql `
  query GetApplicationApplyRoles {
    vm {
      configItem(key: "application-apply-role") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;

export const GET_APPLICATION_AVAILABILITY_SIMPLE = gql `
  query GetApplicationAvailabilitySimple {
    vm {
      configItem(key: "application-availability-simple") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;

export const GET_APPLICATION_AVAILABILITY_COMPLEX = gql `
  query GetApplicationAvailabilityComplex {
    vm {
      configItem(key: "application-availability-complex") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;



