import { Cake, CakeActions, CakeContent, CakeHeader } from '@campfire/cake';
import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box, List, ListItem, ListItemIcon, ListItemText, Theme, Typography } from '@material-ui/core';
import { ChevronRight, EmojiPeople, Event, EventAvailable, SendRounded } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { arrayHead } from '../../common/functions/array-head';
import { TaskCompleteIcon } from '../../common/icons/TaskCompleteIcon';
import { useSession } from '../auth/useSession';
import { useUser } from '../auth/useUser';
import { useSnackbar } from '../config/useSnackbar';
import { recordInteraction } from '../interactions/recordInteraction';
import { useCampfireQuery } from '../network/useCampfireQuery';
import { useEndpointFetch } from '../network/useEndpointFetch';
import { GET_ALL_APPLICANT_TASKS } from './model/applicant-shell-query.gql';
import { GET_USER_INTERACTIONS } from './model/user-interactions-query.gql';
import { GetAllApplicantTasks, GetAllApplicantTasksVariables } from './model/__generated__/GetAllApplicantTasks';
import { GetUserInteractions, GetUserInteractionsVariables } from './model/__generated__/GetUserInteractions';
import { SUYPOverviewTasksListTask } from './model/__generated__/SUYPOverviewTasksListTask';
import {
  GET_APPLICATION_APPLY_ROLES,
  GET_APPLICATION_AVAILABILITY_SIMPLE,
  GET_APPLICATION_ENROL_ACTIVITIES,
} from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/admin-console-applicant-screening.gql';
import { GetApplicationAvailabilitySimple } from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/__generated__/GetApplicationAvailabilitySimple';
import { GetApplicationEnrolActivities } from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/__generated__/GetApplicationEnrolActivities';
import { GetApplicationApplyRoles } from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/__generated__/GetApplicationApplyRoles';

const CONTRACT_SUBMISSION_TOKEN_RETRY_LIMIT = 30;
const CONTRACT_SUBMISSION_TOKEN_REFRESH_INTERVAL = 5000;

type ApplicantShellTabs = 'required-tasks' | 'extra-tasks' | 'roles' | 'activities-availabilities';

type ApplicantTaskListItem = {
  task: SUYPOverviewTasksListTask;
  completedDate?: string;
  status?: string;
};

interface Props {
  progress: number;
  setProgress: (number: number, handleSuccess?: () => void) => void;
}

const SUYPOverview = (props: Props) => {
  const { progress, setProgress } = props;
  const [selectedTab, setSelectedTab] = useQueryParam<ApplicantShellTabs>('view');
  const { reloadSession } = useSession();
  // const tabClasses = useTabClasses();
  const { user } = useUser();
  const { userId } = user;
  const { postInteraction } = recordInteraction('vm');
  const { setSnackbar } = useSnackbar();

  useLayoutEffect(() => {
    if (selectedTab === undefined) {
      setSelectedTab('required-tasks');
    }
  }, []);

  const completeVolunteerProfile = useEndpointFetch<{ profileId: string }>('/vm/volunteer/profile/complete');

  const allApplicantTasksQuery = useCampfireQuery<GetAllApplicantTasks, GetAllApplicantTasksVariables>(
    GET_ALL_APPLICANT_TASKS,
    {
      options: {
        variables: {
          userId,
        },
      },
    }
  );
  const { data: interactionsResponse, loading: interactionsLoading } = useCampfireQuery<
    GetUserInteractions,
    GetUserInteractionsVariables
  >(GET_USER_INTERACTIONS, {
    options: {
      variables: {
        page: 'suyp',
        action: 'viewed',
      },
    },
  });

  const [applicationAvailabilitySimple, setApplicationAvailabilitySimple] = React.useState(false);
  const [applicationActivityEnrolment, setApplicationActivityEnrolment] = React.useState(false);
  const [applicationApplyRoles, setApplicationApplyRoles] = React.useState(false);

  const { data: availabilitySimple } = useCampfireQuery<GetApplicationAvailabilitySimple, undefined>(
    GET_APPLICATION_AVAILABILITY_SIMPLE
  );

  const { data: applicationActivity } = useCampfireQuery<GetApplicationEnrolActivities, undefined>(
    GET_APPLICATION_ENROL_ACTIVITIES
  );

  const { data: applyRoles } = useCampfireQuery<GetApplicationApplyRoles, undefined>(GET_APPLICATION_APPLY_ROLES);

  useEffect(() => {
    setApplicationAvailabilitySimple(availabilitySimple?.vm.configItem?.configItemValue?.flagValue ?? false);
    setApplicationActivityEnrolment(applicationActivity?.vm.configItem?.configItemValue?.flagValue ?? false);
    setApplicationApplyRoles(applyRoles?.vm.configItem?.configItemValue?.flagValue ?? false);
  }, [availabilitySimple, applicationActivity, applyRoles]);

  useEffect(() => {
    if (interactionsResponse && !interactionsResponse.vm.hasUserInteraction) postInteraction('suyp', 'viewed');
  }, [interactionsResponse]);

  const profileId = useMemo(() => {
    return allApplicantTasksQuery.data?.vm.profile?.profileId;
  }, [allApplicantTasksQuery.data]);

  const requiredTasks: Array<ApplicantTaskListItem> = useMemo(() => {
    if (!allApplicantTasksQuery.data) return [];
    return (
      allApplicantTasksQuery.data.vm.application?.relevantTasks
        // tasks from cakeType required
        .filter((task) => task.cake.cakeType === 'required' && !task.dateRemoved)
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((task) => {
          const maybeCompletedTask = allApplicantTasksQuery.data?.vm.profile?.completedTasks.find(
            (completedTask) => completedTask.task.taskId === task.taskId && completedTask.dateRevoked === null
          );
          return {
            task,
            completedDate: maybeCompletedTask?.dateLastUpdated,
            status: maybeCompletedTask?.status,
          };
        }) ?? []
    );
  }, [allApplicantTasksQuery.data]);

  const requiredCake = useMemo(() => {
    return arrayHead(
      (allApplicantTasksQuery?.data?.vm.application?.relevantTasks ?? []).filter(
        (task) => task.cake.cakeType === 'required'
      ) ?? []
    )?.cake;
  }, [allApplicantTasksQuery.data]);

  useMemo(() => {
    if (requiredTasks.length === 0) setProgress(0);
    if (
      requiredTasks.length === requiredTasks.filter((task) => !!task.completedDate && task.status === 'approved').length
    ) {
      setProgress(100);
    }
    const numberCompletedTasks = requiredTasks.reduce(
      (previous, current) => previous + (current.completedDate ? 1 : 0),
      0
    );
    return setProgress(Math.round((numberCompletedTasks / requiredTasks.length) * 100));
  }, [requiredTasks]);

  const requiredTasksComplete = progress === 100;

  const tasksRequiringApproval = useMemo(() => {
    return requiredTasks.filter((task) => task.status === 'pending' || task.status === 'rejected');
  }, [requiredTasks]);

  /**
   * Bunch of functionality to handle retry refresh token.
   * We spin this up if the volunteer profile successfully submits, because we're expecting there to be a resulting
   * volunteer entity within some reasonable time period.
   */
  const [refreshTokenTrys, setRefreshTokenTrys] = useState<number | undefined>();

  useEffect(() => {
    const isActive = refreshTokenTrys !== undefined && refreshTokenTrys < CONTRACT_SUBMISSION_TOKEN_RETRY_LIMIT;
    let interval: any;
    if (isActive) {
      interval = setInterval(() => {
        reloadSession();
        setRefreshTokenTrys((refreshTokenTrysPrev) => (refreshTokenTrysPrev ? refreshTokenTrysPrev + 1 : 1));
      }, CONTRACT_SUBMISSION_TOKEN_REFRESH_INTERVAL);
    } else if (!isActive && refreshTokenTrys !== undefined && interval) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [refreshTokenTrys]);

  const taskListItemClasses = useTaskListItemClasses();

  const handleSubmit = () => {
    if (!profileId) {
      return;
    }
    completeVolunteerProfile.run({ profileId }).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to submit volunteer profile',
          variant: 'error',
        });
        return;
      }

      setSnackbar({
        open: true,
        message: 'Volunteer profile submitted',
        variant: 'success',
      });
      reloadSession();
      setRefreshTokenTrys(1);
    });
  };

  return (
    <Box
      overflow='hidden auto'
      bgcolor='#f1f1f1'
      display='flex'
      flex='1 1 auto'
      flexDirection='column'
      width={1}
      paddingTop={2}
      paddingBottom={8}
      paddingX={1}
      boxSizing='border-box'
      style={{
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      <CircularProgressOverlay
        isLoading={
          interactionsLoading ||
          completeVolunteerProfile.isLoading ||
          (refreshTokenTrys !== undefined &&
            refreshTokenTrys > 0 &&
            refreshTokenTrys < CONTRACT_SUBMISSION_TOKEN_RETRY_LIMIT)
        }
      />

      <Box>
        {selectedTab === 'required-tasks' && (
          <>
            {/* Only show message to people who have completed all tasks with any that require approval */}
            {tasksRequiringApproval.length > 0 && requiredTasksComplete ? (
              <Box width={1} display='flex' justifyContent='center'>
                <Box width={1} maxWidth={720} marginBottom={3}>
                  <Cake>
                    <CakeActions>
                      <VolunteerProfileAwaitingReviewMessage />
                    </CakeActions>
                  </Cake>
                </Box>
              </Box>
            ) : requiredTasksComplete ? (
              <Box width={1} display='flex' justifyContent='center'>
                <Box width={1} maxWidth={720} marginBottom={3}>
                  <Cake>
                    <CakeActions>
                      <VolunteerProfileSubmitYourProfileMessage handleSubmit={handleSubmit} />
                    </CakeActions>
                  </Cake>
                </Box>
              </Box>
            ) : (
              ''
            )}

            <Box width={1} display='flex' justifyContent='center'>
              <Box width={1} maxWidth={720} marginBottom={3}>
                <Cake>
                  <Box style={{ padding: '20px 30px 10px 30px' }}>
                    <Typography
                      style={{
                        fontWeight: 600,
                        fontSize: '19px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#444',
                      }}
                    >
                      Required Tasks
                    </Typography>
                    <Typography variant={'body2'} style={{ fontSize: '13px', color: '#808080' }}>
                      {requiredCake ? requiredCake.description : ''}
                    </Typography>
                  </Box>
                  <CakeContent style={{ padding: '8px 0px 0px 0px' }}>
                    <List style={{ paddingBottom: '0' }}>
                      {requiredTasks.map((task) => (
                        <TaskListItem task={task} key={task.task.taskId} />
                      ))}
                    </List>
                  </CakeContent>
                </Cake>
              </Box>
            </Box>
            {applicationActivityEnrolment || applicationApplyRoles || applicationAvailabilitySimple ? (
              <Box width={1} display='flex' justifyContent='center'>
                <Box width={1} maxWidth={720} marginBottom={3}>
                  <Cake>
                    <Box style={{ padding: '20px 30px 10px 30px' }}>
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: '19px',
                          display: 'flex',
                          alignItems: 'center',
                          color: '#444',
                        }}
                      >
                        Additional
                      </Typography>
                      <Typography variant={'body2'} style={{ fontSize: '13px', color: '#808080' }}>
                        Complete the below items to support your application and suitability
                      </Typography>
                    </Box>
                    <CakeContent style={{ padding: '8px 0px 0px 0px' }}>
                      <List style={{ paddingBottom: '0' }}>
                        {applicationApplyRoles && (
                          <Link
                            to={{
                              pathname: `/roles`,
                            }}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <ListItem className={taskListItemClasses.listItem}>
                              <ListItemIcon style={{ paddingLeft: '15px' }}>
                                <EmojiPeople style={{ fontSize: '25px' }} />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography
                                  style={{
                                    fontWeight: 600,
                                    fontSize: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#444',
                                  }}
                                >
                                  Roles
                                </Typography>
                                <Typography variant={'body2'} style={{ fontSize: '13px', color: '#808080' }}>
                                  Apply for roles and complete any required additional information
                                </Typography>
                              </ListItemText>
                              <ListItemIcon style={{ paddingLeft: '15px' }}>
                                <ChevronRight style={{ fontSize: 24, color: '#777' }} />
                              </ListItemIcon>
                            </ListItem>
                          </Link>
                        )}
                        {applicationActivityEnrolment && (
                          <Link
                            to={{
                              pathname: `/activities`,
                            }}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <ListItem className={taskListItemClasses.listItem}>
                              <ListItemIcon style={{ paddingLeft: '15px' }}>
                                <Event style={{ fontSize: '25px' }} />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography
                                  style={{
                                    fontWeight: 600,
                                    fontSize: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#444',
                                  }}
                                >
                                  Activities
                                </Typography>
                                <Typography variant={'body2'} style={{ fontSize: '13px', color: '#808080' }}>
                                  Indicate interest and apply for activities in your program
                                </Typography>
                              </ListItemText>
                              <ListItemIcon style={{ paddingLeft: '15px' }}>
                                <ChevronRight style={{ fontSize: 24, color: '#777' }} />
                              </ListItemIcon>
                            </ListItem>
                          </Link>
                        )}
                        {applicationAvailabilitySimple && (
                          <Link
                            to={{
                              pathname: `/availability`,
                            }}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <ListItem className={taskListItemClasses.listItem}>
                              <ListItemIcon style={{ paddingLeft: '15px' }}>
                                <EventAvailable style={{ fontSize: '25px' }} />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography
                                  style={{
                                    fontWeight: 600,
                                    fontSize: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#444',
                                  }}
                                >
                                  Availability
                                </Typography>
                                <Typography variant={'body2'} style={{ fontSize: '13px', color: '#808080' }}>
                                  Indicate your general availability
                                </Typography>
                              </ListItemText>
                              <ListItemIcon style={{ paddingLeft: '15px' }}>
                                <ChevronRight style={{ fontSize: 24, color: '#777' }} />
                              </ListItemIcon>
                            </ListItem>
                          </Link>
                        )}
                      </List>
                    </CakeContent>
                  </Cake>
                </Box>
              </Box>
            ) : null}
          </>
        )}
        {selectedTab === 'activities-availabilities' && (
          <>
            <Box width={1} display='flex' justifyContent='center'>
              <Box width={1} maxWidth={720} marginBottom={3}>
                <Cake>
                  {/* Only show message to people who have completed all tasks with any that require approval */}
                  {tasksRequiringApproval.length > 0 && requiredTasksComplete ? (
                    <CakeActions>
                      <VolunteerProfileAwaitingReviewMessage />
                    </CakeActions>
                  ) : requiredTasksComplete ? (
                    <CakeActions>
                      <VolunteerProfileSubmitYourProfileMessage handleSubmit={handleSubmit} />
                    </CakeActions>
                  ) : (
                    ''
                  )}
                </Cake>
              </Box>
            </Box>

            <Box width={1} display='flex' justifyContent='center'>
              <Box width={1} maxWidth={720} marginBottom={3}>
                <Cake>
                  <CakeHeader
                    heading='Complete these tasks to set up your Volaby profile'
                    description={
                      <Typography variant='body2' color='textSecondary'>
                        {requiredCake ? requiredCake.description : ''}
                      </Typography>
                    }
                  />
                  <CakeContent style={{ padding: '8px 0px 0px 0px' }}>
                    <List>
                      {requiredTasks.map((task) => (
                        <TaskListItem task={task} key={task.task.taskId} />
                      ))}
                    </List>
                  </CakeContent>
                </Cake>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

interface TaskListItemProps {
  task: ApplicantTaskListItem;
}

const TaskListItem = ({ task }: TaskListItemProps) => {
  const taskListItemClasses = useTaskListItemClasses();
  const location = useLocation();

  return (
    <Link
      to={{
        pathname: `/task/${task.task.taskId}`,
        search: task.completedDate ? `${location.search}` : location.search,
      }}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <ListItem className={taskListItemClasses.listItem}>
        <ListItemIcon style={{ paddingLeft: '15px' }}>
          <TaskCompleteIcon isComplete={!!task.completedDate} />
        </ListItemIcon>
        <ListItemText>
          <Typography
            style={{ fontWeight: 600, fontSize: '15px', display: 'flex', alignItems: 'center', color: '#444' }}
          >
            {task.task.title}
          </Typography>
          <Typography variant={'body2'} style={{ fontSize: '13px', color: '#808080' }}>
            {task.task.description}
          </Typography>
        </ListItemText>
        <ListItemIcon style={{ paddingLeft: '15px' }}>
          <ChevronRight style={{ fontSize: 24, color: '#777' }} />
        </ListItemIcon>
      </ListItem>
    </Link>
  );
};

interface WelcomeCakeHeaderProps {
  preferredName?: string;
  hasUserInteraction?: boolean;
  requiredTasksComplete?: boolean;
}

const useTaskListItemClasses = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
      border: '.6px solid #00000010',
      padding: '15px',
    },
    checkIcon: {
      fontSize: 29,
      color: '#00ed7e',
    },
    circleIcon: {
      fontSize: 29,
      color: theme.palette.grey[200],
    },
  })
);

const VolunteerProfileAwaitingReviewMessage = () => (
  <Box paddingRight={1} paddingBottom={1} width={1} display='flex' justifyContent='center'>
    <Box textAlign='center' paddingY={3}>
      <Typography variant='h5' style={{ fontWeight: 'bold' }} gutterBottom>
        <span role='img' aria-label='yay' style={{ marginRight: 8 }}>
          ⏳
        </span>
        Awaiting review
      </Typography>
      <Typography
        variant='body2'
        color='textSecondary'
      >{`We'll email you as soon as somebody has reviewed your tasks.`}</Typography>
    </Box>
  </Box>
);

const VolunteerProfileSubmitYourProfileMessage = ({ handleSubmit }: { handleSubmit: () => void }) => (
  <Box width={1} paddingY={2} textAlign='center' display='flex' justifyContent='center'>
    <Box>
      <Box textAlign='center' paddingBottom={1}>
        <Typography variant='h5' style={{ fontWeight: 'bold' }} gutterBottom>
          All tasks complete
        </Typography>
        <Typography variant='body2' color='textSecondary'>{`Ready to activate your volunteer profile?`}</Typography>
      </Box>
      <TabletButton
        variant='contained'
        color='primary'
        style={{ margin: 8 }}
        size='large'
        onClick={handleSubmit}
        endIcon={<SendRounded />}
      >
        {'Submit your profile'}
      </TabletButton>
    </Box>
  </Box>
);

export { SUYPOverview };
