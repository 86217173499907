import { Box, Grid, Typography, IconButton, Button } from '@material-ui/core';
import React, { memo, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import {
  Loop as LoopIcon,
  CalendarToday as CalendarIcon,
  ChevronRight as ChevronRightIcon,
  VisibilityOutlined,
  EventAvailable,
  Add,
} from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { unpackToTime } from '@campfire/hot-date';
import { useVolunteerCommonProfileContext } from '../../VolunteerCommonProfileContext';
import { VolunteerCommonProfileActivityEnrolment } from '../../__generated__/VolunteerCommonProfileActivityEnrolment';
import { EnrolmentForecast } from './EnrolmentForecast';
import { ActivityEnrolmentDialog } from './ActivityEnrolmentDialog';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { ActivityFilters } from '../../../../../global/activity-filters/ActivityFilters';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import {
  GetAllActivitiesForVolunteer,
  GetAllActivitiesForVolunteerVariables,
} from '../../../../../global/activity-filters/__generated__/GetAllActivitiesForVolunteer';
import { ACTIVITIES_FILTERS_LIST } from '../../../../../global/activity-filters/activity-filters-activity.gql';

export const VolunteerActivityEnrolmentsSection = memo(
  ({
    activityEnrolments,
    volunteerId,
    profileId,
  }: {
    activityEnrolments: VolunteerCommonProfileActivityEnrolment[];
    volunteerId: string;
    profileId: string;
  }) => {
    const { theme } = useCampfireTheme();
    const {
      volunteerNameOptions: { preferredName },
      isHistoricalActivityEnrolment,
      setIsHistoricalActivityEnrolment,
      volunteer,
      reloadNewData,
    } = useVolunteerCommonProfileContext();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const apiUrl = useApiUrl();
    const { setSnackbar } = useSnackbar();

    const { run } = useCampfireFetch({
      defer: true,
    });

    const programIds = volunteer?.programs.map((a) => a.programId) ?? [];
    const { data } = useCampfireQuery<GetAllActivitiesForVolunteer, GetAllActivitiesForVolunteerVariables>(
      ACTIVITIES_FILTERS_LIST,
      {
        options: {
          variables: {
            programIds: programIds,
          },
        },
      }
    );

    const setSelectedActivities = (activityList: string[]) => {
      run({
        method: 'post',
        data: {
          activityIds: activityList,
          volunteerId: volunteer?.volunteerId,
        },
        url: `${apiUrl}/vm/activity/enrolment/enrol`,
      }).then((response) => {
        if (response.ok) {
          setSnackbar({
            open: true,
            message: 'Volunteer enrolled',
            variant: 'success',
          });
        }
        if (!response.ok) {
          setSnackbar({
            open: true,
            message: 'Error while enrolling',
            variant: 'error',
          });
        }
      });
      reloadNewData();
    };

    const allActivities = data?.vm.programs.flatMap((a) => a.activities);
    const activities = activityEnrolments.flatMap((a) => a.activity);

    const activitiesList =
      activities.length === 0
        ? allActivities
        : allActivities?.filter((a) => !activities.some((b) => b.activityId === a.activityId));

    return (
      <Grid container spacing={2} direction='column' style={{ position: 'relative' }}>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box>
              <Typography
                style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center', color: '#333' }}
              >
                <EventAvailable style={{ fontSize: '14px', paddingRight: '5px' }} /> Activity Enrolments
              </Typography>
              <Typography variant='body2' style={{ color: '#464646' }} align='left'>
                Activities that {preferredName} is enrolled in
              </Typography>
            </Box>
            <Box>
              <Button
                variant='outlined'
                startIcon={<VisibilityOutlined />}
                style={{
                  height: 36,
                  borderRadius: 6,
                  border: '1px solid #DCDCDC',
                  color: theme?.color?.grey?.neutral400,
                  textTransform: 'capitalize',
                  marginTop: '4px',
                }}
                onClick={() => setIsHistoricalActivityEnrolment(!isHistoricalActivityEnrolment)}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: '13px',
                    lineHeight: '19px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {`Viewing: ${isHistoricalActivityEnrolment ? 'Historical' : 'Current'}`}
                </Typography>
              </Button>
              <TabletButton
                size='small'
                variant='contained'
                color='primary'
                aria-controls='options-menu'
                aria-haspopup='true'
                onClick={() => setDialogOpen(true)}
                startIcon={<Add />}
                style={{
                  border: '1px solid transparent',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                  padding: '6px 12px',
                  marginLeft: '10px',
                  marginTop: '2px',
                  color: '#fff',
                }}
              >
                Add
              </TabletButton>
            </Box>
          </Box>
          <hr style={{ borderTop: '.5px solid #e7e7e7', margin: '20px 0' }} />
          <VolunteerActivityEnrolmentsBlock
            activityEnrolments={activityEnrolments}
            volunteerId={volunteerId}
            profileId={profileId}
          />
        </Grid>
        {dialogOpen && activitiesList && (
          <ActivityFilters
            activities={activitiesList}
            selectedActivities={[]}
            setSelectedActivities={setSelectedActivities}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onCancel={() => setDialogOpen(false)}
            fullScreen={false}
            simple
            addVol
          />
        )}
      </Grid>
    );
  }
);

export const VolunteerActivityEnrolmentsBlock = ({
  activityEnrolments,
  volunteerId,
  profileId,
}: {
  activityEnrolments: VolunteerCommonProfileActivityEnrolment[];
  volunteerId: string;
  profileId: string;
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedActivityEnrolment, setSelectedActivityEnrolment] = useState<VolunteerCommonProfileActivityEnrolment>();
  const { isHistoricalActivityEnrolment } = useVolunteerCommonProfileContext();

  const { theme } = useCampfireTheme();

  const onSelectEnrolment = (activityEnrolment: VolunteerCommonProfileActivityEnrolment) => {
    setSelectedActivityEnrolment(activityEnrolment);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedActivityEnrolment(undefined);
  };

  const parsedActivityEnrolments = useMemo(() => {
    if (isHistoricalActivityEnrolment) {
      return activityEnrolments
        .filter(
          (ae, index) =>
            activityEnrolments.findIndex((fi) => ae.activity.activityId === fi.activity.activityId) === index
        )
        .filter(
          (activityEnrolment) =>
            activityEnrolment.dateRemoved &&
            activityEnrolments.filter(
              (item) => item.activity.activityId === activityEnrolment.activity.activityId && !item.dateRemoved
            ).length === 0
        );
    }
    return activityEnrolments.filter(
      (activityEnrolment) => activityEnrolment.activityEnrolmentId !== null && activityEnrolment.dateRemoved === null
    );
  }, [activityEnrolments, isHistoricalActivityEnrolment]);

  return (
    <>
      <Box>
        {parsedActivityEnrolments.map((activityEnrolment) => {
          const recurrenceHumanReadable =
            activityEnrolment.activity.__typename === 'VOLUNTEER_RecurringActivityType'
              ? activityEnrolment.activity.schedule.recurrences[0].humanReadable
              : 'Flexible';

          const activityStartTime = unpackToTime(activityEnrolment.activity.startTime).toFormat('h:mm a');
          const activityEndTime = unpackToTime(activityEnrolment.activity.endTime).toFormat('h:mm a');

          const getTime = () => {
            const { startDate, endDate } = activityEnrolment.activity;
            if (!startDate && !endDate) {
              return '';
            }

            const diffNow = DateTime.fromISO(startDate)
              .diffNow()
              .as('days');
            if (diffNow > 0) {
              return `Starts ${DateTime.fromISO(startDate).toFormat('dd')} ${DateTime.fromISO(startDate).toFormat(
                'LLLL, y'
              )}`;
            }
            if (diffNow <= 0 && endDate) {
              return `Ends ${DateTime.fromISO(endDate).toFormat('dd')} ${DateTime.fromISO(endDate).toFormat(
                'LLLL, y'
              )}`;
            }
            return '';
          };
          const time = getTime();

          return (
            <Box
              key={activityEnrolment.activityEnrolmentId}
              display='flex'
              style={{
                backgroundColor: '#F9F9F9',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '8px',
                marginTop: '16px',
                cursor: 'pointer',
              }}
              onClick={() => onSelectEnrolment(activityEnrolment)}
            >
              <Grid container>
                <Grid item xs={4}>
                  <Box py='12px' pl='16px' pr='10px'>
                    <Typography style={{ fontSize: '16px', fontWeight: 700 }}>
                      {activityEnrolment.activity.name}
                    </Typography>
                    <Typography style={{ fontSize: '12px', color: theme.color.grey.neutral200 }}>
                      {activityEnrolment.activity.program.name}
                    </Typography>
                    <Box display='flex' alignItems='center' marginTop='16px'>
                      {recurrenceHumanReadable && (
                        <LoopIcon
                          style={{
                            width: '15px',
                            height: '15px',
                            paddingRight: '4px',
                            marginLeft: '-2px',
                            color: theme.color.grey.neutral200,
                          }}
                        />
                      )}
                      <Typography
                        style={{
                          fontSize: '11px',
                          color: theme.color.grey.neutral200,
                        }}
                      >
                        {recurrenceHumanReadable}
                        {activityEnrolment.activity.startTime && activityEnrolment.activity.endTime
                          ? `, ${activityStartTime} - ${activityEndTime}`
                          : ''}
                      </Typography>
                    </Box>

                    {time && (
                      <Box display='flex' alignItems='center'>
                        <CalendarIcon
                          style={{ color: theme.color.grey.neutral200, fontSize: 12, marginRight: '6px' }}
                        />
                        <Typography style={{ fontSize: 11, color: theme.color.grey.neutral200, marginTop: '1px' }}>
                          {time}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box display='flex' height='100%'>
                    <EnrolmentForecast volunteerId={volunteerId} activityEnrolment={activityEnrolment} />
                    <Box display='flex' width='56px' alignItems='center' justifyContent='center'>
                      <IconButton>
                        <ChevronRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })}
        {parsedActivityEnrolments.length === 0 && (
          <Typography
            variant='body2'
            style={{ fontSize: '13px', color: '#494949', textAlign: 'center', padding: '2rem 0' }}
          >
            No results found
          </Typography>
        )}
      </Box>
      {selectedActivityEnrolment && (
        <ActivityEnrolmentDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          activityEnrolment={selectedActivityEnrolment}
          volunteerId={volunteerId}
          profileId={profileId}
        />
      )}
    </>
  );
};
