import { unpackToDate } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import { Form, Formik } from 'formik';
import { Box, Typography, List, ListItem, Button, Theme } from '@material-ui/core';
import { Add, Cancel, CheckCircle, Edit, Remove } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ExpandableCard, getStatusColors } from '../../../../common/cards/ExpandableCard';
import { getTaskFieldValues } from '../../../../global/applicant-shell/model/suyp-task-submission-handler';
import { useUser } from '../../../../global/auth/useUser';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useMyTasksActions } from './my-tasks-actions';
import { GET_MY_TASKS } from './my-tasks-model.gql';
import { GetMyTasks, GetMyTasksVariables } from './__generated__/GetMyTasks';
import { MyTask } from './__generated__/MyTask';
import { MyProfile } from './__generated__/MyProfile';
import { CompletedTaskFormFieldList } from '../../../../common/form/task-form/CompletedTaskViewer';
import { getVolunteerProfileHardcodedFieldValues } from '../../../../common/form/task-form/model/get-volunteer-profile-hardcoded-field-values';
import { initTaskFormFormikValues, TaskFormValuesType } from '../../../../common/form/task-form/model/task-form-init';
import { TaskFormFieldList } from '../../../../common/form/task-form/TaskFormFieldList';
import { dynamicValidationSchema } from '../../../../common/form/task-form/model/validation-schema';
import { TaskFormFieldValue } from '../../../../common/form/task-form/model/__generated__/TaskFormFieldValue';
import { deserializeTaskItems } from '../../../admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';
import { CompletedTask_taskFieldValues as CompletedTaskFieldValuesType } from '../../../../common/form/task-form/model/__generated__/CompletedTask';
import { SimpleCompletedTaskValue } from './SimpleCompletedTaskValue';
import { sharedStyles } from '../../activities-v2/ActivityStyles/shared';

interface MyTasksSectionProps {
  title: string;
  children: any;
}
interface MyTaskListItemProps {
  task: MyTask;
  myProfile?: MyProfile | null;
  refetchMyTasks: () => void | undefined;
}
interface NewTaskListItemContentProps {
  task: MyTask;
  setIsActionCancelled: (x: boolean) => void;
  refetchMyTasks: () => void | undefined;
}
interface CompletedTaskListItemContentProps {
  myProfile?: MyProfile | null;
  task: MyTask;
  refetchMyTasks: () => void | undefined;
}
interface StatusIconProps {
  color: string;
  completed?: boolean;
}

export const StatusIcon = (props: StatusIconProps) =>
  props.completed ? (
    <CheckCircle
      style={{
        fontSize: '22px',
        color: props.color,
      }}
    />
  ) : (
    <Cancel
      style={{
        fontSize: '22px',
        color: props.color,
      }}
    />
  );

interface EdittingTaskListItemProps {
  task: MyTask;
  taskItemValues: { [key: string]: TaskFormFieldValue };
  onCancel: () => void;
  completedTaskId: string;
  onSaveSuccess?: () => void;
  userId?: string;
}

export const EdittingTaskListItem = ({
  task,
  taskItemValues,
  onCancel,
  completedTaskId,
  onSaveSuccess,
  userId,
}: EdittingTaskListItemProps) => {
  const { userIdentity } = useUser();
  const { runSaveCompletedTask } = useMyTasksActions();
  const { theme } = useCampfireTheme();

  const taskItems = deserializeTaskItems(task.taskItems.sort((a, b) => (a.order > b.order ? 1 : -1)));

  const validationSchema = dynamicValidationSchema(taskItems);

  const initialFormikValues = useMemo(() => {
    if (task.taskItems.length === 0) return undefined;
    return initTaskFormFormikValues(task.taskItems, taskItemValues);
  }, [taskItemValues]);

  return (
    <>
      <Typography variant='subtitle1' color='textSecondary'>
        {task.description}
      </Typography>
      {initialFormikValues && (
        <Box marginBottom={2}>
          <Formik
            initialValues={initialFormikValues}
            onSubmit={async (values) => {
              const taskFieldValues = await getTaskFieldValues(taskItems, values);
              const data = {
                taskId: task.taskId,
                userId: userId || userIdentity.userId,
                taskFieldValues,
                completedTaskId,
              };
              runSaveCompletedTask({ ...data, handleSuccess: onSaveSuccess || (() => {}) });
            }}
            validationSchema={validationSchema}
          >
            {({ values }: { values: TaskFormValuesType }) => {
              return (
                <Form>
                  <TaskFormFieldList taskItems={taskItems} values={values} />
                  <Box maxWidth={720} display='flex' justifyContent='flex-end'>
                    <TabletButton
                      variant='text'
                      style={{
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        textTransform: 'none',
                        padding: '7px 12px',
                        minWidth: '40px',
                        marginRight: '8px',
                      }}
                      onClick={onCancel}
                    >
                      Cancel
                    </TabletButton>
                    <TabletButton
                      variant='contained'
                      style={{
                        backgroundColor: theme.color.secondary.main900,
                        color: '#ffffff',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        textTransform: 'none',
                        padding: '7px 12px',
                        minWidth: '40px',
                      }}
                      type='submit'
                    >
                      Save
                    </TabletButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}
    </>
  );
};

const NewTaskListItemContent = ({ task, setIsActionCancelled, refetchMyTasks }: NewTaskListItemContentProps) => {
  const { userIdentity } = useUser();
  const { runSaveCompletedTask } = useMyTasksActions();
  const { theme } = useCampfireTheme();

  const taskItems = task.taskItems.sort((a, b) => (a.order > b.order ? 1 : -1));
  const parsedTaskItems = deserializeTaskItems(taskItems);

  const validationSchema = dynamicValidationSchema(parsedTaskItems);

  const initialFormikValues = useMemo(() => {
    if (taskItems.length === 0) return undefined;
    return initTaskFormFormikValues(taskItems, undefined);
  }, [taskItems]);

  const handleSuccess = () => {
    if (refetchMyTasks) refetchMyTasks();
  };

  return (
    <>
      <Typography variant='subtitle1' color='textSecondary'>
        {task.description}
      </Typography>
      {initialFormikValues && (
        <Box marginBottom={2}>
          <Formik
            initialValues={initialFormikValues}
            onSubmit={async (values) => {
              const taskFieldValues = await getTaskFieldValues(parsedTaskItems, values);
              const data = {
                taskId: task.taskId,
                userId: userIdentity.userId,
                taskFieldValues,
              };

              runSaveCompletedTask({ ...data, handleSuccess });
            }}
            validationSchema={validationSchema}
          >
            {({ values }: { values: TaskFormValuesType }) => {
              return (
                <Form>
                  <TaskFormFieldList taskItems={parsedTaskItems} values={values} />
                  <Box maxWidth={720} display='flex' justifyContent='flex-end'>
                    <TabletButton
                      variant='text'
                      style={{
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        textTransform: 'none',
                        padding: '7px 12px',
                        minWidth: '40px',
                        marginRight: '8px',
                      }}
                      onClick={() => setIsActionCancelled(true)}
                    >
                      Cancel
                    </TabletButton>
                    <TabletButton
                      variant='contained'
                      style={{
                        backgroundColor: theme.color.secondary.main900,
                        color: '#ffffff',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        textTransform: 'none',
                        padding: '7px 12px',
                        minWidth: '40px',
                      }}
                      type='submit'
                    >
                      Save
                    </TabletButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}
    </>
  );
};
const CompletedTaskListItemContent = ({ myProfile, task, refetchMyTasks }: CompletedTaskListItemContentProps) => {
  const completedTask = Array.from(myProfile?.completedTasks || [])
    .sort((a, b) => (a.dateSubmitted > b.dateSubmitted ? -1 : 1))
    .find((ct) => ct.task.taskId === task.taskId);
  const [isEditting, setIsEditting] = React.useState(false);
  const { theme } = useCampfireTheme();

  const completedOnByText =
    completedTask?.dateManagerUpdated && completedTask?.managerUpdatedBy
      ? `Updated on ${unpackToDate(completedTask.dateManagerUpdated).toFormat('d LLLL y')} by ${
          completedTask.managerUpdatedBy.profile.preferredName
        } ${completedTask.managerUpdatedBy.profile.lastName}`
      : `Submitted on ${unpackToDate(completedTask?.dateSubmitted).toFormat('d LLLL y')} by ${
          myProfile?.preferredName
        } ${myProfile?.lastName}`;

  const volunteerProfileHardCodedFieldValues = useMemo(() => {
    if (completedTask?.task.order !== -1 || !myProfile) {
      return undefined;
    }
    return getVolunteerProfileHardcodedFieldValues(myProfile);
  }, [myProfile, completedTask]);

  const taskItems = useMemo(() => {
    if (!task) return [];
    return task.taskItems ?? [];
  }, [task]);

  const keyTaskItemValues = useMemo(() => {
    if (!completedTask) return [];
    return completedTask.taskFieldValues.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.field.taskFieldId]: curr,
      }),
      {}
    );
  }, [completedTask]);

  const removedTaskFieldValues =
    completedTask?.taskFieldValues.filter((taskFieldValue) => {
      return !taskItems.find((taskItem) => {
        if (taskItem.__typename === 'VOLUNTEER_TaskItemFieldType') {
          return taskItem.field.taskFieldId === taskFieldValue.field.taskFieldId;
        }
        return false;
      });
    }) || [];

  if (!completedTask) {
    return null;
  }

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        width={'90%'}
        marginLeft={'auto'}
        marginRight={'auto'}
        paddingTop={'15px'}
      >
        <Typography variant='subtitle2' color='textSecondary'>
          {completedOnByText}
        </Typography>
        {task.allowUpdate ? (
          <TabletButton
            // data-track='actCnl-overview-status-dropdown'
            size='small'
            variant='outlined'
            color='primary'
            aria-controls='task-status-menu'
            aria-haspopup='true'
            onClick={() => setIsEditting(true)}
            startIcon={<Edit />}
            style={{
              border: '1px solid #9e9e9e',
              boxSizing: 'border-box',
              borderRadius: '4px',
              padding: '2px 7px',
              color: theme.color.grey.neutral300,
              marginRight: '8px',
              marginTop: '5px',
            }}
          >
            {'Edit Task'}
          </TabletButton>
        ) : (
          ''
        )}
      </Box>
      {isEditting ? (
        <EdittingTaskListItem
          task={task}
          taskItemValues={keyTaskItemValues}
          onCancel={() => setIsEditting(false)}
          completedTaskId={completedTask.completedTaskId}
          onSaveSuccess={refetchMyTasks}
        />
      ) : (
        <Box style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
          <CompletedTaskFormFieldList
            taskItems={deserializeTaskItems(taskItems)}
            taskItemValues={keyTaskItemValues}
            isTaskZero={!!volunteerProfileHardCodedFieldValues}
            volunteerProfileValues={volunteerProfileHardCodedFieldValues}
          />
          <RemovedTaskItems taskFieldValues={removedTaskFieldValues} />
        </Box>
      )}
    </>
  );
};

const useRemovedTaskItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid lightgray',
      borderRadius: 6,
      padding: '1rem 1.5rem',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .text': {
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.grey.neutral400,
      },
      '& .button': {
        color: theme.color.grey.neutral400,
        border: 'none',
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: 500,
      },
    },
    subTitle: {
      fontSize: '12px',
      color: theme.color.grey.neutral300,
      paddingTop: '3px',
    },
    fieldTitle: {
      fontSize: 14,
      fontWeight: 500,
      color: theme.color.grey.neutralBrand800,
    },
  })
);

interface RemovedTaskItemsProps {
  taskFieldValues: CompletedTaskFieldValuesType[];
}

export const RemovedTaskItems = ({ taskFieldValues }: RemovedTaskItemsProps) => {
  const classes = useRemovedTaskItemStyles();
  const buttonClasses = sharedStyles.button();
  const [showMore, setShowMore] = React.useState(false);
  if (taskFieldValues.length === 0) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography className='text'>Removed Task Fields</Typography>
        <Button
          endIcon={showMore ? <Remove style={{ fontSize: '16px' }} /> : <Add style={{ fontSize: '16px' }} />}
          classes={buttonClasses}
          className='button'
          onClick={() => setShowMore(!showMore)}
        >
          Show
        </Button>
      </Box>
      {showMore && (
        <>
          <Typography className={classes.subTitle}>
            These task fields were submitted as part of this task, but have since been removed by a volunteer manager.
            If the volunteer is to re-complete this task, these fields will no longer display.
          </Typography>
          <Box>
            {taskFieldValues.map((taskFieldValue) => (
              <Box marginTop='0.5rem'>
                <Typography className={classes.fieldTitle}>{taskFieldValue.field.name}</Typography>
                <SimpleCompletedTaskValue taskFieldValue={taskFieldValue} />
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

const MyTaskListItem = ({ task, myProfile, refetchMyTasks }: MyTaskListItemProps) => {
  const [isActionCancelled, setIsActionCancelled] = useState<boolean>(false);
  const { theme } = useCampfireTheme();
  const isCompleted = !!myProfile?.completedTasks.find((ct) => ct.task.taskId === task.taskId);
  const { iconColor } = getStatusColors(isCompleted ? 'completed' : 'new', theme);

  return (
    <Box pb={'8px'} key={task.taskId}>
      <ExpandableCard
        startIcon={<StatusIcon color={iconColor} completed={isCompleted} />}
        cardTitle={task.title}
        status={isCompleted ? 'completed' : 'new'}
        isActionCancelled={isActionCancelled}
        setIsActionCancelled={setIsActionCancelled}
      >
        {isCompleted ? (
          <CompletedTaskListItemContent myProfile={myProfile} task={task} refetchMyTasks={refetchMyTasks} />
        ) : (
          <NewTaskListItemContent
            task={task}
            setIsActionCancelled={setIsActionCancelled}
            refetchMyTasks={refetchMyTasks}
          />
        )}
      </ExpandableCard>
    </Box>
  );
};

const MyTasksSection = ({ title, children }: MyTasksSectionProps) => {
  return (
    <Box pb={'42px'}>
      <Typography
        variant='h4'
        style={{ fontSize: '20px', fontWeight: 900, letterSpacing: '-0.02em', padding: '0px 0px 20px 20px' }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};

const MyTasks = ({ refetchParentTasks, viewMode }: { refetchParentTasks?: () => void; viewMode?: string }) => {
  const { userIdentity } = useUser();
  // const { theme } = useCampfireTheme();

  const { data, loading, refetch: refetchMyTasks } = useCampfireQuery<GetMyTasks, GetMyTasksVariables>(GET_MY_TASKS, {
    options: {
      variables: {
        userId: userIdentity.userId,
        bulletinView: viewMode,
      },
    },
  });

  // const myIncompleteTasks =
  //   data?.vm.cake.tasks
  //     .filter(
  //       (task) => !data?.vm.profile?.completedTasks.find((ct) => ct.task.taskId === task.taskId) || task.allowUpdate
  //     )
  //     .filter((task) => !task.dateRemoved) ?? [];

  // const myRequiredTasks = myIncompleteTasks
  //   .filter((task) => task.cake.cakeType === 'required')
  //   .sort((a, b) => (a.order > b.order ? 1 : -1));

  // const myExtraTasks = myIncompleteTasks
  //   .filter((task) => task.cake.cakeType === 'extra')
  //   .sort((a, b) => (a.order > b.order ? 1 : -1));

  const myProfile = data?.vm.profile;
  const refetchAll = () => {
    if (refetchMyTasks) refetchMyTasks();
    if (refetchParentTasks) refetchParentTasks();
  };
  return loading ? (
    <List disablePadding>
      <ListItem>
        <Skeleton variant='text' width={200} height={15} />
      </ListItem>
      {Array.from(Array(3)).map(() => (
        <ListItem key={uuidv4()}>
          <Skeleton variant={'rect'} width={'100%'} height={'50px'} style={{ borderRadius: '8px' }} />
        </ListItem>
      ))}
    </List>
  ) : (
    // myIncompleteTasks.length === 0 ? (
    //   <Box py={2} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
    //     <CheckCircle
    //       style={{
    //         fontSize: '45px',
    //         color: theme.status.green.light,
    //         marginBottom: '24px',
    //       }}
    //     />
    //     <Typography
    //       variant='h3'
    //       style={{ fontSize: '24px', fontWeight: 900, letterSpacing: '-0.02em', marginBottom: '8px' }}
    //     >
    //       {'Great News!'}
    //     </Typography>
    //     <Typography variant='subtitle1' color='textSecondary'>
    //       {'All of your tasks have been completed.'}
    //     </Typography>
    //   </Box>
    // ) : (
    <>
      {data?.vm.cakes.map(
        (cake) =>
          !cake.isHidden && (
            <MyTasksSection title={cake.title}>
              {cake.tasks?.map((task) => (
                <MyTaskListItem key={task.taskId} task={task} myProfile={myProfile} refetchMyTasks={refetchAll} />
              ))}
            </MyTasksSection>
          )
      )}
    </>
  );
};

export { MyTasks };
