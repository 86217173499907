import React from 'react';
import { Box, Typography, Theme, Button } from '@material-ui/core';
import { Description, Person, ExpandMore, ExpandLess } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { convertFromRaw, EditorState } from 'draft-js';
import { ActivityHeader } from './ActivityHeader';
import { ActivitySession as ActivitySessionType } from './__generated__/ActivitySession';
import { sharedStyles } from '../ActivityStyles/shared';
import { SessionActionType } from '../ActivityTypes';
import { getDisplayTimeSchedule } from '../../../../common/functions/activity-display-helpers';
import { ActivityStatusColorLegendItemMenu } from '../../vol-dashboard/vol-dashboard-my-elements/main-section/my-activities/MyActivityStatusIndicator';

type ISessionNote = { session: { sessionId: string }; notes?: string | null };
interface SessionItemProps {
  session: ActivitySessionType;
  detailed?: boolean;
  actionButton: React.ReactNode;
  isRostered?: boolean;
}

const useSessionItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    heading: {
      fontWeight: 700,
      fontSize: '.95rem',
      color: theme.color.grey.neutralBrand500,
    },
    caption: {
      fontWeight: 600,
      fontSize: '0.75rem',
      color: theme.color.grey.neutral200,
    },
    detailContainer: {
      marginTop: 4,
      fontSize: '0.75rem',
      color: theme.color.grey.neutral200,
    },
    detailReportContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 12,
    },
    detailReportItem: {
      display: 'flex',
      alignItems: 'center',
      '& .text': {
        marginLeft: 7,
        fontSize: '0.75rem',
      },
    },
    detailVolunteerContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& .icon': {
        fontSize: 16,
      },
      '& .belowText': {
        fontSize: 8,
        lineHeight: 'unset',
      },
    },
  })
);

function SessionItem({ detailed, session, actionButton, isRostered }: SessionItemProps) {
  const classes = useSessionItemStyles();
  const sessionTime = getDisplayTimeSchedule(session.startTime, session.endTime);
  const { description } = session;
  const editorState = React.useMemo(
    () => (description ? EditorState.createWithContent(convertFromRaw(JSON.parse(description))) : null),
    [description]
  );

  return (
    <React.Fragment>
      <Box className={classes.container}>
        <Box>
          <Typography variant='h5' className={classes.heading}>
            {session.name}
          </Typography>
          <Typography className={classes.caption}>{sessionTime}</Typography>
        </Box>
        {actionButton}
        {isRostered ? (
          <TabletButton
            data-track='resources-add-new-menu-button'
            variant='outlined'
            color='primary'
            aria-controls='add-new'
            aria-haspopup='true'
            onClick={undefined}
            disabled
            style={{ padding: '8px 12px', border: `1px solid #DCDCDC`, whiteSpace: 'nowrap' }}
          >
            <ActivityStatusColorLegendItemMenu activityStatus={'rostered'} />
          </TabletButton>
        ) : null}
      </Box>
      {detailed && (
        <Box className={classes.detailContainer}>
          {editorState && <CampfireRichEditor editorState={editorState} readOnly />}
          <Box className={classes.detailReportContainer}>
            <Box className={classes.detailReportItem} flex={1}>
              <Description fontSize='small' />
              <Typography className='text'>
                {session.reportType === null
                  ? session.autoReport
                    ? 'No Report Required (Impact Tracked)'
                    : 'No Report Required (No Impact)'
                  : session.reportType?.name}
              </Typography>
            </Box>
            {session.minVolunteers && (
              <Box className={classes.detailReportItem}>
                <Box className={classes.detailVolunteerContainer}>
                  <Person className='icon' />
                  <Typography className='belowText'>MIN</Typography>
                </Box>
                <Typography className='text'>{session.minVolunteers}</Typography>
              </Box>
            )}
            {session.maxVolunteers && (
              <Box className={classes.detailReportItem} paddingLeft='1rem'>
                <Box className={classes.detailVolunteerContainer}>
                  <Person className='icon' />
                  <Typography className='belowText'>MAX</Typography>
                </Box>
                <Typography className='text'>{session.maxVolunteers}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
}

const useSessionStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.color.grey.neutral400,
    },
  })
);

export interface ActivitySessionsProps {
  showAction?: boolean;
  sessions: ActivitySessionType[];
  getActionBySession: (sessionId: string) => SessionActionType;
  onActionSession: (action: SessionActionType, sessionId: string) => void;
  isEnrolled?: boolean;
  sessionRosterings?: string[];
  sessionNotes?: Array<ISessionNote>;
}

export function ActivitySession({
  showAction,
  sessions,
  getActionBySession,
  onActionSession,
  isEnrolled,
  sessionRosterings,
}: ActivitySessionsProps) {
  const [showDetails, setShowDetails] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const showingSessions = showMore ? sessions : sessions?.slice(0, 3);
  const buttonClasses = sharedStyles.button();
  const classes = useSessionStyles();

  const ActionButton = ({ sessionId }: { sessionId: string }) => {
    const sessionAction = getActionBySession(sessionId);

    if (!showAction || !sessionAction) {
      return null;
    }

    if (sessionAction === 'available' || sessionAction === 'roster') {
      return (
        <Button
          classes={buttonClasses}
          onClick={() => onActionSession(sessionAction, sessionId)}
          color='primary'
          variant='contained'
          disabled={!isEnrolled}
        >
          Available
        </Button>
      );
    }

    if (sessionAction === 'unavailable' || sessionAction === 'withdraw') {
      return (
        <Button
          classes={buttonClasses}
          onClick={() => onActionSession(sessionAction, sessionId)}
          color='secondary'
          variant='contained'
          disabled={!isEnrolled}
        >
          Withdraw
        </Button>
      );
    }

    return null;
  };

  return (
    <Box marginTop='2rem'>
      <ActivityHeader
        title='Sessions'
        action={
          <HoverText
            color='primary'
            variant='body2'
            onClick={() => setShowDetails(!showDetails)}
            style={{ fontSize: '12px' }}
          >
            {showDetails ? 'Hide Details' : 'Show Details'}
          </HoverText>
        }
      />
      <Box>
        {showingSessions?.map((session: ActivitySessionType) => (
          <SessionItem
            key={session.sessionId}
            session={session}
            actionButton={<ActionButton sessionId={session.sessionId} />}
            detailed={showDetails}
            isRostered={
              sessionRosterings &&
              !!sessionRosterings?.find((a) => {
                return a === session.sessionId;
              })
            }
          />
        ))}
      </Box>
      {sessions?.length > 3 && (
        <Box display='flex' justifyContent='center' marginTop='12px'>
          {showMore ? (
            <Button
              variant='outlined'
              onClick={() => setShowMore(false)}
              endIcon={<ExpandLess />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show less
            </Button>
          ) : (
            <Button
              variant='outlined'
              onClick={() => setShowMore(true)}
              endIcon={<ExpandMore />}
              classes={buttonClasses}
              className={classes.button}
            >
              Show more
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
