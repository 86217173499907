import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AvailabilityCenterActivityType } from '../__generated__/AvailabilityCenterActivityType';
import { AvailabilitiesActivityItem } from './AvailabilitiesActivityItem';
import { AlertCard, getAlertCardColors } from '../../cards/alert-card/AlertCard';

interface Props {
  activities: AvailabilityCenterActivityType[];
  selectedActivityId?: string;
  onSelectActivity: (activityId: string) => void;
  onClose: () => void;
  managerView?: boolean;
}

export function ActivitySelect({ activities, selectedActivityId, onSelectActivity, onClose, managerView }: Props) {
  const { darkTextColor, lightTextColor } = getAlertCardColors('info');
  return (
    <Box>
      {activities.length > 0 ? (
        activities.map((activity) => (
          <AvailabilitiesActivityItem
            key={activity.activityId}
            activity={activity}
            isSelected={selectedActivityId === activity.activityId}
            onSelectActivity={onSelectActivity}
          />
        ))
      ) : !managerView ? (
        <AlertCard title='No Activities' variant='info'>
          <Typography
            variant='subtitle2'
            style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor, paddingTop: '5px' }}
          >
            {`It looks like you haven't joined an activity team yet.`}
          </Typography>
          <Box>
            <Link
              to={{
                pathname: '/volunteering/activities',
              }}
              style={{ textDecoration: 'none' }}
              onClick={() => onClose()}
            >
              <Typography
                variant='body2'
                display='inline'
                style={{ textDecoration: 'underline', color: darkTextColor, fontWeight: 700 }}
              >
                Click here
              </Typography>
            </Link>
            <Typography
              variant='subtitle2'
              display='inline'
              style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}
            >
              {' to view the activities your organisation has available, and follow the prompts to join.'}
            </Typography>
          </Box>
        </AlertCard>
      ) : (
        <Typography
          variant='body2'
          style={{ fontSize: '13px', color: '#494949', textAlign: 'center', padding: '2rem 0' }}
        >
          Volunteer not enrolled in any activities
        </Typography>
      )}
    </Box>
  );
}
