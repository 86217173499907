import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { Box, LinearProgress, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSession } from '../auth/useSession';
import { useUser } from '../auth/useUser';
import { useCampfireQuery } from '../network/useCampfireQuery';
import { ApplicantShellAppBar, APPLICANT_SHELL_APP_BAR_HEIGHT } from './ApplicantShellAppBar';
import { GET_APPLICANT_VOLUNTEER_PROFILE } from './model/applicant-shell-query.gql';
import {
  GetApplicantVolunteerProfile,
  GetApplicantVolunteerProfileVariables,
} from './model/__generated__/GetApplicantVolunteerProfile';
import { SUYPLimbo } from './SUYPLimbo';
import { SUYPOverview } from './SUYPOverview';
import { SUYPTask } from './SUYPTask';
import { SUYPVolunteerProfile } from './SUYPVolunteerProfile';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { SUYPRoleApplication } from './SUYPRoleApplication';
import { SUYPActivityApplication } from './SUYPActivityApplication';
import { SUYPSimpleAvailability } from './SUYPRoleSimpleAvailability';

const useProgressClasses = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 12,
      borderRadius: 16,
      backgroundColor: theme.palette.grey[200],
      marginRight: '10px',
    },
    bar: {
      borderRadius: 16,
    },
  })
);

const ApplicantShell = () => {
  const { user } = useUser();
  const { userId } = user;
  const { data, loading, refetch } = useCampfireQuery<
    GetApplicantVolunteerProfile,
    GetApplicantVolunteerProfileVariables
  >(GET_APPLICANT_VOLUNTEER_PROFILE, {
    options: {
      variables: {
        userId,
      },
    },
  });

  const hasApprovedProfile = useMemo(() => data?.vm.profile?.isProfileTaskApproved, [data]);
  const profileExists = useMemo(() => !!data?.vm.profile?.contactNumber, [data]);
  const [progress, setProgress] = useState<number>(0);
  const progressClasses = useProgressClasses();
  const { isMobile } = useCampfireTheme();

  const updateProgess = (number: number) => {
    setProgress(number);
  };

  if (loading) return <CircularProgressOverlay isLoading />;

  return (
    <>
      <ApplicantShellAppBar>
        <Box style={{ display: 'flex', alignItems: 'center', minWidth: '35%' }}>
          {!isMobile && progress !== 0 && (
            <Box style={{ minWidth: '75%' }}>
              <LinearProgress value={progress} variant='determinate' classes={progressClasses} />
            </Box>
          )}
          <Box paddingRight={1} width={1} display='flex' justifyContent='flex-start'>
            {progress !== 0 && (
              <Typography
                variant='body2'
                style={{ color: '#796FFF', fontSize: '13px', fontWeight: 500 }}
              >{`${progress}% complete`}</Typography>
            )}
          </Box>
        </Box>
      </ApplicantShellAppBar>

      <Box
        style={{
          minHeight: `calc(100vh - ${APPLICANT_SHELL_APP_BAR_HEIGHT}px)`,
        }}
        bgcolor='#f1f1f1'
      >
        {!data?.vm.programs.length ? (
          <NoProgramsMessage />
        ) : (
          <Switch>
            <Route path='/' exact>
              {hasApprovedProfile ? (
                <SUYPOverview progress={progress} setProgress={updateProgess} />
              ) : !hasApprovedProfile && profileExists ? (
                <SUYPLimbo preferredName={data.vm.profile?.preferredName} />
              ) : (
                <SUYPVolunteerProfile
                  task={data.vm.profileTask}
                  taskItems={data.vm.profileTask.taskItems ?? []}
                  loading={loading}
                  refetch={refetch}
                  programs={data.vm.programs.filter((program) => program.dateSuspended === null) ?? []}
                />
              )}
            </Route>

            {hasApprovedProfile ? (
              <Route path='/task/:taskId'>
                <SUYPTask />
              </Route>
            ) : null}

            {hasApprovedProfile ? (
              <Route path='/roles'>
                <SUYPRoleApplication />
              </Route>
            ) : null}

            {hasApprovedProfile ? (
              <Route path='/activities'>
                <SUYPActivityApplication />
              </Route>
            ) : null}

            {hasApprovedProfile ? (
              <Route path='/availability'>
                <SUYPSimpleAvailability />
              </Route>
            ) : null}

            <Route path='/sign-out'>
              <SignOut />
            </Route>

            <Route>
              <Redirect to='/' />
            </Route>
          </Switch>
        )}
      </Box>
    </>
  );
};

const NoProgramsMessage = () => (
  <Box width={1} height={1} display='flex' justifyContent='center' alignContent='center' alignItems='center'>
    <Box width={1} maxWidth={720} minHeight={360}>
      <Typography variant='h5' style={{ fontWeight: 'bold' }}>
        No Programs
      </Typography>
      <Typography variant='body2'>
        There are no programs to choose from yet. Please come back later and try again.
      </Typography>
    </Box>
  </Box>
);

const SignOut = () => {
  const { logout } = useSession();
  logout();
  return null;
};

export default ApplicantShell;
