import { Cake, CakeContent, CakeHeader } from '@campfire/cake';
import { FileUploadGallery, UploadedFile } from '@campfire/file-upload-gallery/lib';
import { unpackToDate } from '@campfire/hot-date/lib';
import { HoverText } from '@campfire/hover-link/lib';
import { Box, Checkbox, Grid, Radio, Typography } from '@material-ui/core';
import { Lock, Clear as ClearIcon, Check as CheckIcon } from '@material-ui/icons';
import { TitularTooltip } from '@campfire/titular-tooltip';
import React from 'react';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import {
  BasicTaskItemField,
  BasicTaskItemField_field_VOLUNTEER_MultipleChoiceTaskFieldType_multipleChoiceOptions as MultiplechoiceOptionType,
  BasicTaskItemField_field_VOLUNTEER_QuizTaskFieldType_quizTaskFieldCorrect as QuizTaskFieldCorrectType,
} from './model/__generated__/BasicTaskItemField';
import { CompletedTask } from './model/__generated__/CompletedTask';
import { GetAllCompletedTasks_vm_profile_completedTasks_taskFieldValues_VOLUNTEER_AddressTaskFieldValueType_address as AddressTaskFieldValue } from './model/__generated__/GetAllCompletedTasks';
import { TaskFormFieldValue } from './model/__generated__/TaskFormFieldValue';
import { TaskFormItem } from './model/__generated__/TaskFormItem';
import { isTaskItemFieldBreak, TaskItemContents, TaskItemHeader, TaskItemMedia } from './TaskFormFieldList';
import { TaskItemsDeserialize } from '../../../screens/admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';

const DOTTED_UNDERLINE_STYLE = '1px dotted #c3c3c3';
const DOTTED_UNDERLINE_WIDTH = 240;

interface OptionsFieldValueProps {
  options: { name: string; checkboxFieldOptionId: string; checked: boolean }[];
}

export const CheckboxFieldValue = ({ options }: OptionsFieldValueProps) => {
  const { theme } = useCampfireTheme();

  return (
    <>
      {options.map((option) => (
        <Grid container alignItems='center' key={option.checkboxFieldOptionId}>
          <Grid item>
            <Checkbox
              disabled
              checked={option.checked}
              style={{ color: option.checked ? theme.palette.primary.main : theme.palette.grey[500] }}
            />
          </Grid>
          <Grid item xs>
            {option.name}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

interface MultipleChoiceFieldValueProps {
  taskValue: TaskFormFieldValue;
  multipleChoiceOptions: MultiplechoiceOptionType[];
}

export const MultipleChoiceFieldValue = ({ taskValue, multipleChoiceOptions }: MultipleChoiceFieldValueProps) => {
  const { theme } = useCampfireTheme();
  const otherText =
    taskValue?.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldValueType' ? taskValue.otherText : undefined;
  const selectedMultipleChoiceOptionId =
    taskValue?.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldValueType'
      ? taskValue.multipleChoiceTaskFieldOption.multipleChoiceFieldOptionId
      : undefined;

  return (
    <>
      {multipleChoiceOptions
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map((option, optionIndex) => {
          const isChecked = option.multipleChoiceFieldOptionId === selectedMultipleChoiceOptionId;
          const isOtherOption = otherText && optionIndex === multipleChoiceOptions.length - 1;

          return (
            <Box key={option.multipleChoiceFieldOptionId}>
              <div>
                <Radio
                  checked={isChecked}
                  disabled
                  style={{ color: isChecked ? theme.palette.primary.main : theme.palette.grey[500] }}
                />
                <span
                  style={
                    isOtherOption
                      ? {
                          paddingBottom: 4,
                          minWidth: DOTTED_UNDERLINE_WIDTH,
                          borderBottom: DOTTED_UNDERLINE_STYLE,
                        }
                      : undefined
                  }
                >
                  {isOtherOption ? `Other: ${otherText}` : option.name}
                </span>
              </div>
            </Box>
          );
        })}
    </>
  );
};

interface QuizFieldValueProps {
  taskValue: TaskFormFieldValue;
  quizTaskFieldOptions: Array<{
    quizTaskFieldOptionId: string;
    name: string;
    order: number;
  }>;
  quizTaskFieldCorrect: QuizTaskFieldCorrectType | null;
}
export const QuizFieldValue = ({ taskValue, quizTaskFieldOptions, quizTaskFieldCorrect }: QuizFieldValueProps) => {
  const { theme } = useCampfireTheme();
  const selectedQuizOptionId =
    taskValue?.__typename === 'VOLUNTEER_QuizTaskFieldValueType'
      ? taskValue.quizTaskFieldOption.quizTaskFieldOptionId
      : undefined;

  return (
    <>
      {quizTaskFieldOptions
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        .map((option) => {
          const isChecked = option.quizTaskFieldOptionId === selectedQuizOptionId;
          return (
            <Box
              display='flex'
              alignContent='center'
              alignItems='center'
              position='relative'
              key={option.quizTaskFieldOptionId}
            >
              <Box position='absolute' left='-20px' marginTop='2px'>
                {isChecked &&
                  quizTaskFieldCorrect?.quizTaskFieldOption.quizTaskFieldOptionId !== option.quizTaskFieldOptionId && (
                    <ClearIcon color='error' />
                  )}
                {isChecked &&
                  quizTaskFieldCorrect?.quizTaskFieldOption.quizTaskFieldOptionId === option.quizTaskFieldOptionId && (
                    <CheckIcon style={{ color: '#34D399' }} />
                  )}
              </Box>
              <Radio
                checked={isChecked}
                disabled
                style={{ color: isChecked ? theme.palette.primary.main : theme.palette.grey[500] }}
              />
              <span>{option.name}</span>
              {!isChecked &&
                quizTaskFieldCorrect?.quizTaskFieldOption.quizTaskFieldOptionId === option.quizTaskFieldOptionId && (
                  <span style={{ fontSize: '12px', color: 'GrayText', marginLeft: '6px' }}>(Correct Answer)</span>
                )}
            </Box>
          );
        })}
    </>
  );
};

export const AttachmentFieldValue = ({ attachments }: { attachments: UploadedFile[] }) => {
  return (
    <Grid container alignItems='center'>
      <FileUploadGallery allowMultiple viewOnly uploadedFiles={attachments} />
    </Grid>
  );
};

export const SignatureFieldValue = ({ signatureUrl }: { signatureUrl: string }) => {
  return (
    <Grid container alignItems='center'>
      <img style={{ width: '100%', maxWidth: '500px' }} src={signatureUrl} alt='' />
    </Grid>
  );
};

export const BasicFieldValue = ({ fieldValue }: { fieldValue: string }) => (
  <Grid container>
    <Typography style={{ borderBottom: DOTTED_UNDERLINE_STYLE, minWidth: DOTTED_UNDERLINE_WIDTH }}>
      {fieldValue}
    </Typography>
  </Grid>
);

interface CompletedTaskValueProps {
  taskItemField: BasicTaskItemField;
  taskValue: TaskFormFieldValue;
  taskItems: TaskItemsDeserialize;
  taskItemValues: { [key: string]: TaskFormFieldValue };
}

export const CompletedTaskValue = ({
  taskItemField,
  taskValue,
  taskItems,
  taskItemValues,
}: CompletedTaskValueProps) => {
  const getAddress = (address: AddressTaskFieldValue) => {
    if (address.placesAddress?.formatted && address.placesAddress.formatted !== '') {
      return address.placesAddress.formatted;
    }
    if (address.manualAddress?.humanReadable && address.manualAddress.humanReadable !== '') {
      return address.manualAddress.humanReadable;
    }
    return '';
  };

  if (
    taskValue?.__typename === 'VOLUNTEER_CheckboxTaskFieldValueType' &&
    taskItemField.field.__typename === 'VOLUNTEER_CheckboxTaskFieldType'
  ) {
    return (
      <>
        <CheckboxFieldValue
          options={taskItemField.field.checkboxOptions.map((x) => ({
            name: x.name,
            checkboxFieldOptionId: x.checkboxFieldOptionId,
            checked:
              taskValue.checkboxTaskFieldOptions.findIndex(
                (y) => y.checkboxFieldOptionId === x.checkboxFieldOptionId
              ) !== -1,
          }))}
        />
        {taskValue.checkboxTaskFieldOptions
          .map((option) => option.checkboxFieldOptionId)
          .map((optionId) =>
            taskItems.byTrigger[optionId]?.triggerItemIds?.map((itemId) => {
              return (
                <CompletedTaskItem
                  key={itemId}
                  isFieldBreak
                  taskItems={taskItems}
                  item={taskItems.byId[itemId]}
                  taskItemValues={taskItemValues}
                />
              );
            })
          )}
      </>
    );
  }

  if (
    taskValue?.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldValueType' &&
    taskItemField.field.__typename === 'VOLUNTEER_MultipleChoiceTaskFieldType'
  ) {
    return (
      // handle this differently so we can still display the empty options
      <>
        <MultipleChoiceFieldValue
          taskValue={taskValue}
          multipleChoiceOptions={taskItemField.field.multipleChoiceOptions}
        />
        {taskItems.byTrigger[
          taskValue?.multipleChoiceTaskFieldOption?.multipleChoiceFieldOptionId
        ]?.triggerItemIds?.map((itemId) => (
          <CompletedTaskItem
            key={itemId}
            isFieldBreak
            taskItems={taskItems}
            item={taskItems.byId[itemId]}
            taskItemValues={taskItemValues}
          />
        ))}
      </>
    );
  }

  if (taskValue?.__typename === 'VOLUNTEER_AttachmentTaskFieldValueType') {
    return <AttachmentFieldValue attachments={taskValue.attachments.map((a) => ({ fileId: a.attachmentId, ...a }))} />;
  }

  if (taskValue?.__typename === 'VOLUNTEER_PhoneTaskFieldValueType') {
    return <BasicFieldValue fieldValue={taskValue.contactNumber} />;
  }

  if (taskValue?.__typename === 'VOLUNTEER_ShortTextTaskFieldValueType') {
    return <BasicFieldValue fieldValue={taskValue.textValue} />;
  }

  if (taskValue?.__typename === 'VOLUNTEER_DateTaskFieldValueType') {
    return <BasicFieldValue fieldValue={unpackToDate(taskValue.dateValue).toFormat('dd/MM/y')} />;
  }

  if (taskValue?.__typename === 'VOLUNTEER_EmailTaskFieldValueType') {
    return <BasicFieldValue fieldValue={taskValue.email} />;
  }
  if (taskValue?.__typename === 'VOLUNTEER_LongTextTaskFieldValueType') {
    return <BasicFieldValue fieldValue={taskValue.textValue} />;
  }
  if (taskValue?.__typename === 'VOLUNTEER_NumberTaskFieldValueType') {
    return <BasicFieldValue fieldValue={`${taskValue.numericValue}`} />;
  }
  if (taskValue?.__typename === 'VOLUNTEER_AddressTaskFieldValueType') {
    return <BasicFieldValue fieldValue={getAddress(taskValue.address)} />;
  }

  if (taskValue?.__typename === 'VOLUNTEER_DropdownTaskFieldValueType') {
    return (
      <>
        <BasicFieldValue fieldValue={taskValue.otherText ?? taskValue.dropdownTaskFieldOption.name ?? ''} />
        {taskItems.byTrigger[taskValue.dropdownTaskFieldOption.dropdownFieldOptionId]?.triggerItemIds?.map((itemId) => (
          <CompletedTaskItem
            key={itemId}
            isFieldBreak
            taskItems={taskItems}
            item={taskItems.byId[itemId]}
            taskItemValues={taskItemValues}
          />
        ))}
      </>
    );
  }

  if (
    taskItemField.field.__typename === 'VOLUNTEER_QuizTaskFieldType' &&
    taskValue?.__typename === 'VOLUNTEER_QuizTaskFieldValueType'
  ) {
    return (
      // handle this differently so we can still display the empty options
      <>
        <QuizFieldValue
          taskValue={taskValue}
          quizTaskFieldOptions={taskItemField.field.quizTaskFieldOptions}
          quizTaskFieldCorrect={taskItemField.field.quizTaskFieldCorrect}
        />
        {taskItems.byTrigger[taskValue.quizTaskFieldOption.quizTaskFieldOptionId]?.triggerItemIds?.map((itemId) => (
          <CompletedTaskItem
            key={itemId}
            isFieldBreak
            taskItems={taskItems}
            item={taskItems.byId[itemId]}
            taskItemValues={taskItemValues}
          />
        ))}
      </>
    );
  }

  if (
    taskItemField.field.__typename === 'VOLUNTEER_SignatureTaskFieldType' &&
    taskValue?.__typename === 'VOLUNTEER_SignatureTaskFieldValueType'
  ) {
    return (
      // handle this differently so we can still display the empty options
      <>
        <SignatureFieldValue signatureUrl={taskValue.signatureUrl || ''} />
      </>
    );
  }
  return (
    <Typography variant='body2' color='textSecondary'>
      No response
    </Typography>
  );
};

interface HardCodedField {
  name: string;
  optional: boolean;
  description?: string;
  value: string;
}

interface HardCodedFieldProps {
  value: HardCodedField;
}

const VolunteerProfileHardCodedField = (props: HardCodedFieldProps) => {
  const { name, optional, description, value } = props.value;
  return (
    <Box
      marginTop={2}
      marginBottom={3}
      paddingTop={2}
      paddingBottom={2}
      paddingRight={2}
      paddingLeft={2}
      minHeight={72}
      border={'1px solid #e9e9e9'}
      bgcolor='#ffffff'
      borderRadius={7}
      key={name}
    >
      <Grid container direction='row' alignItems={'stretch'}>
        <Grid item xs={11}>
          <span>
            <Typography variant='body1' display='inline' color={'textSecondary'}>
              {name}
            </Typography>
            {optional ? null : (
              <Typography
                variant='body1'
                display='inline'
                color='textSecondary'
                component='span'
                style={{ fontWeight: 'bolder', marginLeft: 4 }}
              >
                {'*'}
              </Typography>
            )}
          </span>

          <Typography style={{ marginBottom: 16 }} variant='body2' color='textSecondary'>
            {description}
          </Typography>

          <BasicFieldValue fieldValue={value} />
        </Grid>

        <Grid item container xs={1} direction={'row'} justify={'flex-end'} style={{ padding: 0 }}>
          <TitularTooltip description={'This field can only be updated by the volunteer'}>
            <Lock color='disabled' />
          </TitularTooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

interface Props {
  taskItems: TaskItemsDeserialize;
  taskItemValues: { [key: string]: TaskFormFieldValue };
  volunteerProfileValues?: HardCodedField[];
  taskHeaderDetails: {
    title: string;
    cake: string;
    description: string;
  };
  revoke?: () => void;
  edit?: () => void;
  isTaskZero: boolean;
  selectedCompletedTask: CompletedTask;
  simple?: boolean;
}

const CompletedTaskViewer = (props: Props) => {
  const {
    taskItems,
    taskItemValues,
    taskHeaderDetails,
    revoke,
    isTaskZero,
    selectedCompletedTask,
    volunteerProfileValues,
    simple,
  } = props;

  return (
    <Box display='flex' justifyContent='center' flex='1 1 auto'>
      <Box maxWidth={720} width={1}>
        {!simple && (
          <Cake cardProps={{ style: { marginBottom: 32 } }}>
            <CakeHeader
              heading={`You've completed this task!`}
              // actions={<CakeImage src='/campfire-camping-gear-001.svg' alt='Volaby camping gear' />}
              description={
                <Box display='flex' alignItems='center' alignContent='center'>
                  {selectedCompletedTask.status === 'approved' ? (
                    <Typography variant='body2' color='textSecondary'>
                      {'This task has been approved.'}
                    </Typography>
                  ) : (
                    <Typography variant='body2' color='textSecondary'>
                      {`Your responses to '${taskHeaderDetails.title}' are awaiting review from a manager. Once all tasks are approved, your volunteer profile will be activated.`}
                    </Typography>
                  )}
                </Box>
              }
            />
            <CakeContent>
              <Box paddingRight={1} paddingBottom={1} width={1} display='flex' justifyContent='flex-end'>
                <Box>
                  <Typography variant='body2' color='textSecondary'>{`Submitted on ${unpackToDate(
                    selectedCompletedTask.dateLastUpdated
                  ).toFormat('dd MMM y')}`}</Typography>
                  <Box display='flex' justifyContent='flex-end'>
                    {!isTaskZero && revoke ? (
                      <HoverText
                        variant='body2'
                        color='error'
                        hoverColor='error'
                        onClick={revoke}
                        style={{ marginRight: 8 }}
                      >
                        {'Delete task'}
                      </HoverText>
                    ) : null}
                  </Box>
                </Box>
              </Box>
            </CakeContent>
          </Cake>
        )}

        <CompletedTaskFormFieldList
          taskItems={taskItems}
          taskItemValues={taskItemValues}
          volunteerProfileValues={volunteerProfileValues}
          isTaskZero={isTaskZero}
          simple={simple}
        />
      </Box>
    </Box>
  );
};

interface CompletedTaskItemProps {
  taskItems: TaskItemsDeserialize;
  item: TaskFormItem;
  isFieldBreak: boolean;
  taskItemValues: { [key: string]: TaskFormFieldValue };
  simple?: boolean;
}

export const CompletedTaskItem = (props: CompletedTaskItemProps) => {
  const { item, isFieldBreak, taskItemValues, taskItems, simple } = props;
  if (item.__typename === 'VOLUNTEER_TaskItemHeadingType') {
    return <TaskItemHeader isFieldBreak={isFieldBreak} content={item.heading} key={item.taskItemId} />;
  }
  if (item.__typename === 'VOLUNTEER_TaskItemContentType') {
    const attachments: UploadedFile[] = item.attachments.map((attachment) => {
      return { url: attachment.url, fileId: attachment.attachmentId, name: attachment.name };
    });
    return (
      <TaskItemContents
        isFieldBreak={isFieldBreak}
        draftJsRawContent={item.content}
        key={item.taskItemId}
        attachments={attachments}
      />
    );
  }
  if (item.__typename === 'VOLUNTEER_TaskItemFieldType') {
    return (
      <Box
        marginTop={!simple ? 2 : 0}
        marginBottom={!simple ? 3 : 0}
        paddingTop={!simple ? 2 : 1}
        paddingLeft={!simple ? 4 : 0}
        paddingBottom={!simple ? 2 : 1}
        paddingRight={!simple ? 2 : 0}
        minHeight={72}
        border={!simple ? '1px solid #e9e9e9' : ''}
        bgcolor='#ffffff'
        borderRadius={7}
        key={item.taskItemId}
      >
        <span>
          <Typography variant='body1' display='inline'>
            {item.field.name}
          </Typography>
          {simple || item.optional ? null : (
            <Typography
              variant='body1'
              display='inline'
              color='error'
              component='span'
              style={{ fontWeight: 'bolder', marginLeft: 4 }}
            >
              {'*'}
            </Typography>
          )}
        </span>

        <Typography style={{ marginBottom: 16 }} variant='body2' color='textSecondary'>
          {item.field.description}
        </Typography>

        <CompletedTaskValue
          taskItemField={item}
          taskValue={taskItemValues[item.field.taskFieldId]}
          taskItems={taskItems}
          taskItemValues={taskItemValues}
        />
      </Box>
    );
  }
  if (item.__typename === 'VOLUNTEER_TaskItemMediaType') {
    return <TaskItemMedia key={item.taskItemId} isFieldBreak media={item.media} />;
  }
  return null;
};

interface CompletedTaskFormFieldListProps {
  taskItems: TaskItemsDeserialize;
  taskItemValues: { [key: string]: TaskFormFieldValue };
  volunteerProfileValues?: HardCodedField[];
  isTaskZero: boolean;
  simple?: boolean;
}

const CompletedTaskFormFieldList = (props: CompletedTaskFormFieldListProps) => {
  const { taskItemValues, taskItems, isTaskZero, volunteerProfileValues, simple } = props;
  return (
    <>
      {isTaskZero && volunteerProfileValues
        ? volunteerProfileValues.map((value) => <VolunteerProfileHardCodedField value={value} key={value.name} />)
        : null}

      {taskItems.allIds
        .filter((itemId) => !taskItems.byTriggerOption[itemId])
        .map((itemId, itemIndex, filteredItems) => {
          const isFieldBreak = isTaskItemFieldBreak(
            taskItems.byId[itemId],
            taskItems.byId[filteredItems[itemIndex - 1]]
          );
          return (
            <CompletedTaskItem
              taskItems={taskItems}
              key={itemId}
              item={taskItems.byId[itemId]}
              isFieldBreak={isFieldBreak}
              taskItemValues={taskItemValues}
              simple={simple}
            />
          );
        })}
    </>
  );
};

export { CompletedTaskViewer, CompletedTaskFormFieldList };
