import { Theme } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';

const TaskRejectedIcon = () => {
  const classes = useStyles();
  return <Cancel className={classes.checked} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checked: {
      fontSize: 24,
      color: theme.color.grey.neutral200,
    },
  })
);

export { TaskRejectedIcon };
