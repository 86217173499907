import React from 'react';
import { Box, Typography, Theme, Button, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { DateTime } from 'luxon';
import { encodeDate } from '@campfire/hot-date';
import { ExpandMore } from '@material-ui/icons';

import { ActivityReportsList } from './ActivityReportsList';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { ACTIVITY_REPORT_GET_ACTIVITY_REPORTS } from '../activity-reports-model.gql';
import { ActivityItem } from './types';
import {
  ActivityReportGetAcitivityReports,
  ActivityReportGetAcitivityReportsVariables,
} from '../__generated__/ActivityReportGetAcitivityReports';
import { filterActivity } from './helpers';
import { LOADING_LIMIT } from './consts';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subTitle: {
      marginTop: 10,
      marginBottom: 20,
      fontSize: '.875rem',
      fontWeight: 500,
      color: theme.color.grey.neutral200,
    },
    button: {
      '& > span': {
        textTransform: 'none',
      },
      borderRadius: 6,
      height: 38,
    },
  })
);

interface CompletedReportProps {
  isOmitted: boolean;
}

export function CompletedReport({ isOmitted }: CompletedReportProps) {
  const classes = useStyles();

  const [queryCompletedActivityReports, { loading }] = useCampfireLazyQuery<
    ActivityReportGetAcitivityReports,
    ActivityReportGetAcitivityReportsVariables
  >(ACTIVITY_REPORT_GET_ACTIVITY_REPORTS);

  const [completedActivities, setCompletedActivities] = React.useState<ActivityItem[]>([]);

  const [query, setQuery] = useQueryParams({
    program: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    q: StringParam,
    activityType: StringParam,
    activityStatus: StringParam,
    duration: StringParam,
    rosterType: StringParam,
    location: StringParam,
    startTime: StringParam,
    endTime: StringParam,
    days: ArrayParam,
    activityDate: StringParam,
    activityId: StringParam,
  });

  const { program: programId, startDate, endDate = encodeDate(DateTime.local()), activityDate, activityId } = query;
  const hasLoadMore = React.useRef(true);

  React.useEffect(() => {
    queryCompletedActivityReports({
      variables: {
        programId: !programId || programId === 'all' ? null : programId,
        from: startDate,
        until: endDate,
        isOmitted,
        pagination: {
          offset: 0,
          limit: LOADING_LIMIT,
        },
      },
    }).then((response) => {
      const completedReports =
        response.data?.vm.activityReports.map(({ activityDate: date, activity }) => ({
          activityDate: date,
          ...activity,
          programName: activity.program.name,
        })) ?? ([] as ActivityItem[]);
      hasLoadMore.current = completedReports.length === LOADING_LIMIT;
      setCompletedActivities(completedReports);
    });
  }, [programId, endDate, isOmitted, startDate]);

  const sortedActivities = completedActivities
    ?.sort((a, b) => (a.activityDate > b.activityDate ? -1 : 1))
    .filter(filterActivity(query));

  const { activityId: firstActivityId, activityDate: firstActivityDate } = sortedActivities[0] || {};
  const isSelectedInList = sortedActivities.some(
    (activity) => activity.activityId === activityId && activity.activityDate === activityDate
  );

  const { isMd } = useCampfireTheme();

  React.useEffect(() => {
    if (!isMd && !isSelectedInList && firstActivityId && firstActivityDate) {
      setQuery({
        activityId: firstActivityId,
        activityDate: firstActivityDate,
      });
    }
  }, [firstActivityId, firstActivityDate, isSelectedInList]);

  const loadMore = () => {
    queryCompletedActivityReports({
      variables: {
        programId: !programId || programId === 'all' ? null : programId,
        from: startDate,
        until: endDate,
        isOmitted,
        pagination: {
          offset: completedActivities.length,
          limit: LOADING_LIMIT,
        },
      },
    }).then((response) => {
      const completedReports =
        response.data?.vm.activityReports.map(({ activityDate: date, activity }) => ({
          activityDate: date,
          ...activity,
          programName: activity.program.name,
        })) ?? ([] as ActivityItem[]);
      hasLoadMore.current = completedReports.length === LOADING_LIMIT;
      setCompletedActivities((prev) => prev.concat(completedReports));
    });
  };

  return (
    <Box flex={1} display='flex' alignItems='stretch' flexDirection='column' paddingBottom='2rem'>
      <Typography className={classes.subTitle}>{`Total ${sortedActivities?.length} ${
        isOmitted ? 'omitted' : 'completed'
      } reports`}</Typography>
      <ActivityReportsList activities={sortedActivities || []} />
      {loading ? (
        <CircularProgress style={{ alignSelf: 'center', marginTop: '1rem' }} />
      ) : hasLoadMore.current ? (
        <Button
          onClick={loadMore}
          style={{ alignSelf: 'center', marginTop: '1rem' }}
          className={classes.button}
          variant='outlined'
          disableElevation
          endIcon={<ExpandMore />}
        >
          Show more
        </Button>
      ) : null}
    </Box>
  );
}
