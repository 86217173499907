import React from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { EditorState, convertFromRaw } from 'draft-js';
import { FullscreenDialog } from '../../../../../common/dialogs/FullscreenDialog';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { AdminConsoleRole } from './AdminConsoleRolesQuery/__generated__/AdminConsoleRole';
import { RoleForm, ValueType } from './RoleForm';
import { useButtonStyles } from './AdminConsoleRoleStyles';

interface Props {
  role: AdminConsoleRole;
  close: () => void;
  onSave: (value: any) => void;
  onCancel: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      marginTop: '1.25rem',
    },
  })
);

export function EditRole({ close, role, onSave, onCancel }: Props) {
  const { isMobile } = useCampfireTheme();

  const [name, setName] = React.useState(role.name);
  const [icon, setIcon] = React.useState(role.icon);
  const [canApply, setCanApply] = React.useState(role.canApply);
  const { description: currentDescription } = role;
  const editorState = React.useMemo(
    () =>
      currentDescription
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(currentDescription)))
        : EditorState.createEmpty(),
    [currentDescription]
  );

  const [description, setDescription] = React.useState(editorState);
  const onChange = (newValue: ValueType) => {
    setName(newValue.name);
    setIcon(newValue.icon);
    setDescription(newValue.description);
    setCanApply(newValue.canApply);
  };
  const buttonClasses = useButtonStyles();
  const onSubmit = (e: any) => {
    e.preventDefault();
    onSave({ activityRoleId: role.activityRoleId, name, icon, description, canApply });
  };

  const classes = useStyles();

  return (
    <FullscreenDialog
      open
      close={close}
      title='Edit role'
      subtitle='Update role name or description'
      maxWidth='xs'
      fullWidth
      fullScreen={isMobile}
    >
      <form onSubmit={onSubmit} className={classes.form}>
        <RoleForm value={{ name, icon, description, canApply }} onChange={onChange} />
      </form>
      <Box display='flex' marginTop='1.25rem' width='100%' justifyContent='flex-end'>
        <Button classes={buttonClasses} onClick={onCancel}>
          Cancel
        </Button>
        <Button classes={buttonClasses} variant='contained' type='submit' color='primary' onClick={onSubmit}>
          Done
        </Button>
      </Box>
    </FullscreenDialog>
  );
}
