import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { EmojiPeople } from '@material-ui/icons';
import { RolePanel } from '../RolePanel';
import { RoleItem, ExtendRole } from './RoleItem';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { SearchField } from '../../../../../../common/inputs/SearchField';

interface Props {
  roles: ExtendRole[];
  isLoading: boolean;
  onEdit: (role: ExtendRole) => void;
  onDelete: (role: ExtendRole) => void;
}

export function RolesList({ roles, onEdit, onDelete, isLoading }: Props) {
  const { isSm } = useCampfireTheme();
  const [searchInput, setSearchInput] = React.useState<string>();

  const filteredRoles = React.useMemo(
    () => roles.filter((role) => (searchInput ? role.name.toLowerCase().includes(searchInput.toLowerCase()) : true)),
    [roles, searchInput]
  );

  return (
    <>
      <RolePanel>
        <Box
          display='flex'
          flexDirection={isSm ? 'column' : 'row'}
          alignItems={isSm ? 'left' : 'center'}
          justifyContent='space-between'
          pr='1.25rem'
          pt={isSm ? '1rem' : 0}
        >
          <Box>
            <Typography style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
              <EmojiPeople style={{ fontSize: '14px', paddingRight: '5px' }} /> Roles
            </Typography>
            <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
              {
                'Roles are a great way to segment your user base. These could include jobs, qualifications, leadership roles, and more.'
              }
              <br />
              Users can apply or be added to roles, and their role status will be visible when rostering.
            </Typography>
          </Box>
          <Box style={{ marginLeft: isSm ? 0 : '0.75rem', paddingTop: isSm ? '1rem' : 0 }}>
            <SearchField
              placeholder='Search Tags'
              onChange={(e: any) => setSearchInput(e.target.value)}
              fullWidth={isSm}
            />
          </Box>
        </Box>
        {filteredRoles.map((role: ExtendRole) => (
          <RoleItem role={role} onEdit={onEdit} onDelete={onDelete} isLoading={isLoading} />
        ))}
      </RolePanel>
    </>
  );
}
