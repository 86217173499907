import React from 'react';
import { Box, Typography, Tooltip, ClickAwayListener, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Edit, DeleteOutline, ExpandMore } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

interface Props {
  onAction: (action: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      padding: '0.5rem',
      borderRadius: '4px',
      cursor: 'pointer',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.75,
      },
    },
    menuItemText: {
      marginLeft: '0.75rem',
      fontSize: '14px',
    },
    tooltip: {
      borderRadius: '12px',
      paddingTop: '0.5rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: '0.5rem',
      backgroundColor: theme.color.tooltip.background,
    },
    tooltipArrow: {
      '&::before': {
        backgroundColor: theme.color.tooltip.background,
      },
    },
  })
);

export function RoleItemMenu({ onAction }: Props) {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.menuItem} onClick={() => onAction('edit')}>
        <Edit fontSize='small' />
        <Typography className={classes.menuItemText}>Edit</Typography>
      </Box>
      <Box className={classes.menuItem} onClick={() => onAction('delete')}>
        <DeleteOutline fontSize='small' />
        <Typography className={classes.menuItemText}>Delete</Typography>
      </Box>
    </Box>
  );
}

export function RoleItemMenuButton({ onAction }: Props) {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const onCloseMenu = () => setMenuOpen(false);
  const onOpenMenu = () => setMenuOpen(true);
  const { theme } = useCampfireTheme();

  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={onCloseMenu}>
      <Tooltip
        arrow
        interactive
        title={<RoleItemMenu onAction={onAction} />}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement='bottom-end'
        open={menuOpen}
        onClose={onCloseMenu}
        onOpen={onOpenMenu}
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.tooltipArrow,
        }}
      >
        <TabletButton
          size='small'
          variant='outlined'
          color='primary'
          aria-controls='task-status-menu'
          aria-haspopup='true'
          onClick={onOpenMenu}
          endIcon={<ExpandMore />}
          style={{
            border: '1px solid #9e9e9e',
            boxSizing: 'border-box',
            borderRadius: '4px',
            padding: '2px 7px',
            fontSize: '12px',
            marginTop: '8px',
            color: theme.color.grey.neutral300,
            marginRight: '8px',
          }}
        >
          {'Options'}
        </TabletButton>
      </Tooltip>
    </ClickAwayListener>
  );
}
