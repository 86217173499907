import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, Typography } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import React, { useState } from 'react';
import { useApiUrl } from '../../../../global/config/useApiUrl';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useCampfireFetch } from '../../../../global/network/useCampfireFetch';
import { useUser } from '../../../../global/auth/useUser';
import { useSession } from '../../../../global/auth/useSession';

interface Props {
  onClose: () => void;
}

const DeactivateAccountPanel = (props: Props) => {
  const { onClose } = props;
  const apiUrl = useApiUrl();
  const { setSnackbar } = useSnackbar();
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();
  const [success, setSuccess] = useState(false);
  const { logout } = useSession();
  const { run } = useCampfireFetch({
    defer: true,
  });

  function somethingWentWrong() {
    setSnackbar({
      message: 'Unable to deactivate your account',
      variant: 'error',
      open: true,
    });
  }

  function greatSuccess() {
    setSnackbar({
      message: 'Your account has been deactivated',
      variant: 'success',
      open: true,
    });
    setSuccess(true);
    setTimeout(() => {
      logout();
    }, 3500);
  }

  const sendPost = () => {
    run({
      method: 'post',
      url: `${apiUrl}/vm/volunteer/self-retire`,
      data: {
        volunteerId: volunteerId,
      },
    })
      .then((res: any) => {
        if (res.ok) {
          greatSuccess();
          return;
        }
        somethingWentWrong();
      })
      .catch(() => {
        somethingWentWrong();
      });
  };

  if (success) {
    return (
      <Box
        display='flex'
        flex='1 1 auto'
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        paddingY={4}
        paddingX={3}
      >
        <Box display='flex' flexDirection='column' alignItems='center' alignContent='center'>
          <Box marginTop={2}>
            <TabletButton
              variant='outlined'
              size='medium'
              onClick={() => {
                onClose();
              }}
            >
              Close this dialog
            </TabletButton>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      direction='column'
      style={{ position: 'relative' }}
      alignContent='center'
      alignItems='center'
    >
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <Typography style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <ExitToApp style={{ fontSize: '14px', paddingRight: '5px' }} /> Deactivate Account
        </Typography>
        <br />
        <Typography variant='body2' style={{ color: '#464646' }} align='left'>
          If you wish to no longer volunteer with this organisation, click below to deactivate your account.
          <br />
          <br />
          To reactivate your account, you will need to contact your organisation directly.
        </Typography>
        <Box style={{ textAlign: 'center' }}>
          <TabletButton
            size='small'
            variant='contained'
            aria-controls='options-menu'
            aria-haspopup='true'
            onClick={() => sendPost()}
            startIcon={<ExitToApp />}
            style={{
              height: '100%',
              border: '1px solid transparent',
              boxSizing: 'border-box',
              borderRadius: '4px',
              padding: '5px 10px',
              backgroundColor: '#D93A00',
              marginTop: '45px',
              marginRight: '10px',
              color: '#fff',
            }}
          >
            Deactivate your account
          </TabletButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export { DeactivateAccountPanel };
