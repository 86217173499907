import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { DateTime } from 'luxon';
import { difference, min } from 'lodash';

import { useUser } from '../../../global/auth/useUser';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import {
  ActivityAvailabilityGetActivity,
  ActivityAvailabilityGetActivityVariables,
  ActivityAvailabilityGetActivity_vm_activity as ActivityType,
} from './__generated__/ActivityAvailabilityGetActivity';
import {
  ActivityAvailabilityGetActivityEnrolment,
  ActivityAvailabilityGetActivityEnrolmentVariables,
} from './__generated__/ActivityAvailabilityGetActivityEnrolment';
import { ACTIVITY_AVAILABILITY_ACTIVITY_ENROLMENT } from './activity-availability-activity-enrolment-model.gql';
import { ACTIVITY_AVAILABILITY_ACTIVITY } from './activity-availability-activity-model.gql';
import { AvailabilityHeader } from '../AvailabilityHeader';
import { SearchField } from '../../inputs/SearchField';
import { SessionSelect } from '../SessionSelect';
import { DateAndFrequencySelect } from '../DateSelect';
import { DateSelect } from '../DateSelect/DateSelect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      paddingTop: '20px',
      paddingBottom: '30px',
      paddingRight: '22px',
    },
    toolbarButton: {
      borderRadius: '8px',
    },
    toolbarButtonSelected: {
      backgroundColor: theme.color.grey.neutral200,
      color: 'white',
      '&:hover': {
        opacity: 0.8,
        backgroundColor: theme.color.grey.neutral200,
      },
    },
    actionButton: {
      textTransform: 'capitalize',
    },
  })
);

interface ActivityAvailabilityProps {
  activityId: string;
  selectedDate: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (selectedDates: string[], sessionIds: string[]) => void;
}

enum AvailaibiltyStepEnum {
  SELECT_SESSIONS,
  SELECT_DATES,
}

export function ActivityAvailability({ activityId, onClose, onSubmit, open, selectedDate }: ActivityAvailabilityProps) {
  const { getVolunteerIdentity } = useUser();
  const { volunteerId } = getVolunteerIdentity();

  const { data: enrolmentData } = useCampfireQuery<
    ActivityAvailabilityGetActivityEnrolment,
    ActivityAvailabilityGetActivityEnrolmentVariables
  >(ACTIVITY_AVAILABILITY_ACTIVITY_ENROLMENT, {
    options: {
      variables: {
        volunteerId,
        activityId,
      },
    },
  });

  const { data: activityData } = useCampfireQuery<
    ActivityAvailabilityGetActivity,
    ActivityAvailabilityGetActivityVariables
  >(ACTIVITY_AVAILABILITY_ACTIVITY, {
    options: {
      variables: {
        activityId,
        from: DateTime.local().toISODate(),
        until: DateTime.local()
          .plus({ years: 1 })
          .toISODate(),
      },
    },
  });

  const activity = activityData?.vm.activity || ({} as ActivityType);

  const [step, setStep] = React.useState(AvailaibiltyStepEnum.SELECT_SESSIONS);
  const [searchValue, setSearchValue] = React.useState('');
  const handleCancel = () => {
    onClose();
  };
  const [value, setValue] = React.useState({
    sessionIds: [] as string[],
    dates: [selectedDate] as string[],
  });

  const filterSessions =
    activity.sessions?.filter((session) => session.name.toLowerCase().includes(searchValue.toLowerCase())) || [];

  const onSelectSession = (selectedSessionIds: string[]) => {
    setValue({
      ...value,
      sessionIds: selectedSessionIds,
    });
  };

  const onSelectDates = (selectedDates: string[]) => {
    setValue({
      ...value,
      dates: selectedDates,
    });
  };

  const recurrences = React.useMemo(() => {
    switch (activity.__typename) {
      case 'VOLUNTEER_RecurringActivityType':
        return activity.schedule.recurrences;
      default:
        return [];
    }
  }, [activity]);

  const classes = useStyles();

  const { availabilities, unavailabilities } = enrolmentData?.vm.volunteer?.activityEnrolment || {};
  const onBack = () => setStep(Math.max(step - 1, 0));
  const isNextEnabled = (() => {
    if (step === AvailaibiltyStepEnum.SELECT_SESSIONS) {
      return Boolean(value.sessionIds?.length);
    }
    return Boolean(value.dates?.length);
  })();

  const handleNext = () => {
    if (step === AvailaibiltyStepEnum.SELECT_DATES) {
      onSubmit(value.dates, value.sessionIds);
    } else {
      setStep(Math.min(step + 1, AvailaibiltyStepEnum.SELECT_DATES));
    }
  };

  const dateAvailabilities = availabilities?.map((availability) => availability.activityDate) || [];
  const dateUnavailabilities = unavailabilities?.map((unavailability) => unavailability.activityDate) || [];
  const occurrences = activity.occurrencesBetween || [];
  const selectableOccurrences = difference(occurrences, dateAvailabilities);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle>
        <AvailabilityHeader
          header='Availability Centre'
          subHeader={step === AvailaibiltyStepEnum.SELECT_SESSIONS ? 'Select Sessions/s' : 'Select Dates'}
        >
          <Box display={'flex'} alignItems='center'>
            {step === AvailaibiltyStepEnum.SELECT_SESSIONS && (
              <SearchField
                value={searchValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setSearchValue(e.target.value)
                }
              />
            )}
          </Box>
        </AvailabilityHeader>
      </DialogTitle>
      <DialogContent>
        <Box>
          {(() => {
            switch (step) {
              case AvailaibiltyStepEnum.SELECT_SESSIONS:
                return (
                  <SessionSelect
                    selectedSessionIds={value.sessionIds || []}
                    sessions={filterSessions}
                    onSelectSession={onSelectSession}
                  />
                );
              case AvailaibiltyStepEnum.SELECT_DATES:
                return recurrences.length > 0 ? (
                  <DateAndFrequencySelect
                    occurrences={selectableOccurrences}
                    recurrences={recurrences}
                    value={value.dates || []}
                    onChange={onSelectDates}
                    key={activity.activityId}
                    availabilities={dateAvailabilities}
                    unavailabilities={dateUnavailabilities}
                    startDate={min([activity.startDate, selectableOccurrences[0]])}
                  />
                ) : (
                  <DateSelect
                    multiple
                    value={value.dates || []}
                    occurrences={selectableOccurrences}
                    availabilities={dateAvailabilities}
                    unavailabilities={dateUnavailabilities}
                    startDate={min([activity.startDate, selectableOccurrences[0]])}
                    onChange={onSelectDates}
                  />
                );
              default:
                return null;
            }
          })()}
        </Box>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        {step === AvailaibiltyStepEnum.SELECT_SESSIONS ? (
          <Button onClick={handleCancel} disableElevation className={classes.actionButton}>
            Cancel
          </Button>
        ) : (
          <Button onClick={onBack} disableElevation className={classes.actionButton}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          disableElevation
          variant='contained'
          color='primary'
          disabled={!isNextEnabled}
          className={classes.actionButton}
        >
          {step === AvailaibiltyStepEnum.SELECT_DATES ? 'Submit' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
