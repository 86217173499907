import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { DatePickerField } from '@campfire/date-picker/lib';
import { encodeDate, unpackToDate } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { date as YupDate, object as YupObject, string as YupString } from 'yup';
import { phoneNumberValidator } from '../../../../common/form/task-form/model/validation-schema';
import { useSession } from '../../../../global/auth/useSession';
import { useUser } from '../../../../global/auth/useUser';
import { useSnackbar } from '../../../../global/config/useSnackbar';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';
import { Avatar } from '../components/Avatar';
import { GET_PROFILE_PANEL } from './volunteer-profile-panel-model.gql';
import { GetProfilePanel, GetProfilePanelVariables } from './__generated__/GetProfilePanel';

interface UpdateProfileAvatarEndpointSpec {
  profileId: string;
  avatar: File;
}

interface DeleteProfileAvatarEndpointSpec {
  profileId: string;
}

interface UpdateProfileAvatarEndpointResponse {
  url: string;
}

enum AvatarAction {
  Update,
  Delete,
}

const ProfilePictureSection = () => {
  const { updateAvatarUrl, reloadSession } = useSession();
  const {
    user: { avatarUrl, profileId, preferredName, lastName },
  } = useUser();
  const ref = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const { setSnackbar } = useSnackbar();

  function handleFileClick() {
    if (!ref || !ref.current) return;
    ref.current.click();
  }

  function clearFile() {
    setFile(undefined);
    if (!ref || !ref.current) return;
    ref.current.value = '';
  }

  function handleRemoveClick() {
    deleteAvatarFetch
      .run({ profileId })
      .then((response) => {
        if (!response || !response.ok) {
          somethingWentWrong(AvatarAction.Delete);
          return;
        }
        greatSuccess(AvatarAction.Delete);
        clearFile();
        updateAvatarUrl(null);
        reloadSession();
      })
      .catch(() => {
        somethingWentWrong(AvatarAction.Delete);
      });
  }

  const updateAvatarFetch = useEndpointFetch<UpdateProfileAvatarEndpointSpec, UpdateProfileAvatarEndpointResponse>(
    '/vm/volunteer/profile/avatar/update',
    {
      formData: true,
    }
  );

  const deleteAvatarFetch = useEndpointFetch<DeleteProfileAvatarEndpointSpec>('/vm/volunteer/profile/avatar/delete');

  function somethingWentWrong(action: AvatarAction) {
    if (action === AvatarAction.Update) {
      clearFile();
    }
    setSnackbar({
      message: action === AvatarAction.Update ? 'Unable to update profile photo' : 'Unable to remove profile photo',
      variant: 'error',
      open: true,
    });
  }

  function greatSuccess(action: AvatarAction) {
    setSnackbar({
      message: action === AvatarAction.Update ? 'Updated profile photo' : 'Removed profile photo',
      variant: 'success',
      open: true,
    });
  }

  useEffect(() => {
    if (!file) return;
    const parcel = {
      profileId,
      avatar: file,
    };
    updateAvatarFetch
      .run(parcel)
      .then((response) => {
        if (!response || !response.ok) {
          somethingWentWrong(AvatarAction.Update);
          return;
        }
        greatSuccess(AvatarAction.Update);
        updateAvatarUrl(response.data.data.url);
        reloadSession();
      })
      .catch(() => {
        somethingWentWrong(AvatarAction.Update);
      });
  }, [file]);

  return (
    <Box display='flex' flex='1 1 auto' alignItems='center' flexWrap='wrap' position='relative'>
      <Box display='flex' flex='1 0 auto' justifyContent='center'>
        <Avatar
          size={96}
          preferredName={preferredName}
          lastName={lastName}
          avatarUrl={avatarUrl}
          avatarFile={file}
          style={{ border: '1px solid #e9e9e9' }}
        />
      </Box>

      <Box display='flex' justifyContent='center' marginTop={2}>
        <Box>
          <Typography variant='body2' color='textSecondary' align='center'>
            Choose a photo to help your teammates recognize you
          </Typography>
          <Box display='flex' justifyContent='center'>
            <HoverText
              disableUnderline
              variant='body2'
              color='primary'
              style={{ marginRight: 16 }}
              onClick={handleFileClick}
              disabled={updateAvatarFetch.isLoading || deleteAvatarFetch.isLoading}
            >
              Upload photo
            </HoverText>
            <HoverText
              disableUnderline
              variant='body2'
              color='textPrimary'
              hoverColor='textPrimary'
              onClick={handleRemoveClick}
              disabled={updateAvatarFetch.isLoading || deleteAvatarFetch.isLoading}
            >
              Remove photo
            </HoverText>
          </Box>
        </Box>
      </Box>

      <input
        type='file'
        accept='image/*'
        hidden
        ref={ref}
        onChange={(event) => {
          const targetFile = event.target.files?.item(0);
          if (!targetFile) return;
          setFile(targetFile);
        }}
      />
    </Box>
  );
};

const validateSchema = YupObject().shape({
  firstName: YupString().required('First name cannot be empty'),
  lastName: YupString().required('Last name cannot be empty'),
  preferredName: YupString(),
  dateOfBirth: YupDate()
    .required()
    .max('2100-01-01', 'Date is after max limit')
    .min('1900-01-01', 'Date is before min limit'),
  email: YupString()
    .required('An email is required for your Volaby account')
    .email('Please enter a valid email'),
  phoneNumber: phoneNumberValidator(false),
  bio: YupString(),
});

interface EditProfileFormValues {
  firstName: string;
  lastName: string;
  preferredName: string;
  dateOfBirth: null | Date;
  email: string;
  phoneNumber: string;
  aboutMe: string;
}

interface EditProfileSpec {
  profileId: string;
  firstName: string;
  lastName: string;
  preferredName: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  aboutMe: string;
}

const EditProfilePanel = ({ refetch }: { refetch?: () => void }) => {
  const { reloadSession } = useSession();
  const { setSnackbar } = useSnackbar();
  const { user: initialUser } = useUser();
  const { profileId } = initialUser;

  const updateProfileFetch = useEndpointFetch<EditProfileSpec>('/vm/volunteer/profile/edit');
  const initialDataQuery = useCampfireQuery<GetProfilePanel, GetProfilePanelVariables>(GET_PROFILE_PANEL, {
    options: {
      variables: {
        profileId,
      },
    },
  });

  function somethingWentWrong(message?: string) {
    setSnackbar({
      message: message ?? 'Unable to update profile',
      variant: 'error',
      open: true,
    });
  }

  function greatSuccess() {
    setSnackbar({
      message: 'Updated profile',
      variant: 'success',
      open: true,
    });
  }

  function handleSubmit(values: EditProfileFormValues, { setFieldError }: FormikHelpers<EditProfileFormValues>) {
    setFieldError('email', undefined);
    const { firstName, lastName, preferredName, dateOfBirth, email, phoneNumber, aboutMe } = values;
    const parcel: EditProfileSpec = {
      profileId,
      firstName,
      lastName,
      preferredName: preferredName === '' ? firstName : preferredName,
      dateOfBirth: dateOfBirth ? encodeDate(DateTime.fromJSDate(dateOfBirth)) : '',
      email,
      phoneNumber,
      aboutMe,
    };
    updateProfileFetch
      .run(parcel)
      .then((response) => {
        if (response && response.ok) {
          greatSuccess();
          reloadSession();
          if (refetch) refetch();
          return;
        }

        if (response.status === 409) {
          setFieldError('email', 'This email already in use');
          somethingWentWrong();
          return;
        }

        somethingWentWrong();
      })
      .catch(() => {
        somethingWentWrong();
      });
  }

  const initialValues: EditProfileFormValues | undefined = useMemo(() => {
    if (!initialDataQuery.data) return undefined;

    const preferredName = initialDataQuery.data.vm.profile?.preferredName ?? '';
    const firstName = initialDataQuery.data.vm.profile?.firstName ?? '';
    const isPreferredNameSameFirstName = preferredName === firstName;

    return {
      firstName,
      lastName: initialDataQuery.data.vm.profile?.lastName ?? '',
      preferredName: isPreferredNameSameFirstName ? '' : preferredName,
      dateOfBirth: initialDataQuery.data.vm.profile?.dateOfBirth
        ? unpackToDate(initialDataQuery.data.vm.profile?.dateOfBirth).toJSDate()
        : null,
      email: initialDataQuery.data.vm.profile?.email ?? '',
      phoneNumber: initialDataQuery.data.vm.profile?.contactNumber ?? '',
      aboutMe: initialDataQuery.data.vm.profile?.aboutMe ?? '',
    };
  }, [initialDataQuery.data]);

  return (
    <Box display='flex' flexDirection='column' position='relative'>
      <Typography
        style={{ fontWeight: 600, fontSize: '16px', display: 'flex', paddingBottom: '10px', alignItems: 'center' }}
      >
        <Person style={{ fontSize: '14px', paddingRight: '5px' }} /> Your Profile
      </Typography>
      <CircularProgressOverlay isLoading={initialDataQuery.loading || updateProfileFetch.isLoading} />

      <ProfilePictureSection />

      {initialValues ? (
        <Formik onSubmit={handleSubmit} validationSchema={validateSchema} initialValues={initialValues}>
          <Form>
            <Box marginTop={2} paddingY={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FastField
                    name='firstName'
                    component={MyTextField}
                    label='First name'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FastField
                    name='lastName'
                    component={MyTextField}
                    label='Last name'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FastField
                    name='preferredName'
                    component={MyTextField}
                    placeholder='Preferred name'
                    label='Preferred name'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DatePickerField
                    name='dateOfBirth'
                    label='Date of birth'
                    autoOk
                    fullWidth
                    inputVariant='outlined'
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
              </Grid>
            </Box>

            <Box marginTop={2} paddingY={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FastField
                    name='email'
                    component={MyTextField}
                    label='Email'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <FastField
                    name='phoneNumber'
                    component={MyTextField}
                    type='tel'
                    label='Mobile'
                    variant='outlined'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <FastField
                    name='aboutMe'
                    component={MyTextField}
                    label='About me'
                    placeholder='I joined because I want to help make a difference. Also I love dogs!'
                    variant='outlined'
                    fullWidth
                    multiline
                    rows={2}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box display='flex' flex='1 1 auto' justifyContent='flex-end' marginTop={3}>
              <TabletButton type='submit' size='medium' variant='contained' color='primary'>
                Save Changes
              </TabletButton>
            </Box>
          </Form>
        </Formik>
      ) : null}
    </Box>
  );
};

const MyTextField = ({ field, form, ...props }: FieldProps) => {
  const error = form.errors[field.name];
  const errorProps = !error
    ? undefined
    : {
        error: true,
        helperText: error,
      };

  return <TextField {...field} {...props} {...errorProps} />;
};

export { EditProfilePanel };
