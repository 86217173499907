import { makeStyles } from '@material-ui/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import React from 'react';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { LeftMenuItemType } from '../../incoming-volunteer-model.gql';
import { GetIncomingVolunteerProfile_vm_profile_application_applicationActivities as ApplicationActivities } from '../../__generated__/GetIncomingVolunteerProfile';
import { TaskPendingReviewIcon } from '../../../../../../common/icons/TaskPendingReviewIcon';
import { TaskCompleteIcon } from '../../../../../../common/icons/TaskCompleteIcon';
import { TaskRejectedIcon } from '../../../../../../common/icons/TaskRejectedIcon';

interface Props {
  setLeftMenuItem: (x: LeftMenuItemType) => void;
  applicationActivities?: ApplicationActivities[];
  applicationAvailabilitySimple: boolean;
}

const useStyles = () => {
  const { theme, isMobile } = useCampfireTheme();
  return makeStyles({
    listItem: {
      padding: isMobile ? '8px 20px' : '6px 25px',
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    taskListItem: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    taskListItemHover: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[100],
      },
    },
    taskListDisabled: {
      opacity: '0.5',
      cursor: 'default',
    },
    taskListItemSelected: {
      backgroundColor: theme.palette.grey[200],
    },
    taskListSubItem: {
      padding: isMobile ? '8px 40px' : '8px 64px',
    },
    iconContainer: {
      marginRight: isMobile ? 16 : 8,
    },
    borderBottom: {
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    borderRight: {
      borderRight: `1px solid ${theme.color.grey.border}`,
    },
  })();
};

const IncomingVolunteerActivitiesSection = (props: Props) => {
  const { setLeftMenuItem, applicationActivities, applicationAvailabilitySimple } = props;
  const classes = useStyles();

  const onClick = (role: ApplicationActivities) => {
    setLeftMenuItem({
      applicationActivityId: role.applicationActivityId,
      type: 'VOLUNTEER_ApplicationActivityType',
    });
  };

  const onAvailabilityClick = () => {
    setLeftMenuItem({
      type: 'VOLUNTEER_SimpleAvailabilityType',
    });
  };

  return (
    <>
      <>
        <Grid container>
          <Grid item container alignItems='center' className={classes.listItem}>
            <Grid item>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '16px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '.6em 0',
                    }}
                  >
                    Activities & Availabilities
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {applicationActivities &&
            applicationActivities.map((activity) => {
              return (
                <Box
                  display='flex'
                  alignContent='center'
                  alignItems='center'
                  width={1}
                  paddingX={1}
                  className={classes.taskListItem}
                  onClick={() => onClick(activity)}
                >
                  <Box
                    display='flex'
                    alignContent='center'
                    alignItems='center'
                    flex={2}
                    className={classes.borderRight}
                    paddingY={2}
                  >
                    <Box display='flex' paddingX={2}>
                      {activity.status === 'pending' ? (
                        <TaskPendingReviewIcon />
                      ) : activity.status === 'to-team' ||
                        activity.status === 'to-application' ||
                        activity.status === 'to-waitlist' ? (
                        <TaskCompleteIcon isComplete />
                      ) : (
                        <TaskRejectedIcon />
                      )}
                    </Box>
                    <Box>
                      <span>
                        <Typography variant='body2' style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}>
                          {activity.activity.name}
                        </Typography>
                      </span>
                    </Box>
                  </Box>
                  <Box display='flex' alignContent='center' alignItems='center' flex={1} paddingLeft={2}>
                    <Typography variant='caption' color='textSecondary'>
                      {activity.dateActioned
                        ? `Updated ${formatDistance(unpackToDateTime(activity.dateActioned))}`
                        : 'Not reviewed'}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          {applicationAvailabilitySimple && (
            <Box
              display='flex'
              alignContent='center'
              alignItems='center'
              width={1}
              paddingX={1}
              className={classes.taskListItem}
              onClick={() => onAvailabilityClick()}
            >
              <Box
                display='flex'
                alignContent='center'
                alignItems='center'
                flex={2}
                className={classes.borderRight}
                paddingY={2}
              >
                <Box display='flex' paddingX={2}>
                  <TaskCompleteIcon isComplete />
                </Box>
                <Box>
                  <span>
                    <Typography variant='body2' style={{ marginRight: 12, display: 'flex', alignItems: 'center' }}>
                      Availability (Simple)
                    </Typography>
                  </span>
                </Box>
              </Box>
              <Box display='flex' alignContent='center' alignItems='center' flex={1} paddingLeft={2}>
                <Typography variant='caption' color='textSecondary'></Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </>
    </>
  );
};

export { IncomingVolunteerActivitiesSection };
