import React from 'react';
import { Theme, createMuiTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export const isThemeMobile = (theme: Theme): boolean => !useMediaQuery(theme.breakpoints.up('sm'));

export const campfireThemeExtendedOptions = {
  status: {
    green: {
      pale: '#EBF9EA',
      light: '#7dd268',
      medium: '#4CAF50',
      dark: '#43A047',
    },
    amber: {
      pale: '#FFF6E6',
      light: '#FABE4D',
      medium: '#FFC107',
      dark: '#FFB300',
    },
    red: {
      pale: '#FDE6E6',
      light: '#FF6969',
      medium: '#EF5350',
      dark: '#F44336',
    },
  },
  alert: {
    red: {
      extraLight: '#FEF2F2',
      light: '#F87171',
      mediumLight: '#ED9B9B',
      medium: '#B91C1C',
      dark: '#991B1B',
    },
    green: {
      extraLight: '#ECFDF5',
      light: '#34D399',
      mediumLight: '#76DDAC',
      medium: '#047857',
      dark: '#065F46',
    },
    yellow: {
      extraLight: '#FFFBEB',
      light: '#FBBF24',
      medium: '#B45309',
      dark: '#92400E',
    },
    blue: {
      extraLight: '#EFF6FF',
      light: '#60A5FA',
      medium: '#1D4ED8',
      dark: '#1E40AF',
    },
  },
  activities: {
    status: {
      joined: {
        primary: '#ABDC40',
        secondary: '#769B25',
      },
      applied: {
        primary: '#FFB956',
        secondary: '#DA8308',
      },
      waitlisted: {
        primary: '#60B386',
        secondary: '#AFA335',
      },
      'not on team': {
        primary: '#F2F2F2',
        secondary: '#9E9E9E',
        additional: '#646464',
      },
    },
  },
  rosters: {
    status: {
      rostered: {
        primary: '#ABDC40',
        secondary: '#769B25',
      },
      available: {
        primary: '#A7D6FC',
        secondary: '#4D81AB',
      },
      unavailable: {
        primary: '#788B9D',
        secondary: '#BACDDF',
      },
      unsure: {
        primary: '#F4DA75',
        secondary: '#AFA335',
      },
      'not on team': {
        primary: '#F2F2F2',
        secondary: '#9E9E9E',
        additional: '#646464',
      },
      cancelled: {
        primary: '#E5B3B3',
        secondary: '#996D6D',
      },
      attended: {
        primary: '#ABDC40',
        secondary: '#769B25',
      },
    },
  },
  color: {
    card: {
      cancelled: '#E5B3B3',
      '3dots': '#C4C4C4',
      available: '#FFFFFF',
    },
    tooltip: {
      background: 'rgba(40, 39, 41, 96)',
      'Copy-2': '#C9C8CC',
    },
    success: {
      500: '#60B386',
    },
    error: {
      900: '#D93A00',
    },
    starYellow: '#FBBC49',
    campfireBlue: '#2196F3',
    orangeGradient: 'linear-gradient(135deg, rgba(255,102,77,1) 0%,rgba(255,152,122,1) 100%)',
    darkGradientHorizontal: `linear-gradient(
              to left,
              rgba(36, 37, 61, 1) 1%,
              rgba(74, 48, 64, 1) 100%
            )`,
    luminusGradientHorizontal: `
              linear-gradient(
                to right,
                rgba(255, 246, 228, 1) 0%,
                rgba(255, 255, 255, 1) 100%
              )`,
    grey: {
      pale: '#ECECEC',
      light: '#929292',
      background: '#F9F9F9',
      border: '#EFEFEF',
      neutral200: '#9E9E9E',
      neutral300: '#646464',
      neutral400: '#545454',
      neutral500: '#444444',
      neutralBrand50: '#F4F0ED',
      neutralBrand100: '#F2F2F2',
      neutralBrand800: '#3E3229',
      neutralBrand200: '#DCDCDC',
      neutralBrand300: '#646464',
      neutralBrand400: '#545454',
      neutralBrand500: '#444444',
      neutralBrand900: '#1B1612',
    },
    white: {
      neutral50: '#FCFCFC',
    },
    black: '#000000',
    primary: {
      extraLight: '#E6E5FC',
    },
    secondary: {
      main50: '#E6E5FC',
      main700: '#7570EA',
      main900: '#7047EB',
    },
    enrolmenStatuses: {
      waitlist: {
        primary: '#F4DA75',
        secondary: '#AFA335',
      },
      applied: {
        primary: '#A7D6FC',
        secondary: '#4D81AB',
      },
      enrolled: {
        primary: '#ABDC40',
        secondary: '#769B25',
      },
      unenrolled: {
        primary: '#788B9D',
        secondary: '#BACDDF',
      },
    },
    rosteredStatuses: {
      available: {
        primary: '#A7D6FC',
        secondary: '#4D81AB',
      },
      unavailable: {
        primary: '#788B9D',
        secondary: '#BACDDF',
      },
      rostered: {
        primary: '#ABDC40',
        secondary: '#769B25',
      },
      unsure: {
        primary: '#F4DA75',
        secondary: '#AFA335',
      },
      'not on team': {
        primary: '#F2F2F2',
        secondary: '#9E9E9E',
        additional: '#646464',
      },
      cancelled: {
        primary: '#423951',
        secondary: '#B1B1B1',
      },
      waitlisted: {
        primary: '#60B386',
        secondary: '#AFA335',
      },
      applied: {
        primary: '#FFB956',
        secondary: '#DA8308',
      },
      attended: {
        primary: '#ABDC40',
        secondary: '#769B25',
      },
    },
    background: {
      primary: {
        dark: '#73638D',
        900: '#FF7705',
      },
    },
    rosters: {
      rostered: '#83C4A0',
      removed: '#EF5350',
      selected: '#A9A6FF',
      unavailabe: 'rgba(0,0,0,0.05)',
      available: '#85c2f3',
      empty: '',
      status: {
        published: {
          primary: '#ABDC40',
          secondary: '#769B25',
        },
        draft: {
          primary: '#FFB956',
          secondary: '#DA8308',
        },
        cancelled: {
          primary: '#423951',
          secondary: '#B1B1B1',
        },
        completed: {
          primary: '#A7E521',
          secondary: '#769B25',
        },
        neutral: {
          primary: '#788B9D',
          secondary: '#BACDDF',
        },
        rejected: {
          primary: '#A82D00',
          secondary: '#AFA335',
        },
        rostered: {
          primary: '#ABDC40',
          secondary: '#769B25',
        },
        joined: {
          primary: '#ABDC40',
          secondary: '#769B25',
        },
        available: {
          primary: '#A7D6FC',
          secondary: '#4D81AB',
        },
        unavailable: {
          primary: '#788B9D',
          secondary: '#BACDDF',
        },
        unsure: {
          primary: '#F4DA75',
          secondary: '#AFA335',
        },
        'not on team': {
          primary: '#F2F2F2',
          secondary: '#9E9E9E',
          additional: '#646464',
        },
        'not-published': {
          primary: '#A7D6FC',
          secondary: '#486B88',
        },
        'report-not-started': {
          primary: '#E5B3B3',
          secondary: '#996D6D',
        },
        'report-missing': {
          primary: '#F4DA75',
          secondary: '#968132',
        },
        waitlisted: {
          primary: '#60B386',
          secondary: '#AFA335',
        },
        applied: {
          primary: '#FFB956',
          secondary: '#DA8308',
        },
        attended: {
          primary: '#ABDC40',
          secondary: '#769B25',
        },
      },
    },
    programs: {
      status: {
        'not on team': '#C4C4C4',
        joined: '#ABDC40',
        pending: '#A7D6FC',
      },
    },
    activities: {
      rostersNeededBackground: '#FFEDF7',
      rostersNeededForeground: '#B5557D',
      volunteersNeededBackground: '#FFF8DC',
      volunteersNeededForeground: '#BDA648',
      activityBackground: '#F1F0F8',
      activityForeground: '#636178',
      activityNotStartedBackground: '#788B9D',
      activityNotStartedForeground: '#BACDDF',
      rosterPublishedBackground: '#ABDC40',
      rosterPublishedForeground: '#769B25',
      rosterCancelledBackground: '#E5B3B3',
      rosterCancelledForeground: '#996D6D',
      status: {
        default: {
          primary: '#DADADA',
          secondary: '#9E9E9E',
          additional: '#646464',
        },
        active: {
          primary: '#ABDC40',
          secondary: '#769B25',
        },
        suspended: {
          primary: '#FFB956',
          secondary: '#DA8308',
        },
        closed: {
          primary: '#E5B3B3',
          secondary: '#996D6D',
        },
        ended: {
          primary: '#E5B3B3',
          secondary: '#996D6D',
        },
        pinned: {
          primary: '#fbbf24',
          secondary: '#DA8308',
        },
        primary: {
          primary: '#796fff',
          secondary: '#DA8308',
        },
      },
    },
  },
  appShell: {
    drawer: {
      width: 260,
      sideNav: {
        itemBorderRadius: 8,
        itemVerticalMargin: 8,
      },
    },
    menu: {
      height: 57,
    },
    subHeader: {
      height: 64,
    },
  },
  responsive: {
    isMobile: (theme: Theme): boolean => !useMediaQuery(theme.breakpoints.up('sm')),
    actionsWords: {
      click: (theme: Theme) => (isThemeMobile(theme) ? 'tap' : 'click'),
    },
  },
};
const breakpoints = createBreakpoints({});

export const campfireTheme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#796fff',
      light: '#af9dff',
      dark: '#635ac7',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff9237',
      light: '#ffc367',
      dark: '#c66300',
      contrastText: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 350,
      sm: 600,
      md: 960,
      lg: 1250,
      xl: 1920,
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        color: '',
        '&$error': {
          color: 'red',
        },
      },
    },
    MuiInput: {
      root: {
        color: 'rgba(0,0,0,0.6)',
        '&$focused': {
          color: '#000',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        flexGrow: 1,
      },
    },
    MUIDataTable: {
      responsiveScrollFullHeight: {
        overflowX: 'scroll',
      },
    },
    MuiTablePagination: {
      root: {
        flex: 1,
      },
      actions: {
        marginLeft: '0px',
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: '0px',
      },
    },
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        minWidth: '0px',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: '0px',
      },
    },
    MuiPickersDatePickerRoot: {
      toolbar: {
        alignItems: 'center',
      },
    },
    MuiDialog: {
      // To enforce override of minWidth of all instances of MuiDialog Paper in Campfire screens
      paper: {
        [breakpoints.down('sm')]: {
          minWidth: '90vw',
        },
      },
    },
  },
  ...campfireThemeExtendedOptions,
});

export const CampfireThemeContext = React.createContext<Theme>(campfireTheme);
export const CampfireThemeContextProvider = CampfireThemeContext.Provider;
export const CampfireThemeContextConsumer = CampfireThemeContext.Consumer;
