import { unpackToTime } from '@campfire/hot-date';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { Loop as LoopIcon } from '@material-ui/icons';
import { ActivityCard } from '../../../../common/cards/activity-card/ActivityCard';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';

interface Props {
  activity: any;
  selected?: boolean;
  availabilityCenterItem?: boolean;
}

export const ActivityItem = (props: Props) => {
  const { theme } = useCampfireTheme();
  const { activity, selected, availabilityCenterItem } = props;

  const [query] = useQueryParams({
    activityId: StringParam,
  });

  const isSelected = (queryParams: any) => {
    if (availabilityCenterItem) {
      return selected;
    }
    return queryParams.activityId === activity.activityId || selected;
  };

  const activityStartTime = unpackToTime(activity.startTime).toFormat('h:mm a');
  const activityEndTime = unpackToTime(activity.endTime).toFormat('h:mm a');
  const recurrenceHumanReadable =
    activity.__typename === 'VOLUNTEER_RecurringActivityType'
      ? activity.schedule.recurrences[0].humanReadable
      : 'Flexible';

  return (
    <Box>
      <ActivityCard
        activity={activity}
        color={isSelected(query) ? theme.color.activities.status.primary : theme.color.activities.status.default}
        selected={isSelected(query)}
      >
        <Typography
          variant={'caption'}
          style={{ color: theme.color.grey.neutral200, display: 'flex', alignItems: 'center' }}
        >
          {recurrenceHumanReadable && <LoopIcon style={{ width: '0.7em', height: '0.7em', paddingRight: '4px' }} />}
          {recurrenceHumanReadable}
          {activity.startTime && activity.endTime ? `, ${activityStartTime} - ${activityEndTime}` : ''}
        </Typography>
      </ActivityCard>
    </Box>
  );
};
