import { Box, Tab, Tabs, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ExitToApp } from '@material-ui/icons';
import React, { useState } from 'react';
import { FullscreenDialog } from '../../../../common/dialogs/FullscreenDialog';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ChangePasswordPanel } from './ChangePasswordPanel';
import { EditProfilePanel } from './EditProfilePanel';
import { DeactivateAccountPanel } from './DeactivateAccountPanel';
import { MultiFactorPanel } from './AuthenticationPanel';

interface AccountSettingsDialogProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  refetch?: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: { overflow: 'visible', padding: '0 !important' },
  })
);

const useTabStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      minWidth: 72,
      textTransform: 'none',
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
      textAlign: 'left',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      '& [class*="MuiSvgIcon-root"]': {
        marginRight: 4,
        marginBottom: '0 !important',
      },
    },
  })
);

const TABS = ['Edit profile', 'Change password', 'Authentication', 'Deactivate Account'];

const AccountSettingsDialog = (props: AccountSettingsDialogProps) => {
  const { open, setOpen, refetch } = props;
  const { isMobile } = useCampfireTheme();
  const classes = useStyles();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(true);

  function onClose() {
    setOpen(false);
  }

  function mobileMenuChange() {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  function handleChange(_unusedEvent: React.ChangeEvent<{}>, value: number) {
    setSelectedTabIndex(value);
    setMobileMenuOpen(false);
  }

  const tabClasses = useTabStyles();

  return (
    <FullscreenDialog
      open={open}
      close={onClose}
      fullScreen={isMobile}
      title={'Account Settings'}
      PaperProps={{ className: classes.dialogPaper }}
      maxWidth='md'
      fullWidth
      noPadding
      mobileActionButton={isMobile}
      mobileAction={mobileMenuChange}
    >
      <Box
        display={'flex'}
        style={{ backgroundColor: '#f9f9f9', height: isMobile ? '100%' : 'auto', justifyContent: 'center' }}
      >
        {(isMobile && mobileMenuOpen) || !isMobile ? (
          <Box style={{ width: isMobile ? '100%' : 'auto' }}>
            <Tabs
              indicatorColor='primary'
              orientation='vertical'
              value={selectedTabIndex}
              onChange={handleChange}
              aria-label='my elements tabs'
              centered={isMobile}
              style={{ backgroundColor: 'white', height: '100%', minWidth: '185px', justifyContent: 'center' }}
            >
              {TABS.map((tabName) => (
                <Tab
                  id={`accountSettings-${tabName}-tab`}
                  icon={tabName === 'Deactivate Account' ? <ExitToApp style={{ fontSize: '12px' }} /> : undefined}
                  disableRipple
                  disableTouchRipple
                  classes={tabClasses}
                  label={tabName}
                  style={{ minHeight: '48px', alignSelf: 'center' }}
                  key={tabName}
                />
              ))}
            </Tabs>
          </Box>
        ) : (
          undefined
        )}
        {(isMobile && !mobileMenuOpen) || !isMobile ? (
          <Box
            display={'block'}
            alignItems={'center'}
            style={{
              height: 'fit-content',
              background: 'white',
              padding: '1.25rem 1.875rem',
              margin: isMobile ? '1rem auto' : '2.25rem auto',
              maxWidth: isMobile ? '80%' : '70%',
            }}
          >
            {selectedTabIndex === 0 ? (
              <EditProfilePanel refetch={refetch} />
            ) : selectedTabIndex === 1 ? (
              <ChangePasswordPanel onClose={onClose} />
            ) : selectedTabIndex === 2 ? (
              <MultiFactorPanel onClose={onClose} />
            ) : selectedTabIndex === 3 ? (
              <DeactivateAccountPanel onClose={onClose} />
            ) : (
              ''
            )}
          </Box>
        ) : (
          undefined
        )}
      </Box>
    </FullscreenDialog>
  );
};

export { AccountSettingsDialog };
