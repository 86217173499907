import { useSnackbar } from '../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../global/network/useEndpointFetch';
import {
  Cake,
  Task,
} from './admin-console-content-pages/admin-console-volunteer-profile/admin-console-volunteer-profile-model';
import { IncidentSeverityType } from './admin-console-content-pages/admin-console-incident-reporting/admin-console-incident-report-model';
import { ScreeningQuestionType } from './admin-console-content-pages/admin-console-onboarding-applications/admin-console-applicant-screening-model';
import {
  ActivityCancellationReasonType,
  ActivityClosureReasonType,
} from './admin-console-content-pages/admin-console-operations/admin-console-operations-model';

export const useSaveActivityClosureReasons = () => {
  return useEndpointFetch<{ reasons: { label: string; activityClosureReasonId?: string }[] }>(
    '/vm/activity/closure-reasons/save'
  );
};

export const useSaveActivityCancellationReasons = () => {
  return useEndpointFetch<{ reasons: { label: string; activityCancellationReasonId?: string }[] }>(
    '/vm/activity/cancellation-reasons/save'
  );
};

export const useAddAdminVolunteer = () => {
  return useEndpointFetch<{ volunteerId: string }>('/vm/admins/add');
};

export const useRemoveAdminVolunteer = () => {
  return useEndpointFetch<{ managerId: string }>('/vm/admins/remove');
};

export const useSaveIncidentSeverities = () => {
  return useEndpointFetch<{
    severities: { incidentSeverityId?: string; name: string; description: string; color: string }[];
  }>('/vm/incident/severities/save');
};

interface SaveIncidentDetail {
  incidentDetailId?: string;
  label: string;
}

interface SaveIncidentCategory {
  incidentCategoryId?: string;
  label: string;
  details: SaveIncidentDetail[];
}

interface SaveIncidentCategoryGroup {
  incidentCategoryGroupId?: string;
  label: string;
  childGroups: SaveIncidentCategoryGroup[];
  categories: SaveIncidentCategory[];
}

interface SaveIncidentCategoriesReq {
  root: SaveIncidentCategoryGroup;
}

export const useSaveIncidentCategories = () => {
  return useEndpointFetch<{ root: SaveIncidentCategoryGroup }>('/vm/incident/categories/save');
};

export const useSaveScreeningQuestions = () => {
  return useEndpointFetch<{ screeningQuestions: { screeningQuestionId?: string; label: string; order: number }[] }>(
    '/vm/screening-questions/save'
  );
};

export const useSaveTask = () => {
  return useEndpointFetch('/vm/task/save');
};

export const useSaveCake = () => {
  return useEndpointFetch('/vm/cake/save');
};

export const useUpdateTaskZeroManualApproval = () => {
  return useEndpointFetch('/vm/task/auto-approval/update');
};

export const useAdminConsoleActions = () => {
  const saveActivityCancellationReasons = useSaveActivityCancellationReasons();
  const saveActivityClosureReasons = useSaveActivityClosureReasons();
  const addAdminVolunteer = useAddAdminVolunteer();
  const removeAdminVolunteer = useRemoveAdminVolunteer();
  const saveIncidentSeverities = useSaveIncidentSeverities();
  const saveScreeningQuestions = useSaveScreeningQuestions();
  const saveIncidentCategories = useSaveIncidentCategories();
  const saveTask = useSaveTask();
  const saveCake = useSaveCake();
  const updateTaskZeroManualApproval = useUpdateTaskZeroManualApproval();
  const { setSnackbar } = useSnackbar();

  const runSaveActivityCancellationReasons = ({
    reasons,
    handleSuccess,
  }: {
    reasons: ActivityCancellationReasonType[];
    handleSuccess?: () => void;
  }) => {
    saveActivityCancellationReasons
      .run({
        reasons,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save changes',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Changes saved',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save changes',
          variant: 'error',
        })
      );
  };

  const runSaveActivityClosureReasons = ({
    reasons,
    handleSuccess,
  }: {
    reasons: ActivityClosureReasonType[];
    handleSuccess?: () => void;
  }) => {
    saveActivityClosureReasons
      .run({
        reasons,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save changes',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Changes saved',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save changes',
          variant: 'error',
        })
      );
  };

  const runAddAdminVolunteer = ({
    volunteerId,
    handleSuccess,
  }: {
    volunteerId: string;
    handleSuccess?: () => void;
  }) => {
    addAdminVolunteer
      .run({
        volunteerId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save admin user',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Saved volunteer as admin user',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save admin user',
          variant: 'error',
        })
      );
  };

  const runRemoveAdminVolunteer = ({ managerId, handleSuccess }: { managerId: string; handleSuccess?: () => void }) => {
    removeAdminVolunteer
      .run({
        managerId,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to remove admin user',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Removed volunteer as admin user',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to remove admin user',
          variant: 'error',
        })
      );
  };

  const runSaveIncidentSeverities = ({
    severities,
    handleSuccess,
  }: {
    severities: IncidentSeverityType[];
    handleSuccess?: () => void;
  }) => {
    saveIncidentSeverities
      .run({
        severities,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save severity',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Severity saved',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save severity',
          variant: 'error',
        })
      );
  };

  const runSaveScreeningQuestions = ({
    screeningQuestions,
    handleSuccess,
  }: {
    screeningQuestions: ScreeningQuestionType[];
    handleSuccess?: () => void;
  }) => {
    saveScreeningQuestions
      .run({
        screeningQuestions,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save screening questions',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Screening questions updated',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      });
    // .catch(() =>
    //   setSnackbar({
    //     open: true,
    //     message: 'Unable to save screening questions',
    //     variant: 'error',
    //   })
    // );
  };

  const runSaveIncidentCategories = ({
    root,
    handleSuccess,
  }: {
    root: SaveIncidentCategoryGroup;
    handleSuccess?: () => void;
  }) => {
    saveIncidentCategories
      .run({
        root,
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save changes',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Changes saved',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      })
      .catch(() =>
        setSnackbar({
          open: true,
          message: 'Unable to save changes',
          variant: 'error',
        })
      );
  };

  const runSaveTask = (task: Task, handleSuccess?: () => void) => {
    return saveTask
      .run({
        ...task,
        rules: [],
      })
      .then((res) => {
        if (!res.ok) {
          setSnackbar({
            open: true,
            message: 'Unable to save task',
            variant: 'error',
          });
          return;
        }

        setSnackbar({
          open: true,
          message: 'Task saved',
          variant: 'success',
        });
        if (handleSuccess) handleSuccess();
      });
  };

  const runSaveCake = (cake: Cake, handleSuccess?: () => void) => {
    saveCake.run(cake).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to save cake',
          variant: 'error',
        });
        return;
      }

      setSnackbar({
        open: true,
        message: 'Cake saved',
        variant: 'success',
      });
      if (handleSuccess) handleSuccess();
    });
  };

  const runUpdateTaskZeroManualApproval = (
    data: { taskId: string; autoApproval: boolean },
    handleSuccess?: () => void
  ) => {
    updateTaskZeroManualApproval.run(data).then((res) => {
      if (!res.ok) {
        setSnackbar({
          open: true,
          message: 'Unable to update manual approval',
          variant: 'error',
        });
        return;
      }

      setSnackbar({
        open: true,
        message: 'Manual approval updated',
        variant: 'success',
      });
      if (handleSuccess) handleSuccess();
    });
  };

  return {
    runSaveActivityCancellationReasons,
    runSaveActivityCancellationReasonsLoading: saveActivityCancellationReasons.isLoading,
    runSaveActivityClosureReasons,
    runSaveActivityClosureReasonsLoading: saveActivityClosureReasons.isLoading,
    runAddAdminVolunteer,
    runAddAdminVolunteerLoading: addAdminVolunteer.isLoading,
    runRemoveAdminVolunteer,
    runSaveIncidentSeverities,
    runSaveIncidentSeveritiesLoading: saveIncidentSeverities.isLoading,
    runSaveScreeningQuestions,
    runSaveScreeningQuestionsLoading: saveScreeningQuestions.isLoading,
    runSaveIncidentCategories,
    runSaveIncidentCategoriesLoading: saveIncidentCategories.isLoading,
    runSaveTask,
    runSaveTaskIsLoading: saveTask.isLoading,
    runSaveCake,
    runSaveCakeIsLoading: saveCake.isLoading,
    runUpdateTaskZeroManualApproval,
    runUpdateTaskZeroManualApprovalIsLoading: updateTaskZeroManualApproval.isLoading,
  };
};
