import { gql } from '@apollo/client';

export const GET_ALL_APPLICANT_ROLES = gql`
  query GetAllApplicantRoles($userId: String!) {
    vm {
      application(userId: $userId) {
        applicationRoles {
            applicationRoleId
            role {
                activityRoleId
            }
            status
        }
      }
    }
}`;