import { Box, Switch, Typography } from '@material-ui/core';
import { AssignmentTurnedIn } from '@material-ui/icons';
import React from 'react';

interface AutoVerificationEditFormProps {
  isLoading: boolean;
  autoVerification?: boolean;
  setAutoVerification: (value: boolean) => void;
}

export const AutoVerificationEditForm = (props: AutoVerificationEditFormProps) => {
  const { isLoading, autoVerification, setAutoVerification } = props;
  return (
    <Box>
      <>
        <Typography style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <AssignmentTurnedIn style={{ fontSize: '14px', paddingRight: '5px' }} /> Application Approval
        </Typography>
        <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
          {'Settings related to approvals of applicants'}
        </Typography>
        {isLoading ? null : autoVerification !== undefined ? (
          <Box display='flex' flex='1 1 auto' marginTop='1.5rem'>
            <Box style={{ paddingTop: 4, paddingRight: 8 }}>
              <Switch
                name='auto-verification-toggle'
                color='primary'
                checked={autoVerification}
                onChange={(e) => setAutoVerification(e.target.checked)}
                inputProps={{ 'aria-label': 'verification checkbox' }}
              />
            </Box>
            <Box>
              <Box ml={1}>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#333',
                  }}
                >
                  {`Automatically Approve (${autoVerification === false ? 'Off' : 'On'})`}
                </Typography>
                <Typography style={{ fontSize: '12px', color: '#808080' }}>
                  {autoVerification === false
                    ? 'Applications will always require manual review.'
                    : 'Applications will pass automatically if all screening questions are answered correctly.'}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : null}
      </>
    </Box>
  );
};
