import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, Typography } from '@material-ui/core';
import { EventBusy } from '@material-ui/icons';
import { FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useAdminConsoleActions } from '../../admin-console-actions';
import { AddReasonDialog } from './AddReasonDialog';
import {
  ActivityReasonType,
  generateFormContentBasedOnType,
  ReasonTypeFormValues,
} from './admin-console-operations-model';
import { ReasonListFieldSection } from './ReasonListFieldSection';

type ActivityReasonEditFormType = {
  initialValues: ReasonTypeFormValues;
  type: ActivityReasonType;
  refetchReasons?: () => void;
};

export const ActivityReasonEditForm = (props: ActivityReasonEditFormType) => {
  const { initialValues, type, refetchReasons } = props;
  const [openAddReasonDialog, setOpenAddReasonDialog] = useState<boolean>(false);
  const { runSaveActivityCancellationReasons, runSaveActivityClosureReasons } = useAdminConsoleActions();
  const formContent = generateFormContentBasedOnType(type);

  return (
    <Box pb={'2em'}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          if (type === ActivityReasonType.cancellation) {
            runSaveActivityCancellationReasons(values);
          }
          if (type === ActivityReasonType.closure) {
            runSaveActivityClosureReasons(values);
          }
        }}
      >
        <Form>
          <FieldArray name='reasons'>
            {(arrayHelpers) => (
              <>
                <Grid container direction='row' style={{ paddingBottom: 16 }}>
                  <Grid item xs>
                    <Typography style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                      <EventBusy style={{ fontSize: '14px', paddingRight: '5px' }} />
                      {formContent.formTitle}
                    </Typography>
                    <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
                      {formContent.helperText}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems='flex-start' justify='flex-end'>
                      <TabletButton
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          setOpenAddReasonDialog(true);
                        }}
                      >
                        {formContent.addButtonText}
                      </TabletButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs>
                  <ReasonListFieldSection arrayHelpers={arrayHelpers} type={type} />
                </Grid>

                <AddReasonDialog
                  open={openAddReasonDialog}
                  onClose={() => setOpenAddReasonDialog(false)}
                  title={formContent.addReasonDialogTitle}
                  type={type}
                  refetchReasons={refetchReasons}
                />
              </>
            )}
          </FieldArray>
        </Form>
      </Formik>
    </Box>
  );
};
