import { Box } from '@material-ui/core';
import React from 'react';
import Wordmark from '../../assets/branding/logo/volaby-original.svg';
import { AppBarAvatarMenu } from '../app-shell/app-bar/AppBarAvatarMenu';

export const APPLICANT_SHELL_APP_BAR_HEIGHT = 73;

interface Props {
  children?: React.ReactElement;
}

const ApplicantShellAppBar = (props: Props) => {
  const { children } = props;
  return (
    <Box>
      <Box
        display='flex'
        justifyContent='space-between'
        alignContent='center'
        alignItems='center'
        paddingY={1}
        paddingX={6}
        height={APPLICANT_SHELL_APP_BAR_HEIGHT}
        bgcolor='#ffffff'
        borderBottom='solid 1px #e9e9e9'
        boxSizing='border-box'
      >
        <Box display='flex' alignContent='center' alignItems='center' style={{ flexGrow: 1, flexBasis: 0 }}>
          <img src={Wordmark} width={128} alt='volaby-logo' />
        </Box>
        {children}
        <Box style={{ flexGrow: 1, flexBasis: 0, textAlign: 'right' }}>
          <AppBarAvatarMenu hideTasksMenuItem />
        </Box>
      </Box>
    </Box>
  );
};

export { ApplicantShellAppBar };
