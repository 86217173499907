import React, { memo, useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import { ListItemText, ListItem, Typography, Box, Button, Grid } from '@material-ui/core';
import { ChatBubble, Forum, PhoneIphone as PhoneIcon, StayPrimaryPortrait } from '@material-ui/icons';
import { unpackToDateTime } from '@campfire/hot-date';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useVolunteerCommonProfileContext } from '../../VolunteerCommonProfileContext';
import { VolunteerCommonProfileEmail } from '../../__generated__/VolunteerCommonProfileEmail';
import EmailBouncedIcon from '../../../../../assets/icons/emailBouncedIcon.svg';
import EmailClickedIcon from '../../../../../assets/icons/emailClickedIcon.svg';
import EmailDeliveredIcon from '../../../../../assets/icons/emailDeliveredIcon.svg';
import EmailOpenedIcon from '../../../../../assets/icons/emailOpenedIcon.svg';
import EmailSentIcon from '../../../../../assets/icons/emailSentIcon.svg';
import EmailIcon from '../../../../../assets/icons/emailIcon.svg';
import { CommunicationViewMoreDialog } from './CommunicationViewMoreDialog';
import { SmsLogTypeParsed } from '../../VolunteerCommonProfileDialog';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';

const EmailListItem = ({
  email,
  type,
  iconType,
  color,
}: {
  email: VolunteerCommonProfileEmail;
  type: string;
  iconType: string;
  color: string;
}) => (
  <Box display='flex' justifyContent='flex-between' key={email.postmarkEmailId}>
    <Box mt='28px' width='85px' position='relative'>
      <Box
        style={{
          display: 'flex',
          backgroundColor: `${color}`,
          borderRadius: '3px',
          padding: '4px 8px',
          alignItems: 'center',
          position: 'absolute',
          right: 0,
        }}
      >
        <img src={iconType} alt='' style={{ marginRight: '4px' }} />
        <Typography style={{ fontWeight: 600, fontSize: '11px', color: '#FCFCFC' }}>{type}</Typography>
      </Box>
    </Box>
    <ListItem style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <ListItemText
        primary={
          <Typography variant='h6' style={{ fontWeight: 900, fontSize: '14px', color: '#444444' }}>
            {email.subject}
          </Typography>
        }
        secondary={
          <Typography style={{ fontSize: '10px', color: '#9E9E9E', fontStyle: 'italic' }}>
            {email.sentAt && `Sent ${unpackToDateTime(email.sentAt).toFormat('h:mm a, dd LLLL yyyy')}`}
          </Typography>
        }
      />
      <Box display='flex'>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#d9d9d9',
            borderRadius: '3px',
            padding: '4px 8px',
            marginRight: '10px',
          }}
        >
          <img src={EmailIcon} alt='' style={{ marginRight: '4px', marginBottom: '0.5px' }} />
          <Typography style={{ fontWeight: 600, fontSize: '10px', color: '#545454' }}>{`Email`}</Typography>
        </Box>
        {email.communicationTemplate && (
          <Box style={{ border: '1px solid #d9d9d9', borderRadius: '3px', padding: '4px 8px' }}>
            <Typography style={{ fontWeight: 600, fontSize: '10px', color: '#545454' }}>
              {email.communicationTemplate.name}
            </Typography>
          </Box>
        )}
      </Box>
    </ListItem>
  </Box>
);

const SmsListItem = ({ sms }: { sms: SmsLogTypeParsed }) => {
  const { theme } = useCampfireTheme();

  return (
    <Box display='flex' justifyContent='flex-between' key={sms.smsLogId}>
      <Box display='flex' justifyContent='flex-between'>
        <Box mt='28px' width='85px' position='relative'>
          <Box
            style={{
              display: 'flex',
              backgroundColor: sms.status === 'replied' ? theme.color.secondary.main900 : '#83C4A0',
              borderRadius: '3px',
              padding: '4px 8px',
              alignItems: 'center',
              position: 'absolute',
              right: 0,
            }}
          >
            {sms.status === 'replied' ? (
              <Box display='flex' alignItems='flex-start' mr='4px'>
                <StayPrimaryPortrait style={{ width: '10px', height: '10px', color: '#ffffff' }} />
                <ChatBubble style={{ width: '10px', height: '7px', color: '#ffffff', marginLeft: '-2px' }} />
              </Box>
            ) : (
              <PhoneIcon style={{ width: '10px', height: '10px', color: '#ffffff', marginRight: '4px' }} />
            )}
            <Typography style={{ fontWeight: 600, fontSize: '11px', color: '#ffffff' }}>
              {capitalize(sms.status)}
            </Typography>
          </Box>
        </Box>
        <ListItem style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <ListItemText
            primary={
              <Typography variant='h6' style={{ fontWeight: 900, fontSize: '14px', color: '#444444' }}>
                SMS Volunteer Activity Attendance Confirmation
              </Typography>
            }
            secondary={
              <Typography style={{ fontSize: '10px', color: '#9E9E9E', fontStyle: 'italic' }}>
                {`${capitalize(sms.status)} ${unpackToDateTime(sms.sentAt).toFormat('h:mm a, dd LLLL yyyy')}`}
              </Typography>
            }
          />
          <Box display='flex'>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#d9d9d9',
                borderRadius: '3px',
                padding: '4px 8px',
                marginRight: '10px',
              }}
            >
              <PhoneIcon style={{ width: '10px', height: '10px', color: '#545454' }} />
              <Typography style={{ fontWeight: 600, fontSize: '10px', color: '#545454' }}>SMS</Typography>
            </Box>
          </Box>
        </ListItem>
      </Box>
    </Box>
  );
};

export const VolunteerCommunicationParsed = memo(
  ({ communication }: { communication: VolunteerCommonProfileEmail | SmsLogTypeParsed }) => {
    return (
      <>
        {communication && communication.__typename === 'EMAIL_PostmarkOutboundEmailType' ? (
          <>
            {communication.postmarkBounce ? (
              <EmailListItem email={communication} type='Bounced' iconType={EmailBouncedIcon} color='#D93A00' />
            ) : communication.postmarkClicks.length > 0 ? (
              communication.postmarkClicks.map(() => (
                <EmailListItem email={communication} type='Clicked' iconType={EmailClickedIcon} color='#7570EA' />
              ))
            ) : communication.postmarkOpens.length > 0 ? (
              communication.postmarkOpens.map(() => (
                <EmailListItem email={communication} type='Opened' iconType={EmailOpenedIcon} color='#2FC0F9' />
              ))
            ) : communication.postmarkDelivery ? (
              <EmailListItem email={communication} type='Delivered' iconType={EmailDeliveredIcon} color='#48976C' />
            ) : communication ? (
              <EmailListItem email={communication} type='Sent' iconType={EmailSentIcon} color='#83C4A0' />
            ) : (
              ''
            )}
          </>
        ) : (
          <SmsListItem sms={communication} />
        )}
      </>
    );
  }
);

export const VolunteerCommunicationsSection = memo(
  ({ emails, smsLogs }: { emails: Array<VolunteerCommonProfileEmail>; smsLogs: Array<SmsLogTypeParsed> }) => {
    const LIMIT = 10;
    const [viewMore, setViewMore] = useState(false);

    const {
      volunteerNameOptions: { preferredName },
    } = useVolunteerCommonProfileContext();

    const communications = useMemo(() => {
      return [...emails, ...smsLogs].sort((a, b) => {
        if (!b.sentAt) {
          return a.sentAt ? -1 : 1;
        }
        return unpackToDateTime(a.sentAt) > unpackToDateTime(b.sentAt) ? -1 : 1;
      });
    }, [emails, smsLogs]);

    const onViewMore = () => {
      setViewMore(true);
    };

    const closeViewMore = () => {
      setViewMore(false);
    };

    return (
      <Grid container spacing={2} direction='column' style={{ position: 'relative' }}>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <Typography
            style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center', color: '#333' }}
          >
            <Forum style={{ fontSize: '14px', paddingRight: '5px' }} /> Communications
          </Typography>
          <Typography variant='body2' style={{ color: '#464646' }} align='left'>
            Communications that have been sent to and received from {preferredName}
            <hr style={{ borderTop: '.5px solid #e7e7e7', margin: '20px 0' }} />
          </Typography>
          {communications.slice(0, LIMIT).map((communication) => (
            <VolunteerCommunicationParsed communication={communication} />
          ))}
          {communications.length > 10 && (
            <Box display='flex' justifyContent='center'>
              <Button
                onClick={onViewMore}
                disableElevation
                color='primary'
                disabled={false}
                size='medium'
                endIcon={<ExpandMoreIcon />}
                style={{
                  alignSelf: 'center',
                  marginTop: '1rem',
                  color: '#545454',
                  padding: '2px 6px',
                  fontSize: '12px',
                  fontWeight: 400,
                  borderRadius: '6px',
                  textTransform: 'capitalize',
                }}
              >
                View more
              </Button>
            </Box>
          )}
          {viewMore && (
            <CommunicationViewMoreDialog
              open
              handleClose={closeViewMore}
              communications={communications}
              subtitle={`Communications that have been sent to ${preferredName}`}
            />
          )}
        </Grid>
        {communications.length === 0 && (
          <Typography
            variant='body2'
            style={{ fontSize: '13px', color: '#494949', textAlign: 'center', padding: '2rem 0' }}
          >
            No results found
          </Typography>
        )}
      </Grid>
    );
  }
);
