import React from 'react';
import { Box, Button, Checkbox, ListItemText, MenuItem, TextField, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { FullscreenDialog } from '../../common/dialogs/FullscreenDialog';
import { AlertCard, getAlertCardColors } from '../../common/cards/alert-card/AlertCard';
import { GetAllActivitiesForVolunteer_vm_programs_activities as ActivityType } from './__generated__/GetAllActivitiesForVolunteer';

interface ActivityFiltersProps {
  open: boolean;
  fullScreen: boolean;
  onClose: () => void;
  activities: ActivityType[];
  selectedActivities: string[];
  setSelectedActivities: (selectedActivities: string[]) => void;
  onCancel: () => void;
  simple?: boolean;
  addVol?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      textTransform: 'none',
    },
  })
);

const ALL_ITEMS = [
  {
    label: `All activities`,
    value: 'all',
    secondary: '',
  },
  {
    label: `None of activities`,
    value: 'none',
    secondary: '',
  },
];

export function ActivityFilters(props: ActivityFiltersProps) {
  const {
    open,
    onClose,
    fullScreen,
    activities,
    selectedActivities,
    setSelectedActivities,
    onCancel,
    simple,
    addVol,
  } = props;
  const [stateSelectActivities, setStateSelectActivities] = React.useState(selectedActivities);
  const classes = useStyles();
  const { lightTextColor } = getAlertCardColors('info');

  const onSetActivities = () => {
    setSelectedActivities(stateSelectActivities.filter((activityId) => activityId !== 'all'));
    onClose();
  };

  const onSelectActivities = (event: React.ChangeEvent<{ value: unknown }>) => {
    const values = event.target.value as string[];
    if (values.includes('all') && !values.includes('none')) {
      if (stateSelectActivities.includes('all')) {
        setStateSelectActivities(activities.map((activity) => activity.activityId));
      } else {
        setStateSelectActivities(['all']);
      }
    } else if (values.includes('none')) {
      setStateSelectActivities([]);
    } else {
      setStateSelectActivities(event.target.value as string[]);
    }
  };

  const DialogActions = () => (
    <React.Fragment>
      <Button onClick={onSetActivities} className={classes.button} variant='contained' color='primary'>
        {addVol ? 'Add' : 'Continue'}
      </Button>
    </React.Fragment>
  );

  const sortedActivities = activities.sort((a, b) => a.name.localeCompare(b.name));
  const activityOptions = ALL_ITEMS.concat(
    sortedActivities.map((activity) => ({
      label: activity.name,
      value: activity.activityId,
      secondary: activity.program.name,
    })) || []
  );

  return (
    <FullscreenDialog
      title='Select activities'
      open={open}
      close={onCancel}
      fullScreen={fullScreen}
      dialogActions={DialogActions}
    >
      <Box>
        {!simple && (
          <Box paddingBottom={'10px'}>
            <AlertCard title='Activity Filter' variant='info'>
              <Typography style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                Select one or many activities below to manage the relevant activities and rosters. Selecting fewer
                activities at a time will increase load speed.
              </Typography>
            </AlertCard>
          </Box>
        )}
        {addVol && (
          <Box paddingBottom={'10px'}>
            <AlertCard title='Enrol Volunteer' variant='info'>
              <Typography style={{ fontSize: '14px', fontWeight: 400, color: lightTextColor }}>
                Select one or many activities below to enrol a volunteer in. <br />
                Volunteers may then be rostered, receive segmented communications, tasks, resources, etc.
              </Typography>
            </AlertCard>
          </Box>
        )}
        <TextField
          style={{ marginTop: '1rem' }}
          fullWidth
          variant='outlined'
          select
          InputLabelProps={{ shrink: true }}
          SelectProps={{
            multiple: true,
            displayEmpty: true,
            renderValue: () => {
              if (stateSelectActivities.length === 0 || stateSelectActivities.includes('all')) {
                return 'All activities';
              }
              if (stateSelectActivities.length >= 3) {
                return `${stateSelectActivities.length} activities`;
              }
              return stateSelectActivities
                .map((activityId) => activities.find((activity) => activity.activityId === activityId))
                .filter((activity) => !!activity)
                .map((activity) => activity?.name)
                .join(', ');
            },
          }}
          onChange={onSelectActivities}
          value={stateSelectActivities}
          label='Activities'
        >
          {activityOptions.map((item) => {
            if (item.value === 'all') {
              return (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary='Select All' />
                </MenuItem>
              );
            }
            if (item.value === 'none') {
              return (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary='Deselect All' />
                </MenuItem>
              );
            }
            return (
              <MenuItem key={item.value} value={item.value}>
                <Checkbox
                  color='primary'
                  value={item.value}
                  checked={stateSelectActivities.includes(item.value) || stateSelectActivities.includes('all')}
                />
                <ListItemText primary={item.label} secondary={item.secondary} />
              </MenuItem>
            );
          })}
        </TextField>
      </Box>
    </FullscreenDialog>
  );
}
