import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { SaveableCommentField } from '@campfire/saveable-comment-field';
import { FormControl, InputLabel, Typography, Box } from '@material-ui/core';
import React, { memo } from 'react';
import { InfoBlock } from '../../../common/InfoBlock';
import { useVolunteerCommonProfileContext } from '../../../VolunteerCommonProfileContext';
import { useVolunteerProfileActions } from '../../../volunteer-profile-actions';

export const VolunteerComments = memo(
  ({
    volunteerId,
    comments,
    editable,
    refetch,
  }: {
    volunteerId: string;
    comments: string | null;
    editable?: boolean;
    onClose: () => void;
    refetch: () => void;
  }) => {
    const { setUnsavedComments } = useVolunteerCommonProfileContext();
    const handleSuccess = () => {
      setUnsavedComments(false);
      refetch();
    };
    const { runSaveComments, runSaveCommentsLoading, runSaveCommentsError } = useVolunteerProfileActions();

    return editable ? (
      <>
        <CircularProgressOverlay isLoading={runSaveCommentsLoading} />
        <Box py={1} pr={1}>
          <FormControl fullWidth>
            <InputLabel style={{ marginTop: -24, paddingLeft: 8 }} htmlFor='comments-input'>
              <Typography variant='caption' display='block' color={runSaveCommentsError ? 'error' : 'textPrimary'}>
                {runSaveCommentsError ? 'Comments (NOT SAVED)' : 'Comments'}
              </Typography>
            </InputLabel>

            <SaveableCommentField
              rawContent={comments || undefined}
              onSave={(rawContent) => {
                runSaveComments({
                  volunteerId: volunteerId,
                  comments: rawContent,
                  handleSuccess,
                });
              }}
              setUnsavedComments={setUnsavedComments}
            />
          </FormControl>
        </Box>
      </>
    ) : comments ? (
      <InfoBlock label='Comments' value={comments} />
    ) : null;
  }
);
