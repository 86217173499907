import { Box, Switch, Typography } from '@material-ui/core';
import { EventAvailable } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import {
  GET_APPLICATION_AVAILABILITY_SIMPLE,
  GET_APPLICATION_ENROL_ACTIVITIES,
  GET_APPLICATION_AVAILABILITY_COMPLEX,
} from './admin-console-applicant-screening.gql';
import { GetApplicationAvailabilitySimple } from './__generated__/GetApplicationAvailabilitySimple';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetApplicationAvailabilityComplex } from './__generated__/GetApplicationAvailabilityComplex';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { GetApplicationEnrolActivities } from './__generated__/GetApplicationEnrolActivities';

export const ActivitiesAndAvailabilitiesForm = () => {
  const { setSnackbar } = useSnackbar();
  const [applicationAvailabilitySimple, setApplicationAvailabilitySimple] = React.useState(false);
  const [applicationAvailabilityComplex, setApplicationAvailabilityComplex] = React.useState(false);
  const [applicationActivityEnrolment, setApplicationActivityEnrolment] = React.useState(false);
  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });

  const { data: availabilitySimple, refetch: availabilitySimpleRefetch } = useCampfireQuery<
    GetApplicationAvailabilitySimple,
    undefined
  >(GET_APPLICATION_AVAILABILITY_SIMPLE);

  const { data: availabilityComplex, refetch: availabilityComplexRefetch } = useCampfireQuery<
    GetApplicationAvailabilityComplex,
    undefined
  >(GET_APPLICATION_AVAILABILITY_COMPLEX);

  const { data: applicationActivity, refetch: applicationActivityRefetch } = useCampfireQuery<
    GetApplicationEnrolActivities,
    undefined
  >(GET_APPLICATION_ENROL_ACTIVITIES);

  useEffect(() => {
    setApplicationAvailabilityComplex(availabilityComplex?.vm.configItem?.configItemValue?.flagValue ?? false);
    setApplicationAvailabilitySimple(availabilitySimple?.vm.configItem?.configItemValue?.flagValue ?? false);
    setApplicationActivityEnrolment(applicationActivity?.vm.configItem?.configItemValue?.flagValue ?? false);
  }, [availabilityComplex, availabilitySimple, applicationActivity]);

  const refetchValues = () => {
    availabilityComplexRefetch();
    availabilitySimpleRefetch();
    applicationActivityRefetch();
    setApplicationAvailabilityComplex(availabilityComplex?.vm.configItem?.configItemValue?.flagValue ?? false);
    setApplicationAvailabilitySimple(availabilitySimple?.vm.configItem?.configItemValue?.flagValue ?? false);
    setApplicationActivityEnrolment(applicationActivity?.vm.configItem?.configItemValue?.flagValue ?? false);
  };

  const onValueChange = (type: string) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/admin-console/application/application-availability`,
        method: 'post',
        data: {
          value: type === 'complex' ? !applicationAvailabilityComplex : !applicationAvailabilitySimple,
          type: type,
        },
      })
      .then(() => {
        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Settings updated',
        });
        refetchValues();
      });
  };

  const onActivityChange = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/admin-console/config`,
        method: 'post',
        data: {
          value: !applicationActivityEnrolment,
          type: 'application-enrol-activity',
        },
      })
      .then(() => {
        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Settings updated',
        });
        refetchValues();
      });
  };

  return (
    <Box>
      <>
        <Typography style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <EventAvailable style={{ fontSize: '14px', paddingRight: '5px' }} /> Activities & Availabilities
        </Typography>
        <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
          {'Settings related to applicants indicating activity and availability preferences'}
        </Typography>
        <Box>
          <Typography
            style={{ fontWeight: 600, fontSize: '15px', display: 'flex', alignItems: 'center', marginTop: '1.5rem' }}
          >
            Activities
          </Typography>
        </Box>
        <Box display='flex' flex='1 1 auto' marginTop='.5em'>
          <Box style={{ paddingTop: 4, paddingRight: 8 }}>
            <Switch
              name='auto-verification-toggle'
              color='primary'
              checked={applicationActivityEnrolment}
              onChange={() => onActivityChange()}
              inputProps={{ 'aria-label': 'verification checkbox' }}
            />
          </Box>
          <Box>
            <Box ml={1}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#333',
                }}
              >
                Activity Preferences & Enrolment
              </Typography>
              <Typography style={{ fontSize: '13px', color: '#808080' }}>
                Applicants will be able to indicate their activity preferences as part of the application workflow.{' '}
                <br />
                This will enable automatic enrolment to the activity team, transfer to the application list or waitlist
                on manager approval
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography
            style={{ fontWeight: 600, fontSize: '15px', display: 'flex', alignItems: 'center', marginTop: '1.5rem' }}
          >
            Availabilities
          </Typography>
          <Typography style={{ fontSize: '13px', color: '#808080' }}>
            You may only enable one of the following
          </Typography>
        </Box>
        <Box display='flex' flex='1 1 auto' marginTop='1em'>
          <Box style={{ paddingTop: 4, paddingRight: 8 }}>
            <Switch
              name='auto-verification-toggle'
              color='primary'
              checked={applicationAvailabilitySimple}
              onChange={() => onValueChange('simple')}
              inputProps={{ 'aria-label': 'verification checkbox' }}
            />
          </Box>
          <Box>
            <Box ml={1}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#333',
                }}
              >
                Simple Availability
              </Typography>
              <Typography style={{ fontSize: '13px', color: '#808080' }}>
                Applicants can indicate availability to managers to in a simple weekday and time structure. <br />
                This information will not transfer to visible availability on roster sessions.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box display='flex' flex='1 1 auto' marginTop='1.5rem'>
          <Box style={{ paddingTop: 4, paddingRight: 8 }}>
            <Switch
              name='auto-verification-toggle'
              color='primary'
              checked={applicationAvailabilityComplex}
              onChange={() => onValueChange('complex')}
              inputProps={{ 'aria-label': 'verification checkbox' }}
            />
          </Box>
          <Box>
            <Box ml={1}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#333',
                }}
              >
                Enhanced Availability
              </Typography>
              <Typography style={{ fontSize: '13px', color: '#808080' }}>
                Applicants can indicate availabity to managers on a date and session basis. <br />
                This information, on applicant approval, will transfer directly to visible availability on rosters.
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    </Box>
  );
};
