import React, { useState } from 'react';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { AccessTime, Close, ExitToApp, ExpandMore, Group, HourglassEmpty, SyncRounded } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { unpackToDate, unpackToDateTime, unpackToTime } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import { convertFromRaw, EditorState } from 'draft-js';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { TaskMenuBarHeader } from '../TaskSideMenuBar';
import { useApiUrl } from '../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../global/network/useCampfireFetch';
import { useSnackbar } from '../../../../../../../global/config/useSnackbar';
import { SnackbarContextProps } from '../../../../../../../global/config/SnackbarContext';
import { GetIncomingVolunteerProfile_vm_profile_application_applicationActivities as ApplicationActivities } from '../../../__generated__/GetIncomingVolunteerProfile';
import { getDisplayTimeSchedule } from '../../../../../../../common/functions/activity-display-helpers';

interface IncomingVolunteerTaskViewProps {
  onClose: () => void;
  applicationActivityId?: string;
  reload?: () => void;
  setSnackbar?: (props: SnackbarContextProps | undefined) => void;
  activityApplication?: ApplicationActivities;
}

const useTaskListItemClasses = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      border: '.6px solid #00000010',
      padding: '15px 30px',
      backgroundColor: '#fff',
    },
    checkIcon: {
      fontSize: 29,
      color: '#00ed7e',
    },
    circleIcon: {
      fontSize: 29,
      color: theme.palette.grey[200],
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      datesContainer: {
        background: theme.color.grey.neutralBrand100,
        borderRadius: 4,
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
      },
      dateGridItem: {
        background: theme.color.white.neutral50,
        padding: '6px',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '8px',
        '&:hover': {
          background: theme.palette.grey[200],
        },
      },
      padding: '0.3rem',
      borderRadius: '4px',
      cursor: 'pointer',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.75,
      },
    },
    menuItemText: {
      marginLeft: '0.3rem',
      fontSize: '14px',
    },
    menuItemTextInstructions: {
      marginLeft: '0.3rem',
      fontSize: '12px',
    },
    tooltip: {
      borderRadius: '12px',
      paddingTop: '0.5rem',
      paddingLeft: '.7rem',
      paddingRight: '.7rem',
      paddingBottom: '0.5rem',
      backgroundColor: theme.color.tooltip.background,
    },
    tooltipArrow: {
      '&::before': {
        backgroundColor: theme.color.tooltip.background,
      },
    },
    datesContainer: {
      background: theme.color.grey.neutralBrand100,
      borderRadius: 4,
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
    },
    dateGridItem: {
      background: theme.color.white.neutral50,
      padding: '6px',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      '&:hover': {
        background: theme.palette.grey[200],
      },
    },
    title: {
      color: theme.color.grey.neutral400,
      fontWeight: 600,
      width: '100%',
      letterSpacing: '-0.02em',
      fontSize: '13px',
    },
    subTitle: {
      fontSize: 11,
      color: theme.color.grey.neutral200,
    },
    subTitlesContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    subTitleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: '7px',
      color: theme.color.grey.neutral200,
    },
  })
);

export const IncomingVolunteerActivityView = ({
  onClose,
  reload,
  activityApplication,
}: IncomingVolunteerTaskViewProps) => {
  return activityApplication ? (
    <>
      <Box>
        <TaskMenuBarHeader onClose={onClose}>
          <Box>
            <Typography>Activity Application</Typography>
          </Box>
        </TaskMenuBarHeader>
      </Box>

      <Box flex={1} style={{ overflowY: 'scroll', backgroundColor: '#f6f8f9', width: '100%' }} display='flex'>
        <Box position='relative' display='flex' flex='1 1 auto' height={'auto'} minHeight={1} justifyContent={'center'}>
          <ActivityListItem appliedActivity={activityApplication} reload={reload} />
        </Box>
      </Box>
    </>
  ) : (
    <></>
  );
};

interface ActivityProps {
  appliedActivity?: ApplicationActivities;
  reload?: () => void;
}

const ActivityListItem = (props: ActivityProps) => {
  const taskListItemClasses = useTaskListItemClasses();
  const classes = useStyles();
  const { theme } = useCampfireTheme();
  const { appliedActivity, reload } = props;
  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  const { setSnackbar } = useSnackbar();
  const [withdrawOpen, setWithdrawOpen] = useState<boolean>(false);

  const applicationStatus = () => {
    if (appliedActivity?.status === 'pending') {
      return 'unsure';
    }
    if (appliedActivity?.status === 'to-team') {
      return 'rostered';
    }
    if (appliedActivity?.status === 'to-application') {
      return 'applied';
    }
    if (appliedActivity?.status === 'to-waitlist') {
      return 'waitlisted';
    }
    if (appliedActivity?.status === 'decline') {
      return 'rejected';
    }
    return 'available';
  };

  const applicationStatusDesc = () => {
    if (appliedActivity?.status === 'pending') {
      return 'Pending';
    }
    if (appliedActivity?.status === 'to-team') {
      return 'To Team';
    }
    if (appliedActivity?.status === 'to-application') {
      return 'To Application';
    }
    if (appliedActivity?.status === 'to-waitlist') {
      return 'To Waitlist';
    }
    if (appliedActivity?.status === 'decline') {
      return 'Rejected';
    }
    return 'Apply';
  };

  const applicationStatusIcon = () => {
    if (appliedActivity?.status === 'pending') {
      return <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
    }
    if (appliedActivity?.status === 'approved') {
      return <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
    }
    if (appliedActivity?.status === 'rejected') {
      return <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
    }
    return <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
  };

  const onActivityUpdate = (activityId: string, status: string) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/application/activity-application/update-status`,
        method: 'post',
        data: {
          applicationActivityId: activityId,
          status: status,
        },
      })
      .then((response) => {
        if (reload) reload();
        if (response.ok) {
          setSnackbar({
            variant: 'success',
            open: true,
            message: 'Status Updated',
          });
        } else {
          setSnackbar({
            variant: 'error',
            open: true,
            message: 'Error updating status',
          });
        }
      });
  };
  const managerName = appliedActivity?.actionedBy?.profile.preferredName
    ? `${appliedActivity?.actionedBy?.profile.preferredName} ${appliedActivity?.actionedBy.profile.lastName}`
    : `${appliedActivity?.actionedBy?.profile.firstName} ${appliedActivity?.actionedBy?.profile.lastName}`;

  const statusColor = applicationStatus();
  const recurrence =
    appliedActivity?.activity.__typename === 'VOLUNTEER_RecurringActivityType'
      ? appliedActivity?.activity.schedule.recurrences[0].humanReadable
      : 'Flexible';

  const formatTime = (time: string) => unpackToTime(time).toFormat('h:mm a');
  const editorState = React.useMemo(
    () =>
      appliedActivity?.activity.description
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(appliedActivity?.activity.description)))
        : null,
    [appliedActivity?.activity.description]
  );

  const availabilitySessions = (appliedActivity?.availabilitySessions as unknown) as { sessionIds: string };
  const availabilityDates = (appliedActivity?.availabilityDates as unknown) as string[];

  return (
    <List style={{ paddingBottom: '0', paddingTop: '10px', alignItems: 'center', flexGrow: 1, margin: '1em 2em' }}>
      <ListItem className={taskListItemClasses.listItem} style={{ alignItems: 'flex-start' }}>
        <ListItemText>
          <Typography
            style={{ fontWeight: 600, fontSize: '15px', display: 'flex', alignItems: 'center', color: '#444' }}
          >
            {appliedActivity?.activity.name}
          </Typography>
          <Box display='flex' alignItems='center' paddingTop={'2px'}>
            <SyncRounded style={{ color: theme.color.grey.neutral200, fontSize: 16, marginRight: '6px' }} />
            <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200 }}>
              {recurrence}
              {appliedActivity?.activity.startTime && appliedActivity?.activity.endTime
                ? `, ${formatTime(appliedActivity?.activity.startTime)} - ${formatTime(
                    appliedActivity?.activity.endTime
                  )}`
                : ''}
            </Typography>
          </Box>
        </ListItemText>
        <ListItemIcon style={{ paddingLeft: '15px', paddingTop: '7px' }}>
          <ClickAwayListener onClickAway={() => setWithdrawOpen(false)}>
            <Tooltip
              arrow
              interactive
              title={
                <Box>
                  {appliedActivity?.status !== 'to-team' && (
                    <Box
                      className={classes.menuItem}
                      onClick={() =>
                        appliedActivity ? onActivityUpdate(appliedActivity.applicationActivityId, 'to-team') : undefined
                      }
                      style={{ color: theme.alert.green.light }}
                    >
                      <Group style={{ fontSize: '17px', marginRight: '5px' }} />
                      <Typography className={classes.menuItemText}>To Team</Typography>
                    </Box>
                  )}
                  {appliedActivity?.status !== 'to-application' && (
                    <Box
                      className={classes.menuItem}
                      onClick={() =>
                        appliedActivity
                          ? onActivityUpdate(appliedActivity.applicationActivityId, 'to-application')
                          : undefined
                      }
                      style={{ color: theme.color.enrolmenStatuses.applied.primary }}
                    >
                      <ExitToApp style={{ fontSize: '17px', marginRight: '5px' }} />
                      <Typography className={classes.menuItemText}>To Application</Typography>
                    </Box>
                  )}
                  {appliedActivity?.status !== 'to-waitlist' && (
                    <Box
                      className={classes.menuItem}
                      onClick={() =>
                        appliedActivity
                          ? onActivityUpdate(appliedActivity.applicationActivityId, 'to-waitlist')
                          : undefined
                      }
                      style={{ color: theme.color.enrolmenStatuses.waitlist.primary }}
                    >
                      <HourglassEmpty style={{ fontSize: '17px', marginRight: '5px' }} />
                      <Typography className={classes.menuItemText}>To Waitlist</Typography>
                    </Box>
                  )}
                  {appliedActivity?.status !== 'decline' && (
                    <Box
                      className={classes.menuItem}
                      onClick={() =>
                        appliedActivity ? onActivityUpdate(appliedActivity.applicationActivityId, 'decline') : undefined
                      }
                      style={{ color: theme.alert.red.light }}
                    >
                      <Close style={{ fontSize: '17px', marginRight: '5px' }} />
                      <Typography className={classes.menuItemText}>Reject</Typography>
                    </Box>
                  )}
                </Box>
              }
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClick={() => setWithdrawOpen(true)}
              placement='bottom-end'
              open={withdrawOpen}
              onClose={() => setWithdrawOpen(false)}
              onOpen={() => setWithdrawOpen(true)}
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
              }}
            >
              <TabletButton
                data-track='resources-add-new-menu-button'
                variant='outlined'
                color='primary'
                aria-controls='add-new'
                aria-haspopup='true'
                endIcon={applicationStatusIcon()}
                style={{ padding: '8px 12px', border: `1px solid #DCDCDC`, whiteSpace: 'nowrap' }}
              >
                <Box display='flex' alignItems='center'>
                  <Box
                    height='10px'
                    width='10px'
                    borderRadius='50%'
                    marginRight='8px'
                    bgcolor={theme.color.rosters.status[statusColor].primary}
                  />
                  <Typography
                    variant='caption'
                    style={{
                      fontSize: '12px',
                      fontWeight: 700,
                      color: theme.color.grey.neutral400,
                      textTransform: 'uppercase',
                      lineHeight: '14px',
                      marginTop: '1px',
                    }}
                  >
                    {applicationStatusDesc()}
                  </Typography>
                </Box>
              </TabletButton>
            </Tooltip>
          </ClickAwayListener>
        </ListItemIcon>
      </ListItem>
      <ListItem className={taskListItemClasses.listItem} style={{ alignItems: 'flex-start' }}>
        <ListItemText>
          <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080', paddingTop: '5px' }}>
            {editorState && <CampfireRichEditor readOnly editorState={editorState} />}
          </Typography>
          {availabilityDates && availabilitySessions && (
            <>
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#444',
                    paddingTop: '10px',
                    paddingBottom: '5px',
                  }}
                >
                  Availability - Sessions
                </Typography>
              </Box>
              <Box className={classes.datesContainer} padding={'12px 8px 8px'} justifyContent={'unset'}>
                {appliedActivity?.activity.sessions.map((session) => {
                  return (
                    <Box style={{ backgroundColor: '#fff', padding: '.5em 1em', borderRadius: '10px' }}>
                      <Typography className={classes.title}>{session.name}</Typography>
                      <Box>
                        <Box className={classes.subTitlesContainer}>
                          <Box className={classes.subTitleContainer}>
                            <AccessTime style={{ fontSize: 17 }} className={classes.icon} />
                            <Typography className={classes.subTitle}>
                              {getDisplayTimeSchedule(session.startTime, session.endTime)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <Box>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#444',
                    paddingTop: '10px',
                    paddingBottom: '5px',
                  }}
                >
                  Availability - Dates
                </Typography>
              </Box>
              <Box className={classes.datesContainer} padding={'12px 8px 8px'} justifyContent={'unset'}>
                {appliedActivity?.activity.occurrencesBetween.map((date: any) => {
                  return (
                    <Box key={date} margin='4px'>
                      <Box display='flex' justifyContent='center' alignItems='center'>
                        <ButtonBase
                          disableRipple
                          disableTouchRipple
                          disabled
                          className={classes.dateGridItem}
                          data-track='fs-volPrf-enrolment-roster-date-item'
                          style={{
                            backgroundColor: undefined,
                            borderBottom: availabilityDates.find((a) => a === date)
                              ? `8px solid ${theme.color.rosters.status.available.primary}`
                              : `8px solid ${theme.color.rosters.status.unavailable.primary}`,
                            minWidth: '87px',
                          }}
                        >
                          <Typography variant='body2' style={{ fontSize: '11px' }}>
                            {unpackToDate(date).toLocaleString(DateTime.DATE_MED)}
                          </Typography>
                        </ButtonBase>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </>
          )}
        </ListItemText>
      </ListItem>

      {appliedActivity?.dateActioned && appliedActivity.actionedBy ? (
        <ListItem className={taskListItemClasses.listItem} style={{ marginTop: '15px' }}>
          <ListItemText>
            <Typography
              style={{ fontWeight: 600, fontSize: '13px', display: 'flex', alignItems: 'center', color: '#444' }}
            >
              Status
              <br />
            </Typography>
            <Typography variant={'body2'} style={{ fontSize: '12px', color: '#808080' }}>
              <span style={{ textTransform: 'capitalize' }}>{applicationStatusDesc()}</span> by {managerName},{' '}
              {unpackToDateTime(appliedActivity.dateActioned).toFormat('DDDD')}
            </Typography>
          </ListItemText>
        </ListItem>
      ) : (
        undefined
      )}

      <ListItem
        className={taskListItemClasses.listItem}
        style={{ marginTop: '2em', backgroundColor: 'transparent', border: 'none', padding: '0 1.5em' }}
      >
        <ListItemText>
          <Typography
            style={{ fontWeight: 600, fontSize: '13px', display: 'flex', alignItems: 'center', color: '#666' }}
          >
            Application Actions
          </Typography>
          <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
            <Box style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px' }}>
              <Group style={{ fontSize: '17px' }} />
              <Typography className={classes.menuItemTextInstructions}>
                <strong style={{ paddingRight: '5px' }}>To Team</strong>
                Users will be assigned into the activity team when their application is approved
              </Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px' }}>
              <ExitToApp style={{ fontSize: '17px' }} />
              <Typography className={classes.menuItemTextInstructions}>
                <strong style={{ paddingRight: '5px' }}>To Application</strong>
                Users will be assigned to the activity application list when their application is approved
              </Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px' }}>
              <HourglassEmpty style={{ fontSize: '17px' }} />
              <Typography className={classes.menuItemTextInstructions}>
                <strong style={{ paddingRight: '5px' }}>To Waitlist</strong>
                Users will be assigned to the activity waitlist when their application is approved
              </Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', paddingBottom: '5px' }}>
              <Close style={{ fontSize: '17px' }} />
              <Typography className={classes.menuItemTextInstructions}>
                <strong style={{ paddingRight: '5px' }}>Reject</strong>
                Users will be rejected from this activity, but able to apply again in future
              </Typography>
            </Box>
          </Typography>
        </ListItemText>
      </ListItem>
    </List>
  );
};
