import { Theme } from '@material-ui/core';
import { CheckCircleRounded, RadioButtonUncheckedOutlined } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';

interface Props {
  isComplete: boolean;
}

const TaskCompleteIcon = ({ isComplete }: Props) => {
  const classes = useStyles();

  if (isComplete) {
    return <CheckCircleRounded className={classes.checked} />;
  }
  return <RadioButtonUncheckedOutlined className={classes.unChecked} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checked: {
      fontSize: 24,
      color: '#7dd268',
    },
    unChecked: {
      fontSize: 24,
      color: theme.palette.grey[200],
    },
  })
);

export { TaskCompleteIcon };
