import { FugFile } from '@campfire/file-upload-gallery';
import { encodeDate } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import {
  TaskFormAttachmentField,
  TaskFormFieldValueType,
  TaskFormValuesType,
} from '../../../common/form/task-form/model/task-form-init';
import { TaskFormField } from '../../../common/form/task-form/model/__generated__/TaskFormField';
import { TaskFormikMultipleChoiceType, TaskFormikQuizType } from '../../../common/inputs/task-fields/TaskFormikFields';
import { TaskItemsDeserialize } from '../../../screens/admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/form-builder/utils';
import { TaskItemField } from '../../../screens/admin/admin-console/admin-console-content-pages/admin-console-volunteer-profile/__generated__/TaskItemField';

type AddressTaskFieldValue = {
  __typename: 'VOLUNTEER_AddressTaskFieldValue';
  taskFieldId: string;
  addressOverride: boolean;
  address: {
    placesAddress?: null | {
      description: string;
      formatted: string;
      latitude: number;
      longitude: number;
      placesId?: string;
    };
    manualAddress?: null | {
      lineOne: string;
      lineTwo?: string;
      suburb: string;
      postcode: string;
      state: string;
      country: string;
    };
  };
};

type AttachmentTaskFieldValue = {
  __typename: 'VOLUNTEER_AttachmentTaskFieldValue';
  taskFieldId: string;
  addedAttachments: string[];
  attachmentCreationTokens: string[];
  attachmentNames: string[];
  removedAttachmentIds: string[];
};

type CheckboxTaskFieldValue = {
  __typename: 'VOLUNTEER_CheckboxTaskFieldValue';
  taskFieldId: string;
  checkboxTaskFieldOptionIds: Array<string>;
  otherText?: string; // Validator::nullable(Validator::stringType())
};

type DateTaskFieldValue = {
  __typename: 'VOLUNTEER_DateTaskFieldValue';
  taskFieldId: string;
  dateValue: string;
};

type DropdownTaskFieldValue = {
  __typename: 'VOLUNTEER_DropdownTaskFieldValue';
  taskFieldId: string;
  dropdownTaskFieldOptionId: string;
  otherText?: string; // Validator::nullable(Validator::stringType())
};

type EmailTaskFieldValue = {
  __typename: 'VOLUNTEER_EmailTaskFieldValue';
  taskFieldId: string;
  emailValue: string;
};

type LongTextTaskFieldValue = {
  __typename: 'VOLUNTEER_LongTextTaskFieldValue';
  taskFieldId: string;
  longTextValue: string;
};

type MultipleChoiceTaskFieldValue = {
  __typename: 'VOLUNTEER_MultipleChoiceTaskFieldValue';
  taskFieldId: string;
  multipleChoiceTaskFieldOptionId: string;
  otherText?: string; // Validator::stringType()
};

type QuizTaskFieldValue = {
  __typename: 'VOLUNTEER_QuizTaskFieldValue';
  taskFieldId: string;
  quizTaskFieldOptionId: string;
};

type NumberTaskFieldValue = {
  __typename: 'VOLUNTEER_NumberTaskFieldValue';
  taskFieldId: string;
  numericValue: number;
};

type PhoneTaskFieldValue = {
  __typename: 'VOLUNTEER_PhoneTaskFieldValue';
  taskFieldId: string;
  phoneValue: string;
};

type ShortTextTaskFieldValue = {
  __typename: 'VOLUNTEER_ShortTextTaskFieldValue';
  taskFieldId: string;
  shortTextValue: string;
};
type SignatureTaskFieldValue = {
  __typename: 'VOLUNTEER_SignatureTaskFieldValue';
  taskFieldId: string;
  signature: string;
};

export type TaskFieldValueType =
  | AddressTaskFieldValue
  | AttachmentTaskFieldValue
  | CheckboxTaskFieldValue
  | DateTaskFieldValue
  | DropdownTaskFieldValue
  | EmailTaskFieldValue
  | LongTextTaskFieldValue
  | MultipleChoiceTaskFieldValue
  | NumberTaskFieldValue
  | PhoneTaskFieldValue
  | ShortTextTaskFieldValue
  | SignatureTaskFieldValue
  | QuizTaskFieldValue;

async function getFieldValue(field: TaskFormField, value: TaskFormFieldValueType): Promise<TaskFieldValueType> {
  const fieldTypeName = field.__typename;
  if (fieldTypeName === 'VOLUNTEER_ShortTextTaskFieldType') {
    return {
      __typename: 'VOLUNTEER_ShortTextTaskFieldValue',
      taskFieldId: field.taskFieldId,
      shortTextValue: value as string,
    };
  }

  if (fieldTypeName === 'VOLUNTEER_LongTextTaskFieldType') {
    return {
      __typename: 'VOLUNTEER_LongTextTaskFieldValue',
      taskFieldId: field.taskFieldId,
      longTextValue: value as string,
    };
  }

  if (fieldTypeName === 'VOLUNTEER_NumberTaskFieldType') {
    return {
      __typename: 'VOLUNTEER_NumberTaskFieldValue',
      taskFieldId: field.taskFieldId,
      numericValue: value as number,
    };
  }

  if (fieldTypeName === 'VOLUNTEER_MultipleChoiceTaskFieldType') {
    const multipleChoiceValue = value as TaskFormikMultipleChoiceType;
    return {
      __typename: 'VOLUNTEER_MultipleChoiceTaskFieldValue',
      taskFieldId: field.taskFieldId,
      multipleChoiceTaskFieldOptionId: multipleChoiceValue.multipleChoiceOptionId,
      otherText: multipleChoiceValue.otherOptionSelected ? multipleChoiceValue.otherText : undefined,
    };
  }

  if (fieldTypeName === 'VOLUNTEER_QuizTaskFieldType') {
    const quizValue = value as TaskFormikQuizType;
    return {
      __typename: 'VOLUNTEER_QuizTaskFieldValue',
      taskFieldId: field.taskFieldId,
      quizTaskFieldOptionId: quizValue.quizTaskFieldOptionId,
    };
  }

  if (fieldTypeName === 'VOLUNTEER_CheckboxTaskFieldType') {
    return {
      __typename: 'VOLUNTEER_CheckboxTaskFieldValue',
      taskFieldId: field.taskFieldId,
      checkboxTaskFieldOptionIds: value as string[],
    };
  }

  if (fieldTypeName === 'VOLUNTEER_DropdownTaskFieldType') {
    return {
      __typename: 'VOLUNTEER_DropdownTaskFieldValue',
      taskFieldId: field.taskFieldId,
      dropdownTaskFieldOptionId: value as string,
    };
  }

  if (fieldTypeName === 'VOLUNTEER_AttachmentTaskFieldType') {
    return getAttachmentValueAsync(field, value);
  }

  if (fieldTypeName === 'VOLUNTEER_DateTaskFieldType') {
    return {
      __typename: 'VOLUNTEER_DateTaskFieldValue',
      taskFieldId: field.taskFieldId,
      dateValue: encodeDate(value as DateTime),
    };
  }

  if (fieldTypeName === 'VOLUNTEER_AddressTaskFieldType') {
    const addressValue = (value as unknown) as AddressTaskFieldValue;
    const address = addressValue.addressOverride
      ? { manualAddress: addressValue.address.manualAddress }
      : { placesAddress: addressValue.address.placesAddress };

    return {
      __typename: 'VOLUNTEER_AddressTaskFieldValue',
      taskFieldId: field.taskFieldId,
      addressOverride: addressValue.addressOverride,
      address: address,
    };
  }

  if (fieldTypeName === 'VOLUNTEER_PhoneTaskFieldType') {
    return { __typename: 'VOLUNTEER_PhoneTaskFieldValue', taskFieldId: field.taskFieldId, phoneValue: value as string };
  }

  if (fieldTypeName === 'VOLUNTEER_EmailTaskFieldType') {
    return { __typename: 'VOLUNTEER_EmailTaskFieldValue', taskFieldId: field.taskFieldId, emailValue: value as string };
  }

  if (fieldTypeName === 'VOLUNTEER_SignatureTaskFieldType') {
    return { __typename: 'VOLUNTEER_SignatureTaskFieldValue', taskFieldId: field.taskFieldId, signature: value as string };
  }

  return { __typename: 'VOLUNTEER_ShortTextTaskFieldValue', taskFieldId: field.taskFieldId, shortTextValue: '' };
}

async function getAttachmentValueAsync(
  field: TaskFormField,
  value: TaskFormFieldValueType
): Promise<AttachmentTaskFieldValue> {
  const fieldTypeName = field.__typename;

  if (fieldTypeName === 'VOLUNTEER_AttachmentTaskFieldType') {
    const attachmentField = value as TaskFormAttachmentField;

    const addedAttachments = await Promise.all(
      attachmentField.addedAttachments.map((fugFile) => getBase64FromAttachment(fugFile))
    );
    const attachmentCreationTokens = addedAttachments.map(() => uuidv4());
    const attachmentNames = attachmentField.addedAttachments.map((fugFile) => fugFile.name);
    const { removedAttachmentIds, attachments } = attachmentField;

    return Promise.resolve({
      __typename: 'VOLUNTEER_AttachmentTaskFieldValue',
      taskFieldId: field.taskFieldId,
      addedAttachments,
      attachmentCreationTokens,
      attachmentNames,
      removedAttachmentIds,
      attachments: attachments.map((attachment) => attachment.fileId),
    });
  }

  throw new Error('nah');
}

async function getBase64FromAttachment(file: FugFile): Promise<string> {
  const base64File = await toBase64(file);
  if (base64File) {
    return base64File.toString().substr(base64File.toString().indexOf(',') + 1);
  }
  return '';
}

function toBase64(file: FugFile): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function isDummyString(value: TaskFormFieldValueType) {
  return !value || value === '';
}

function isRequiredValue(taskItemField: TaskItemField, value: TaskFormFieldValueType): boolean {
  const fieldType = taskItemField.field.__typename;
  const isFieldOptional = taskItemField.optional;
  const isBasicFieldType =
    fieldType === 'VOLUNTEER_ShortTextTaskFieldType' ||
    fieldType === 'VOLUNTEER_LongTextTaskFieldType' ||
    fieldType === 'VOLUNTEER_NumberTaskFieldType' ||
    fieldType === 'VOLUNTEER_DropdownTaskFieldType' ||
    fieldType === 'VOLUNTEER_EmailTaskFieldType' ||
    fieldType === 'VOLUNTEER_PhoneTaskFieldType';

  if (isFieldOptional && isBasicFieldType && isDummyString(value)) return false;

  if (fieldType === 'VOLUNTEER_DateTaskFieldType') {
    return !isFieldOptional || value !== null;
  }

  if (fieldType === 'VOLUNTEER_MultipleChoiceTaskFieldType') {
    const multipleChoiceValue = value as TaskFormikMultipleChoiceType;
    return !isFieldOptional || !isDummyString(multipleChoiceValue.multipleChoiceOptionId);
  }
  if (fieldType === 'VOLUNTEER_QuizTaskFieldType') {
    const quizValue = value as TaskFormikQuizType;
    return !isFieldOptional || !isDummyString(quizValue.quizTaskFieldOptionId);
  }

  if (fieldType === 'VOLUNTEER_AddressTaskFieldType') {
    const addressValue = (value as unknown) as AddressTaskFieldValue;
    const address = addressValue.addressOverride
      ? { manualAddress: addressValue.address.manualAddress }
      : { placesAddress: addressValue.address.placesAddress };

    return !isFieldOptional || !!address.placesAddress?.placesId || !!address.manualAddress?.lineOne;
  }

  return true;
}

export async function getTaskFieldValues(
  taskItems: TaskItemsDeserialize,
  values: TaskFormValuesType
): Promise<Array<TaskFieldValueType>> {
  const relevantTaskItemValuePairs: Array<{ taskItemField: TaskItemField; value: TaskFormFieldValueType }> = [];
  taskItems.allIds.forEach((itemId) => {
    const taskItem = taskItems.byId[itemId];
    if (
      !(
        taskItem.__typename === 'VOLUNTEER_TaskItemFieldType' &&
        isRequiredValue(taskItem, values[taskItem.field.taskFieldId])
      )
    ) {
      return;
    }
    const optionId = taskItems.byTriggerOption[taskItem.taskItemId];
    if (!optionId) {
      relevantTaskItemValuePairs.push({
        taskItemField: taskItem,
        value: values[taskItem.field.taskFieldId],
      });
      return;
    }
    if (optionId && !taskItems.byTrigger[optionId]) {
      return;
    }
    const { optionType, taskFieldId } = taskItems.byTrigger[optionId];
    if (optionType === 'checkbox' && (values[taskFieldId] as string[]).includes(optionId)) {
      relevantTaskItemValuePairs.push({
        taskItemField: taskItem,
        value: values[taskItem.field.taskFieldId],
      });
    }
    if (optionType === 'dropdown' && values[taskFieldId] === optionId) {
      relevantTaskItemValuePairs.push({
        taskItemField: taskItem,
        value: values[taskItem.field.taskFieldId],
      });
    }
    if (
      optionType === 'multipleChoice' &&
      (values[taskFieldId] as TaskFormikMultipleChoiceType).multipleChoiceOptionId === optionId
    ) {
      relevantTaskItemValuePairs.push({
        taskItemField: taskItem,
        value: values[taskItem.field.taskFieldId],
      });
    }
    if (optionType === 'quiz' && (values[taskFieldId] as TaskFormikQuizType).quizTaskFieldOptionId === optionId) {
      relevantTaskItemValuePairs.push({
        taskItemField: taskItem,
        value: values[taskItem.field.taskFieldId],
      });
    }
  });

  const taskFieldValues = Promise.all(
    relevantTaskItemValuePairs.map(async (taskItemValuePair) => {
      const fieldValue = await getFieldValue(taskItemValuePair.taskItemField.field, taskItemValuePair.value);
      return fieldValue;
    })
  );

  return taskFieldValues;
}
