import React from 'react';
import { Box, ListItem, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Explore, LocationOnRounded } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { unpackToTime } from '@campfire/hot-date';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { MyElementsMyActivityType } from './my-elements-my-activity-types';
import { MyActivityRosterings } from '../../__generated__/MyActivityRosterings';
import { DateInfoCard } from '../../../../../../common/cards/date-info-card/DateInfoCard';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerWrapper: {
      display: (props: { isMobile: boolean; isMd: boolean }) => (props.isMobile || props.isMd ? 'auto' : 'flex'),
      alignItems: 'center',
      marginTop: '40px',
    },
    footerItem: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '24px',
      paddingTop: (props: { isMobile: boolean; isMd: boolean }) => (props.isMobile || props.isMd ? '8px' : '0px'),
    },
    footerItemIcon: {
      color: theme.color.grey.neutral200,
      fontSize: 14,
      marginRight: '6px',
    },
    footerItemLargeNumber: {
      fontWeight: 600,
      color: theme.color.grey.neutral200,
      fontSize: 14,
      marginRight: '6px',
    },
    footerItemText: {
      color: theme.color.grey.neutral200,
      marginTop: '1px',
    },
  })
);

interface Props {
  myActivity: MyElementsMyActivityType;
  onClick: () => void;
  color: {
    primary: string;
    secondary: string;
  };
  actionMenu: React.ReactNode;
  rostering?: MyActivityRosterings;
  isSelected: boolean;
}

export const MyElementsMyActivityListItem = (props: Props) => {
  const {
    myActivity: { activityDate, activityName, startTime, endTime, activityLocation, numSessions, programName },
    onClick,
    color,
    actionMenu,
    rostering,
    isSelected,
  } = props;

  const { isMobile, isMd, theme } = useCampfireTheme();
  const classes = useStyles({ isMobile, isMd });
  const formatTime = (time: string) => unpackToTime(time).toFormat('h:mm a');

  return (
    <ListItem
      onClick={onClick}
      style={{
        cursor: 'pointer',
        paddingLeft: '0',
        paddingRight: '0',
      }}
    >
      <DateInfoCard date={activityDate} color={color} selected={isSelected} size='l'>
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' justifyContent='space-between' alignSelf='stretch'>
            <TitularTooltip title={activityName}>
              <Typography
                variant={'h6'}
                noWrap={!isMobile}
                style={
                  isMobile
                    ? {
                        color: theme.color.grey.neutral400,
                        fontWeight: 800,
                        width: '100%',
                        letterSpacing: '-0.02em',
                        overflow: 'hidden',
                        maxHeight: '3.5em',
                        textOverflow: 'ellipsis',
                      }
                    : { color: theme.color.grey.neutral400, fontWeight: 800, width: '100%', letterSpacing: '-0.02em' }
                }
              >
                {activityName}
              </Typography>
            </TitularTooltip>
            {actionMenu}
          </Box>
          {rostering ? (
            <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200 }}>
              Attending:{' '}
              {rostering.sessionRosterings.map((sessionRostering) => sessionRostering.session.name).join(', ')}
            </Typography>
          ) : null}
          <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200 }}>
            {startTime && endTime ? `${formatTime(startTime)} - ${formatTime(endTime)}` : ''}
          </Typography>
          <Box className={classes.footerWrapper}>
            <Box className={classes.footerItem}>
              <LocationOnRounded className={classes.footerItemIcon} />
              <Typography variant='caption' className={classes.footerItemText}>
                {activityLocation}
              </Typography>
            </Box>
            {/* <Box className={classes.footerItem}>
              <AccessTime className={classes.footerItemIcon} />
              <Typography variant='caption' className={classes.footerItemText}>
                {hasOpenRoster ? 'Open Roster' : 'Managed Roster'}
              </Typography>
            </Box> */}
            <Box className={classes.footerItem}>
              <Typography variant='h2' className={classes.footerItemLargeNumber}>
                {numSessions}
              </Typography>
              <Typography variant='caption' className={classes.footerItemText}>
                {numSessions > 1 ? 'sessions available' : 'session available'}
              </Typography>
            </Box>
            <Box className={classes.footerItem}>
              <Box display='flex' alignItems='center' pt={1}>
                <Explore style={{ color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }} />
                <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200, marginTop: '1px' }}>
                  {programName}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </DateInfoCard>
    </ListItem>
  );
};
