import { Box, Grid, Typography } from '@material-ui/core';
import { EventAvailable } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { DateTime } from 'luxon';
import { useVolunteerCommonProfileContext } from '../../VolunteerCommonProfileContext';
import {
  AvailabilityCenterDialog,
  EnrolmentDataType,
  ValueProps,
} from '../../../../../common/availability-center/AvailabilityCenterDialog';
import {
  ProgramActivities,
  ProgramActivitiesVariables,
} from '../../../../../common/models/__generated__/ProgramActivities';
import { GET_ACTIVITYIDS } from '../../../../../common/models/program-activities.gql';
import {
  GetVolunteerEnrolmentsData,
  GetVolunteerEnrolmentsDataVariables,
} from '../../../../../common/models/volunteer-enrolments/__generated__/GetVolunteerEnrolmentsData';
import { GET_VOLUNTEER_ENROLMENTS_DATA } from '../../../../../common/models/volunteer-enrolments/volunteer-enrolments.gql';
import {
  AvailabilityCenterGetActivities,
  AvailabilityCenterGetActivitiesVariables,
} from '../../../../../global/components/AvailabilityCenter/__generated__/AvailabilityCenterGetActivities';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useCampfireLazyQuery } from '../../../../../global/network/useCampfireLazyQuery';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { VolunteerType } from '../../../../../common/models/fragments/__generated__/VolunteerType';
import { AlertFullSessionDialog } from '../../../../../common/alert-full-session-dialog/AlertFullSessionDialog';
import { GET_ACTIVITIES } from '../../../../../global/components/AvailabilityCenter/get-activities.gql';
import { SimpleAvailabilityComponent } from '../../../../../common/simple-availability-select/SimpleAvailabilitySelect';

interface Props {
  volunteer: VolunteerType;
}

export const VolunteerAvailabilitySection = (props: Props) => {
  const {
    volunteerNameOptions: { preferredName },
  } = useVolunteerCommonProfileContext();
  const { volunteer } = props;

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [value, setValue] = React.useState<ValueProps>({});
  const [fullSessionIds, setAllSessionIds] = React.useState<string[]>([]);
  const [activityDatesSubmited, setActivityDatesSubmited] = React.useState<string[]>([]);
  const [activityIdSubmited, setActivityIdSubmited] = React.useState<string>('');
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);

  const [getEnrolmentData, { data: vmVolunteerData, refetch: refetchVolunteerData }] = useCampfireLazyQuery<
    GetVolunteerEnrolmentsData,
    GetVolunteerEnrolmentsDataVariables
  >(GET_VOLUNTEER_ENROLMENTS_DATA, {
    options: {
      variables: {
        volunteerId: volunteer.volunteerId,
      },
    },
  });

  const { data: vmPrograms } = useCampfireQuery<ProgramActivities, ProgramActivitiesVariables>(GET_ACTIVITYIDS, {
    options: {
      variables: {
        volunteerId: volunteer.volunteerId,
      },
    },
  });

  const activityIds =
    vmPrograms?.vm.volunteer?.programs.flatMap((program) =>
      program.activities.map((activity) => activity.activityId)
    ) || [];

  const [getActivities, { data: vmActivities }] = useCampfireLazyQuery<
    AvailabilityCenterGetActivities,
    AvailabilityCenterGetActivitiesVariables
  >(GET_ACTIVITIES, {
    options: {
      variables: {
        activityIds,
        from: DateTime.local().toISODate(),
        until: DateTime.local()
          .plus({ years: 1 })
          .toISODate(),
      },
    },
  });

  useEffect(() => {
    if (dialogOpen) {
      getEnrolmentData({
        variables: {
          volunteerId: volunteer.volunteerId,
        },
      });
      getActivities({
        variables: {
          activityIds,
          from: DateTime.local().toISODate(),
          until: DateTime.local()
            .plus({ years: 1 })
            .toISODate(),
        },
      });
    }
  }, [dialogOpen, volunteer]);

  const onChange = (newValue: ValueProps) => {
    setValue(newValue);
  };

  const campfireMutation = useCampfireFetch({ defer: true });
  const apiUrl = useApiUrl();
  const { setSnackbar } = useSnackbar();

  const handleSubmit = (selectedValue: ValueProps) => {
    const { activityId, sessionIds, dates } = selectedValue;
    if (!activityId || !dates?.length) {
      return;
    }
    const enrolment = vmVolunteerData?.vm.volunteer?.activityEnrolments.find(
      (activityEnrolment) => activityEnrolment.activity.activityId === activityId
    );

    // if (enrolment?.activity.hasOpenRoster) {

    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity/enrolment/availability/save`,
        method: 'post',
        data: {
          activityEnrolmentId: enrolment?.activityEnrolmentId,
          activityDates: dates,
          activityRoleIds: [],
          sessionIds: sessionIds ?? [],
          activityId: activityId,
        },
      })
      .then((response) => {
        if (enrolment?.activity.hasOpenRoster) {
          campfireMutation.run({
            url: `${apiUrl}/vm/activity/enrolment/attendance/add`,
            method: 'post',
            data: {
              activityEnrolmentId: enrolment?.activityEnrolmentId,
              activityDates: dates,
              activityRoleIds: [],
              sessionIds: sessionIds ?? [],
              activityId: activityId,
              activityAvailabilityId: null,
              volunteerId: volunteer.volunteerId,
            },
          });
        }
        const data = response.data.data as {
          activityAvailabilityId: string[];
          activityId: string;
          fullSessionIds: string[];
          activityDates: string[];
        };

        if (data && data.fullSessionIds?.length > 0 && data.activityId) {
          setAllSessionIds(data.fullSessionIds);
          setActivityIdSubmited(data.activityId);
          setActivityDatesSubmited(data.activityDates);
          setShowAlertDialog(true);
        }

        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Update availability successfully',
        });
        if (refetchVolunteerData) {
          setTimeout(refetchVolunteerData, 0);
        }
      });
  };

  React.useEffect(() => {
    setValue({});
  }, [dialogOpen]);

  const enrolmentDataByActivityId =
    vmVolunteerData?.vm.volunteer?.activityEnrolments.reduce(
      (acc, activityEnrolment) => ({
        ...acc,
        [activityEnrolment.activity.activityId]: {
          availabilities: activityEnrolment.availabilities
            .filter((availability) => !availability.dateRemoved)
            .map((availability) => availability.activityDate),
          unavailabilities: activityEnrolment.unavailabilities
            .filter((unavailability) => unavailability.dateRemoved)
            .map((unavailability) => unavailability.activityDate),
        },
      }),
      {} as EnrolmentDataType
    ) || {};

  const enrolmentActivities = vmActivities?.vm.activities.filter(
    (activity) => enrolmentDataByActivityId[activity.activityId as string]
  );

  const handleAlertClose = () => {
    setShowAlertDialog(false);
    setAllSessionIds([]);
    setActivityDatesSubmited([]);
    setActivityIdSubmited('');
  };

  return (
    <Grid container spacing={2} direction='column' style={{ position: 'relative' }}>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            <Typography
              style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center', color: '#333' }}
            >
              <EventAvailable style={{ fontSize: '14px', paddingRight: '5px' }} /> Availability
            </Typography>
            <Typography variant='body2' style={{ color: '#464646' }} align='left'>
              {preferredName}&apos;s activity availability
            </Typography>
          </Box>
          <TabletButton
            size='small'
            variant='contained'
            color='primary'
            aria-controls='options-menu'
            aria-haspopup='true'
            onClick={() => setDialogOpen(true)}
            startIcon={<EventAvailable />}
            style={{
              height: '100%',
              border: '1px solid transparent',
              boxSizing: 'border-box',
              borderRadius: '4px',
              padding: '6px 12px',
              marginRight: '10px',
              color: '#fff',
            }}
          >
            Availability Centre
          </TabletButton>
        </Box>
        <hr style={{ borderTop: '.5px solid #e7e7e7', margin: '20px 0' }} />
        {volunteer.profile.profileId && (
          <SimpleAvailabilityComponent profileId={volunteer.profile.profileId} editing={false} />
        )}
      </Grid>
      {dialogOpen && (
        <AvailabilityCenterDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          value={value}
          onChange={onChange}
          activities={enrolmentActivities || []}
          onSubmit={handleSubmit}
          enrolmentData={enrolmentDataByActivityId}
          managerView
        />
      )}
      {showAlertDialog && (
        <AlertFullSessionDialog
          open={showAlertDialog}
          onClose={handleAlertClose}
          fullSessionIds={fullSessionIds}
          activityIdSubmited={activityIdSubmited}
          activityDatesSubmited={activityDatesSubmited}
        />
      )}
    </Grid>
  );
};
