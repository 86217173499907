import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Settings } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { TabletButton } from '@campfire/tablet-button';
import { StringParam, useQueryParam } from 'use-query-params';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { AccountSettingsDialog } from '../../account-settings/AccountSettingsDialog';
import { Avatar } from '../../components/Avatar';

type VolunteerProfileBlockProps = {
  preferredName: string | undefined;
  lastName: string | undefined;
  position: string;
  initialLoading: boolean;
  avatarUrl: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px 16px',
    [theme.breakpoints.up('xl')]: {
      padding: '24px 128px',
    },
  },
}));

const MyElementsVolunteerProfileBlock = (props: VolunteerProfileBlockProps) => {
  const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);
  const { preferredName, lastName, position, initialLoading, avatarUrl } = props;
  const [selectedProfileTab] = useQueryParam('action', StringParam);
  const { theme } = useCampfireTheme();
  const {
    spacing,
    palette: { grey },
  } = theme;

  const styles = useStyles();

  useEffect(() => {
    setOpenEditProfileDialog(selectedProfileTab !== undefined);
  }, [selectedProfileTab]);

  return (
    <Box
      display='flex'
      flex='1'
      alignContent='center'
      alignItems='center'
      bgcolor={grey['50']}
      className={styles.container}
    >
      {!initialLoading && preferredName && lastName ? (
        <>
          <Avatar
            size={96}
            avatarUrl={avatarUrl}
            preferredName={preferredName}
            lastName={lastName}
            style={{ marginLeft: spacing(2), marginRight: spacing(2) }}
          />
          <Box display='flex' flexDirection='column' marginLeft={2}>
            <Typography variant='subtitle1'>{`${preferredName} ${lastName}`}</Typography>
            <TabletButton
              size='small'
              variant='contained'
              color='default'
              aria-controls='options-menu'
              aria-haspopup='true'
              onClick={() => setOpenEditProfileDialog(true)}
              startIcon={<Settings />}
              style={{
                height: '100%',
                border: '1px solid transparent',
                boxSizing: 'border-box',
                borderRadius: '4px',
                padding: '5px 10px',
                marginRight: '10px',
                color: '#545454',
                borderColor: '#888',
                marginLeft: 'auto',
              }}
            >
              Account Settings
            </TabletButton>
            <Typography variant='body2'>{position}</Typography>
          </Box>
          <AccountSettingsDialog open={openEditProfileDialog} setOpen={setOpenEditProfileDialog} />
        </>
      ) : (
        <Grid container alignContent='center' alignItems='center' justify='center' direction='row' spacing={3}>
          <Grid item>
            <Skeleton variant='circle' width={56} height={56} />
          </Grid>
          <Grid item>
            <Skeleton variant='text' width={200} height={16} />
            <Skeleton variant='text' width={150} height={10} />
            <Skeleton variant='text' width={100} height={9} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export { MyElementsVolunteerProfileBlock };
