import { Field } from '@campfire/field';
import { HoverLink } from '@campfire/hover-link';
import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Collapse, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import AuthCode from 'react-auth-code-input';
import { Form as FormikForm, Formik, useFormikContext } from 'formik';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { object, string } from 'yup';
import { PasswordField } from '../../../common/password-field/PasswordField';
import { TemplateSnackbarContent } from '../../../common/snackbars/TemplateSnackbar';
import { AnalyticsService } from '../../../global/analytics/AnalyticsService';
import { useSession } from '../../../global/auth/useSession';
import { useCampfireQuery } from '../../../global/network/useCampfireQuery';
import { useVerifyEmailFetch } from '../../../global/static-shell/static-shell-actions';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { GET_ORG_NAME } from './sign-in-model.gql';
import { GetOrgName } from './__generated__/GetOrgName';

interface SignInForm {
  email: string;
  password: string;
  totp?: string;
}

const SignInFormSchema = object().shape({
  email: string()
    .required('Please enter your email')
    .email('Please enter a valid email'),
  password: string()
    .min(6, 'Please enter a password of at least 6 characters')
    .max(64, 'Please enter a password shorter than 64 characters')
    .required('Please enter your password'),
});

const initialValues: SignInForm = { email: '', password: '', totp: undefined };

const getEmailFromSearch = (encodedEmail?: string): string | undefined => {
  return encodedEmail ? atob(encodedEmail) : undefined;
};

const Form = () => {
  const [encodedEmail] = useQueryParam('email', StringParam);
  const [hash] = useQueryParam('h', StringParam);
  const { setFieldValue } = useFormikContext();
  const passwordFieldEl = useRef<HTMLInputElement>(null);
  const { isLoading, error, totpState } = useSession();
  const [email, setEmail] = useState<string | undefined>();
  const focusPasswordField = () => {
    if (passwordFieldEl === null || passwordFieldEl.current === null) return;
    passwordFieldEl.current.focus();
    passwordFieldEl.current.select();
  };

  const verifyEmailFetch = useVerifyEmailFetch();
  const runVerifyEmail = (verifyEmail: string, verifyHash: string) => {
    verifyEmailFetch
      .run({ hash: verifyHash, email: verifyEmail })
      .then((response) => {
        if (!response.ok) {
          console.info('Email verified');
          return;
        }

        console.error('Unable to verify email');
      })
      .catch(() => {
        console.error('Unable to verify email');
      });
  };

  useEffect(() => {
    setEmail(getEmailFromSearch(encodedEmail));
  }, []);

  useEffect(() => {
    if (!email || !hash) return;
    runVerifyEmail(email, hash);
  }, [email, hash]);

  useEffect(() => {
    if (email) {
      setFieldValue('email', email);
      focusPasswordField();
    }
  }, [email]);

  useEffect(() => {
    if (error) {
      focusPasswordField();
    }
  }, [error]);

  return (
    <FormikForm>
      <Grid container justify='center' spacing={3}>
        {!totpState && (
          <>
            <Grid item xs={12}>
              <Field
                variant='outlined'
                disabled={isLoading}
                type='email'
                required
                fullWidth
                name='email'
                label='Email Address'
                placeholder='example@example.com'
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordField
                required
                fullWidth
                variant='outlined'
                disabled={isLoading}
                name='password'
                placeholder='Your Password'
                inputRef={passwordFieldEl}
              />
            </Grid>
          </>
        )}
        {totpState && (
          <>
            <Typography style={{ fontSize: '14px', color: '#0000008a', marginTop: '15px' }}>
              {`Enter the six-digit code found in your authenticator app`}
            </Typography>
            <Box style={{ marginTop: '45px', marginBottom: '35px' }}>
              <AuthCode
                onChange={(e) => {
                  setFieldValue('totp', e);
                }}
                inputClassName='otp-input'
              />
            </Box>
          </>
        )}

        <Grid item xs={12}>
          <Box>
            <Collapse in={!!error}>
              <TemplateSnackbarContent style={{ boxShadow: 'none' }} variant='error' message={error} />
            </Collapse>
          </Box>
        </Grid>
      </Grid>

      <Grid container alignItems='center' direction='row' justify='space-between' style={{ paddingTop: 12 }}>
        {!totpState && (
          <Grid item xs>
            <Typography color='textSecondary' variant='subtitle2'>
              {`Don't have an account? `}
              <HoverLink color='primary' to='/apply'>
                {'Sign up'}
              </HoverLink>
            </Typography>

            <Typography color='textSecondary' variant='subtitle2'>
              <HoverLink
                color='primary'
                onClick={isLoading ? (e: SyntheticEvent) => e.preventDefault : undefined}
                to='forgot-password'
              >
                {'Forgot password?'}
              </HoverLink>
            </Typography>
          </Grid>
        )}

        <Grid container item justify='flex-end'>
          <TabletButton
            size='large'
            variant='contained'
            color='primary'
            type='submit'
            onClick={() => {
              AnalyticsService.trackEvent({ action: 'click', label: 'Sign in submit' });
            }}
            style={{
              paddingLeft: 48,
              paddingRight: 48,
            }}
          >
            {`Sign In`}
          </TabletButton>
        </Grid>
      </Grid>
    </FormikForm>
  );
};

export const SignInScreen = () => {
  const { login, isLoading, totpState } = useSession();
  const { data, loading } = useCampfireQuery<GetOrgName, null>(GET_ORG_NAME);

  const { isMobile } = useCampfireTheme();

  return (
    <>
      <LinearProgressOverlay isLoading={isLoading} />
      <Box marginTop={isMobile ? 3 : 7} marginBottom={8} marginLeft={2} marginRight={2}>
        <Grid justify='center' container style={{ flexGrow: 1 }} spacing={1}>
          {loading ? (
            <>
              <Grid item xs={12} sm={10}>
                <Skeleton variant='text' height={20} width={200} />
              </Grid>

              <Grid item xs={12} sm={10}>
                <Skeleton variant='text' height={40} width={300} />
              </Grid>

              <Grid item xs={12} sm={10}>
                <Skeleton variant='text' height={40} width={300} />
              </Grid>

              <Grid item xs={12} sm={10}>
                <Skeleton variant='text' height={15} width={170} />
                <Skeleton variant='text' height={15} width={130} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={10} style={{ display: 'flex', justifyContent: 'center' }}>
                {data && data.orgLogo !== null ? (
                  <img
                    src={data.orgLogo}
                    alt={data?.orgName}
                    style={{
                      height: 65,
                      width: 'auto',
                      display: 'block',
                      alignSelf: 'center',
                      paddingBottom: '15px',
                    }}
                  />
                ) : null}
              </Grid>
              {!totpState && (
                <Grid item xs={12} sm={10} style={{ marginBottom: 15 }}>
                  <Typography variant='h5' style={{ fontWeight: 'bold' }} color='textPrimary'>
                    {`Sign in to ${data?.orgName}`}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={10}>
                <Formik
                  initialValues={initialValues}
                  onSubmit={(values: SignInForm) => login(values.email, values.password, values.totp)}
                  validationSchema={SignInFormSchema}
                >
                  {() => <Form />}
                </Formik>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};
