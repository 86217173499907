import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, IconButton, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Delete as DeleteIcon, HowToRegRounded, KeyboardArrowLeft, Email as EmailIcon, Redo } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { StatusChip } from '../../../../common/chips/StatusChip';
import { ConfirmationDialog } from '../../../../common/dialogs/ConfirmationDialog';
import { SnackbarContextProps } from '../../../../global/config/SnackbarContext';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useEndpointFetch } from '../../../../global/network/useEndpointFetch';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { FallbackAvatar } from '../../../general/user-profile/components/Avatar';
import { BorderLinearProgress } from '../incoming-volunteers/IncomingVolunteersList';
import { IncomingVolunteersApplicationTaskSection } from './components/incoming-volunteer-task-list-sections/IncomingVolunteersApplicationTaskSection';
import { IncomingVolunteersInviteTaskSection } from './components/incoming-volunteer-task-list-sections/IncomingVolunteersInviteTaskSection';
import { IncomingVolunteerTasksSection } from './components/incoming-volunteer-task-list-sections/IncomingVolunteerTasksSection';
import { IncomingVolunteerConfirmationDialog } from './components/IncomingVolunteerConfirmationDialog';
import { TaskSideMenuBar } from './components/side-menu-content/TaskSideMenuBar';
import { IncomingVolunteersLeftColumnWrapper } from './components/wrappers/IncomingVolunteersLeftColumnWrapper';
import { IncomingVolunteersPageWrapper } from './components/wrappers/IncomingVolunteersPageWrapper';
import { IncomingVolunteersRightColumnWrapper } from './components/wrappers/IncomingVolunteersRightColumnWrapper';
import { useIncomingVolunteerActions } from './incoming-volunteer-action.gql';
import { GET_INCOMING_VOLUNTEER_PROFILE, LeftMenuItemType } from './incoming-volunteer-model.gql';
import {
  GetIncomingVolunteerProfile,
  GetIncomingVolunteerProfileVariables,
} from './__generated__/GetIncomingVolunteerProfile';
import { useVolunteersContext } from '../VolunteersContext';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { CommentsList } from '../common/CommentsList';
import { BypassDialog } from './BypassDialog';
import {
  GET_APPLICATION_APPLY_ROLES,
  GET_APPLICATION_AVAILABILITY_COMPLEX,
  GET_APPLICATION_AVAILABILITY_SIMPLE,
  GET_APPLICATION_ENROL_ACTIVITIES,
} from '../../../admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/admin-console-applicant-screening.gql';
import { GetApplicationAvailabilitySimple } from '../../../admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/__generated__/GetApplicationAvailabilitySimple';
import { GetApplicationAvailabilityComplex } from '../../../admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/__generated__/GetApplicationAvailabilityComplex';
import { GetApplicationEnrolActivities } from '../../../admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/__generated__/GetApplicationEnrolActivities';
import { GetApplicationApplyRoles } from '../../../admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/__generated__/GetApplicationApplyRoles';
import { IncomingVolunteerRolesSection } from './components/incoming-volunteer-task-list-sections/IncomingVolunteerRolesSection';
import { IncomingVolunteerActivitiesSection } from './components/incoming-volunteer-task-list-sections/IncomingVolunteerActivitiesSection';

const useStyles = () => {
  const { theme, isMobile } = useCampfireTheme();
  return makeStyles({
    listItem: {
      padding: isMobile ? '16px 8px' : '16px 48px',
      borderBottom: `1px solid ${theme.color.grey.border}`,
    },
    iconContainer: {
      marginRight: 8,
    },
    taskMenuBarWrapper: {
      borderLeft: `1px solid ${theme.color.grey.border}`,
      borderTop: `1px solid ${theme.color.grey.border}`,
      boxShadow: '0px 5px 8px rgba(114, 114, 114, 0.16), 0px 7px 14px rgba(214, 214, 214, 0.26)',
    },
  })();
};

const IncomingVolunteerViewProfileScreen = ({
  profileId,
  goBack,
  reloadList,
  setSnackbar,
}: {
  profileId: string;
  goBack: () => void;
  reloadList?: () => void;
  setSnackbar: (props: SnackbarContextProps | undefined) => void;
}) => {
  const [leftMenuItem, setLeftMenuItem] = useState<LeftMenuItemType>();
  const [openApproveProfileDialog, setOpenApproveProfileDialog] = useState(false);
  const [selectedVolunteerProfileId] = useQueryParam('volunteerProfileId', StringParam);
  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const { runRemoveApplicant, runRemoveApplicantLoading } = useIncomingVolunteerActions();
  const completeVolunteerProfile = useEndpointFetch<{ profileId: string; bypass?: boolean }>(
    '/vm/volunteer/profile/complete'
  );
  const { theme, styles, isMobile, isSm } = useCampfireTheme();

  const { data, loading, refetch } = useCampfireQuery<
    GetIncomingVolunteerProfile,
    GetIncomingVolunteerProfileVariables
  >(GET_INCOMING_VOLUNTEER_PROFILE, {
    options: {
      variables: {
        profileId,
        from: DateTime.local().toISODate(),
        until: DateTime.local()
          .plus({ years: 1 })
          .toISODate(),
      },
    },
  });

  const [applicationAvailabilitySimple, setApplicationAvailabilitySimple] = React.useState(false);
  const [applicationAvailabilityComplex, setApplicationAvailabilityComplex] = React.useState(false);
  const [applicationActivityEnrolment, setApplicationActivityEnrolment] = React.useState(false);
  const [applicationApplyRoles, setApplicationApplyRoles] = React.useState(false);

  const { data: availabilitySimple } = useCampfireQuery<GetApplicationAvailabilitySimple, undefined>(
    GET_APPLICATION_AVAILABILITY_SIMPLE
  );

  const { data: availabilityComplex } = useCampfireQuery<GetApplicationAvailabilityComplex, undefined>(
    GET_APPLICATION_AVAILABILITY_COMPLEX
  );

  const { data: applicationActivity } = useCampfireQuery<GetApplicationEnrolActivities, undefined>(
    GET_APPLICATION_ENROL_ACTIVITIES
  );

  const { data: applyRoles } = useCampfireQuery<GetApplicationApplyRoles, undefined>(GET_APPLICATION_APPLY_ROLES);

  useEffect(() => {
    setApplicationAvailabilityComplex(availabilityComplex?.vm.configItem?.configItemValue?.flagValue ?? false);
    setApplicationAvailabilitySimple(availabilitySimple?.vm.configItem?.configItemValue?.flagValue ?? false);
    setApplicationActivityEnrolment(applicationActivity?.vm.configItem?.configItemValue?.flagValue ?? false);
    setApplicationApplyRoles(applyRoles?.vm.configItem?.configItemValue?.flagValue ?? false);
  }, [availabilityComplex, availabilitySimple, applicationActivity, applyRoles]);

  const profile = useMemo(() => {
    return data?.vm.profile ?? undefined;
  }, [data]);

  const allCompletedTasks = useMemo(() => {
    return profile?.completedTasks ?? [];
  }, [profile]);

  const isPendingApproval = useMemo(() => {
    const completedTasks =
      data?.vm?.profile?.completedTasks.filter((x) => x.task.cake.cakeType === 'required' && x.status === 'approved') ??
      [];

    const requiredTasks =
      data?.vm?.profile?.application?.relevantTasks.filter((x) => x.cake.cakeType === 'required') ?? [];

    const requiredCompletedTasks =
      requiredTasks.filter(
        (requiredTask) => completedTasks.find((ct) => ct.task.taskId === requiredTask.taskId) !== undefined
      ) ?? [];

    const activityApplications =
      data?.vm?.profile?.application?.applicationActivities.filter((x) => x.status === 'pending') ?? [];

    const roleApplications =
      data?.vm?.profile?.application?.applicationRoles.filter((x) => x.status === 'pending') ?? [];

    console.log(roleApplications);

    return (
      requiredCompletedTasks.length === requiredTasks.length &&
      !!requiredTasks.length &&
      !!requiredCompletedTasks.length &&
      roleApplications.length === 0 &&
      activityApplications.length === 0
    );
  }, [data]);

  const application = profile?.application;
  const invitation = application?.invitee;
  const accountStatus =
    application?.status === 'approved' && application?.profile?.userId ? 'Account created' : 'Pending review';
  const isProfilePending = allCompletedTasks.find(
    (x) => x.task.cake.cakeType === 'required' && x.status === 'pending' && !x.task.dateRemoved
  );
  const isProfileApproved = profile?.isCompleted && profile?.isProfileTaskApproved;
  const profileCompletionStatus = isProfilePending
    ? 'Pending review'
    : isProfileApproved
    ? 'Profile completed'
    : undefined;
  const isApplicationApproved = application?.status === 'approved';
  const [openBypass, setOpenBypass] = React.useState(false);
  const onBypass = () => {
    if (selectedVolunteerProfileId) {
      completeVolunteerProfile
        .run({
          profileId: selectedVolunteerProfileId,
          bypass: true,
        })
        .then(() => {
          if (reloadList) reloadList();
          goBack();
        });
    }
  };
  const isApplicationRemoved = application?.status === 'rejected' || application?.status === 'removed';
  const isProfileRemoved = Boolean(profile?.dateRemoved);

  return (
    <IncomingVolunteersPageWrapper>
      <ConfirmationDialog
        title={'Confirm to remove applicant?'}
        body={
          <>
            <Typography variant='body1' gutterBottom>
              {`All onboarding progress will be removed for the applicant and their account will be deactivated.`}
            </Typography>
            <Typography variant='body1'>
              {'Removed applicants will receive an email notifying them that their application has been unsuccessful.'}
            </Typography>
          </>
        }
        open={openConfirmation}
        closeActionText={'Cancel'}
        confirmActionText={'Remove Applicant'}
        handleCloseClick={() => setOpenConfirmation(false)}
        handleConfirmClick={() => {
          runRemoveApplicant({
            applicationId: application?.applicationId ?? '',
            status: 'removed',
          }).then(() => {
            if (reloadList) reloadList();
            goBack();
          });
        }}
      />

      <IncomingVolunteerConfirmationDialog
        title='Approve Volunteer Profile?'
        body={`This volunteer has finished all onboarding tasks. Approving this task will activate their Volaby account. They'll receive an email inviting them to start looking for activities to join. If this user has applied for roles or activities, they will be added to this automatically.`}
        open={openApproveProfileDialog}
        id={selectedVolunteerProfileId ?? ''}
        disabled={completeVolunteerProfile.isLoading}
        handleCloseClick={() => setOpenApproveProfileDialog(false)}
        approveButtonText='Approve Volunteer Profile'
        handleApproveClick={() => {
          if (selectedVolunteerProfileId) {
            completeVolunteerProfile.run({ profileId: selectedVolunteerProfileId }).then((res) => {
              if (res.ok) {
                setSnackbar({
                  open: true,
                  message: 'Volunteer Profile Approved',
                  variant: 'success',
                });
                if (reloadList) {
                  reloadList();
                  setOpenApproveProfileDialog(false);
                }
                return;
              }
              setSnackbar({
                open: true,
                message: 'Cannot approve volunteer profile',
                variant: 'error',
              });
            });
          } else {
            setSnackbar({
              open: true,
              message: 'Cannot approve volunteer profile',
              variant: 'error',
            });
          }
        }}
      />

      <CircularProgressOverlay isLoading={loading || runRemoveApplicantLoading} />
      <IncomingVolunteersLeftColumnWrapper rightColumnOpen={!!leftMenuItem}>
        {profile && (
          <Grid item container direction='row' wrap='nowrap' alignItems='center' className={classes.listItem}>
            <Grid item>
              <IconButton onClick={() => goBack()}>
                <KeyboardArrowLeft fontSize={isMobile ? 'small' : 'large'} />
              </IconButton>
            </Grid>
            <Grid item className={classes.iconContainer}>
              <FallbackAvatar
                initials={`${profile.preferredName} ${profile.lastName}`
                  .split(' ')
                  .map((n) => n[0])
                  .join('')}
                size={32}
              />
            </Grid>
            <Grid
              item
              container
              direction={isMobile ? 'column' : 'row'}
              wrap={isMobile ? 'wrap' : 'nowrap'}
              spacing={isMobile ? 1 : 0}
              alignItems='center'
            >
              <Grid item container direction='column' spacing={isSm ? 1 : 0}>
                <Grid item container direction={isMobile ? 'column' : 'row'}>
                  <Grid item>
                    <Typography display='inline' style={{ marginRight: 8 }}>
                      {`${profile.preferredName} ${profile.lastName} `}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography display='inline' variant='body2' color='textSecondary'>
                      {profile.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container direction='row' alignItems='center' spacing={1}>
                  <Grid item>
                    <StatusChip status={invitation ? 'Invited' : 'Applied'} bgcolor={theme.status.green.light} />
                  </Grid>
                  {accountStatus && (
                    <Grid item>
                      <StatusChip
                        status={accountStatus}
                        bgcolor={accountStatus === 'Account created' ? '#2196F3' : theme.status.red.medium}
                      />
                    </Grid>
                  )}
                  {!!profile.percentageComplete && (
                    <Grid item style={{ width: 250 }}>
                      <BorderLinearProgress variant='determinate' value={profile.percentageComplete * 100} />
                    </Grid>
                  )}
                  {profileCompletionStatus && (
                    <Grid item>
                      <StatusChip
                        status={profileCompletionStatus}
                        bgcolor={
                          profileCompletionStatus === 'Profile completed'
                            ? theme.palette.primary.main
                            : theme.status.red.medium
                        }
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Typography variant='caption' color='textSecondary'>
                      {`Updated ${formatDistance(unpackToDateTime(profile.dateLastUpdated))}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item className={styles.xsOnly}>
                  <IncomingVolunteerViewProfileActionMenu
                    setOpenApproveProfileDialog={setOpenApproveProfileDialog}
                    setOpenConfirmation={setOpenConfirmation}
                    isPendingApproval={isPendingApproval}
                    leftMenuItem={leftMenuItem}
                    application={application}
                    setOpenBypass={setOpenBypass}
                    isAccountRemoved={isApplicationRemoved || isProfileRemoved}
                  />
                </Grid>
              </Grid>
              <Box className={styles.notXs}>
                <IncomingVolunteerViewProfileActionMenu
                  setOpenApproveProfileDialog={setOpenApproveProfileDialog}
                  setOpenConfirmation={setOpenConfirmation}
                  isPendingApproval={isPendingApproval}
                  leftMenuItem={leftMenuItem}
                  application={application}
                  setOpenBypass={setOpenBypass}
                  isAccountRemoved={isApplicationRemoved || isProfileRemoved}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} flexGrow={1}>
          <Box flex={1}>
            {invitation ? (
              <IncomingVolunteersInviteTaskSection
                subText={`Invited by ${invitation.invitedBy.profile.preferredName} ${invitation.invitedBy.profile.lastName}`}
                status={`Invited ${formatDistance(unpackToDateTime(invitation.dateInvited))}`}
                onClick={() => setLeftMenuItem({ inviteeId: invitation.inviteeId, type: invitation.__typename })}
              />
            ) : null}

            {application ? (
              <IncomingVolunteersApplicationTaskSection
                status={isApplicationApproved ? 'approved' : 'pending'}
                taskRecentActivityStatus={`Submitted ${formatDistance(unpackToDateTime(application.dateSubmitted))}`}
                onClick={() =>
                  setLeftMenuItem({
                    applicationId: application.applicationId,
                    type: application.__typename,
                  })
                }
              />
            ) : null}

            {profile && (
              <IncomingVolunteerTasksSection
                selectedTaskId={
                  leftMenuItem?.type === 'VOLUNTEER_PermissionlessTaskType' ? leftMenuItem.taskId : undefined
                }
                setLeftMenuItem={setLeftMenuItem}
                completedTasks={allCompletedTasks}
                userId={profile.userId}
              />
            )}

            {applicationApplyRoles && (
              <IncomingVolunteerRolesSection
                setLeftMenuItem={setLeftMenuItem}
                applicationRoles={application?.applicationRoles}
              />
            )}
            {applicationActivityEnrolment || applicationAvailabilityComplex || applicationAvailabilitySimple ? (
              <IncomingVolunteerActivitiesSection
                setLeftMenuItem={setLeftMenuItem}
                applicationActivities={application?.applicationActivities}
                applicationAvailabilitySimple={applicationAvailabilitySimple}
              />
            ) : (
              undefined
            )}
          </Box>
          {!leftMenuItem && application && !isMobile && (
            <CollapsibleSideBar>
              <Box width='100%'>
                <Typography style={{ color: theme.color.grey.neutralBrand800, fontWeight: 900, fontSize: '1.25rem' }}>
                  Comments
                </Typography>
                <Box
                  width='100%'
                  style={{
                    borderBottomColor: theme.color.grey.neutral200,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                  }}
                  my='1rem'
                />
                <CommentsList applicationId={application.applicationId} />
              </Box>
            </CollapsibleSideBar>
          )}
          {!leftMenuItem && application && isMobile && (
            <Box p={2}>
              <Box width='100%'>
                <Typography style={{ color: theme.color.grey.neutralBrand800, fontWeight: 900, fontSize: '1.25rem' }}>
                  Comments
                </Typography>
                <Box
                  width='100%'
                  style={{
                    borderBottomColor: theme.color.grey.neutral200,
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                  }}
                  my='1rem'
                />
                <CommentsList applicationId={application.applicationId} />
              </Box>
            </Box>
          )}
        </Box>
      </IncomingVolunteersLeftColumnWrapper>

      {leftMenuItem ? (
        <IncomingVolunteersRightColumnWrapper>
          <TaskSideMenuBar
            selectedUserId={profile?.userId}
            onClose={() => setLeftMenuItem(undefined)}
            leftMenuItem={leftMenuItem}
            reload={() => {
              refetch();
            }}
            reloadList={reloadList}
            setSnackbar={setSnackbar}
            roleApplications={application?.applicationRoles}
            activityApplications={application?.applicationActivities}
          />
        </IncomingVolunteersRightColumnWrapper>
      ) : null}
      <BypassDialog
        open={openBypass}
        onClose={() => setOpenBypass(false)}
        onCancel={() => setOpenBypass(false)}
        onBypass={onBypass}
      />
    </IncomingVolunteersPageWrapper>
  );
};

const IncomingVolunteerViewProfileActionMenu = (props: {
  setOpenApproveProfileDialog: (x: boolean) => void;
  setOpenConfirmation: (x: boolean) => void;
  isPendingApproval: boolean;
  leftMenuItem?: any;
  application?: any;
  setOpenBypass: React.Dispatch<React.SetStateAction<boolean>>;
  isAccountRemoved?: boolean;
}) => {
  const {
    setOpenApproveProfileDialog,
    setOpenConfirmation,
    isPendingApproval,
    leftMenuItem,
    application,
    setOpenBypass,
    isAccountRemoved,
  } = props;

  const { isMobile } = useCampfireTheme();
  const { setRemindDialog, setApplicationsReminder } = useVolunteersContext();

  const onClickSendReminder = () => {
    setApplicationsReminder([application]);
    setRemindDialog(true);
  };

  return !leftMenuItem ? (
    <Grid
      item
      container
      style={{
        display: 'flex',
        gap: '8px',
        minWidth: isMobile ? 'auto' : '400px',
        flexWrap: isMobile ? 'wrap' : 'nowrap',
      }}
      justify={isMobile ? 'flex-start' : 'flex-end'}
    >
      <Box display='flex' style={{ gap: '8px' }}>
        {isPendingApproval ? (
          <TabletButton
            color='primary'
            variant='outlined'
            style={{ borderRadius: 4, padding: '0.5rem 0.5rem' }}
            onClick={() => setOpenApproveProfileDialog(true)}
            disabled={isAccountRemoved}
          >
            {isMobile ? null : <HowToRegRounded color='primary' style={{ fontSize: 14 }} />}
            <Typography style={{ fontSize: 14, marginLeft: 8, fontWeight: 500 }}>
              {isMobile ? 'Approve' : 'Approve Profile'}
            </Typography>
          </TabletButton>
        ) : (
          <React.Fragment>
            <TabletButton
              data-track='fs-incVol-invite-volunteers-button'
              variant='contained'
              color='primary'
              onClick={onClickSendReminder}
              style={{ borderRadius: 4, padding: '0.5rem 0.5rem' }}
            >
              {isMobile ? null : <EmailIcon color='inherit' style={{ fontSize: 14 }} />}
              <Typography style={{ fontSize: 14, marginLeft: 8, fontWeight: 500 }}>
                {isMobile ? 'Reminder' : 'Send Reminder'}
              </Typography>
            </TabletButton>
            <TabletButton
              data-track='fs-incVol-invite-volunteers-button'
              variant='outlined'
              onClick={() => setOpenBypass(true)}
              style={{ borderRadius: 4, padding: '0.5rem 0.5rem' }}
              disabled={isAccountRemoved}
            >
              <Redo color='inherit' style={{ fontSize: 14 }} />
              <Typography style={{ fontSize: 14, marginLeft: 8, fontWeight: 500 }}>Bypass</Typography>
            </TabletButton>
          </React.Fragment>
        )}
      </Box>
      {!isAccountRemoved && (
        <Grid item>
          <TabletButton
            color='error'
            variant='outlined'
            onClick={() => setOpenConfirmation(true)}
            style={{ borderRadius: 4, padding: '0.5rem 0.5rem' }}
          >
            {isMobile ? null : <DeleteIcon style={{ fontSize: 14 }} />}
            <Typography style={{ fontSize: 14, marginLeft: 8, fontWeight: 500 }}>
              {isMobile ? 'Remove' : 'Remove Applicant'}
            </Typography>
          </TabletButton>
        </Grid>
      )}
    </Grid>
  ) : null;
};

export { IncomingVolunteerViewProfileScreen };
