import { CircularProgressOverlay } from '@campfire/circular-progress-overlay';
import { formatDistance, unpackToDateTime } from '@campfire/hot-date';
import { ErrorOutline } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { FullPageMessage } from '../../../../../../common/FullPageMessage';
import { TaskCompleteIcon } from '../../../../../../common/icons/TaskCompleteIcon';
import { TaskPendingReviewIcon } from '../../../../../../common/icons/TaskPendingReviewIcon';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';
import { GET_INCOMING_VOLUNTEER_ALL_TASKS, LeftMenuItemType } from '../../incoming-volunteer-model.gql';
import {
  GetIncomingVolunteerAllTasks,
  GetIncomingVolunteerAllTasksVariables,
} from '../../__generated__/GetIncomingVolunteerAllTasks';
import { IncomingVolunteerCompletedTask } from '../../__generated__/IncomingVolunteerCompletedTask';
import { IncomingVolunteerTask } from '../../__generated__/IncomingVolunteerTask';
import { IncomingVolunteerTaskList } from '../IncomingVolunteerTaskList';

interface Props {
  completedTasks?: IncomingVolunteerCompletedTask[];
  locked?: boolean;
  selectedTaskId?: string;
  setLeftMenuItem: (x: LeftMenuItemType) => void;
  userId: string;
}

const IncomingVolunteerTasksSection = (props: Props) => {
  const { completedTasks, locked, selectedTaskId, setLeftMenuItem, userId } = props;

  const { data, error, loading } = useCampfireQuery<
    GetIncomingVolunteerAllTasks,
    GetIncomingVolunteerAllTasksVariables
  >(GET_INCOMING_VOLUNTEER_ALL_TASKS, {
    options: {
      variables: {
        userId,
      },
    },
  });

  const allRequiredTasks = useMemo(() => {
    return (
      data?.vm.application?.relevantTasks
        .filter((task) => task.cake.cakeType === 'required' && !task.dateRemoved)
        .sort((a, b) => (a.order < b.order ? -1 : 1)) ?? []
    );
  }, [data]);

  function taskToTaskListItem(task: IncomingVolunteerTask, completedTask?: IncomingVolunteerCompletedTask) {
    const status: 'pending' | 'approved' | 'none' = !completedTask
      ? 'none'
      : completedTask?.status === 'pending'
      ? 'pending'
      : task.automateApproval || completedTask?.status === 'approved'
      ? 'approved'
      : 'none';

    return {
      taskId: task.taskId,
      disabled: false,
      selected: task.taskId === selectedTaskId,
      taskTitle: task.title,
      taskSubText: status === 'pending' ? 'Pending review' : '',
      taskRecentActivityStatus: completedTask
        ? `Updated ${formatDistance(unpackToDateTime(completedTask.dateLastUpdated))}`
        : 'Not started',
      onClick: () => {
        return completedTask
          ? setLeftMenuItem({
              taskId: completedTask.task.taskId,
              completedTaskId: completedTask.completedTaskId,
              type: task.__typename,
            })
          : setLeftMenuItem({
              taskId: task.taskId,
              completedTaskId: undefined,
              type: task.__typename,
            });
      },
      icon: status === 'pending' ? <TaskPendingReviewIcon /> : <TaskCompleteIcon isComplete={status === 'approved'} />,
      adminOnly: task.adminOnly,
      roleReq: task.roles,
    };
  }

  return (
    <>
      <CircularProgressOverlay isLoading={loading} />
      {error ? (
        <FullPageMessage
          title={'Network Error'}
          subtitle={'Unable to load incoming volunteers tasks.'}
          Icon={ErrorOutline}
        />
      ) : (
        <>
          {allRequiredTasks.length ? (
            <IncomingVolunteerTaskList
              header='Onboarding Tasks'
              items={allRequiredTasks.map((requiredTask) => {
                const completedRequiredTask = completedTasks?.find(
                  (completedTask) => completedTask.task.taskId === requiredTask.taskId
                );
                return taskToTaskListItem(requiredTask, completedRequiredTask);
              })}
              locked={locked}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export { IncomingVolunteerTasksSection };
