import React, { useState } from 'react';
import {
  Box,
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { unpackToDateTime } from '@campfire/hot-date';
import { Check, ChevronRight, Close, ExpandMore } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { convertFromRaw, EditorState } from 'draft-js';
import { useCampfireTheme } from '../../../../../../../theme/useCampfireTheme';
import { TaskMenuBarHeader } from '../TaskSideMenuBar';
import { useApiUrl } from '../../../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../../../global/network/useCampfireFetch';
import { useSnackbar } from '../../../../../../../global/config/useSnackbar';
import { SnackbarContextProps } from '../../../../../../../global/config/SnackbarContext';
import { RoleIcon } from '../../../../../../../common/icons/RoleIconList';
import { GetIncomingVolunteerProfile_vm_profile_application_applicationRoles as ApplicationRoles } from '../../../__generated__/GetIncomingVolunteerProfile';

interface IncomingVolunteerTaskViewProps {
  onClose: () => void;
  applicationRoleId?: string;
  reload?: () => void;
  setSnackbar?: (props: SnackbarContextProps | undefined) => void;
  roleApplication?: ApplicationRoles;
}

const useTaskListItemClasses = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      border: '.6px solid #00000010',
      padding: '15px 30px',
      backgroundColor: '#fff',
    },
    checkIcon: {
      fontSize: 29,
      color: '#00ed7e',
    },
    circleIcon: {
      fontSize: 29,
      color: theme.palette.grey[200],
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      padding: '0.3rem',
      borderRadius: '4px',
      cursor: 'pointer',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.75,
      },
    },
    menuItemText: {
      marginLeft: '0.3rem',
      fontSize: '14px',
    },
    menuItemTextInstructions: {
      marginLeft: '0.3rem',
      fontSize: '12px',
    },
    tooltip: {
      borderRadius: '12px',
      paddingTop: '0.5rem',
      paddingLeft: '.7rem',
      paddingRight: '.7rem',
      paddingBottom: '0.5rem',
      backgroundColor: theme.color.tooltip.background,
    },
    tooltipArrow: {
      '&::before': {
        backgroundColor: theme.color.tooltip.background,
      },
    },
  })
);

export const IncomingVolunteerRoleView = ({ onClose, reload, roleApplication }: IncomingVolunteerTaskViewProps) => {
  return roleApplication ? (
    <>
      <Box>
        <TaskMenuBarHeader onClose={onClose}>
          <Box>
            <Typography>Role Application</Typography>
          </Box>
        </TaskMenuBarHeader>
      </Box>

      <Box flex={1} style={{ overflowY: 'scroll', backgroundColor: '#f6f8f9', width: '100%' }} display='flex'>
        <Box position='relative' display='flex' flex='1 1 auto' height={'auto'} minHeight={1} justifyContent={'center'}>
          <RoleListItem appliedRole={roleApplication} reload={reload} />
        </Box>
      </Box>
    </>
  ) : (
    <></>
  );
};

interface RoleProps {
  appliedRole?: ApplicationRoles;
  reload?: () => void;
}

const RoleListItem = (props: RoleProps) => {
  const taskListItemClasses = useTaskListItemClasses();
  const classes = useStyles();
  const { theme } = useCampfireTheme();
  const { appliedRole, reload } = props;
  const editorState = React.useMemo(
    () =>
      appliedRole?.role.description
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(appliedRole?.role.description)))
        : null,
    [appliedRole?.role.description]
  );
  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  const { setSnackbar } = useSnackbar();
  const [withdrawOpen, setWithdrawOpen] = useState<boolean>(false);

  const applicationStatus = () => {
    if (appliedRole?.status === 'pending') {
      return 'unsure';
    }
    if (appliedRole?.status === 'approved') {
      return 'rostered';
    }
    if (appliedRole?.status === 'rejected') {
      return 'rejected';
    }
    return 'available';
  };

  const applicationStatusDesc = () => {
    if (appliedRole?.status === 'pending') {
      return 'Pending';
    }
    if (appliedRole?.status === 'approved') {
      return 'Approved';
    }
    if (appliedRole?.status === 'rejected') {
      return 'Rejected';
    }
    return 'Apply';
  };

  const applicationStatusIcon = () => {
    if (appliedRole?.status === 'pending') {
      return <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
    }
    if (appliedRole?.status === 'approved') {
      return <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
    }
    if (appliedRole?.status === 'rejected') {
      return <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
    }
    return <ChevronRight style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
  };

  const onRoleUpdate = (activityRoleId: string, status: string) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/activity-roles/update-application-status`,
        method: 'post',
        data: {
          applicationRoleId: activityRoleId,
          status: status,
        },
      })
      .then((response) => {
        if (reload) reload();
        if (response.ok) {
          setSnackbar({
            variant: 'success',
            open: true,
            message: 'Status Updated',
          });
        } else {
          setSnackbar({
            variant: 'error',
            open: true,
            message: 'Error updating status',
          });
        }
      });
  };
  const managerName = appliedRole?.actionedBy?.profile.preferredName
    ? `${appliedRole?.actionedBy?.profile.preferredName} ${appliedRole?.actionedBy.profile.lastName}`
    : `${appliedRole?.actionedBy?.profile.firstName} ${appliedRole?.actionedBy?.profile.lastName}`;

  const statusColor = applicationStatus();
  return (
    <List style={{ paddingBottom: '0', paddingTop: '10px', alignItems: 'center', flexGrow: 1, margin: '1em 2em' }}>
      <ListItem className={taskListItemClasses.listItem}>
        <ListItemText>
          <Typography
            style={{ fontWeight: 600, fontSize: '15px', display: 'flex', alignItems: 'center', color: '#444' }}
          >
            {appliedRole?.role?.icon ? (
              <Box
                style={{
                  width: '1.125rem',
                  height: '1.125rem',
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '0.5rem',
                }}
              >
                <RoleIcon iconName={appliedRole.role.icon} color={theme.color.grey.neutral300} />
              </Box>
            ) : (
              ''
            )}
            {appliedRole?.role.name}
          </Typography>
          {editorState && (
            <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
              {editorState && <CampfireRichEditor readOnly editorState={editorState} />}
            </Typography>
          )}
        </ListItemText>
        <ListItemIcon style={{ paddingLeft: '15px' }}>
          <ClickAwayListener onClickAway={() => setWithdrawOpen(false)}>
            <Tooltip
              arrow
              interactive
              title={
                <Box>
                  {appliedRole?.status !== 'approved' && (
                    <Box
                      className={classes.menuItem}
                      onClick={() =>
                        appliedRole ? onRoleUpdate(appliedRole.applicationRoleId, 'approved') : undefined
                      }
                      style={{ color: theme.alert.green.light }}
                    >
                      <Check fontSize='small' />
                      <Typography className={classes.menuItemText}>Approve</Typography>
                    </Box>
                  )}
                  {appliedRole?.status !== 'rejected' && (
                    <Box
                      className={classes.menuItem}
                      onClick={() =>
                        appliedRole ? onRoleUpdate(appliedRole.applicationRoleId, 'rejected') : undefined
                      }
                      style={{ color: theme.alert.red.light }}
                    >
                      <Close fontSize='small' />
                      <Typography className={classes.menuItemText}>Reject</Typography>
                    </Box>
                  )}
                </Box>
              }
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClick={() => setWithdrawOpen(true)}
              placement='bottom-end'
              open={withdrawOpen}
              onClose={() => setWithdrawOpen(false)}
              onOpen={() => setWithdrawOpen(true)}
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
              }}
            >
              <TabletButton
                data-track='resources-add-new-menu-button'
                variant='outlined'
                color='primary'
                aria-controls='add-new'
                aria-haspopup='true'
                endIcon={applicationStatusIcon()}
                style={{ padding: '8px 12px', border: `1px solid #DCDCDC`, whiteSpace: 'nowrap' }}
              >
                <Box display='flex' alignItems='center'>
                  <Box
                    height='10px'
                    width='10px'
                    borderRadius='50%'
                    marginRight='8px'
                    bgcolor={theme.color.rosters.status[statusColor].primary}
                  />
                  <Typography
                    variant='caption'
                    style={{
                      fontSize: '12px',
                      fontWeight: 700,
                      color: theme.color.grey.neutral400,
                      textTransform: 'uppercase',
                      lineHeight: '14px',
                      marginTop: '1px',
                    }}
                  >
                    {applicationStatusDesc()}
                  </Typography>
                </Box>
              </TabletButton>
            </Tooltip>
          </ClickAwayListener>
        </ListItemIcon>
      </ListItem>

      {appliedRole?.dateActioned && appliedRole.actionedBy ? (
        <ListItem className={taskListItemClasses.listItem} style={{ marginTop: '15px' }}>
          <ListItemText>
            <Typography
              style={{ fontWeight: 600, fontSize: '13px', display: 'flex', alignItems: 'center', color: '#444' }}
            >
              Status
              <br />
            </Typography>
            <Typography variant={'body2'} style={{ fontSize: '12px', color: '#808080' }}>
              <span style={{ textTransform: 'capitalize' }}>{appliedRole.status}</span> by {managerName},{' '}
              {unpackToDateTime(appliedRole.dateActioned).toFormat('DDDD')}
            </Typography>
          </ListItemText>
        </ListItem>
      ) : (
        undefined
      )}

      <ListItem
        className={taskListItemClasses.listItem}
        style={{ marginTop: '2em', backgroundColor: 'transparent', border: 'none', padding: '0 1.5em' }}
      >
        <ListItemText>
          <Typography
            style={{ fontWeight: 600, fontSize: '13px', display: 'flex', alignItems: 'center', color: '#666' }}
          >
            Application Actions
          </Typography>
          <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Check style={{ fontSize: '17px' }} />
              <Typography className={classes.menuItemTextInstructions}>
                <strong style={{ paddingRight: '5px' }}>Approve</strong>
                Users will be assigned this role when their application is approved
              </Typography>
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Close style={{ fontSize: '17px' }} />
              <Typography className={classes.menuItemTextInstructions}>
                <strong style={{ paddingRight: '5px' }}>Reject</strong>
                Users will be rejected from this role, but able to apply again in future
              </Typography>
            </Box>
          </Typography>
        </ListItemText>
      </ListItem>
    </List>
  );
};
