import React, { useMemo } from 'react';
import Icon from '@mdi/react';
import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Switch, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import {
  iconList,
  iconListAnimals,
  iconListCommunication,
  iconListFoodDrink,
  iconListIcons,
  iconListJobsRoles,
  iconListObjects,
  iconListPeople,
  iconListPlaces,
  iconListVehicles,
} from '../../../../../common/icons/RoleIconList';

export interface ValueType {
  name: string;
  icon: any;
  description: any;
  canApply: boolean;
}

export interface Props {
  value: ValueType;
  onChange: (value: ValueType) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    badge: {
      minWidth: '100px',
      marginRight: '16px',
    },
    select: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '20px',
    },
    list: {
      '& li': {
        display: 'inline-block',
      },
    },
    paper: {
      width: '400px',
      height: '300px',
      overfolowY: 'auto',
    },
  })
);

export function RoleForm({ value, onChange }: Props) {
  const classes = useStyles();
  const iconListOptionPeople = useMemo(() => {
    return Object.entries(iconListPeople).map(([key, valueObj]) => ({ key, value: valueObj }));
  }, [iconList]);

  const iconListOptionJobsRoles = useMemo(() => {
    return Object.entries(iconListJobsRoles).map(([key, valueObj]) => ({ key, value: valueObj }));
  }, [iconList]);

  const iconListOptionCommunication = useMemo(() => {
    return Object.entries(iconListCommunication).map(([key, valueObj]) => ({ key, value: valueObj }));
  }, [iconList]);

  const iconListOptionVehicles = useMemo(() => {
    return Object.entries(iconListVehicles).map(([key, valueObj]) => ({ key, value: valueObj }));
  }, [iconList]);

  const iconListOptionPlaces = useMemo(() => {
    return Object.entries(iconListPlaces).map(([key, valueObj]) => ({ key, value: valueObj }));
  }, [iconList]);

  const iconListOptionObjects = useMemo(() => {
    return Object.entries(iconListObjects).map(([key, valueObj]) => ({ key, value: valueObj }));
  }, [iconList]);

  const iconListOptionIcons = useMemo(() => {
    return Object.entries(iconListIcons).map(([key, valueObj]) => ({ key, value: valueObj }));
  }, [iconList]);

  const iconListOptionAnimals = useMemo(() => {
    return Object.entries(iconListAnimals).map(([key, valueObj]) => ({ key, value: valueObj }));
  }, [iconList]);

  const iconListOptionFoodDrink = useMemo(() => {
    return Object.entries(iconListFoodDrink).map(([key, valueObj]) => ({ key, value: valueObj }));
  }, [iconList]);

  return (
    <>
      <Box display='flex'>
        <FormControl variant='outlined' className={classes.badge}>
          <InputLabel htmlFor='input-badge'>Badge</InputLabel>
          <Select
            value={value.icon}
            onChange={(e) => onChange({ ...value, icon: e.target.value })}
            label='Age'
            inputProps={{
              name: 'badge',
              id: 'input-badge',
            }}
            classes={{
              select: classes.select,
            }}
            MenuProps={{
              classes: {
                list: classes.list,
                paper: classes.paper,
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              getContentAnchorEl: null,
            }}
          >
            <small style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '5px' }}>People</small>
            <br />
            {iconListOptionPeople.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Icon path={item.value} size={1} />
              </MenuItem>
            ))}
            <br />
            <br />
            <small style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '5px' }}>Jobs & Roles</small>
            <br />
            {iconListOptionJobsRoles.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Icon path={item.value} size={1} />
              </MenuItem>
            ))}
            <br />
            <br />
            <small style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '5px' }}>Communication</small>
            <br />
            {iconListOptionCommunication.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Icon path={item.value} size={1} />
              </MenuItem>
            ))}
            <br />
            <br />
            <small style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '5px' }}>Vehicles</small>
            <br />
            {iconListOptionVehicles.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Icon path={item.value} size={1} />
              </MenuItem>
            ))}
            <br />
            <br />
            <small style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '5px' }}>Places</small>
            <br />
            {iconListOptionPlaces.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Icon path={item.value} size={1} />
              </MenuItem>
            ))}
            <br />
            <br />
            <small style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '5px' }}>Objects</small>
            <br />
            {iconListOptionObjects.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Icon path={item.value} size={1} />
              </MenuItem>
            ))}
            <br />
            <br />
            <small style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '5px' }}>Icons</small>
            <br />
            {iconListOptionIcons.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Icon path={item.value} size={1} />
              </MenuItem>
            ))}
            <br />
            <br />
            <small style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '5px' }}>Animals</small>
            <br />
            {iconListOptionAnimals.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Icon path={item.value} size={1} />
              </MenuItem>
            ))}
            <br />
            <br />
            <small style={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '5px' }}>Food & Drink</small>
            <br />
            {iconListOptionFoodDrink.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                <Icon path={item.value} size={1} />
              </MenuItem>
            ))}
            <br />
          </Select>
        </FormControl>

        <TextField
          fullWidth
          variant='outlined'
          label='Role Title'
          name='name'
          value={value.name}
          onChange={(e) => onChange({ ...value, name: e.target.value })}
        />
      </Box>
      <Box marginTop='1.25rem'>
        <CampfireRichEditor
          placeholder='Add a description for this role (eg. duties, expectations, etc.)…'
          editorState={value.description}
          setEditorState={(eState) => {
            onChange({ ...value, description: eState });
          }}
        />
      </Box>
      <Box marginTop='1.25rem'>
        <Box display='flex' flex='1 1 auto' marginTop='.5em'>
          <Switch
            name='can-apply-switch'
            color='primary'
            checked={value.canApply}
            onChange={() => onChange({ ...value, canApply: !value.canApply })}
            inputProps={{ 'aria-label': 'can apply switch' }}
          />
          <Box ml={1}>
            <Typography
              style={{
                fontWeight: 500,
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                color: '#333',
              }}
            >
              Users can apply
            </Typography>
            <Typography style={{ fontSize: '13px', color: '#808080' }}>
              Applicants will be able to apply to this role as part of the application process.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
