import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Assignment, Person } from '@material-ui/icons';
import { unpackToDate } from '@campfire/hot-date';
import PrivilegeRules from '../../../../../global/auth/PrivilegeRules';
import { useUser } from '../../../../../global/auth/useUser';
import {
  VolunteerCommonProfile,
  VolunteerCommonProfile_profile_completedProfileTask as CompletedProfileTask,
} from '../../__generated__/VolunteerCommonProfile';
import { InfoBlock } from '../../common/InfoBlock';
import { hasRuleForVolunteer } from '../../common/utils';
import { VolunteerComments } from './volunteer-comments/VolunteerComments';
import { VolunteerRoles } from './VolunteerRoles';
import { CompletedTaskViewer } from '../../../../../common/form/task-form/CompletedTaskViewer';
import { deserializeTaskItems } from '../../../../admin/tasks/utils';
import { GetVolunteerCommonProfile_vm_profileTask as ProfileTask } from '../../__generated__/GetVolunteerCommonProfile';
import { AlertCard, getAlertCardColors } from '../../../../../common/cards/alert-card/AlertCard';

export const VolunteerPersonalDetail = ({
  volunteer,
  onClose,
  refetch,
  profileTask,
}: {
  volunteer: VolunteerCommonProfile;
  onClose: () => void;
  refetch: () => void;
  profileTask?: ProfileTask;
}) => {
  const {
    volunteerId,
    flagging,
    dateCreated,
    application,
    profile: { dateOfBirth, comments, completedProfileTask },
    activityRoles,
  } = volunteer;
  const {
    user: { userIdentityService },
  } = useUser();
  const { lightTextColor } = getAlertCardColors('urgent');

  const canUpdateActivityRoles =
    volunteer && hasRuleForVolunteer(volunteer, userIdentityService, PrivilegeRules.updateVolunteerActivityRoles);
  const canUpdateComments =
    volunteer && hasRuleForVolunteer(volunteer, userIdentityService, PrivilegeRules.updateVolunteerComments);

  useMemo(() => {
    refetch();
  }, []);

  return (
    <>
      <Box p={2}>
        <Typography style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center', color: '#333' }}>
          <Person style={{ fontSize: '14px', paddingRight: '5px' }} /> Personal Details
        </Typography>
        {flagging && (
          <AlertCard variant='urgent' title='User Flagged & Exited'>
            <Typography display='block' component='span' style={{ color: lightTextColor, fontSize: '14px' }}>
              Exited by{' '}
              <strong>
                {flagging.flaggedBy.profile.preferredName} {flagging.flaggedBy.profile.lastName}
              </strong>
              <br />
              {`Reason: ${flagging.flaggedReason}`}
            </Typography>
          </AlertCard>
        )}
        {dateOfBirth && <InfoBlock label='Date of Birth' value={dateOfBirth} />}
        {dateCreated && (
          <InfoBlock label='Profile Approval Date' value={unpackToDate(dateCreated).toFormat('d LLLL, yyyy')} />
        )}
        {application && (
          <InfoBlock
            label='Application Date'
            value={unpackToDate(application.dateSubmitted).toFormat('d LLLL, yyyy')}
          />
        )}
        <VolunteerComments
          volunteerId={volunteerId}
          comments={comments}
          editable={canUpdateComments}
          onClose={onClose}
          refetch={refetch}
        />
        <VolunteerRoles
          volunteerId={volunteerId}
          volunteerActivityRoles={activityRoles}
          editable={canUpdateActivityRoles}
        />
      </Box>
      {completedProfileTask && profileTask && (
        <>
          <hr style={{ borderTop: '.5px solid #e7e7e7', margin: '20px 0' }} />
          <TaskItems profileTask={profileTask} completedProfileTask={completedProfileTask} />
        </>
      )}
    </>
  );
};

interface TaskItemProps {
  profileTask: ProfileTask;
  completedProfileTask: CompletedProfileTask;
}

const TaskItems = (props: TaskItemProps) => {
  const { profileTask, completedProfileTask } = props;

  const taskHeaderDetails = useMemo(() => {
    return {
      title: completedProfileTask.task.title,
      description: completedProfileTask.task.description,
      cake: completedProfileTask.task.cake.title,
    };
  }, [completedProfileTask]);

  const taskItems = useMemo(() => {
    if (!profileTask) return [];
    return profileTask.taskItems.sort((a, b) => (a.order > b.order ? 1 : -1));
  }, [completedProfileTask]);

  const selectedCompletedTask = completedProfileTask;

  const taskItemValues = useMemo(() => {
    if (!selectedCompletedTask) return [];
    return selectedCompletedTask.taskFieldValues.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.field.taskFieldId]: curr,
      }),
      {}
    );
  }, [selectedCompletedTask]);

  const parsedTaskItems = deserializeTaskItems(taskItems);

  return (
    <Box p={2}>
      <Typography style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center', color: '#333' }}>
        <Assignment style={{ fontSize: '14px', paddingRight: '5px' }} /> Profile Responses
      </Typography>
      <CompletedTaskViewer
        taskItems={parsedTaskItems}
        taskItemValues={taskItemValues}
        volunteerProfileValues={undefined}
        taskHeaderDetails={taskHeaderDetails}
        selectedCompletedTask={selectedCompletedTask}
        isTaskZero={false}
        simple
      />
    </Box>
  );
};
