import React from 'react';
import { Box, Typography, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { StringParam, useQueryParams } from 'use-query-params';
import { RosterContext, RosterContextProvider } from './RosterContext';
import { RosterTable } from './RosterTable';
import { RosterMenu } from './RosterTable/RosterMenu';
import { ACTIVITIES_LIST } from './activity-list.gql';
import { GetAllActivities, GetAllActivitiesVariables } from './__generated__/GetAllActivities';
import { CollapsibleSideBar } from '../../../../common/CollapsibleSideBar';
import { useDeepEffect } from '../../../../hooks/useDeepEffect';
import { SaveDrawer } from './SaveDrawer';
import { RosterActivities } from '../sidebar/RosterActivities';
import { RosterToolbar } from '../common/RosterToolbar';
import { ActivityCancelDialog } from './ActivityCancelDialog';
import { AddVolunteer } from './AddVolunteer';
import { RosterFilter } from './RosterFilter';
import { RosterSettingDialog } from '../settings/RosterSettingDialog';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { ProgramsContext } from '../../../../global/programs-sell/ProgramsContext';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { useBulkSharedPagination } from '../usePagination';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontWeight: 700,
      fontSize: 18,
      color: theme.color.grey.neutralBrand800,
    },
    activitiesContainer: {
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      overflowY: 'scroll',
    },
  })
);

export function BulkRosterScreen() {
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [openSetting, setOpenSetting] = React.useState(false);
  const { theme } = useCampfireTheme();

  const [query, setQuery] = useQueryParams({
    activityId: StringParam,
    view: StringParam,
    programId: StringParam,
    activityStatus: StringParam,
  });

  const isActive = query.activityStatus !== 'active';

  const classes = useStyles();
  const onToggleView = () => setQuery({ view: 'single' });

  const { selectedPrograms } = React.useContext(ProgramsContext);

  const { programId, activityId } = query;

  const [listActivityIds, vmListActivityIds] = useCampfireLazyQuery<GetAllActivities, GetAllActivitiesVariables>(
    ACTIVITIES_LIST
  );
  const { setPage } = useBulkSharedPagination();

  React.useEffect(() => {
    listActivityIds({
      variables: {
        isActive,
        programIds: selectedPrograms.length > 0 ? selectedPrograms : undefined,
      },
    });
  }, []);

  useDeepEffect(() => {
    if (selectedPrograms.length > 0) {
      listActivityIds({
        variables: {
          isActive,
          programIds: programId && programId !== 'all' ? [programId] : undefined,
        },
      });
    }
  }, [programId, isActive]);

  const activityIds = vmListActivityIds.data?.vm.programs.flatMap((p) => p.activities.map((a) => a.activityId)) || [];

  useDeepEffect(() => {
    if (!activityId || (!activityIds.includes(activityId) && activityIds.length > 0)) {
      setQuery({ activityId: activityIds[0] });
    }
  }, [activityIds, activityId]);

  React.useEffect(() => {
    setPage(0);
  }, [activityId]);

  if (!query.activityId) {
    return null;
  }

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <RosterContextProvider activityId={query.activityId}>
        <React.Fragment>
          <Box
            style={{
              margin: '10px 30px 0px',
              borderBottom: '1px solid',
              borderColor: theme.color.grey.neutralBrand200,
            }}
          >
            <RosterToolbar
              title='Bulk Rosters'
              onView={onToggleView}
              isFiltering={isFiltering}
              setIsFiltering={setIsFiltering}
              onSetting={() => setOpenSetting(true)}
            />
          </Box>
          <Box display={'flex'} style={{ padding: '0px 0px 0px 30px' }} overflow='hidden'>
            <Box display='flex' flex={1} flexDirection='column' overflow='scroll' paddingBottom='6rem'>
              {isFiltering && (
                <Box flexGrow={0}>
                  <RosterFilter />
                </Box>
              )}
              <Box position='sticky' top={0} bgcolor='white' zIndex={2}>
                <RosterMenu />
              </Box>
              <RosterTable />
              <Box alignSelf={'flex-start'} marginTop='1rem'>
                <AddVolunteer />
              </Box>
              <SaveDrawer />
              <ActivityCancelDialog />
            </Box>
            <RosterContext.Consumer>
              {(value) => (
                <CollapsibleSideBar setSiblingExpanded={value.setExpanded}>
                  <Box flex={1} display='flex' flexDirection='column' overflow='hidden'>
                    <Box
                      minHeight='35px'
                      borderBottom='1px solid rgb(239, 239, 239)'
                      display='flex'
                      alignItems='center'
                    >
                      <Typography className={classes.heading}>Activities</Typography>
                    </Box>
                    <Box className={classes.activitiesContainer}>
                      <RosterActivities activityIds={activityIds} />
                    </Box>
                  </Box>
                </CollapsibleSideBar>
              )}
            </RosterContext.Consumer>
          </Box>
        </React.Fragment>
      </RosterContextProvider>
      {openSetting ? <RosterSettingDialog open={openSetting} onClose={() => setOpenSetting(false)} /> : null}
    </Box>
  );
}
