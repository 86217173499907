import { gql } from '@apollo/client';

export const GET_ACTIVITY_CANCELLATION_CLOSURE_REASONS = gql`
  query GetActivityCancellationAndClosureReasons {
    vm {
      activityCancellationReasons {
        activityCancellationReasonId
        label
      }
      activityClosureReasons {
        activityClosureReasonId
        label
      }
    }
  }
`;

export const GET_ADMIN_CONSOLE_OPS_SESSION_VISIBILITY = gql `
  query GetAdminConsoleOpsSessionVisibility {
    vm {
      configItem(key: "operations-allow-roster-session-visibility") {
        configItemId
        key
        description
        defaultValue
        configItemValue {
          flagValue
        }
      }
    }
  }
`;
