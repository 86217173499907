import { unpackToDateTime } from '@campfire/hot-date';
import { HoverText } from '@campfire/hover-link';
import { Box, Divider, IconButton, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { BorderColor, Delete as DeleteIcon, GetApp, MoreHoriz, Photo } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { Skeleton } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/styles';
import { capitalize } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FullscreenDialog } from '../../../common/dialogs/FullscreenDialog';
import { FullPageMessage } from '../../../common/FullPageMessage';
import { useUser } from '../../../global/auth/useUser';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';
import { Avatar } from '../../general/user-profile/components/Avatar';
import { VolunteerCommonProfileActionMenu } from './profile-sections/action-menu/VolunteerCommonProfileActionMenu';
import { VolunteerActivityEnrolmentsSection } from './profile-sections/activity-enrolment-section/VolunteerActivityEnrolmentsSection';
import { VolunteerPersonalDetail } from './profile-sections/personal-detail-section/VolunteerPersonalDetail';
import { VolunteerAdditionalTasksSection } from './profile-sections/tasks-section/VolunteerAdditionalTasksSection';
import { VolunteerTasksSection } from './profile-sections/tasks-section/VolunteerTasksSection';
import { VolunteerActivityApplicationsSection } from './profile-sections/VolunteerActivityApplicationsSection';
import { VolunteerActivityWaitlistingsSection } from './profile-sections/VolunteerActivityWaitlistingsSection';
import { VolunteerProgramsSection } from './profile-sections/VolunteerProgramsSection';
import { VolunteerCommunicationsSection } from './profile-sections/communications-section/VolunteerCommunicationsSection';
import { VolunteerProfileActions } from './volunteer-profile-actions';
import { VolunteerCommonProfileProvider, useVolunteerCommonProfileContext } from './VolunteerCommonProfileContext';
import { GetVolunteerCommonProfile_vm_volunteer_rosterings_smsLogs as SmsLogType } from './__generated__/GetVolunteerCommonProfile';
import { VolunteerCommonProfile } from './__generated__/VolunteerCommonProfile';
import { VolunteerImpactSection } from './profile-sections/VolunteerImpactSection';
import { useSession } from '../../../global/auth/useSession';
import { useSnackbar } from '../../../global/config/useSnackbar';
import { useEndpointFetch } from '../../../global/network/useEndpointFetch';
import { TaskBadge } from '../../admin/tasks/TaskBadge';
import { VolunteerAvailabilitySection } from './profile-sections/availability-section/VolunteerAvailabilitySection';
import { StatusChip } from '../../../common/chips/StatusChip';

type VolunteerCommonProfileDialogProps = {
  open: boolean;
  onClose: () => void;
  profileId: string;
  userId: string;
  updateVolunteerOnProfileActions?: VolunteerProfileActions;
};

export interface SmsLogTypeParsed extends SmsLogType {
  status: string;
}

const AVATAR_RADIUS_DESKTOP = 108;
const AVATAR_RADIUS_MOBILE = 88;

interface ProfilePictureSection {
  avatarUrl: string;
  profileId: string;
  preferredName: string;
  lastName: string;
}

enum AvatarAction {
  Update,
  Delete,
}

interface UpdateProfileAvatarEndpointSpec {
  profileId: string;
  avatar: File;
}

interface DeleteProfileAvatarEndpointSpec {
  profileId: string;
}

interface UpdateProfileAvatarEndpointResponse {
  url: string;
}

const AdminProfilePictureSection = (props: ProfilePictureSection) => {
  const { updateAvatarUrl, reloadSession } = useSession();
  const { avatarUrl, profileId, preferredName, lastName } = props;
  const [avatarUrlProfile, setAvatarUrlProfile] = useState<string | null>(avatarUrl);
  const ref = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [openEditDialog, setOpenEditDialog] = useState<boolean>();
  const { setSnackbar } = useSnackbar();
  const { isMobile } = useCampfireTheme();

  function handleFileClick() {
    if (!ref || !ref.current) return;
    ref.current.click();
  }

  function clearFile() {
    setFile(undefined);
    if (!ref || !ref.current) return;
    ref.current.value = '';
  }

  function handleRemoveClick() {
    deleteAvatarFetch
      .run({ profileId })
      .then((response) => {
        if (!response || !response.ok) {
          somethingWentWrong(AvatarAction.Delete);
          return;
        }
        greatSuccess(AvatarAction.Delete);
        clearFile();
        updateAvatarUrl(null);
        setAvatarUrlProfile(null);
        setOpenEditDialog(false);
        reloadSession();
      })
      .catch(() => {
        somethingWentWrong(AvatarAction.Delete);
      });
  }

  const updateAvatarFetch = useEndpointFetch<UpdateProfileAvatarEndpointSpec, UpdateProfileAvatarEndpointResponse>(
    '/vm/volunteer/profile/avatar/update',
    {
      formData: true,
    }
  );

  const deleteAvatarFetch = useEndpointFetch<DeleteProfileAvatarEndpointSpec>('/vm/volunteer/profile/avatar/delete');

  function somethingWentWrong(action: AvatarAction) {
    if (action === AvatarAction.Update) {
      clearFile();
    }
    setSnackbar({
      message: action === AvatarAction.Update ? 'Unable to update profile photo' : 'Unable to remove profile photo',
      variant: 'error',
      open: true,
    });
  }

  function greatSuccess(action: AvatarAction) {
    setSnackbar({
      message: action === AvatarAction.Update ? 'Updated profile photo' : 'Removed profile photo',
      variant: 'success',
      open: true,
    });
  }

  useEffect(() => {
    if (!file) return;
    const parcel = {
      profileId,
      avatar: file,
    };
    updateAvatarFetch
      .run(parcel)
      .then((response) => {
        if (!response || !response.ok) {
          somethingWentWrong(AvatarAction.Update);
          return;
        }
        greatSuccess(AvatarAction.Update);
        updateAvatarUrl(response.data.data.url);
        setAvatarUrlProfile(response.data.data.url);
        setOpenEditDialog(false);
        reloadSession();
      })
      .catch(() => {
        somethingWentWrong(AvatarAction.Update);
      });
  }, [file]);

  return (
    <Box position='relative'>
      <Box display='flex' flex='1 0 auto' justifyContent='center'>
        <Avatar
          size={isMobile ? AVATAR_RADIUS_MOBILE : AVATAR_RADIUS_DESKTOP}
          preferredName={preferredName}
          lastName={lastName}
          avatarUrl={avatarUrlProfile}
          avatarFile={file}
          style={{ border: '1px solid #e9e9e9' }}
        />
      </Box>
      <Box display='flex' justifyContent='center' marginTop={'8px'}>
        <Box>
          <Box display='flex' justifyContent='center'>
            {!openEditDialog && (
              <TabletButton
                variant='text'
                color='primary'
                style={{
                  boxSizing: 'border-box',
                  textTransform: 'none',
                  minWidth: '40px',
                }}
                onClick={() => setOpenEditDialog(true)}
              >
                <BorderColor style={{ marginTop: '1px', fontSize: '12px' }} />
                <Typography
                  style={{
                    marginLeft: '10px',
                    fontWeight: 600,
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Edit
                </Typography>
              </TabletButton>
            )}
            {openEditDialog && (
              <>
                <TabletButton
                  variant='text'
                  color='primary'
                  style={{
                    boxSizing: 'border-box',
                    textTransform: 'none',
                    minWidth: '40px',
                  }}
                  onClick={() => handleFileClick()}
                  disabled={updateAvatarFetch.isLoading || deleteAvatarFetch.isLoading}
                >
                  <Photo style={{ marginTop: '1px', fontSize: '12px' }} />
                  <Typography
                    style={{
                      marginLeft: '10px',
                      fontWeight: 600,
                      fontSize: '12px',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    Upload
                  </Typography>
                </TabletButton>
                {avatarUrlProfile && (
                  <TabletButton
                    variant='text'
                    color='error'
                    style={{
                      boxSizing: 'border-box',
                      textTransform: 'none',
                      minWidth: '40px',
                    }}
                    onClick={() => handleRemoveClick()}
                    disabled={updateAvatarFetch.isLoading || deleteAvatarFetch.isLoading}
                  >
                    <DeleteIcon style={{ marginTop: '1px', fontSize: '12px' }} />
                    <Typography
                      style={{
                        marginLeft: '10px',
                        fontWeight: 600,
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      Remove
                    </Typography>
                  </TabletButton>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>

      <input
        type='file'
        accept='image/*'
        hidden
        ref={ref}
        onChange={(event) => {
          const targetFile = event.target.files?.item(0);
          if (!targetFile) return;
          setFile(targetFile);
        }}
      />
    </Box>
  );
};

const useTabStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      minWidth: 72,
      textTransform: 'none',
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
      textAlign: 'left',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
      '&:focus': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
    wrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      '& [class*="MuiSvgIcon-root"]': {
        marginRight: 4,
        marginBottom: '0 !important',
      },
    },
  })
);

export const VolunteerCommonProfileDialogInner = ({
  open,
  onClose,
  profileId,
  updateVolunteerOnProfileActions,
}: VolunteerCommonProfileDialogProps) => {
  const { isMobile } = useCampfireTheme();
  const {
    unsavedComments,
    setShowUnsavedCommentsDialog,
    setProfileId,
    setUserId,
    setVolunteerNameOptions,
    setUpdateParentVolunteerActions,
    emails,
    smsLogs,
    hasMore,
    completedTasks,
    volunteer,
    reloadNewData,
    onLoadMore,
    error,
    profileTask,
  } = useVolunteerCommonProfileContext();

  const tabClasses = useTabStyles();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(true);

  function mobileMenuChange() {
    setMobileMenuOpen(!mobileMenuOpen);
  }

  function handleChange(_unusedEvent: React.ChangeEvent<{}>, value: number) {
    setSelectedTabIndex(value);
    setMobileMenuOpen(false);
  }

  useEffect(() => {
    if (!volunteer) return;
    setProfileId(volunteer.profile.profileId);
    setUserId(volunteer.profile.userId);
    setVolunteerNameOptions({
      fullName: `${volunteer.profile.preferredName} ${volunteer.profile.lastName}`,
      preferredName: volunteer.profile.preferredName,
    });
  }, [volunteer]);

  useEffect(() => {
    if (!updateVolunteerOnProfileActions) return;
    setUpdateParentVolunteerActions(updateVolunteerOnProfileActions);
  }, [updateVolunteerOnProfileActions]);

  const handleClose = () => {
    if (unsavedComments) {
      setShowUnsavedCommentsDialog(true);
      return;
    }
    onClose();
  };

  const {
    user: { userIdentityService },
  } = useUser();

  const activityEnrolments = useMemo(() => {
    if (!volunteer) return [];
    if (
      userIdentityService.isActivityLeader &&
      (!userIdentityService.isProgramManager || userIdentityService.isVmAdmin)
    ) {
      return volunteer.activityEnrolments.filter((activityEnrolment) =>
        userIdentityService.isLeaderOfActivity(activityEnrolment.activity.activityId)
      );
    }
    return volunteer.activityEnrolments;
  }, [userIdentityService, volunteer, profileId]);

  const filteredAdditionalTasks = volunteer?.tasks.filter(
    (task) => !volunteer?.profile.completedTasks.find((ct) => ct.task.taskId === task.taskId) && !task.dateRemoved
  );

  const isLoading = !volunteer;

  const TABS = [
    { name: 'Profile ', number: 0 },
    { name: 'Complete Tasks', number: completedTasks.length },
    { name: 'Incomplete Tasks', number: filteredAdditionalTasks?.length },
    { name: 'Availability', number: 0 },
    { name: 'Program Enrolments', number: volunteer?.programs.length },
    { name: 'Activity Enrolments', number: volunteer?.activityEnrolments.length },
    { name: 'Activity Applications', number: volunteer?.activityApplications.length },
    { name: 'Activity Waitlistings', number: volunteer?.activityWaitlistings.length },
    { name: 'Communications', number: 0 },
    { name: 'Impact ', number: 0 },
  ];

  return (
    <FullscreenDialog
      open={open}
      close={handleClose}
      fullScreen={isMobile}
      title={volunteer ? `${volunteer?.profile.preferredName} ${volunteer.profile.lastName}` : ''}
      maxWidth='md'
      dialogHeaderOption={() => (volunteer ? <VolunteerCommonProfileActionMenu volunteer={volunteer} /> : <></>)}
      fullWidth
      noPadding
      mobileActionButton={isMobile}
      mobileAction={mobileMenuChange}
      scroll='paper'
    >
      {error ? (
        <FullPageMessage title='Network Error' subtitle='Unable to load volunteer profile.' />
      ) : isLoading ? (
        <VolunteerCommonProfileDialogSkeleton />
      ) : (
        volunteer && (
          <Box
            display={'flex'}
            style={{ backgroundColor: '#f9f9f9', height: isMobile ? '100%' : 'auto', justifyContent: 'center' }}
          >
            {(isMobile && mobileMenuOpen) || !isMobile ? (
              <Box style={{ width: isMobile ? '100%' : 'auto' }}>
                <Tabs
                  indicatorColor='primary'
                  orientation='vertical'
                  value={selectedTabIndex}
                  onChange={handleChange}
                  aria-label='my elements tabs'
                  centered={isMobile}
                  style={{
                    backgroundColor: 'white',
                    height: '100%',
                    minWidth: '200px',
                    justifyContent: 'center',
                    paddingLeft: '10px',
                    paddingBottom: '25px',
                  }}
                >
                  {TABS.map(({ name, number }) => (
                    <Tab
                      id={`accountSettings-${name}-tab`}
                      disableRipple
                      disableTouchRipple
                      classes={tabClasses}
                      label={
                        <>
                          {name}{' '}
                          {number !== 0 && number !== undefined ? (
                            <Box style={{ marginLeft: '10px' }}>
                              <TaskBadge number={number} variant='default' />
                            </Box>
                          ) : (
                            undefined
                          )}
                        </>
                      }
                      style={{ minHeight: '48px', alignSelf: 'flex-start', justifyContent: 'flex-start' }}
                      key={name}
                    />
                  ))}
                </Tabs>
              </Box>
            ) : (
              undefined
            )}
            {(isMobile && !mobileMenuOpen) || !isMobile ? (
              <Box
                display={'block'}
                alignItems={'center'}
                style={{
                  height: 'fit-content',
                  background: 'white',
                  padding: '1.25rem 1.875rem',
                  margin: isMobile ? '1rem auto' : '2.25rem auto',
                  maxWidth: isMobile ? '80%' : '70%',
                  minWidth: '60%',
                }}
              >
                {selectedTabIndex === 0 ? (
                  <Box>
                    <VolunteerAvatarInfo volunteer={volunteer} />
                    <Divider />
                    <Box paddingX={2}>
                      <VolunteerPersonalDetail
                        volunteer={volunteer}
                        onClose={onClose}
                        refetch={reloadNewData}
                        profileTask={profileTask}
                      />
                    </Box>
                  </Box>
                ) : selectedTabIndex === 1 ? (
                  userIdentityService.isProgramManager || userIdentityService.isVmAdmin ? (
                    <VolunteerTasksSection
                      completedTasks={completedTasks}
                      userId={volunteer.profile.userId ?? ''}
                      profileId={volunteer.profile.profileId}
                      hasMore={hasMore}
                      loadMore={onLoadMore}
                      refetch={reloadNewData}
                    />
                  ) : null
                ) : selectedTabIndex === 2 ? (
                  userIdentityService.isProgramManager || userIdentityService.isVmAdmin ? (
                    <VolunteerAdditionalTasksSection
                      tasks={filteredAdditionalTasks ?? []}
                      userId={volunteer.profile.userId ?? ''}
                      profileId={volunteer.profile.profileId}
                      refetch={reloadNewData}
                    />
                  ) : null
                ) : selectedTabIndex === 3 ? (
                  <VolunteerAvailabilitySection volunteer={volunteer} />
                ) : selectedTabIndex === 4 ? (
                  <VolunteerProgramsSection programs={volunteer.programs} />
                ) : selectedTabIndex === 5 ? (
                  <VolunteerActivityEnrolmentsSection
                    activityEnrolments={activityEnrolments}
                    volunteerId={volunteer.volunteerId}
                    profileId={volunteer.profile.profileId}
                  />
                ) : selectedTabIndex === 6 ? (
                  <VolunteerActivityApplicationsSection activityApplications={volunteer.activityApplications} />
                ) : selectedTabIndex === 7 ? (
                  <VolunteerActivityWaitlistingsSection activityWaitlistings={volunteer.activityWaitlistings} />
                ) : selectedTabIndex === 8 ? (
                  <VolunteerCommunicationsSection emails={emails} smsLogs={smsLogs} />
                ) : selectedTabIndex === 9 ? (
                  <VolunteerImpactSection />
                ) : (
                  ''
                )}
              </Box>
            ) : (
              undefined
            )}
          </Box>
        )
      )}
    </FullscreenDialog>
  );
};

export const VolunteerCommonProfileDialog = (props: VolunteerCommonProfileDialogProps) => (
  <VolunteerCommonProfileProvider userId={props.userId} profileId={props.profileId}>
    <VolunteerCommonProfileDialogInner {...props} />
  </VolunteerCommonProfileProvider>
);

const VolunteerAvatarInfo = ({ volunteer }: { volunteer: VolunteerCommonProfile }) => {
  const {
    profile: { firstName, lastName, preferredName, email, contactNumber, importOperation, addedBy, profileId },
    flagging,
    dateDeactivated,
  } = volunteer;
  const { isMobile, theme } = useCampfireTheme();

  const managerNameImport = importOperation
    ? `${importOperation.importedBy.profile.firstName} ${importOperation.importedBy.profile.lastName}`
    : null;

  const managerNameAdded = addedBy ? `${addedBy.profile.firstName} ${addedBy.profile.lastName}` : null;

  const dateManuallyAdded = addedBy
    ? unpackToDateTime(volunteer.dateCreated).toLocaleString({
        year: 'numeric',
        weekday: 'long',
        month: 'long',
        day: '2-digit',
      })
    : null;
  const importDate = importOperation
    ? unpackToDateTime(importOperation.dateCreated).toLocaleString({
        year: 'numeric',
        weekday: 'long',
        month: 'long',
        day: '2-digit',
      })
    : null;

  return (
    <Box
      display='flex'
      flexDirection='row'
      alignContent='center'
      alignItems='center'
      justifyContent='center'
      px={2}
      paddingBottom={isMobile ? '16px' : '32px'}
    >
      <AdminProfilePictureSection
        preferredName={volunteer.profile.preferredName}
        lastName={volunteer.profile.lastName}
        avatarUrl={volunteer.profile.avatarUrl ?? ''}
        profileId={profileId}
      />
      <Box px={3} mt={'-16px'}>
        {flagging && <StatusChip status='Flagged' bgcolor={theme.color.error[900]} />}
        {dateDeactivated && !flagging && (
          <div style={{ paddingBottom: '10px' }}>
            <StatusChip status='Retired' bgcolor={theme.color.grey.neutral200} />
          </div>
        )}
        <Typography variant='body1' style={{ fontWeight: 600 }}>{`${capitalize(firstName)} ${capitalize(
          lastName
        )} (${capitalize(preferredName)})`}</Typography>
        {importOperation && (
          <HoverText title={`Imported by ${managerNameImport} on ${importDate}`} disableUnderline>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: '#0000008a',
                alignItems: 'center',
                paddingBottom: '8px',
              }}
            >
              <GetApp style={{ fontSize: '12px' }}></GetApp>
              <Typography style={{ fontSize: '11px', paddingLeft: '2px' }}>Imported Volunteer</Typography>
            </Box>
          </HoverText>
        )}
        {addedBy && (
          <HoverText title={`Added by ${managerNameAdded} on ${dateManuallyAdded}`} disableUnderline>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: '#0000008a',
                alignItems: 'center',
                paddingBottom: '8px',
              }}
            >
              <GetApp style={{ fontSize: '12px' }}></GetApp>
              <Typography style={{ fontSize: '11px', paddingLeft: '2px' }}>Manually Added Volunteer</Typography>
            </Box>
          </HoverText>
        )}
        {email && <Typography variant='body2'>{email}</Typography>}

        {contactNumber && <Typography variant='body2'>{contactNumber}</Typography>}
      </Box>
    </Box>
  );
};

const VolunteerCommonProfileDialogSkeleton = () => {
  const { isMobile } = useCampfireTheme();

  return (
    <Box minWidth={!isMobile ? 500 : null} minHeight={1}>
      <Box display='flex' justifyContent='flex-end'>
        <IconButton disabled>
          <MoreHoriz />
        </IconButton>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        alignContent='center'
        alignItems='center'
        justifyContent='center'
        px={2}
        paddingBottom='16px'
      >
        <Box px={1}>
          <Skeleton
            variant='circle'
            width={isMobile ? AVATAR_RADIUS_MOBILE : AVATAR_RADIUS_DESKTOP}
            height={isMobile ? AVATAR_RADIUS_MOBILE : AVATAR_RADIUS_DESKTOP}
          />
        </Box>
        <Box px={2} width='50%'>
          <Skeleton variant='text' width='100%' height={10} />
          <Skeleton variant='text' width='80%' height={7} />
          <Skeleton variant='text' width='60%' height={7} />
        </Box>
      </Box>

      <Box paddingLeft={3}>
        <Box p={2}>
          <Skeleton variant='text' width='30%' height={15} />
        </Box>
        <Box px={2} py={0.5}>
          <Skeleton variant='text' width='50%' height={7} />
          <Skeleton variant='text' width='45%' height={12} />
        </Box>
        <Box px={2} py={0.5}>
          <Skeleton variant='text' width='70%' height={7} />
          <Skeleton variant='text' width='65%' height={12} />
        </Box>
        <Box px={2} py={0.5}>
          <Skeleton variant='text' width='50%' height={7} />
          <Skeleton variant='text' width='45%' height={12} />
        </Box>
        <Box px={2} py={0.5}>
          <Skeleton variant='text' width='30%' height={7} />
          <Skeleton variant='text' width='25%' height={12} />
        </Box>
      </Box>
    </Box>
  );
};
