import React from 'react';
import { unpackToTime } from '@campfire/hot-date';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Box, Typography, Theme } from '@material-ui/core';
import { LocationOnRounded, Explore } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { DateInfoCard } from '../../../../common/cards/date-info-card/DateInfoCard';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { VolunteerRosters } from '../ActivityQueries/__generated__/VolunteerRosters';
import { RosteredStatus } from '../ActivityTypes';

import { ActivityTimelineItem as ActivityTimelineItemFragments } from '../ActivityTimeline/__generated__/ActivityTimelineItem';

export type MyShiftItemProps = ActivityTimelineItemFragments & {
  activityDate: string;
  rosterTemplateAddress?: string;
};

const customDateInfoStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayContainer: {
      [theme.breakpoints.only('lg')]: {
        width: 108,
      },
      [theme.breakpoints.down('md')]: {
        width: 84,
      },
    },
    dayText: {
      fontSize: 40,
    },
    bodyContainer: {
      [theme.breakpoints.up('lg')]: {
        width: 'auto',
      },
      [theme.breakpoints.only('sm')]: {
        width: 252,
      },
      [theme.breakpoints.only('xs')]: {
        width: 214,
      },
      [theme.breakpoints.only('md')]: {
        width: 252,
      },
    },
  })
);

export interface MyShiftItemMenuProps {
  rosteredStatus: RosteredStatus;
  onUpdateAvailability: () => void;
  onUnavailable: () => void;
  onWithdraw: () => void;
}

export function MyShiftItem(props: {
  activity: MyShiftItemProps;
  ActivityMenu: any;
  onSelect: () => void;
  isSelected: boolean;
  color: any;
  rostering?: VolunteerRosters;
}) {
  const { activity, onSelect, isSelected, ActivityMenu, color, rostering } = props;
  const { theme, isMobile, isMd } = useCampfireTheme();
  const formatTime = (time: string) => unpackToTime(time).toFormat('h:mm a');
  const dateInfoClasses = customDateInfoStyles();

  return (
    <Box marginTop='1.875rem' onClick={onSelect}>
      <DateInfoCard date={activity.activityDate} size='l' color={color} selected={isSelected} classes={dateInfoClasses}>
        <Box display='flex' justifyContent='space-between' alignSelf='stretch'>
          <TitularTooltip title={activity.name}>
            <Typography
              variant={'h6'}
              noWrap
              style={{ color: theme.color.grey.neutral400, fontWeight: 800, width: '100%', letterSpacing: '-0.02em' }}
            >
              {activity.name}
            </Typography>
          </TitularTooltip>
          {ActivityMenu}
        </Box>
        {rostering ? (
          <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200 }}>
            Attending: {rostering.sessionRosterings.map((sessionRostering) => sessionRostering.session.name).join(', ')}
          </Typography>
        ) : null}
        <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200 }}>
          {activity.startTime && activity.endTime
            ? `${formatTime(activity.startTime)} - ${formatTime(activity.endTime)}`
            : ''}
        </Typography>
        <Box display={isMobile || isMd ? 'auto' : 'flex'} alignItems='center' mt='40px'>
          <Box display='flex' alignItems='center' pr={2} pt={isMobile || isMd ? 1 : 0}>
            <LocationOnRounded style={{ color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }} />
            <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200, marginTop: '1px' }}>
              {activity?.rosterTemplateAddress
                ? activity?.rosterTemplateAddress
                : activity.activityLocation
                ? activity.activityLocation.placesAddress.description
                : activity.activityRemoteLocation?.details}
            </Typography>
          </Box>
          {/* <Box display='flex' alignItems='center' pr={3} pt={isMobile || isMd ? 1 : 0}>
            <AccessTimeIcon style={{ color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }} />
            <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200, marginTop: '1px' }}>
              {activity.hasOpenRoster ? 'Open Roster' : 'Managed Roster'}
            </Typography>
          </Box> */}
          <Box display='flex' alignItems='center' pr={2} pt={isMobile || isMd ? 1 : 0}>
            <Typography
              variant={'h2'}
              style={{ fontWeight: 600, color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }}
            >
              {activity.sessions.length}
            </Typography>
            <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200, marginTop: '1px' }}>
              {activity.sessions.length > 1 ? 'sessions available' : 'session available'}
            </Typography>
          </Box>
          <Box display='flex' alignItems='center' pr={2} pt={isMobile || isMd ? 1 : 0}>
            <Explore style={{ color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }} />
            <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200, marginTop: '1px' }}>
              {activity?.program.name}
            </Typography>
          </Box>
        </Box>
      </DateInfoCard>
    </Box>
  );
}
