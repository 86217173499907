import { gql } from '@apollo/client';

export const PublishedRosterFragment = gql`
  fragment PublishedRoster on VOLUNTEER_PublishedRosterType {
    activityDate
    rosterNotes
    sessionNotes {
      session {
        sessionId
        name
      }
      notes
    }
    activeRosterings {
      dateRemoved
      volunteer {
        volunteerId
        profile {
          profileId
          userId
          firstName
          lastName
          avatarUrl
        }
        activityRoles {
          name
        }
        privilegeLevel
      }
      sessionRosterings {
        session {
          sessionId
          name
        }
      }
    }
  }
`;
