import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { difference, min } from 'lodash';
import { SearchField } from '../inputs/SearchField';
import { AvailabilityCenterGetActivities_vm_activities as ActivityType } from '../../global/components/AvailabilityCenter/__generated__/AvailabilityCenterGetActivities';
import { AvailabilityHeader } from '../availability-center/AvailabilityHeader';
import { DateAndFrequencySelect } from '../availability-center/DateSelect';
import { DateSelect } from '../availability-center/DateSelect/DateSelect';
import { useOrgInfo } from '../../global/auth/use-org-info/use-org-info';
import { SessionSelectComplex } from './SessionSelectComplex';
import '../../theme/src/multi-date-picker.css';

enum AvailaibiltyStepEnum {
  SELECT_ACTIVITY,
  SELECT_SESSIONS,
  SELECT_DATES,
}

export type ValueProps = {
  activityId?: string;
  sessionIds?: string[];
  dates?: string[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionContainer: {
      paddingTop: '20px',
      paddingBottom: '30px',
      paddingRight: '22px',
    },
    toolbarButton: {
      borderRadius: '8px',
    },
    toolbarButtonSelected: {
      backgroundColor: theme.color.grey.neutral200,
      color: 'white',
      '&:hover': {
        opacity: 0.8,
        backgroundColor: theme.color.grey.neutral200,
      },
    },
    actionButton: {
      textTransform: 'capitalize',
    },
  })
);

export type EnrolmentDataType = {
  [activityId: string]: {
    availabilities: string[];
    unavailabilities: string[];
  };
};

interface Props {
  value: ValueProps;
  onChange: (newValue: ValueProps) => void;
  activities: ActivityType[];
  open: boolean;
  onClose: () => void;
  onSubmit: (value: ValueProps) => void;
  enrolmentData: EnrolmentDataType;
}

const SUB_HEADERS = ['', 'Select relevant sessions', 'Select dates or frequency schedules'];

export function ComplexAvailabilityComponent({
  value,
  onChange,
  activities,
  onSubmit,
  open,
  onClose,
  enrolmentData,
}: Props) {
  const classes = useStyles();
  const [step, setStep] = React.useState(AvailaibiltyStepEnum.SELECT_ACTIVITY);
  const [searchValue, setSearchValue] = React.useState('');
  const handleNext = () => {
    if (step === AvailaibiltyStepEnum.SELECT_DATES) {
      onSubmit(value);
      onClose();
    } else {
      setStep(Math.min(step + 1, AvailaibiltyStepEnum.SELECT_DATES));
    }
  };

  const onSelectSession = (selectedSessionIds: string[]) => {
    onChange({
      ...value,
      sessionIds: selectedSessionIds,
    });
  };

  const onSelectDates = (selectedDates: string[]) => {
    onChange({
      ...value,
      dates: selectedDates,
    });
  };

  const selectedActivity = activities.find((activity) => activity.activityId === value.activityId);

  const recurrences = React.useMemo(() => {
    switch (selectedActivity?.__typename) {
      case 'VOLUNTEER_RecurringActivityType':
        return selectedActivity.schedule.recurrences;
      default:
        return [];
    }
  }, [selectedActivity]);

  const organisation = useOrgInfo();

  const handleCancel = () => {
    onClose();
  };

  const filterSessions = (selectedActivity?.sessions || []).filter((session) =>
    session.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const isNextEnabled = (() => {
    if (step === AvailaibiltyStepEnum.SELECT_ACTIVITY) {
      return selectedActivity;
    }
    if (step === AvailaibiltyStepEnum.SELECT_SESSIONS) {
      return Boolean(value.sessionIds?.length);
    }
    return Boolean(value.dates?.length);
  })();

  React.useEffect(() => {
    setSearchValue('');
  }, [step]);

  const onBack = () => setStep(Math.max(step - 1, 0));
  const occurrences = selectedActivity?.occurrencesBetween || [];

  const selectedActivityId = selectedActivity?.activityId;

  React.useEffect(() => {
    onChange({
      activityId: selectedActivityId,
      dates: [],
      sessionIds: [],
    });
  }, [selectedActivityId]);

  const dateAvailabilities = enrolmentData[selectedActivity?.activityId as string]?.availabilities || [];
  const dateUnavailabilities = enrolmentData[selectedActivity?.activityId as string]?.unavailabilities || [];
  const selectableOccurrences = difference(occurrences, dateAvailabilities);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <AvailabilityHeader header='Activity Availability' subHeader={SUB_HEADERS[step]}>
          <Box display={'flex'} alignItems='center'>
            {[AvailaibiltyStepEnum.SELECT_ACTIVITY, AvailaibiltyStepEnum.SELECT_SESSIONS].includes(step) && (
              <SearchField
                value={searchValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setSearchValue(e.target.value)
                }
              />
            )}
          </Box>
        </AvailabilityHeader>
      </DialogTitle>
      <DialogContent>
        <Box>
          {(() => {
            switch (step) {
              case AvailaibiltyStepEnum.SELECT_ACTIVITY:
                return (
                  <Box>
                    <Typography variant={'body2'} style={{ fontSize: '14px', color: '#606060', marginTop: '-10px' }}>
                      As part of your activity application, <strong>{organisation?.name}</strong> is seeking to
                      understand your availability.
                      <br />
                      <br />
                      Please follow the prompts, and select sessions, times and dates that you can attend.
                      <br />
                      <br />
                      If your activity application is approved, this will be imported directly into your user account.
                      You will be able to update, add and remove availabilities once your application has been approved.
                    </Typography>
                  </Box>
                );
              case AvailaibiltyStepEnum.SELECT_SESSIONS:
                return (
                  <SessionSelectComplex
                    selectedSessionIds={value.sessionIds || []}
                    sessions={filterSessions}
                    onSelectSession={onSelectSession}
                  />
                );
              case AvailaibiltyStepEnum.SELECT_DATES:
                return recurrences.length > 0 ? (
                  <DateAndFrequencySelect
                    startDate={min([selectedActivity?.startDate, selectableOccurrences[0]])}
                    occurrences={selectableOccurrences}
                    recurrences={recurrences}
                    value={value.dates || []}
                    onChange={onSelectDates}
                    key={selectedActivity?.activityId}
                    availabilities={dateAvailabilities}
                    unavailabilities={dateUnavailabilities}
                  />
                ) : (
                  <DateSelect
                    multiple
                    value={value.dates || []}
                    occurrences={selectableOccurrences}
                    availabilities={dateAvailabilities}
                    unavailabilities={dateUnavailabilities}
                    startDate={min([selectedActivity?.startDate, selectableOccurrences[0]])}
                    onChange={onSelectDates}
                  />
                );
              default:
                return null;
            }
          })()}
        </Box>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        {step === AvailaibiltyStepEnum.SELECT_ACTIVITY ? (
          <Button onClick={handleCancel} disableElevation className={classes.actionButton}>
            Cancel
          </Button>
        ) : (
          <Button onClick={onBack} disableElevation className={classes.actionButton}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          disableElevation
          variant='contained'
          color='primary'
          disabled={!isNextEnabled}
          className={classes.actionButton}
        >
          {step === AvailaibiltyStepEnum.SELECT_DATES ? 'Submit' : 'Next'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
