import React from 'react';
import { uniq } from 'lodash';

import { useApiUrl } from '../../../global/config/useApiUrl';
import { useSnackbar } from '../../../global/config/useSnackbar';
import { useCampfireFetch } from '../../../global/network/useCampfireFetch';
import { RosteredStatus, ActivityStatus, ActionType, SessionActionType } from './ActivityTypes';
import { MyElementsUpcomingRostersActivity } from '../user-profile/my-elements/upcoming-rosters-card/__generated__/MyElementsUpcomingRostersActivity';

export type ActivityActionType = MyElementsUpcomingRostersActivity & {
  activityDate: string;
  status: RosteredStatus | ActivityStatus;
  isEnrollable: boolean;
};

export const useActivityActions = (vmVolunteerRefetch?: any) => {
  const { setSnackbar } = useSnackbar();
  const campfireFetch = useCampfireFetch({ defer: true });
  const apiUrl = useApiUrl();
  const [showDialog, setShowDialog] = React.useState(false);
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [showCheckInDialog, setShowCheckInDialog] = React.useState(false);
  const [showCheckOutDialog, setShowCheckOutDialog] = React.useState(false);
  const [CICODialogType, setCICODialogType] = React.useState<'myself' | 'multiple'>('myself');
  const [fullSessionIds, setAllSessionIds] = React.useState<string[]>([]);
  const [activityIdSubmited, setActivityIdSubmited] = React.useState<string>('');
  const [activityDatesSubmited, setActivityDatesSubmited] = React.useState<string[]>([]);
  const selectedActivity = React.useRef<ActivityActionType>();
  const currentAction = React.useRef<ActionType>();

  const onCloseDialog = () => {
    setShowDialog(false);
    setShowUpdateDialog(false);
    setShowCheckInDialog(false);
    selectedActivity.current = undefined;
  };

  const handleAlertClose = () => {
    setShowAlertDialog(false);
    setAllSessionIds([]);
    setActivityDatesSubmited([]);
    setActivityIdSubmited('');
  };

  const onApply = (activityId: string, selectedSesionIds?: string[], selectedActivityDates?: string[]) => {
    return campfireFetch
      .run({
        url: `${apiUrl}/vm/activity/application/save`,
        method: 'post',
        data: {
          activityId,
          activityDates: selectedActivityDates?.map((selectedActivityDate) => ({
            activityDate: selectedActivityDate,
            sessionIds: selectedSesionIds,
          })),
        },
      })
      .then(() => {
        setSnackbar({
          variant: 'success',
          message: 'Applied successfully',
          open: true,
        });
        if (vmVolunteerRefetch) {
          setTimeout(vmVolunteerRefetch, 0);
        }
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          message: 'Cannot apply',
          open: true,
        });
      });
  };

  const onPutOnWaitlist = (activityId: string) => {
    return campfireFetch
      .run({
        url: `${apiUrl}/vm/activity/waitlist/add`,
        method: 'post',
        data: {
          activityId,
        },
      })
      .then(() => {
        setSnackbar({
          variant: 'success',
          message: 'Added to waitlist successfully',
          open: true,
        });
        if (vmVolunteerRefetch) {
          setTimeout(vmVolunteerRefetch, 0);
        }
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          message: 'Cannot be added to waitlist',
          open: true,
        });
      });
  };

  const onRemoveWaitlist = (activityId: string, activityWaitlistingId: string) => {
    return campfireFetch
      .run({
        url: `${apiUrl}/vm/activity/waitlist/remove`,
        method: 'post',
        data: {
          activityId,
          activityWaitlistingId,
          removalReason: '',
        },
      })
      .then(() => {
        setSnackbar({
          variant: 'success',
          message: 'Withdrawn from waitlist successfully',
          open: true,
        });
        if (vmVolunteerRefetch) {
          setTimeout(vmVolunteerRefetch, 0);
        }
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          message: 'Cannot withdraw waitlist',
          open: true,
        });
      });
  };

  const onWithdrawApplication = (activityId: string, activityApplicationId: string) => {
    return campfireFetch
      .run({
        url: `${apiUrl}/vm/activity/application/withdraw`,
        method: 'post',
        data: {
          activityId,
          activityApplicationId,
        },
      })
      .then(() => {
        if (vmVolunteerRefetch) {
          setTimeout(vmVolunteerRefetch, 0);
        }
        setSnackbar({
          variant: 'success',
          message: 'Application withdrawn successfully',
          open: true,
        });
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          open: true,
          message: 'Cannot withdraw application',
        });
      });
  };

  const onWithdraw = (enrolmentId: string) => {
    return campfireFetch
      .run({
        url: `${apiUrl}/vm/activity/enrolment/withdraw`,
        method: 'post',
        data: {
          activityEnrolmentId: enrolmentId,
        },
      })
      .then(() => {
        if (vmVolunteerRefetch) {
          setTimeout(vmVolunteerRefetch, 0);
        }
        setSnackbar({
          variant: 'success',
          message: 'Enrolment withdrawn successfully',
          open: true,
        });
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          open: true,
          message: 'Failed to withdraw enrolment',
        });
      });
  };

  const onAddAttendance = (
    activityId: string,
    selectedDates: string[],
    sessionIds: string[],
    activityEnrolmentId?: string,
    activityAvailabilityId?: string
  ) => {
    return campfireFetch
      .run({
        url: `${apiUrl}/vm/activity/enrolment/attendance/add`,
        method: 'post',
        data: {
          activityId,
          activityEnrolmentId,
          activityAvailabilityId,
          activityDates: selectedDates,
          sessionIds,
          activityRoleIds: [],
        },
      })
      .then(() => {
        if (vmVolunteerRefetch) {
          setTimeout(vmVolunteerRefetch, 0);
        }
        setSnackbar({
          variant: 'success',
          message: 'Success!',
          open: true,
        });
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          message: 'Unsuccessful',
          open: true,
        });
      });
  };

  const onUpdateAvailability = (
    activityId: string,
    selectedDates: string[],
    sessionIds: string[],
    activityEnrolmentId?: string,
    activityAvailabilityId?: string
  ) => {
    return campfireFetch
      .run({
        url: `${apiUrl}/vm/activity/enrolment/availability/save`,
        method: 'post',
        data: {
          activityId,
          activityEnrolmentId,
          activityAvailabilityId,
          activityDates: selectedDates,
          sessionIds,
          activityRoleIds: [],
        },
      })
      .then((response) => {
        if (vmVolunteerRefetch) {
          setTimeout(vmVolunteerRefetch, 0);
        }
        setSnackbar({
          variant: 'success',
          message: 'Updated availability successfully',
          open: true,
        });
        return response.data.data as {
          activityAvailabilityIds: string[];
          activityDates: string[];
          activityId: string;
          fullSessionIds: string[];
        };
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          message: 'Cannot update availability',
          open: true,
        });
      });
  };

  const onRemoveAttendance = (publishedRosteringId: string) => {
    return campfireFetch
      .run({
        url: `${apiUrl}/vm/activity/enrolment/attendance/remove`,
        method: 'post',
        data: {
          publishedRosteringId,
        },
      })
      .then(() => {
        if (vmVolunteerRefetch) {
          setTimeout(vmVolunteerRefetch, 0);
        }
        setSnackbar({
          variant: 'success',
          message: 'Withdrawn from roster successfully',
          open: true,
        });
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          message: 'Cannot withdraw from roster',
          open: true,
        });
      });
  };

  const onSetUnavailability = (enrolmentId: string, activityDate: string) => {
    return campfireFetch
      .run({
        url: `${apiUrl}/vm/activity/enrolment/unavailability/save`,
        method: 'post',
        data: {
          activityEnrolmentId: enrolmentId,
          activityDate: activityDate,
        },
      })
      .then(() => {
        if (vmVolunteerRefetch) {
          setTimeout(vmVolunteerRefetch, 0);
        }
        setSnackbar({
          variant: 'success',
          message: 'Unavailability set successfully',
          open: true,
        });
      })
      .catch(() => {
        setSnackbar({
          variant: 'error',
          message: 'Cannot set unavailability',
          open: true,
        });
      });
  };

  const onAction = ({
    enrolmentId,
    activityApplicationId,
    activityWaitlistingId,
    publishedRosteringId,
  }: {
    enrolmentId?: string;
    activityApplicationId?: string;
    activityWaitlistingId?: string;
    publishedRosteringId?: string;
  }) => async (action: ActionType, activity: ActivityActionType) => {
    switch (action) {
      case 'apply':
        if (activity.isEnrollable) {
          onApply(activity.activityId);
        } else {
          onPutOnWaitlist(activity.activityId);
        }
        return;
      case 'update':
        setShowUpdateDialog(true);
        selectedActivity.current = activity;
        return;
      case 'available': {
        setShowDialog(true);
        currentAction.current = 'available';
        selectedActivity.current = activity;
        return;
      }
      case 'unavailable': {
        if (enrolmentId) {
          onSetUnavailability(enrolmentId, activity.activityDate);
        }
        if (publishedRosteringId && activity.hasOpenRoster) {
          onRemoveAttendance(publishedRosteringId);
        }
        return;
      }
      case 'withdraw': {
        if (activity.status === 'applied' && activityApplicationId) {
          onWithdrawApplication(activity.activityId, activityApplicationId);
        }
        if (activity.status === 'waitlisted' && activityWaitlistingId) {
          onRemoveWaitlist(activity.activityId, activityWaitlistingId);
        }
        if (activity.status === 'joined' && enrolmentId) {
          onWithdraw(enrolmentId);
        }
        if (activity.status === 'joined' && publishedRosteringId) {
          onRemoveAttendance(publishedRosteringId);
        }
        break;
      }
      default:
        break;
    }
  };

  const onSubmit = ({
    enrolmentId,
    availabilityId,
  }: {
    enrolmentId?: string;
    availabilityId?: string;
    publishedRosteringId?: string;
  }) => async (selectedDates: string[], sessionIds: string[]) => {
    if (!selectedActivity.current) {
      return;
    }
    if (
      selectedActivity.current.status === 'rostered' &&
      enrolmentId &&
      availabilityId &&
      selectedActivity.current.hasOpenRoster
    ) {
      onAddAttendance(selectedActivity.current.activityId, selectedDates, sessionIds, enrolmentId, availabilityId);
    } else {
      const res = await onUpdateAvailability(
        selectedActivity.current.activityId,
        selectedDates,
        sessionIds,
        enrolmentId,
        availabilityId
      );
      if (res && selectedActivity.current.hasOpenRoster) {
        const [updateAvailabilityId] = res.activityAvailabilityIds;
        onAddAttendance(
          selectedActivity.current.activityId,
          selectedDates,
          sessionIds,
          enrolmentId,
          updateAvailabilityId
        );
      }

      if (res && res.fullSessionIds?.length > 0 && res.activityId) {
        setAllSessionIds(res.fullSessionIds);
        setActivityIdSubmited(res.activityId);
        setActivityDatesSubmited(res.activityDates);
        setShowAlertDialog(true);
      }
    }
    onCloseDialog();
  };

  const onSessionAction = ({
    enrolmentId,
    availabilityId,
    availableSessionIds,
    rosteringSessionIds,
  }: {
    updatingSessionIds?: string[];
    enrolmentId?: string;
    publishedRosteringId?: string;
    availabilityId?: string;
    availableSessionIds?: string[];
    rosteringSessionIds?: string[];
  }) => async (action: SessionActionType, activity: ActivityActionType, sessionId: string) => {
    selectedActivity.current = activity;
    const addingSessionIds = (currentSessionIds: string[]) => uniq(currentSessionIds.concat(sessionId));
    const removingSessionIds = (currentSessionIds: string[]) =>
      currentSessionIds.filter((currentSessionId) => currentSessionId !== sessionId);

    switch (action) {
      case 'available':
        onSubmit({ enrolmentId, availabilityId })([activity.activityDate], addingSessionIds(availableSessionIds || []));
        break;
      case 'roster':
        onSubmit({ enrolmentId, availabilityId })([activity.activityDate], addingSessionIds(rosteringSessionIds || []));
        break;
      case 'unavailable': {
        const updatingAvailabilitySessionIds = removingSessionIds(availableSessionIds || []);
        if (!updatingAvailabilitySessionIds?.length && enrolmentId) {
          onSetUnavailability(enrolmentId, activity.activityDate);
        } else {
          onSubmit({ enrolmentId, availabilityId })([activity.activityDate], updatingAvailabilitySessionIds);
        }
        break;
      }
      case 'withdraw': {
        const updatingRosteringSessionIds = removingSessionIds(rosteringSessionIds || []);
        if (!updatingRosteringSessionIds?.length && enrolmentId) {
          onSetUnavailability(enrolmentId, activity.activityDate);
        } else {
          onSubmit({ enrolmentId, availabilityId })([activity.activityDate], updatingRosteringSessionIds);
        }
        break;
      }
      default:
        break;
    }
  };

  const onCICOAction = (activity: ActivityActionType, type: 'myself' | 'multiple', isCheckOut: boolean = false) => {
    if(isCheckOut) {
      setShowCheckOutDialog(true)
    }else {
      setShowCheckInDialog(true);
    }
    selectedActivity.current = activity;
    setCICODialogType(type);
  };

  return {
    onAction,
    onSubmit,
    selectedActivity,
    showDialog,
    showAlertDialog,
    handleAlertClose,
    onCloseDialog,
    onSessionAction,
    showUpdateDialog,
    fullSessionIds,
    activityIdSubmited,
    activityDatesSubmited,
    onCICOAction,
    showCheckInDialog,
    showCheckOutDialog,
    setShowCheckOutDialog,
    CICODialogType,
  };
};
