import { gql } from '@apollo/client';

const TaskFormFieldFragment = gql`
  fragment TaskFormField on VOLUNTEER_TaskFieldType {
    __typename
    taskFieldId
    name
    description
    # ... on VOLUNTEER_ShortTextTaskFieldType {}
    # ... on VOLUNTEER_LongTextTaskFieldType {}
    # ... on VOLUNTEER_NumberTaskFieldType {}
    ... on VOLUNTEER_MultipleChoiceTaskFieldType {
      dynamic
      multipleChoiceOptions {
        multipleChoiceFieldOptionId
        name
        order
        allowText
        trigger {
          taskTriggerId
          items {
            taskTriggerItemId
            order
            item {
              ...on VOLUNTEER_TaskItemHeadingType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemFieldType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemContentType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemMediaType {
                taskItemId
              }
            }
          }
        }
      }
    }
    ... on VOLUNTEER_CheckboxTaskFieldType {
      dynamic
      checkboxOptions {
        checkboxFieldOptionId
        name
        order
        allowText
        trigger {
          taskTriggerId
          items {
            taskTriggerItemId
            order
            item {
              ...on VOLUNTEER_TaskItemHeadingType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemFieldType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemContentType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemMediaType {
                taskItemId
              }
            }
          }
        }
      }
    }
    ... on VOLUNTEER_DropdownTaskFieldType {
      dynamic
      dropdownOptions {
        dropdownFieldOptionId
        name
        order
        allowText
        trigger {
          taskTriggerId
          items {
            taskTriggerItemId
            order
            item {
              ...on VOLUNTEER_TaskItemHeadingType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemFieldType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemContentType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemMediaType {
                taskItemId
              }
            }
          }
        }
      }
    }
    ... on VOLUNTEER_AttachmentTaskFieldType {
      allowMultiple
    }
    ... on VOLUNTEER_DateTaskFieldType {
      isExpiry
    }
    ... on VOLUNTEER_AddressTaskFieldType {
      allowManual
    }
    # ... on VOLUNTEER_PhoneTaskFieldType {}
    # ... on VOLUNTEER_EmailTaskFieldType {}
    ... on VOLUNTEER_QuizTaskFieldType {
      allowRetry
      dynamic
      quizTaskFieldOptions {
        quizTaskFieldOptionId
        name
        order
        allowText
        trigger {
          taskTriggerId
          items {
            taskTriggerItemId
            order
            item {
              ...on VOLUNTEER_TaskItemHeadingType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemFieldType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemContentType {
                taskItemId
              }

              ...on VOLUNTEER_TaskItemMediaType {
                taskItemId
              }
            }
          }
        }
      }
      quizTaskFieldCorrect {
        quizTaskFieldCorrectId
        correctText
        quizTaskFieldOption {
          quizTaskFieldOptionId
        }
      }
    }
    # ... on VOLUNTEER_SignatureTaskFieldType {}
  }
`;

const TASK_ITEM_TRIGGER_FRAGMENT = gql`
  fragment TaskItemTrigger on VOLUNTEER_TaskTriggerItemType {
    taskTriggerItemId
    order
    trigger {
      taskTriggerId
      ... on VOLUNTEER_DynamicMultipleChoiceFieldTriggerType {
        triggerOption {
          multipleChoiceFieldOptionId
        }
      }
      ...on VOLUNTEER_DynamicCheckboxFieldTriggerType {
        triggerOption {
          checkboxFieldOptionId
        }
      }
      ...on VOLUNTEER_DynamicDropdownFieldTriggerType {
        triggerOption {
          dropdownFieldOptionId
        }
      }
      ...on VOLUNTEER_DynamicQuizFieldTriggerType {
        triggerOption {
          quizTaskFieldOptionId
        }
      }
    }
  }
`;
export const TASK_FORM_ITEM_FRAGMENT = gql`
  fragment TaskFormItem on VOLUNTEER_TaskItemType {
    ... on VOLUNTEER_TaskItemHeadingType {
      taskItemId
      order
      heading
      triggerItem {
        ...TaskItemTrigger
      }
    }
    ... on VOLUNTEER_TaskItemContentType {
      taskItemId
      order
      content
      triggerItem {
        ...TaskItemTrigger
      }
      attachments {
        attachmentId
        fileType
        url
        name
      }
    }
    ... on VOLUNTEER_TaskItemFieldType {
      ...BasicTaskItemField
    }

    ... on VOLUNTEER_TaskItemMediaType {
      ...BasicTaskItemMedia
      media {
        ... on VOLUNTEER_YoutubeMediaType {
          name
          description
          videoId
        }
        ... on VOLUNTEER_VimeoMediaType {
          name
          description
          videoId
        }
      }
    }
  }

  fragment BasicTaskItemField on VOLUNTEER_TaskItemFieldType {
    taskItemId
    order
    optional
    triggerItem {
      ...TaskItemTrigger
    }
    field {
      ...TaskFormField
    }
  }

  fragment BasicTaskItemMedia on VOLUNTEER_TaskItemMediaType {
    taskItemId
    order
    triggerItem {
      ...TaskItemTrigger
    }
  }

  ${TaskFormFieldFragment}
  ${TASK_ITEM_TRIGGER_FRAGMENT}
`;

export const COMPLETED_TASK_FRAGMENT_WITH_TASK = gql`
  fragment CompletedTaskWithTask on VOLUNTEER_CompletedTaskType {
    completedTaskId
    dateLastUpdated
    dateSubmitted
    task {
      taskId
      title
      description
      order
      cake {
        cakeType
        title
      }
    }
    status
    taskFieldValues {
      ...TaskFormFieldValue
    }
    dateManagerUpdated
    managerUpdatedBy {
      managerId
      profile {
        profileId
        preferredName
        lastName
        userId
      }
    }
  }

  fragment TaskFormFieldValue on VOLUNTEER_TaskFieldValueType {
    __typename
    taskFieldValueId
    field {
      taskFieldId
      ...TaskFormField
    }
    ... on VOLUNTEER_AddressTaskFieldValueType {
      address {
        placesAddress {
          placesId
          formatted
          description
          latLong {
            lat
            long
          }
        }
        manualAddress {
          lineOne
          lineTwo
          suburb
          state
          postcode
          country
          humanReadable
        }
      }
    }
    ... on VOLUNTEER_AttachmentTaskFieldValueType {
      attachments {
        attachmentId
        fileType
        url
        name
      }
    }
    ... on VOLUNTEER_CheckboxTaskFieldValueType {
      otherText
      checkboxTaskFieldOptions {
        checkboxFieldOptionId
        name
        allowText
        order
      }
    }
    ... on VOLUNTEER_DropdownTaskFieldValueType {
      otherText
      dropdownTaskFieldOption {
        dropdownFieldOptionId
        name
        allowText
        order
      }
    }
    ... on VOLUNTEER_PhoneTaskFieldValueType {
      contactNumber
    }
    ... on VOLUNTEER_ShortTextTaskFieldValueType {
      textValue
    }
    ... on VOLUNTEER_DateTaskFieldValueType {
      dateValue
    }
    ... on VOLUNTEER_MultipleChoiceTaskFieldValueType {
      otherText
      multipleChoiceTaskFieldOption {
        multipleChoiceFieldOptionId
        name
        allowText
        order
      }
    }
    ... on VOLUNTEER_QuizTaskFieldValueType {
      otherText
      quizTaskFieldOption {
        quizTaskFieldOptionId
        name
        allowText
        order
      }
    }

    ... on VOLUNTEER_EmailTaskFieldValueType {
      email
    }

    ... on VOLUNTEER_LongTextTaskFieldValueType {
      textValue
    }
    ... on VOLUNTEER_NumberTaskFieldValueType {
      numericValue
    }
    ... on VOLUNTEER_SignatureTaskFieldValueType {
      signatureUrl
    }
  }
`;

export const COMPLETED_TASK_FRAGMENT = gql`
  fragment CompletedTask on VOLUNTEER_CompletedTaskType {
    completedTaskId
    dateLastUpdated
    dateSubmitted
    task {
      taskId
      title
      description
      order
      cake {
        cakeType
      }
    }
    status
    taskFieldValues {
      ...TaskFormFieldValue
    }
    dateManagerUpdated
    managerUpdatedBy {
      managerId
      profile {
        profileId
        preferredName
        lastName
        userId
      }
    }
  }
  ${TASK_FORM_ITEM_FRAGMENT}

  fragment TaskFormFieldValue on VOLUNTEER_TaskFieldValueType {
    __typename
    taskFieldValueId
    field {
      taskFieldId
      ...TaskFormField
    }
    ... on VOLUNTEER_AddressTaskFieldValueType {
      address {
        placesAddress {
          placesId
          formatted
          description
          latLong {
            lat
            long
          }
        }
        manualAddress {
          lineOne
          lineTwo
          suburb
          state
          postcode
          country
          humanReadable
        }
      }
    }
    ... on VOLUNTEER_AttachmentTaskFieldValueType {
      attachments {
        attachmentId
        fileType
        url
        name
      }
    }
    ... on VOLUNTEER_CheckboxTaskFieldValueType {
      otherText
      checkboxTaskFieldOptions {
        checkboxFieldOptionId
        name
        allowText
        order
      }
    }
    ... on VOLUNTEER_DropdownTaskFieldValueType {
      otherText
      dropdownTaskFieldOption {
        dropdownFieldOptionId
        name
        allowText
        order
      }
    }
    ... on VOLUNTEER_PhoneTaskFieldValueType {
      contactNumber
    }
    ... on VOLUNTEER_ShortTextTaskFieldValueType {
      textValue
    }
    ... on VOLUNTEER_DateTaskFieldValueType {
      dateValue
    }
    ... on VOLUNTEER_MultipleChoiceTaskFieldValueType {
      otherText
      multipleChoiceTaskFieldOption {
        multipleChoiceFieldOptionId
        name
        allowText
        order
      }
    }
    ... on VOLUNTEER_QuizTaskFieldValueType {
      otherText
      quizTaskFieldOption {
        quizTaskFieldOptionId
        name
        allowText
        order
      }
    }

    ... on VOLUNTEER_EmailTaskFieldValueType {
      email
    }

    ... on VOLUNTEER_LongTextTaskFieldValueType {
      textValue
    }
    ... on VOLUNTEER_NumberTaskFieldValueType {
      numericValue
    }
    ... on VOLUNTEER_SignatureTaskFieldValueType {
      signatureUrl
    }
  }
`;

export const GET_ALL_COMPLETED_TASKS = gql`
  query GetAllCompletedTasks($userId: String!) {
    vm {
      profile(userId: $userId) {
        firstName
        lastName
        preferredName
        dateOfBirth
        contactNumber
        programs {
          name
        }
        completedTasks {
          ...CompletedTask
        }
      }
    }
  }

  ${COMPLETED_TASK_FRAGMENT}
`;
