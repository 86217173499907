import React from 'react';
import { Box } from '@material-ui/core';
import { Help, CheckCircle, Cancel } from '@material-ui/icons';

import { AvatarName, VolunteerProfile } from '../../../../../../common/AvatarName';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';
import { Volunteer } from '../roster-types';
import {
  GetRosterScreenRosterData_vm_draftRoster_draftRosterings_volunteer_profile as DraftRosteringProfile,
  GetRosterScreenRosterData_vm_draftRoster_publishedRoster_rosterings_volunteer_profile as PublishedRosteringProfile,
} from '../__generated__/GetRosterScreenRosterData';
import { AvailabilityTrigger } from './AvailabilityTrigger';
import { useCampfireQuery } from '../../../../../../global/network/useCampfireQuery';

import { ROSTER_VOLUNTEER_INFO } from '../rosters-model.gql';
import {
  GetRosterVolunteerInfo,
  GetRosterVolunteerInfoVariables,
  GetRosterVolunteerInfo_vm_volunteer_activityRoles_tasks as ActivityRoleTasks,
} from '../__generated__/GetRosterVolunteerInfo';

const getName = (profile: any) => `${profile?.preferredName} ${profile?.lastName}`;

type RosterProfileType = DraftRosteringProfile | PublishedRosteringProfile;

type VolunteerCellProps = {
  roster: Volunteer;
  handleRoleBadgeClick?: (roleTasks: ActivityRoleTasks[], profile: RosterProfileType) => void;
  isRosterCompleted?: boolean;
  onChangeAvailability?: (volunteerId: string) => void;
  hideActions?: boolean;
  printOnly?: boolean;
};

const RosterStatusIndicator = ({ attending }: { attending: boolean | null }) =>
  attending === null ? (
    <Help fontSize='small' htmlColor='#00000024' style={{ fontSize: 15, background: 'white', borderRadius: '50%' }} />
  ) : attending ? (
    <CheckCircle
      fontSize='small'
      htmlColor='#7DD268'
      style={{ fontSize: 15, background: 'white', borderRadius: '50%' }}
    />
  ) : (
    <Cancel fontSize='small' color='error' style={{ fontSize: 15, background: 'white', borderRadius: '50%' }} />
  );

export const VolunteerCell = ({
  roster,
  handleRoleBadgeClick,
  isRosterCompleted,
  onChangeAvailability,
  hideActions,
  printOnly,
}: VolunteerCellProps) => {
  const { theme } = useCampfireTheme();

  const { data: volunteerInfoData } = useCampfireQuery<GetRosterVolunteerInfo, GetRosterVolunteerInfoVariables>(
    ROSTER_VOLUNTEER_INFO,
    {
      options: {
        variables: {
          volunteerId: roster.volunteerId,
        },
      },
    }
  );
  const activityRoles = volunteerInfoData?.vm.volunteer?.activityRoles || [];

  const activityRolesParsed = activityRoles.map((role) => {
    const isCompletedTask = role.tasks.every(
      (task) => task.completedTasks.filter((cTask) => cTask.profile?.profileId === roster.profile.profileId).length > 0
    );
    return { ...role, isCompletedTask };
  });

  const onRoleBadgeClick = (activityRoleId: string) => {
    if (!activityRoles) return;
    const roleTasks = activityRoles.find((item) => item.activityRoleId === activityRoleId)?.tasks || [];
    if (handleRoleBadgeClick) {
      handleRoleBadgeClick(roleTasks, roster.profile);
    }
  };

  return (
    <Box
      display='flex'
      alignItems='center'
      width='100%'
      justifyContent={printOnly ? 'center' : 'space-between'}
      height='100%'
    >
      {printOnly ? (
        <AvatarName
          avatarUrl={roster.profile.avatarUrl}
          name={`${getName(roster?.profile)}`}
          width='200px'
          avatarColor={!roster.enrolled ? theme.color.rosters.rostered : undefined}
          statusIndicator={<RosterStatusIndicator attending={roster.attending} />}
          activityRoles={activityRolesParsed}
        />
      ) : (
        <>
          <VolunteerProfile
            profileId={roster.profile.profileId}
            userId={roster.profile.userId}
            lastName={roster.profile.lastName}
            preferredName={roster.profile.preferredName}
            name={`${getName(roster?.profile)}`}
            avatarUrl={roster.profile.avatarUrl}
            avatarColor={!roster.enrolled ? theme.color.rosters.rostered : undefined}
            activityRoles={activityRolesParsed}
            noTrim
            tooltipAll={false}
            statusIndicator={<RosterStatusIndicator attending={roster.attending} />}
            onRoleBadgeClick={onRoleBadgeClick}
          />
          {roster.enrolled && !hideActions && !isRosterCompleted && volunteerInfoData?.vm.volunteer?.volunteerId && (
            <AvailabilityTrigger
              onClick={() => {
                if (onChangeAvailability) onChangeAvailability(roster.volunteerId);
              }}
              unavailable={!roster.unavailable}
              volunteerId={volunteerInfoData?.vm.volunteer?.volunteerId}
            />
          )}
        </>
      )}
    </Box>
  );
};
