import React, { FunctionComponent } from 'react';
import { CheckRounded } from '@material-ui/icons';
import { Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';

const IncomingVolunteerConfirmationDialog: FunctionComponent<{
  title: string;
  body: string;
  id: string;
  disabled?: boolean;
  open: boolean;
  approveButtonText?: string;
  handleCloseClick: (open: boolean) => void;
  handleApproveClick: (id: string) => void;
}> = ({ title, body, open, id, handleCloseClick, approveButtonText, handleApproveClick, disabled }) => {
  return (
    <Dialog open={open} onClose={() => handleCloseClick(false)}>
      <Typography
        style={{
          fontWeight: 600,
          fontSize: '19px',
          display: 'flex',
          alignItems: 'center',
          color: '#444',
          padding: '1em 1.2em 0em 1.2em',
        }}
      >
        {title}
      </Typography>
      <DialogContent>
        <Typography gutterBottom style={{ fontSize: '15px', color: '#606060' }}>
          {body}
        </Typography>
      </DialogContent>
      <DialogActions>
        <TabletButton
          disabled={disabled}
          data-track='fs-incVol-approve-dialog-cancel-button'
          size='medium'
          onClick={() => handleCloseClick(false)}
        >
          {'Cancel'}
        </TabletButton>
        <TabletButton
          disabled={disabled}
          data-track='fs-incVol-approve-dialog-approve-button'
          size='medium'
          onClick={() => handleApproveClick(id)}
          startIcon={<CheckRounded />}
          variant='contained'
          color='primary'
          autoFocus
        >
          {approveButtonText ?? 'Approve'}
        </TabletButton>
      </DialogActions>
    </Dialog>
  );
};

export { IncomingVolunteerConfirmationDialog };
