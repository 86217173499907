import { HoverLink } from '@campfire/hover-link';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { Typography, ListItemText, ListItem, ListItemIcon, Box, Divider, Grid } from '@material-ui/core';
import { Event, Link as LinkIcon } from '@material-ui/icons';
import React, { memo } from 'react';
import { VolunteerCommonProfileActivityApplication } from '../__generated__/VolunteerCommonProfileActivityApplication';
import { useVolunteerCommonProfileContext } from '../VolunteerCommonProfileContext';

export const VolunteerActivityApplicationsSection = memo(
  ({ activityApplications }: { activityApplications: VolunteerCommonProfileActivityApplication[] }) => {
    const {
      volunteerNameOptions: { preferredName },
    } = useVolunteerCommonProfileContext();
    return (
      <Grid container spacing={2} direction='column' style={{ position: 'relative' }}>
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <Typography
            style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center', color: '#333' }}
          >
            <Event style={{ fontSize: '14px', paddingRight: '5px' }} /> Activity Applications
          </Typography>
          <Typography variant='body2' style={{ color: '#464646' }} align='left'>
            Activities that {preferredName} has applied for
            <hr style={{ borderTop: '.5px solid #e7e7e7', margin: '20px 0' }} />
          </Typography>
          {activityApplications
            .filter((activityApplication) => activityApplication.activityApplicationId !== null)
            .map((activityApplication, idx) => (
              <HoverLink
                data-track='fs-volPrf-activity-application-item'
                key={activityApplication.activityApplicationId}
                hoverColor='primary'
                to={`/management/activity-management?activityId=${activityApplication.activity.activityId}&programId=${activityApplication.activity.program.programId}`}
                disableUnderline
              >
                <TitularTooltip description='Open Activity Overview'>
                  <Box key={activityApplication.activityApplicationId}>
                    <ListItem key={activityApplication.activityApplicationId} button>
                      <ListItemIcon>
                        <LinkIcon color='disabled' />
                      </ListItemIcon>
                      <ListItemText
                        primary={activityApplication.activity.name}
                        secondary={activityApplication.activity.program.name}
                      />
                    </ListItem>
                    {idx !== activityApplications.length - 1 && <Divider style={{ marginTop: '8px' }} />}
                  </Box>
                </TitularTooltip>
              </HoverLink>
            ))}
          {activityApplications.length === 0 && (
            <Typography
              variant='body2'
              style={{ fontSize: '13px', color: '#494949', textAlign: 'center', padding: '2rem 0' }}
            >
              No results found
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }
);
