import { Pane } from '@campfire/pane';
import { Close, EventAvailable } from '@material-ui/icons';
import { Box, Button, Typography } from '@material-ui/core';
import { Cake, CakeContent } from '@campfire/cake';
import React from 'react';
import { useHistory } from 'react-router';
import { SimpleAvailabilityComponent } from '../../common/simple-availability-select/SimpleAvailabilitySelect';
import { useUser } from '../auth/useUser';

const SUYPSimpleAvailability = () => {
  const history = useHistory();
  const { user } = useUser();

  return (
    <Pane style={{ width: '100%', height: '100%', backgroundColor: '#f1f1f1' }}>
      <Box
        overflow='hidden auto'
        bgcolor='#f1f1f1'
        display='flex'
        flex='1 1 auto'
        flexDirection='column'
        width={1}
        paddingTop={2}
        paddingBottom={8}
        paddingX={1}
        boxSizing='border-box'
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Box width={1} display='flex' justifyContent='center'>
          <Box width={1} maxWidth={720} marginBottom={3}>
            <Cake>
              <Box style={{ padding: '20px 30px 10px 30px' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '19px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#444',
                    }}
                  >
                    <EventAvailable style={{ fontSize: '22px', paddingRight: '5px' }} /> Availability
                  </Typography>
                  <Button onClick={() => history.push('/')}>
                    <Close style={{ color: '#666' }} />
                  </Button>
                </Box>
                <Typography variant={'body2'} style={{ fontSize: '13px', color: '#808080' }}>
                  Choose your available days and times from the table below. <br />
                  <br />
                  You can return to this window and update this availability up until your application has been
                  approved.
                </Typography>
              </Box>
              <CakeContent style={{ padding: '8px 0px 0px 0px' }}>
                <Box>
                  <SimpleAvailabilityComponent profileId={user.profileId} editing />
                </Box>
              </CakeContent>
            </Cake>
          </Box>
        </Box>
      </Box>
    </Pane>
  );
};

export { SUYPSimpleAvailability };
