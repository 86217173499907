import React from 'react';
import { ClickAwayListener, IconButton, Tooltip, Button, Box } from '@material-ui/core';
import { ExpandMore, Close, Restore, DateRange } from '@material-ui/icons';
import { makeStyles, createStyles } from '@material-ui/styles';
import { StringParam, useQueryParams } from 'use-query-params';
import { BulkRostering } from '../../../../../../common/availability-center/BulkRostering/BulkRostering';

const useStyles = makeStyles(() =>
  createStyles({
    availabilityButton: {
      textTransform: 'none',
      borderRadius: 6,
      fontSize: '12px',
    },
    tooltip: {
      background: '#616161',
      borderRadius: 6,
    },
    arrow: {
      '&::before': {
        background: '#616161',
      },
    },
  })
);

interface Props {
  onClick: () => void;
  unavailable: boolean;
  volunteerId: string;
}

export function AvailabilityTrigger({ onClick, unavailable, volunteerId }: Props) {
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const classes = useStyles();

  const title = unavailable ? 'Mark as unavailable' : 'Remove unavailability';
  const color = unavailable ? '#ff9c9c' : '#FFFFFF';

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event: any) => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    onClick();
    handleTooltipClose();
  };

  const [{ activityId }] = useQueryParams({
    activityId: StringParam,
  });

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            disableFocusListener
            disableHoverListener
            disableTouchListener
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
            interactive
            arrow
            PopperProps={{
              disablePortal: true,
            }}
            open={open}
            onClose={handleTooltipClose}
            title={
              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Button
                  onClick={handleClick}
                  className={classes.availabilityButton}
                  style={{ color: color }}
                  startIcon={unavailable ? <Close style={{ fontSize: 14 }} /> : <Restore style={{ fontSize: 14 }} />}
                >
                  {title}
                </Button>
                {unavailable && (
                  <Button
                    onClick={() => {
                      setDialogOpen(true);
                      setOpen(false);
                    }}
                    className={classes.availabilityButton}
                    style={{ color: '#F9F9F9' }}
                    startIcon={<DateRange style={{ fontSize: 14 }} />}
                  >
                    Bulk Roster
                  </Button>
                )}
              </Box>
            }
          >
            <IconButton onClick={handleTooltipOpen}>
              <ExpandMore />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
      {dialogOpen && activityId && (
        <BulkRostering
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          activityId={activityId}
          volunteerId={volunteerId}
        />
      )}
    </>
  );
}
