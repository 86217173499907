import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { QueryBuilderOutlined, PanToolOutlined, RoomOutlined, Star, DateRange } from '@material-ui/icons';
import { unpackToDate } from '@campfire/hot-date';
import { useVolunteerCommonProfileContext } from '../VolunteerCommonProfileContext';
import { Statistics } from '../../../../common/statistics/Statistics';

export const VolunteerImpactSection = () => {
  const {
    volunteer,
    volunteerNameOptions: { preferredName },
  } = useVolunteerCommonProfileContext();

  const attended = useMemo(() => {
    return volunteer?.volunteerStatistics.attended ?? 0;
  }, [volunteer]);

  const hours = useMemo(() => {
    const operationalHours = volunteer?.volunteerStatistics.sessionReportStatistics.operationalHours ?? 0;
    const additionalHours = parseFloat(`${volunteer?.profile.additionalVolunteerHours ?? '0'}`);
    return operationalHours + additionalHours;
  }, [volunteer]);

  const roundedHours = Math.round(hours * 100) / 100;

  const locations = useMemo(() => {
    return volunteer?.volunteerStatistics.sessionReportStatistics.activityLocations.length ?? 0;
  }, [volunteer]);

  const yearsService = useMemo(() => {
    const startDateTime = unpackToDate(volunteer?.dateCreated);
    const diffNow = startDateTime.diffNow(['years', 'months', 'days']).toObject();
    const diffNowYears = +Math.abs(diffNow.years || 0).toFixed();
    return diffNowYears;
  }, [volunteer]);

  return (
    <Grid container spacing={2} direction='column' style={{ position: 'relative' }}>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <Typography style={{ fontWeight: 600, fontSize: '18px', display: 'flex', alignItems: 'center', color: '#333' }}>
          <Star style={{ fontSize: '14px', paddingRight: '5px' }} /> Impact
        </Typography>
        <Typography variant='body2' style={{ color: '#464646' }} align='left'>
          {preferredName}&apos;s impact
          <hr style={{ borderTop: '.5px solid #e7e7e7', margin: '20px 0' }} />
        </Typography>
        {volunteer && (
          <Box mt='24px'>
            <Statistics
              value={roundedHours}
              icon={QueryBuilderOutlined}
              title='Hours'
              subtitle='of volunteering'
              color='#7570EA'
              simple
            />
            <Statistics
              value={attended}
              icon={PanToolOutlined}
              title='Activities'
              subtitle='attended'
              color='#FE6F40'
              simple
            />
            <Statistics
              value={locations}
              icon={RoomOutlined}
              title='Locations'
              subtitle='visited'
              color='#60B386'
              simple
            />
            {yearsService >= 1 && (
              <Statistics
                value={yearsService}
                icon={DateRange}
                title='Years'
                subtitle='of service'
                color='#0B627F'
                simple
              />
            )}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
