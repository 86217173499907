import classNames from 'classnames';
import { Pane } from '@campfire/pane';
import { Grid, Typography, List, ListItem, ListItemText, Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useAdminConsoleRoutesMap } from './admin-console-routes-map';
import { SIDE_NAV_WIDTH } from '../AdminConsoleScreen';

const AdminConsoleSideNav = withRouter(({ match }: RouteComponentProps) => {
  const { routeGroups } = useAdminConsoleRoutesMap(match.path);

  return (
    <Grid
      item
      container
      style={{
        overflow: 'hidden',
        display: 'flex',
        minWidth: SIDE_NAV_WIDTH,
        maxWidth: SIDE_NAV_WIDTH,
        flexDirection: 'column',
      }}
    >
      <Grid item style={{ display: 'flex', flex: 1, width: '100%', overflow: 'hidden' }}>
        <Pane style={{ width: '100%' }}>
          <Box style={{ width: '100%', paddingBottom: '16px' }}>
            <List disablePadding>
              {routeGroups?.map((group) => (
                <React.Fragment>
                  <ListItem style={{ paddingLeft: '24px' }}>
                    <ListItemText
                      primary={
                        <Typography style={{ whiteSpace: 'normal', fontWeight: 'bold' }} variant='body1'>
                          {group.heading}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {group.routeOptions
                    .filter((option) => option.enabled !== false)
                    .map((option) => (
                      <AdminConsoleSideNavItems to={option.route} label={option.label} key={option.label} />
                    ))}
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Pane>
      </Grid>
    </Grid>
  );
});

export { AdminConsoleSideNav };

interface AdminConsoleSideNavItemsProps extends RouteComponentProps {
  label: string;
  to: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      textDecoration: 'none',
      textDecorationColor: 'none',
      color: 'inherit',
      paddingLeft: 32,
      borderLeft: `4px solid transparent`,
    },
    activeListItem: {
      backgroundColor: theme.color.grey.border,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
    activeListItemText: {
      fontWeight: 500,
    },
  })
);
const AdminConsoleSideNavItems = withRouter(({ location, history, to, label }: AdminConsoleSideNavItemsProps) => {
  const classes = useStyles();
  const active = location.pathname.includes(to.toLowerCase());
  return label ? (
    <ListItem
      disableGutters
      button
      onClick={() => history.push(to)}
      className={classNames(classes.listItem, { [classes.activeListItem]: active })}
    >
      <ListItemText
        style={{
          flex: label === 'Volunteer Profile' ? 'none' : '1 1 auto',
          paddingRight: label === 'Volunteer Profile' ? '7px' : '0',
        }}
        primary={
          <Typography variant='body1' className={classNames({ [classes.activeListItemText]: active })}>
            {label}
          </Typography>
        }
      />
    </ListItem>
  ) : null;
});
