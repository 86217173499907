import {
  Dialog,
  DialogTitle,
  Grid,
  Box,
  Typography,
  IconButton,
  DialogActions,
  DialogContent,
  Badge,
} from '@material-ui/core';
import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import AuthCode from 'react-auth-code-input';
import { CheckCircle, Close as CloseIcon, ErrorRounded, Launch } from '@material-ui/icons';
import { TabletButton } from '@campfire/tablet-button';
import { useCampfireTheme } from '../../../../../theme/useCampfireTheme';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';

import '../../../../../theme/src/otp-input.css';

interface AddVolunteerDialogProps {
  open: boolean;
  handleClose: () => void;
  onSuccess: () => void;
  orgForce?: boolean;
}

export const AddAuthenticatorAppDialog = (props: AddVolunteerDialogProps) => {
  const { open, handleClose, orgForce, onSuccess } = props;
  const { theme } = useCampfireTheme();
  const [step, setStep] = useState(orgForce ? -1 : 0);
  const [otp, setOtp] = useState<string>();
  const [displayManualKey, setDisplayManualKey] = useState(false);
  const [totpURL, setTOTPUrl] = useState<string | null>(null);
  const [totpSecret, setTOTPSecret] = useState<string | null>(null);
  const [authError, setAuthError] = useState(0);
  const apiUrl = useApiUrl();
  const goBack = () => {
    setStep(orgForce ? (step !== -1 ? step - 1 : step) : step !== 0 ? step - 1 : step);
  };

  const { run } = useCampfireFetch({
    defer: true,
  });

  const totpAdd = () => {
    run({
      method: 'get',
      url: `${apiUrl}/im/user/totp/add`,
    }).then((response) => {
      if (response.ok) {
        const data = response.data.data as {
          url: string;
          secretEncoded: string;
        };
        setTOTPUrl(data.url);
        setTOTPSecret(data.secretEncoded);
      }
    });
  };

  const totpConfirm = () => {
    run({
      method: 'post',
      data: {
        key: otp,
      },
      url: `${apiUrl}/im/user/totp/confirm`,
    }).then((response) => {
      if (response.ok) {
        setStep(3);
      }
      if (response.status === 401) {
        setAuthError(authError + 1);
      }
    });
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth='sm' fullWidth>
      <DialogTitle style={{ paddingBottom: 0 }}>
        <Grid container justify='space-between'>
          <Grid item>
            <Box alignItems='center'>
              <Typography
                style={{
                  fontSize: '23px',
                  fontWeight: 550,
                  marginRight: '16px',
                  color: theme.color.grey.neutral400,
                  paddingLeft: '5px',
                }}
              >
                Add Authenticator App
                {orgForce && step === -1 && (
                  <Badge
                    style={{
                      backgroundColor: '#D93A00',
                      color: '#ffffff',
                      padding: '6px',
                      marginLeft: '15px',
                      borderRadius: '3px',
                      height: 'fit-content',
                    }}
                  >
                    <ErrorRounded style={{ fontSize: '16px' }} />
                    <Typography style={{ fontSize: '12px', paddingLeft: '4px', fontWeight: 500, paddingRight: '2px' }}>
                      Critical
                    </Typography>
                  </Badge>
                )}
              </Typography>
            </Box>
          </Grid>
          {!orgForce && (
            <Grid item>
              <Box display='flex' alignItems='center'>
                <IconButton key='close' aria-label='Close' color='inherit' onClick={() => handleClose()}>
                  <CloseIcon color='action' />
                </IconButton>
              </Box>
            </Grid>
          )}
        </Grid>
        {step === -1 && (
          <Box pr={3} mt={0}>
            <Typography
              style={{ fontSize: 14, paddingLeft: '5px', paddingRight: '10px', paddingTop: '10px' }}
              color='textSecondary'
            >
              Your organisation has made a change to required authentication methods for accessing Volaby.
              <br />
              <br />
              You must setup an additional authentication method to continue accessing Volaby.
              <br />
              <br />
              Please press <strong>&apos;Next&apos;</strong> to continue.
            </Typography>
          </Box>
        )}
        {step === 0 && (
          <Box pr={3} mt={0}>
            <Typography
              style={{ fontSize: 12, paddingLeft: '5px', paddingRight: '10px', paddingTop: '5px' }}
              color='textSecondary'
            >
              Follow the prompts to add an additional authentication method.
              <br />
              {!orgForce ? 'Hit &apos;Cancel&apos; to close this window.' : ''}
            </Typography>
          </Box>
        )}
        {step === 1 && (
          <Box>
            <Typography
              style={{
                fontSize: '12px',
                paddingLeft: '5px',
                lineHeight: '17px',
                color: theme.color.grey.neutral300,
                paddingTop: '5px',
              }}
            >
              Follow the prompts to add an additional authentication method.
              <br />
              <br />
            </Typography>
          </Box>
        )}
        {step === 2 && (
          <Box>
            <Typography
              style={{
                fontSize: '12px',
                paddingLeft: '5px',
                lineHeight: '17px',
                color: theme.color.grey.neutral300,
                paddingTop: '5px',
              }}
            >
              Follow the prompts to add an additional authentication method.
              <br />
              <br />
            </Typography>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        {step === 0 && (
          <Box style={{ gap: '15px', justifyContent: 'center', paddingTop: '10px' }}>
            <Typography style={{ fontSize: '14px', paddingLeft: '5px', color: theme.color.grey.neutral400 }}>
              To maintain account security, Volaby uses a timed code to secure your account.
              <br />
              <br />
              Download a free authenticator app on your mobile device, using the links below. Return to this screen once
              downloaded and press &apos;Next&apos;.
            </Typography>
            <Box style={{ paddingTop: '15px', textAlign: 'center' }}>
              <TabletButton
                size='small'
                variant='contained'
                color='primary'
                aria-controls='options-menu'
                aria-haspopup='true'
                endIcon={<Launch />}
                onClick={() => {
                  window.open('https://support.google.com/accounts/answer/1066447?hl=en', '_blank');
                }}
                style={{
                  height: '100%',
                  border: '1px solid transparent',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                  padding: '5px 10px',
                  marginTop: '10px',
                  color: '#fff',
                }}
              >
                Google Authenticator
              </TabletButton>
              <br />
              <TabletButton
                size='small'
                variant='contained'
                color='primary'
                onClick={() => {
                  window.open('https://www.microsoft.com/en-au/security/mobile-authenticator-app', '_blank');
                }}
                aria-controls='options-menu'
                aria-haspopup='true'
                endIcon={<Launch />}
                style={{
                  height: '100%',
                  border: '1px solid transparent',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                  padding: '5px 10px',
                  marginTop: '10px',
                  color: '#fff',
                }}
              >
                Microsoft Authenticator
              </TabletButton>
              <br />
              <TabletButton
                size='small'
                variant='contained'
                color='primary'
                onClick={() => {
                  window.open('https://www.authy.com/download/', '_blank');
                }}
                aria-controls='options-menu'
                aria-haspopup='true'
                endIcon={<Launch />}
                style={{
                  height: '100%',
                  border: '1px solid transparent',
                  boxSizing: 'border-box',
                  borderRadius: '4px',
                  padding: '5px 10px',
                  marginTop: '10px',
                  color: '#fff',
                }}
              >
                Authy
              </TabletButton>
              <br />
            </Box>
          </Box>
        )}
        {step === 1 && (
          <Box style={{ gap: '15px', justifyContent: 'center', paddingTop: '10px' }}>
            <Typography
              style={{ fontSize: '14px', paddingLeft: '5px', color: theme.color.grey.neutral400, textAlign: 'center' }}
            >
              {totpURL ? <QRCode value={totpURL} size={175} /> : null}
              <br />
              <br />
              Scan the above QR code in your authentication app.
              <br />
              Click &apos;Next&apos; when scanned and your rotating code is displaying.
              <br />
              <br />
              Not working?
              <Typography
                onClick={() => setDisplayManualKey(!displayManualKey)}
                style={{
                  fontSize: '14px',
                  paddingLeft: '5px',
                  color: theme.palette.primary.main,
                  display: 'inline-block',
                }}
              >
                Click here to display key for manual input
              </Typography>
            </Typography>
            {displayManualKey && (
              <Box
                style={{
                  fontFamily: 'monospace',
                  padding: '10px',
                  fontSize: '14px',
                  backgroundColor: '#e7e7e7',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  textAlign: 'center',
                  color: '#444',
                  marginTop: '15px',
                }}
              >
                {totpSecret ?? null}
              </Box>
            )}
          </Box>
        )}
        {step === 2 && (
          <Box style={{ gap: '15px', justifyContent: 'center', paddingTop: '10px' }}>
            <Typography
              style={{
                fontSize: '14px',
                paddingLeft: '5px',
                color: theme.color.grey.neutral400,
                textAlign: 'center',
                padding: '25px 0',
              }}
            >
              Please enter the six-digit code on your chosen authenticator app
              <br />
              <br />
              <AuthCode
                onChange={(e) => {
                  setOtp(e);
                }}
                inputClassName='otp-input'
              />
              {authError >= 1 && authError < 5 && (
                <Typography style={{ fontSize: '14px', color: theme.color.error[900], textAlign: 'center' }}>
                  <br />
                  Please try again, the entered code didn&apos;t match.
                </Typography>
              )}
              {authError >= 5 && (
                <Typography style={{ fontSize: '14px', color: theme.color.error[900], textAlign: 'center' }}>
                  <br />
                  Please go back and try adding the QR Code again to your authentication app
                </Typography>
              )}
            </Typography>
          </Box>
        )}
        {step === 3 && (
          <Box style={{ gap: '15px', justifyContent: 'center', paddingTop: '10px', textAlign: 'center' }}>
            <br />
            <br />
            <CheckCircle style={{ color: theme.alert.green.light, fontSize: '45px' }} />
            <br />
            <Typography
              style={{ fontSize: '14px', paddingLeft: '5px', color: theme.color.grey.neutral400, textAlign: 'center' }}
            >
              <br />
              <strong>Success! Authentication method has been added. </strong>
              <br />
              <br />
              You will need to input this code when logging into Volaby when required.
              <br />
              You may now close this window.
            </Typography>
            <br />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box padding={2}>
          {step === 1 && !orgForce ? (
            <TabletButton
              variant='text'
              color='error'
              onClick={() => {
                handleClose();
              }}
              style={{ marginRight: 8 }}
            >
              {'Cancel'}
            </TabletButton>
          ) : step === 3 ? null : (
            <TabletButton
              variant='text'
              color='default'
              onClick={() => {
                goBack();
                setAuthError(0);
              }}
              style={{ marginRight: 8 }}
            >
              {'Back'}
            </TabletButton>
          )}
          {step !== 3 ? (
            <TabletButton
              data-track='actCnl-add-volunteers'
              variant='contained'
              color='primary'
              type='submit'
              onClick={() => {
                if (step === 0) {
                  setStep(step + 1);
                  totpAdd();
                }
                if (step === 2) {
                  totpConfirm();
                } else {
                  setStep(step + 1);
                }
              }}
            >
              {'Next'}
            </TabletButton>
          ) : (
            <TabletButton
              variant='contained'
              color='primary'
              style={{ marginRight: 8 }}
              onClick={() => {
                onSuccess();
              }}
            >
              {'Close'}
            </TabletButton>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
