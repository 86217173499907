import { LinearProgressOverlay } from '@campfire/linear-progress-overlay';
import { Box, Switch, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Visibility } from '@material-ui/icons';
import React, { useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetActivityCancellationAndClosureReasons } from './__generated__/GetActivityCancellationAndClosureReasons';
import { ActivityReasonEditForm } from './ActivityReasonEditForm';
import { ActivityReasonType } from './admin-console-operations-model';
import {
  GET_ACTIVITY_CANCELLATION_CLOSURE_REASONS,
  GET_ADMIN_CONSOLE_OPS_SESSION_VISIBILITY,
} from './admin-console-operations.gql';
import { GetAdminConsoleOpsSessionVisibility } from './__generated__/GetAdminConsoleOpsSessionVisibility';
import { useSnackbar } from '../../../../../global/config/useSnackbar';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useApiUrl } from '../../../../../global/config/useApiUrl';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 20,
      backgroundColor: '#F9F9F9',
      minHeight: 'calc(100% - 20px)',
    },
    contentContainer: {
      background: 'white',
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem',
      paddingLeft: '1.875rem',
      paddingRight: '1.875rem',
      marginTop: '2.25rem',
    },
    heading: {
      alignItems: 'center',
      paddingBottom: '8px',
      borderBottom: '1px solid #efefef',
    },
    headingTitle: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 600,
      paddingBottom: '5px',
      fontSize: 20,
    },
    infoBody: {
      color: theme.alert.blue.dark,
      fontSize: '14px',
      fontWeight: 400,
    },
  })
);

const AdminConsoleOperationsPanel = () => {
  const classes = useStyles();
  const { setSnackbar } = useSnackbar();
  const [allowSessionVis, setAllowSessionVis] = React.useState(false);
  const { data: allReasonsResponse, loading: allReasonsLoading, refetch: refetchReasons } = useCampfireQuery<
    GetActivityCancellationAndClosureReasons,
    undefined
  >(GET_ACTIVITY_CANCELLATION_CLOSURE_REASONS);
  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });

  const initialCancellationReasonValues = useMemo(() => {
    if (!allReasonsResponse?.vm.activityCancellationReasons) return { reasons: [] };
    return {
      reasons: allReasonsResponse.vm.activityCancellationReasons.map((reason) => {
        return {
          activityCancellationReasonId: reason.activityCancellationReasonId,
          label: reason.label,
          listId: uuidv4(),
        };
      }),
    };
  }, [allReasonsResponse]);

  const initialClosureReasonValues = useMemo(() => {
    if (!allReasonsResponse?.vm.activityClosureReasons) return { reasons: [] };
    return {
      reasons: allReasonsResponse.vm.activityClosureReasons.map((reason) => {
        return {
          activityClosureReasonId: reason.activityClosureReasonId,
          label: reason.label,
          listId: uuidv4(),
        };
      }),
    };
  }, [allReasonsResponse]);

  const { data: opsSessionVisibility, refetch: opsSessionVisibilityRefetch } = useCampfireQuery<
    GetAdminConsoleOpsSessionVisibility,
    undefined
  >(GET_ADMIN_CONSOLE_OPS_SESSION_VISIBILITY);

  useEffect(() => {
    setAllowSessionVis(opsSessionVisibility?.vm.configItem?.configItemValue?.flagValue ?? false);
  }, [opsSessionVisibility]);

  const onSessionVisChange = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/admin-console/config`,
        method: 'post',
        data: {
          value: !allowSessionVis,
          type: 'operations-allow-roster-session-visibility',
        },
      })
      .then(() => {
        if (opsSessionVisibilityRefetch) {
          setTimeout(opsSessionVisibilityRefetch, 0);
        }
        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Settings updated',
        });
      });

    setAllowSessionVis(!allowSessionVis);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.heading}>
        <Typography variant='h2' className={classes.headingTitle}>
          Operations
        </Typography>
        <Typography variant={'body2'}>{'Manage settings relating to operations, activities and rosters'}</Typography>
      </Box>
      <Box style={{ marginTop: '25px' }} className={classes.contentContainer}>
        <LinearProgressOverlay isLoading={allReasonsLoading} />
        <ActivityReasonEditForm
          initialValues={initialCancellationReasonValues}
          type={ActivityReasonType.cancellation}
          refetchReasons={refetchReasons}
        />
      </Box>
      <Box style={{ marginTop: '25px' }} className={classes.contentContainer}>
        <ActivityReasonEditForm
          initialValues={initialClosureReasonValues}
          type={ActivityReasonType.closure}
          refetchReasons={refetchReasons}
        />
      </Box>
      <Box style={{ marginTop: '25px' }} className={classes.contentContainer}>
        <Typography style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <Visibility style={{ fontSize: '14px', paddingRight: '5px' }} /> Visibility
        </Typography>
        <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
          {'Options relating to visibility of activity and roster detail'}
        </Typography>
        <Box marginTop='1.5rem'>
          <Box mt={2}>
            <Box display='flex' alignItems={'center'}>
              <Switch
                checked={allowSessionVis}
                onChange={onSessionVisChange}
                color='primary'
                inputProps={{ 'aria-label': 'allow comments' }}
              />
              <Box ml={1}>
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Roster Session Visibility
                </Typography>
                <Typography variant={'body2'} style={{ fontSize: '13px', color: '#808080' }}>
                  {'Users can see details of sessions that other team members are rostered on for'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { AdminConsoleOperationsPanel };
