import { DateTime } from 'luxon';
import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React, { ReactNode, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { TitularTooltip } from '@campfire/titular-tooltip';
import { LocationOnRounded, CalendarToday as CalendarIcon, Flag as FlagsIcon, Explore } from '@material-ui/icons';
import { useCampfireTheme } from '../../../theme/useCampfireTheme';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      display: 'flex',
      width: '100%',
      borderRadius: '8px',
      boxSizing: 'border-box',
      overflow: 'hidden',
      '&:hover': {
        cursor: 'pointer',
        boxShadow: `0px 0px 4px rgba(0, 0, 0, 0.25)`,
      },
    },
    cardNoHover: {
      display: 'flex',
      width: '100%',
      borderRadius: '8px',
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
  })
);

interface LinkInfo {
  pathname: string;
  search: string;
}

interface ActivityCardProps {
  activity: any;
  activityMenu?: any;
  color?: {
    primary: string;
    secondary: string;
    additional?: string;
  };
  children: ReactNode;
  selected?: boolean;
  noHover?: boolean;
  hasFooterCard?: boolean;
  classes?: { [key: string]: string };
}
interface Props extends ActivityCardProps {
  linkInfo?: LinkInfo;
}

const Card = (props: ActivityCardProps) => {
  const {
    color,
    children,
    selected,
    noHover,
    activity,
    activityMenu,
    classes: customClasses = {},
    hasFooterCard = true,
  } = props;
  const { theme, isMobile, isMd } = useCampfireTheme();
  const classes = useStyles();

  const time = useMemo(() => {
    const startDate = activity?.startDate;
    const endDate = activity?.endDate;
    if (!startDate && !endDate) {
      return '';
    }

    const diffNow = DateTime.fromISO(startDate)
      .diffNow()
      .as('days');
    if (diffNow > 0) {
      return `Starts ${DateTime.fromISO(startDate).toFormat('dd')} ${DateTime.fromISO(startDate).toFormat('LLLL, y')}`;
    }
    if (diffNow <= 0 && endDate) {
      return `Ends ${DateTime.fromISO(endDate).toFormat('dd')} ${DateTime.fromISO(endDate).toFormat('LLLL, y')}`;
    }
    return '';
  }, [activity]);

  return (
    <Box
      className={classNames(customClasses.root, { [classes.cardNoHover]: noHover, [classes.card]: !noHover })}
      border={selected ? `2px solid ${color?.primary}` : `1px solid ${theme.color.grey.border}`}
      borderBottom={selected ? `2px solid ${color?.primary}` : `1px solid ${theme.color.grey.border}`}
      borderRight={selected ? `2px solid ${color?.primary}` : `1px solid ${theme.color.grey.border}`}
      bgcolor={color?.primary ?? theme.color.grey.neutralBrand100}
      overflow={'hidden'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        minWidth={'36px'}
        width='36px'
        borderRadius={'8px 0px 0px 8px'}
      ></Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        flexGrow={2}
        alignItems={'flex-start'}
        justifyContent={'center'}
        style={{ padding: '16px 20px' }}
        overflow={'hidden'}
        width={!isMobile || !isMd ? '300px' : '250px'}
        bgcolor={theme.color.white.neutral50}
        className={customClasses.bodyContainer}
      >
        <Box display='flex' justifyContent='space-between' alignSelf='stretch'>
          <TitularTooltip title={activity.name}>
            <Box
              display='flex'
              alignItems='center'
              style={{
                width: '100%',
                letterSpacing: '-0.02em',
                overflow: 'hidden',
                maxHeight: '3.5em',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography
                variant={'h6'}
                noWrap
                style={{ color: theme.color.grey.neutral400, fontWeight: 800, width: '100%', letterSpacing: '-0.02em' }}
              >
                {activity.name}
              </Typography>
              {activity?.priority && <FlagsIcon style={{ margin: '0 6px 4px', color: '#EB7612' }} />}
            </Box>
          </TitularTooltip>
          {activityMenu && activityMenu}
        </Box>

        {children}

        {hasFooterCard && (
          <Box display={isMobile || isMd ? 'auto' : 'flex'} alignItems='center' mt='30px'>
            {time && (
              <Box display='flex' alignItems='center' pr={2} pt={isMobile || isMd ? 1 : 0}>
                <CalendarIcon style={{ color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }} />
                <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200, marginTop: '1px' }}>
                  {time}
                </Typography>
              </Box>
            )}

            <Box display='flex' alignItems='center' pr={2} pt={isMobile || isMd ? 1 : 0}>
              <LocationOnRounded style={{ color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }} />
              <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200, marginTop: '1px' }}>
                {activity?.activityLocation
                  ? activity?.activityLocation.placesAddress.description
                  : activity?.activityRemoteLocation?.details}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' pr={2} pt={isMobile || isMd ? 1 : 0}>
              <Typography
                variant={'h2'}
                style={{ fontWeight: 600, color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }}
              >
                {activity?.sessions.length}
              </Typography>
              <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200, marginTop: '1px' }}>
                {activity?.sessions.length > 1 ? 'sessions available' : 'session available'}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center' pr={2} pt={isMobile || isMd ? 1 : 0}>
              <Explore style={{ color: theme.color.grey.neutral200, fontSize: 14, marginRight: '6px' }} />
              <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200, marginTop: '1px' }}>
                {activity?.program.name}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const ActivityCard = (props: Props) => {
  const { linkInfo, color, children, selected, noHover, activity, classes, activityMenu, hasFooterCard } = props;
  if (linkInfo) {
    const { pathname, search } = linkInfo;
    return (
      <Link
        to={{
          pathname,
          search,
        }}
        style={{ textDecoration: 'none', width: '100%', color: '#000' }}
      >
        <Card
          activity={activity}
          color={color}
          activityMenu={activityMenu}
          selected={selected}
          noHover={noHover}
          classes={classes}
          hasFooterCard={hasFooterCard}
        >
          {children}
        </Card>
      </Link>
    );
  }
  return (
    <Card
      activity={activity}
      color={color}
      activityMenu={activityMenu}
      selected={selected}
      noHover={noHover}
      hasFooterCard={hasFooterCard}
    >
      {children}
    </Card>
  );
};
