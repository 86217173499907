import { TabletButton } from '@campfire/tablet-button';
import { Box, Grid, Typography } from '@material-ui/core';
import { Add, LiveHelp } from '@material-ui/icons';
import { FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useAdminConsoleActions } from '../../admin-console-actions';
import { AddScreeningQuestionDialog } from './AddScreeningQuestionDialog';
import { ScreeningQuestionsFormValues } from './admin-console-applicant-screening-model';
import { ScreeningQuestionListFieldSection } from './ScreeningQuestionListFieldSection';

type ScreeningQuestionEditFormType = {
  initialValues: ScreeningQuestionsFormValues;
  refetchQuestions?: () => void;
};

export const ScreeningQuestionEditForm = ({ initialValues, refetchQuestions }: ScreeningQuestionEditFormType) => {
  const [openAddReasonDialog, setOpenAddReasonDialog] = useState<boolean>(false);
  const { runSaveScreeningQuestions } = useAdminConsoleActions();

  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          screeningQuestions: initialValues.screeningQuestions.sort((a, b) => (a.order < b.order ? -1 : 1)),
        }}
        onSubmit={(values) => {
          runSaveScreeningQuestions(values);
        }}
      >
        <Form>
          <FieldArray name='screeningQuestions'>
            {(arrayHelpers) => (
              <>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                      <LiveHelp style={{ fontSize: '14px', paddingRight: '5px' }} /> Screening Questions
                    </Typography>
                    <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
                      {'New applicants will see and respond to screening questions before their account is created'}
                    </Typography>
                  </Box>
                  <TabletButton
                    size='small'
                    variant='contained'
                    color='primary'
                    aria-controls='options-menu'
                    aria-haspopup='true'
                    startIcon={<Add />}
                    style={{
                      border: '1px solid transparent',
                      boxSizing: 'border-box',
                      borderRadius: '4px',
                      padding: '6px 12px',
                      marginLeft: '10px',
                      marginTop: '2px',
                      color: '#fff',
                    }}
                    onClick={() => {
                      setOpenAddReasonDialog(true);
                    }}
                  >
                    {'Add'}
                  </TabletButton>
                </Box>

                <Grid item xs>
                  <ScreeningQuestionListFieldSection arrayHelpers={arrayHelpers} />
                </Grid>

                <AddScreeningQuestionDialog
                  open={openAddReasonDialog}
                  onClose={() => setOpenAddReasonDialog(false)}
                  refetchReasons={refetchQuestions}
                />
              </>
            )}
          </FieldArray>
        </Form>
      </Formik>
    </Box>
  );
};
