import { gql } from '@apollo/client';

export const GET_ADD_VOLUNTEER_PROGRAMS = gql`
  query GetAddVolunteerPrograms {
    vm {
        programs {
            programId
            name
            dateSuspended
        }
    }
}
`;