import React, { ChangeEvent } from 'react';
import { Box, Checkbox, FormControlLabel, Typography, Theme } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AvailabilityCenterSession } from '../availability-center/SessionSelect/__generated__/AvailabilityCenterSession';
import { getDisplayTimeSchedule } from '../functions/activity-display-helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.color.grey.neutral400,
      fontWeight: 600,
      width: '100%',
      letterSpacing: '-0.02em',
      fontSize: '15px',
    },
    subTitle: {
      fontSize: 12,
      color: theme.color.grey.neutral200,
    },
    subTitlesContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    subTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '22px',
    },
    captionText: {
      fontSize: 7,
      color: theme.color.grey.neutral200,
    },
    captionContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    container: {
      width: '100%',
      paddingTop: '15px',
      paddingBottom: '15px',
      border: '2px solid',
      borderColor: theme.color.grey.border,
      backgroundColor: theme.color.grey.background,
      borderRadius: '8px',
      marginBottom: '10px',
    },
    checkBox: {
      marginLeft: '9px',
      marginRight: '15px',
    },
    icon: {
      marginRight: '7px',
      color: theme.color.grey.neutral200,
    },
  })
);

interface Props {
  session: AvailabilityCenterSession;
  isSelected?: boolean;
  onSelect: (event: ChangeEvent<HTMLInputElement>) => void;
}

function Session({ session }: { session: AvailabilityCenterSession }) {
  const classes = useStyles();
  const sessionTime = getDisplayTimeSchedule(session.startTime, session.endTime);

  return (
    <Box>
      <Typography className={classes.title}>{session.name}</Typography>
      <Box>
        <Box className={classes.subTitlesContainer}>
          <Box className={classes.subTitleContainer}>
            <AccessTime style={{ fontSize: 17 }} className={classes.icon} />
            <Typography className={classes.subTitle}>{sessionTime}</Typography>
          </Box>
          {/* <Box className={classes.subTitleContainer}>
            <Box className={`${classes.captionContainer} ${classes.icon}`}>
              <Person style={{ fontSize: 17 }} />
              <Typography className={classes.captionText}>MIN</Typography>
            </Box>
            <Typography className={classes.subTitle}>{session.minVolunteers || 1}</Typography>
          </Box> */}
          {/* {session.maxVolunteers && (
            <Box className={classes.subTitleContainer}>
              <Box className={`${classes.captionContainer} ${classes.icon}`}>
                <Person style={{ fontSize: 17 }} />
                <Typography className={classes.captionText}>MAX</Typography>
              </Box>
              <Typography className={classes.subTitle}>{session.maxVolunteers}</Typography>
            </Box>
          )} */}
        </Box>
      </Box>
    </Box>
  );
}

export function SessionItemComplex({ session, isSelected, onSelect }: Props) {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.container}
      control={
        <Checkbox
          className={classes.checkBox}
          checked={isSelected}
          name={session.sessionId}
          onChange={onSelect}
          color='primary'
        />
      }
      label={<Session session={session} />}
    />
  );
}
