import { Pane } from '@campfire/pane';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';
import { ChevronRight, Close, Event, ExitToApp, ExpandMore, SyncRounded } from '@material-ui/icons';
import { convertFromRaw, EditorState } from 'draft-js';
import { unpackToTime } from '@campfire/hot-date';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  ClickAwayListener,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { TabletButton } from '@campfire/tablet-button';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { Cake, CakeContent } from '@campfire/cake';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useCampfireTheme } from '../../theme/useCampfireTheme';
import { useApiUrl } from '../config/useApiUrl';
import { useCampfireQuery } from '../network/useCampfireQuery';
import { useCampfireFetch } from '../network/useCampfireFetch';
import { useSnackbar } from '../config/useSnackbar';
import { useUser } from '../auth/useUser';
import {
  GET_ALL_APPLICANT_ACTIVITIES,
  INCOMING_ACTIVITY_APPLY_QUERY,
  INCOMING_ACTIVITY_DETAIL_QUERY,
} from './model/applicant-activities-application-query.gql';
import {
  GetIncomingActivityApply,
  GetIncomingActivityApplyVariables,
} from './model/__generated__/GetIncomingActivityApply';
import {
  GetIncomingActivityDetail,
  GetIncomingActivityDetail_vm_activities as ActivityDetailProps,
  GetIncomingActivityDetailVariables,
} from './model/__generated__/GetIncomingActivityDetail';
import {
  GetAllApplicantActivities,
  GetAllApplicantActivities_vm_application_applicationActivities as AppliedActivityProps,
  GetAllApplicantActivitiesVariables,
} from './model/__generated__/GetAllApplicantActivities';
import { GetApplicationAvailabilityComplex } from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/__generated__/GetApplicationAvailabilityComplex';
import { GET_APPLICATION_AVAILABILITY_COMPLEX } from '../../screens/admin/admin-console/admin-console-content-pages/admin-console-onboarding-applications/admin-console-applicant-screening.gql';
import {
  ComplexAvailabilityComponent,
  ValueProps,
} from '../../common/complex-availability-select/ComplexAvailabilitySelect';

const useTaskListItemClasses = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      '&:hover': {
        cursor: 'pointer',
      },
      border: '.6px solid #00000010',
      padding: '15px 30px',
      alignItems: 'flex-start',
    },
    checkIcon: {
      fontSize: 29,
      color: '#00ed7e',
    },
    circleIcon: {
      fontSize: 29,
      color: theme.palette.grey[200],
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      padding: '0.3rem',
      borderRadius: '4px',
      cursor: 'pointer',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.75,
      },
    },
    menuItemText: {
      marginLeft: '0.75rem',
      fontSize: '14px',
    },
    tooltip: {
      borderRadius: '12px',
      paddingTop: '0.5rem',
      paddingLeft: '.7rem',
      paddingRight: '.7rem',
      paddingBottom: '0.5rem',
      backgroundColor: theme.color.tooltip.background,
    },
    tooltipArrow: {
      '&::before': {
        backgroundColor: theme.color.tooltip.background,
      },
    },
  })
);

const SUYPActivityApplication = () => {
  const { user } = useUser();
  const history = useHistory();
  const { data: appliableActivitiesIds } = useCampfireQuery<
    GetIncomingActivityApply,
    GetIncomingActivityApplyVariables
  >(INCOMING_ACTIVITY_APPLY_QUERY, {
    options: {
      variables: {
        userId: user.userId,
      },
    },
  });

  const { data: availabilityComplex } = useCampfireQuery<GetApplicationAvailabilityComplex, undefined>(
    GET_APPLICATION_AVAILABILITY_COMPLEX
  );

  const [applicationAvailabilityComplex, setApplicationAvailabilityComplex] = React.useState(false);

  useEffect(() => {
    setApplicationAvailabilityComplex(availabilityComplex?.vm.configItem?.configItemValue?.flagValue ?? false);
  }, [availabilityComplex]);

  const activityIds =
    React.useMemo(
      () =>
        appliableActivitiesIds?.vm?.profile?.programs
          .flatMap(({ activities: programActivities }) => programActivities)
          .map(({ activityId }) => activityId),
      [appliableActivitiesIds]
    ) || [];

  const { data: appliableActivitiesData } = useCampfireQuery<
    GetIncomingActivityDetail,
    GetIncomingActivityDetailVariables
  >(INCOMING_ACTIVITY_DETAIL_QUERY, {
    options: {
      variables: {
        activityIds: activityIds,
        from: DateTime.local().toISODate(),
        until: DateTime.local()
          .plus({ years: 1 })
          .toISODate(),
      },
    },
  });

  const { data: appliedActivitiesData, refetch, loading } = useCampfireQuery<
    GetAllApplicantActivities,
    GetAllApplicantActivitiesVariables
  >(GET_ALL_APPLICANT_ACTIVITIES, {
    options: {
      variables: {
        userId: user.userId,
      },
    },
  });

  const appliedActivities = appliedActivitiesData?.vm.application?.applicationActivities;
  const appliableActivities = appliableActivitiesData?.vm.activities;

  return (
    <Pane style={{ width: '100%', height: '100%', backgroundColor: '#f1f1f1' }}>
      <Box
        overflow='hidden auto'
        bgcolor='#f1f1f1'
        display='flex'
        flex='1 1 auto'
        flexDirection='column'
        width={1}
        paddingTop={2}
        paddingBottom={8}
        paddingX={1}
        boxSizing='border-box'
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        <Box width={1} display='flex' justifyContent='center'>
          <Box width={1} maxWidth={720} marginBottom={3}>
            <Cake>
              <Box style={{ padding: '20px 30px 10px 30px' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: '19px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#444',
                    }}
                  >
                    <Event style={{ fontSize: '22px', paddingRight: '5px' }} /> Activities
                  </Typography>
                  <Button onClick={() => history.push('/')}>
                    <Close style={{ color: '#666' }} />
                  </Button>
                </Box>
                <Typography variant={'body2'} style={{ fontSize: '13px', color: '#808080' }}>
                  The activities below are available for you to apply to. <br />
                  <br />
                  Once you have applied to an activity, return to the previous screen to complete any additional tasks
                  that may be required. You may return to this screen anytime to apply to additional activities or
                  revoke any applications.
                  <br />
                  <br />
                  You organisation will approve applications when they review your profile.
                </Typography>
              </Box>
              <CakeContent style={{ padding: '8px 0px 0px 0px' }}>
                {!loading ? (
                  <List style={{ paddingBottom: '0' }}>
                    {appliableActivities?.map((activity) =>
                      appliedActivities?.length === 0 ? (
                        <ActivityListItem
                          activity={activity}
                          refetch={refetch}
                          availability={applicationAvailabilityComplex}
                        />
                      ) : (
                        appliedActivities && (
                          <ActivityListItem
                            activity={activity}
                            appliedActivity={appliedActivities.find(
                              (aActivity) => aActivity.activity.activityId === activity.activityId
                            )}
                            refetch={refetch}
                            availability={applicationAvailabilityComplex}
                          />
                        )
                      )
                    )}
                  </List>
                ) : (
                  <Box padding='1.5em'>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </Box>
                )}
              </CakeContent>
            </Cake>
          </Box>
        </Box>
      </Box>
    </Pane>
  );
};

interface RoleProps {
  activity: ActivityDetailProps;
  appliedActivity?: AppliedActivityProps;
  refetch: () => void;
  availability: boolean;
}

const ActivityListItem = (props: RoleProps) => {
  const { user } = useUser();
  const taskListItemClasses = useTaskListItemClasses();
  const classes = useStyles();
  const { theme } = useCampfireTheme();
  const { activity, appliedActivity, refetch, availability } = props;
  const editorState = React.useMemo(
    () =>
      activity.description ? EditorState.createWithContent(convertFromRaw(JSON.parse(activity.description))) : null,
    [activity.description]
  );
  const apiUrl = useApiUrl();
  const campfireMutation = useCampfireFetch({ defer: true });
  const { setSnackbar } = useSnackbar();
  const [withdrawOpen, setWithdrawOpen] = useState<boolean>(false);
  const [availabilityOpen, setAvailabilityOpen] = useState<boolean>(false);
  const recurrence =
    activity.__typename === 'VOLUNTEER_RecurringActivityType'
      ? activity.schedule.recurrences[0].humanReadable
      : 'Flexible';

  const applicationStatus = () => {
    if (appliedActivity?.status === 'pending') {
      return 'unsure';
    }
    if (appliedActivity?.status === 'to-team') {
      return 'rostered';
    }
    if (appliedActivity?.status === 'to-application') {
      return 'applied';
    }
    if (appliedActivity?.status === 'to-waitlist') {
      return 'waitlisted';
    }
    if (appliedActivity?.status === 'decline') {
      return 'rejected';
    }
    return 'available';
  };

  const applicationStatusDesc = () => {
    if (appliedActivity?.status === 'pending') {
      return 'Pending';
    }
    if (appliedActivity?.status === 'to-team') {
      return 'Approved';
    }
    if (appliedActivity?.status === 'to-application') {
      return 'To Application';
    }
    if (appliedActivity?.status === 'to-waitlist') {
      return 'To Waitlist';
    }
    if (appliedActivity?.status === 'decline') {
      return 'Rejected';
    }
    return 'Apply';
  };

  const applicationStatusIcon = () => {
    if (appliedActivity?.status === 'pending') {
      return <ExpandMore style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
    }
    if (
      appliedActivity?.status === 'to-team' ||
      appliedActivity?.status === 'decline' ||
      appliedActivity?.status === 'to-application' ||
      appliedActivity?.status === 'to-waitlist'
    ) {
      return undefined;
    }
    return <ChevronRight style={{ color: theme.color.grey.neutral300, marginLeft: '-2px' }} />;
  };

  const onRoleApply = (userId: string, activityId: string, availabilities?: ValueProps) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/application/activity-application/apply`,
        method: 'post',
        data: availabilities
          ? {
              activityId: activityId,
              userId: userId,
              availabilitySessions: availabilities.sessionIds,
              availabilityDates: availabilities.dates,
            }
          : {
              activityId: activityId,
              userId: userId,
            },
      })
      .then((response) => {
        refetch();
        if (response.ok) {
          setSnackbar({
            variant: 'success',
            open: true,
            message: 'Applied',
          });
        } else {
          setSnackbar({
            variant: 'error',
            open: true,
            message: 'Error when applying',
          });
        }
      });
  };

  const onRoleWithdraw = (userId: string, activityActivityId: string) => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/application/activity-application/withdraw`,
        method: 'post',
        data: {
          applicationActivityId: activityActivityId,
          userId: userId,
        },
      })
      .then((response) => {
        refetch();
        if (response.ok) {
          setSnackbar({
            variant: 'success',
            open: true,
            message: 'Withdrawn role application',
          });
        } else {
          setSnackbar({
            variant: 'error',
            open: true,
            message: 'Error when withdrawing',
          });
        }
      });
  };

  const statusColor = applicationStatus();
  const formatTime = (time: string) => unpackToTime(time).toFormat('h:mm a');
  const [complexAvailabilities, setComplexAvailabilities] = React.useState<ValueProps>({
    activityId: activity.activityId,
  });
  const onChange = (newValue: ValueProps) => {
    setComplexAvailabilities(newValue);
  };

  return (
    <>
      <ListItem className={taskListItemClasses.listItem}>
        <ListItemText>
          <Box>
            <Typography
              style={{ fontWeight: 600, fontSize: '15px', display: 'flex', alignItems: 'center', color: '#444' }}
            >
              {activity.name}
            </Typography>
            <Box display='flex' alignItems='center' paddingTop={'2px'}>
              <SyncRounded style={{ color: theme.color.grey.neutral200, fontSize: 16, marginRight: '6px' }} />
              <Typography variant={'caption'} style={{ color: theme.color.grey.neutral200 }}>
                {recurrence}
                {activity.startTime && activity.endTime
                  ? `, ${formatTime(activity.startTime)} - ${formatTime(activity.endTime)}`
                  : ''}
              </Typography>
            </Box>
            <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080', paddingTop: '15px' }}>
              {editorState && <CampfireRichEditor readOnly editorState={editorState} />}
            </Typography>
          </Box>
        </ListItemText>
        <ListItemIcon style={{ paddingLeft: '15px', paddingTop: '10px' }}>
          <ClickAwayListener onClickAway={() => setWithdrawOpen(false)}>
            <Tooltip
              arrow
              interactive
              title={
                <Box>
                  <Box
                    className={classes.menuItem}
                    onClick={() =>
                      appliedActivity ? onRoleWithdraw(user.userId, appliedActivity.applicationActivityId) : undefined
                    }
                  >
                    <ExitToApp fontSize='small' />
                    <Typography className={classes.menuItemText}>Withdraw</Typography>
                  </Box>
                </Box>
              }
              disableFocusListener
              disableHoverListener
              disableTouchListener
              placement='bottom-end'
              open={withdrawOpen}
              onClose={() => setWithdrawOpen(false)}
              onOpen={() => setWithdrawOpen(true)}
              classes={{
                tooltip: classes.tooltip,
                arrow: classes.tooltipArrow,
              }}
            >
              <TabletButton
                data-track='resources-add-new-menu-button'
                variant='outlined'
                color='primary'
                aria-controls='add-new'
                aria-haspopup='true'
                disabled={
                  appliedActivity?.status === 'to-team' ||
                  appliedActivity?.status === 'decline' ||
                  appliedActivity?.status === 'to-application' ||
                  appliedActivity?.status === 'to-waitlist'
                }
                onClick={() =>
                  appliedActivity?.status === 'pending'
                    ? setWithdrawOpen(true)
                    : availability && activity.nextX.length !== 0
                    ? setAvailabilityOpen(true)
                    : appliedActivity?.status === 'to-team' ||
                      appliedActivity?.status === 'decline' ||
                      appliedActivity?.status === 'to-application' ||
                      appliedActivity?.status === 'to-waitlist'
                    ? undefined
                    : onRoleApply(user.userId, activity.activityId)
                }
                endIcon={applicationStatusIcon()}
                style={{ padding: '8px 12px', border: `1px solid #DCDCDC`, whiteSpace: 'nowrap' }}
              >
                <Box display='flex' alignItems='center'>
                  <Box
                    height='10px'
                    width='10px'
                    borderRadius='50%'
                    marginRight='8px'
                    bgcolor={theme.color.rosters.status[statusColor].primary}
                  />
                  <Typography
                    variant='caption'
                    style={{
                      fontSize: '12px',
                      fontWeight: 700,
                      color: theme.color.grey.neutral400,
                      textTransform: 'uppercase',
                      lineHeight: '14px',
                      marginTop: '1px',
                    }}
                  >
                    {applicationStatusDesc()}
                  </Typography>
                </Box>
              </TabletButton>
            </Tooltip>
          </ClickAwayListener>
        </ListItemIcon>
      </ListItem>
      {availabilityOpen ? (
        <ComplexAvailabilityComponent
          value={complexAvailabilities}
          onChange={onChange}
          open={availabilityOpen}
          onClose={() => setAvailabilityOpen(false)}
          onSubmit={() => onRoleApply(user.userId, activity.activityId, complexAvailabilities)}
          enrolmentData={{}}
          activities={[activity]}
        />
      ) : (
        undefined
      )}
    </>
  );
};

export { SUYPActivityApplication };
