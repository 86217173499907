import React from 'react';
import { Box, Typography, Theme, Button, CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { max, uniq } from 'lodash';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { DateTime } from 'luxon';
import { encodeDate, unpackToDate } from '@campfire/hot-date';
import { ExpandMore } from '@material-ui/icons';

import { ActivityReportsList } from './ActivityReportsList';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import {
  ActivityReportIncompleteActivityReport,
  ActivityReportIncompleteActivityReportVariables,
} from '../__generated__/ActivityReportIncompleteActivityReport';
import { ACTIVITY_REPORT_GET_INCOMPLETE_ACTIVITY_REPORTS } from '../activity-reports-model.gql';
import { ActivityItem } from './types';
import { filterActivity } from './helpers';
import { LOADING_PERIOD } from './consts';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { useUser } from '../../../../global/auth/useUser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subTitle: {
      marginTop: 10,
      marginBottom: 20,
      fontSize: '.875rem',
      fontWeight: 500,
      color: theme.color.grey.neutral200,
    },
    button: {
      '& > span': {
        textTransform: 'none',
      },
      borderRadius: 6,
      height: 38,
    },
  })
);

export function IncompleteReport() {
  const classes = useStyles();

  const [queryIncompleteActivityReports, { loading }] = useCampfireLazyQuery<
    ActivityReportIncompleteActivityReport,
    ActivityReportIncompleteActivityReportVariables
  >(ACTIVITY_REPORT_GET_INCOMPLETE_ACTIVITY_REPORTS);

  const [incompleteActivities, setIncompleteActivities] = React.useState<ActivityItem[]>([]);
  const [isCaptionLoading, setIsCaptionLoading] = React.useState(false);

  const [query, setQuery] = useQueryParams({
    program: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    q: StringParam,
    activityType: StringParam,
    activityStatus: StringParam,
    duration: StringParam,
    rosterType: StringParam,
    location: StringParam,
    startTime: StringParam,
    endTime: StringParam,
    days: ArrayParam,
    activityId: StringParam,
    activityDate: StringParam,
  });

  const { program: programId, startDate, endDate = encodeDate(DateTime.local()), activityDate, activityId } = query;
  const { theme } = useCampfireTheme();
  const loadingStartDate = React.useRef<string>();
  const {
    user: {
      orgInfo: { dateCreated },
    },
  } = useUser();

  React.useEffect(() => {
    loadingStartDate.current = max([encodeDate(unpackToDate(endDate).minus(LOADING_PERIOD)), startDate]);
  }, [endDate, startDate]);

  const hasLoadMore =
    (!startDate || (loadingStartDate.current && startDate < loadingStartDate.current)) &&
    unpackToDate(loadingStartDate.current || endDate) > dateCreated;

  React.useEffect(() => {
    queryIncompleteActivityReports({
      variables: {
        programIds: !programId || programId === 'all' ? null : [programId],
        from: loadingStartDate.current,
        until: endDate,
        includeInactiveActivities: true,
      },
    }).then((response) => {
      const incompleteReports =
        response.data?.vm.incompleteActivityReports.flatMap((activity) => {
          return uniq(activity.occurrences).map((date) => ({
            ...activity,
            activityDate: date,
            programName: activity.program.name,
          }));
        }) || ([] as ActivityItem[]);

      if (incompleteActivities.length === 0 && incompleteReports.length === 0) {
        setIsCaptionLoading(true);
        loadFirstPageMore();
      }

      setIncompleteActivities(incompleteReports);
    });
  }, [programId, endDate]);

  const sortedActivities = incompleteActivities
    ?.sort((a, b) => (a.activityDate > b.activityDate ? -1 : 1))
    .filter(filterActivity(query));

  const loadFirstPageMore = () => {
    if (
      !loadingStartDate.current ||
      unpackToDate(loadingStartDate.current) < dateCreated ||
      (startDate && unpackToDate(loadingStartDate.current) <= unpackToDate(startDate))
    ) {
      setIsCaptionLoading(false);
      return;
    }

    const nextLoadingStartDate = max([
      encodeDate(unpackToDate(loadingStartDate.current || endDate).minus(LOADING_PERIOD)),
      startDate,
    ]);

    queryIncompleteActivityReports({
      variables: {
        programIds: !programId || programId === 'all' ? null : [programId],
        from: nextLoadingStartDate,
        until: loadingStartDate.current,
      },
    }).then((response) => {
      const incompleteReports =
        response.data?.vm.incompleteActivityReports.flatMap((activity) => {
          return uniq(activity.occurrences).map((date) => ({
            ...activity,
            activityDate: date,
            programName: activity.program.name,
          }));
        }) || ([] as ActivityItem[]);

      if (incompleteActivities.length === 0 && incompleteReports.length === 0) {
        loadingStartDate.current = nextLoadingStartDate;
        loadFirstPageMore();
        return;
      }
      setIsCaptionLoading(false);
      setIncompleteActivities((prev) => prev.concat(incompleteReports));
      loadingStartDate.current = nextLoadingStartDate;
    });
  };

  const loadMore = () => {
    if (!loadingStartDate.current) {
      return;
    }
    const nextLoadingStartDate = max([
      encodeDate(unpackToDate(loadingStartDate.current || endDate).minus({ days: 8 })),
      startDate,
    ]);

    const nextLoadingUntilDate = encodeDate(unpackToDate(loadingStartDate.current).minus({ days: 1 }));

    queryIncompleteActivityReports({
      variables: {
        programIds: !programId || programId === 'all' ? null : [programId],
        from: nextLoadingStartDate,
        until: nextLoadingUntilDate,
        includeInactiveActivities: true,
      },
    }).then((response) => {
      const incompleteReports =
        response.data?.vm.incompleteActivityReports.flatMap((activity) => {
          return uniq(activity.occurrences).map((date) => ({
            ...activity,
            activityDate: date,
            programName: activity.program.name,
          }));
        }) || ([] as ActivityItem[]);
      setIncompleteActivities((prev) => prev.concat(incompleteReports));
      loadingStartDate.current = nextLoadingStartDate;
    });
  };

  const { activityId: firstActivityId, activityDate: firstActivityDate } = sortedActivities[0] || {};
  const isSelectedInList = sortedActivities.some(
    (activity) => activity.activityId === activityId && activity.activityDate === activityDate
  );

  const { isMd } = useCampfireTheme();

  React.useEffect(() => {
    if (!isMd && !isSelectedInList && firstActivityId && firstActivityDate) {
      setQuery({
        activityId: firstActivityId,
        activityDate: firstActivityDate,
      });
    }
  }, [firstActivityId, firstActivityDate, isSelectedInList, isMd]);

  return (
    <Box flex={1} display='flex' alignItems='stretch' flexDirection='column' paddingBottom='2rem'>
      <Typography className={classes.subTitle}>{`Total ${sortedActivities?.length} incomplete reports`}</Typography>
      <ActivityReportsList activities={sortedActivities || []} />
      {loading ? (
        <>
          <CircularProgress style={{ alignSelf: 'center', marginTop: '1rem', marginBottom: '1rem' }} />
          {loadingStartDate.current && isCaptionLoading ? (
            <Typography variant='caption' color='textSecondary' align='center'>
              {`We're looking for any activity reports (${unpackToDate(loadingStartDate.current || endDate)
                .minus(LOADING_PERIOD)
                .toFormat('dd LLLL yyyy')} - ${unpackToDate(loadingStartDate.current).toFormat('dd LLLL yyyy')})`}
            </Typography>
          ) : null}
        </>
      ) : hasLoadMore ? (
        <Button
          onClick={loadMore}
          style={{ alignSelf: 'center', marginTop: '1rem' }}
          className={classes.button}
          variant='outlined'
          disableElevation
          endIcon={<ExpandMore />}
        >
          Show more (1 week earlier)
        </Button>
      ) : (
        <Typography
          style={{ textAlign: 'center', color: theme.color.grey.neutral400, fontSize: '15px', paddingTop: '12px' }}
        >
          <span role='img' aria-label='Congratulations!'>
            🎉
          </span>{' '}
          You have completed all of your activity reports
        </Typography>
      )}
    </Box>
  );
}
