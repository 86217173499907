import React from 'react';
import { Box, Typography, Theme, Chip } from '@material-ui/core';
import { lighten } from '@material-ui/core/styles';
import { makeStyles, createStyles } from '@material-ui/styles';
import pluralize from 'pluralize';
import { CampfireRichEditor } from '@campfire/campfire-rich-editor';
import { convertFromRaw, EditorState } from 'draft-js';
import { RoleItemMenuButton } from './RoleItemMenu';

import { AdminConsoleRole } from '../AdminConsoleRolesQuery/__generated__/AdminConsoleRole';
import { RoleIcon } from '../../../../../../common/icons/RoleIconList';
import { useCampfireTheme } from '../../../../../../theme/useCampfireTheme';

export interface ExtendRole extends AdminConsoleRole {
  volunteerCount: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '&:not(:first-child)': {
        marginTop: '1.25rem',
      },
      paddingTop: '.6rem',
      paddingBottom: '.6rem',
      borderTopColor: '#EFEFEF',
      borderTop: '1px solid',
      paddingRight: '1rem',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerNameWrapper: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    name: {
      color: theme.color.grey.neutralBrand900,
      fontWeight: 900,
      fontSize: '1rem',
      textAlign: 'center',
    },
    iconWrapper: {
      width: '1.125rem',
      height: '1.125rem',
      display: 'flex',
      alignItems: 'center',
      marginRight: '0.5rem',
    },
    parenthese: {
      fontWeight: 400,
      color: theme.color.grey.neutralBrand900,
      marginLeft: '0.75rem',
    },
    volunteer: {
      fontWeight: 400,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
        color: lighten(theme.palette.primary.main, 0.3),
      },
    },
    description: {
      fontSize: '0.875rem',
      color: theme.color.grey.neutralBrand200,
    },
  })
);

interface Props {
  role: ExtendRole;
  isLoading: boolean;
  onEdit: (role: ExtendRole) => void;
  onDelete: (role: ExtendRole) => void;
}

export function RoleItem({ role, onEdit, onDelete, isLoading = false }: Props) {
  const classes = useStyles();
  const { name, description, icon, volunteerCount } = role;
  const { theme } = useCampfireTheme();
  const editorState = React.useMemo(
    () => (description ? EditorState.createWithContent(convertFromRaw(JSON.parse(description))) : null),
    [role.description]
  );

  const onAction = (action: string) => {
    switch (action) {
      case 'edit':
        onEdit(role);
        break;
      case 'delete':
        onDelete(role);
        break;
      default:
        break;
    }
  };

  const readableCount = pluralize('Volunteer', volunteerCount, true);

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box className={classes.headerNameWrapper}>
          <Typography style={{ fontWeight: 600, fontSize: '15px', display: 'flex', alignItems: 'center' }}>
            {icon && (
              <Box className={classes.iconWrapper}>
                <RoleIcon iconName={icon} color={theme.color.grey.neutral300} />
              </Box>
            )}
            {name}
          </Typography>
        </Box>
        <RoleItemMenuButton onAction={onAction} />
      </Box>
      <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
        {editorState && <CampfireRichEditor readOnly editorState={editorState} />}
      </Typography>
      <Box paddingTop='20px'>
        {!isLoading && (
          <Chip
            label={readableCount}
            style={{ borderRadius: '4px', fontSize: '11px', fontWeight: 500, color: '#333', marginRight: '8px' }}
          />
        )}
        <Chip
          label={role.canApply ? 'Applications Open' : 'Applications Prevented'}
          style={{
            borderRadius: '4px',
            fontSize: '11px',
            fontWeight: 500,
            color: role.canApply ? '#fff' : '#333',
            backgroundColor: role.canApply ? '#48976C' : '#e0e0e0',
          }}
        />
      </Box>
    </Box>
  );
}
