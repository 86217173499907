import { Box, Switch, Typography } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useApiUrl } from '../../../../../global/config/useApiUrl';
import { useCampfireFetch } from '../../../../../global/network/useCampfireFetch';
import { useCampfireQuery } from '../../../../../global/network/useCampfireQuery';
import { GetApplicationApplyRoles } from './__generated__/GetApplicationApplyRoles';
import { GET_APPLICATION_APPLY_ROLES } from './admin-console-applicant-screening.gql';
import { useSnackbar } from '../../../../../global/config/useSnackbar';

export const RolesForm = () => {
  const apiUrl = useApiUrl();
  const { setSnackbar } = useSnackbar();
  const campfireMutation = useCampfireFetch({ defer: true });
  const [applicationApplyRoles, setApplicationApplyRoles] = React.useState(false);
  const { data: applicationRoles, refetch: applicationRolesRefetch } = useCampfireQuery<
    GetApplicationApplyRoles,
    undefined
  >(GET_APPLICATION_APPLY_ROLES);

  useEffect(() => {
    setApplicationApplyRoles(applicationRoles?.vm.configItem?.configItemValue?.flagValue ?? false);
  }, [applicationRoles]);

  const refetchValues = () => {
    applicationRolesRefetch();
    setApplicationApplyRoles(applicationRoles?.vm.configItem?.configItemValue?.flagValue ?? false);
  };

  const onRoleChange = () => {
    campfireMutation
      .run({
        url: `${apiUrl}/vm/admin-console/config`,
        method: 'post',
        data: {
          value: !applicationApplyRoles,
          type: 'application-apply-role',
        },
      })
      .then(() => {
        setSnackbar({
          variant: 'success',
          open: true,
          message: 'Settings updated',
        });
        refetchValues();
      });
  };

  return (
    <Box>
      <>
        <Typography style={{ fontWeight: 600, fontSize: '16px', display: 'flex', alignItems: 'center' }}>
          <Person style={{ fontSize: '14px', paddingRight: '5px' }} /> Roles
        </Typography>
        <Typography variant={'body2'} style={{ fontSize: '14px', color: '#808080' }}>
          {'Settings related to applicants indicating role preferences'}
        </Typography>
        <Box display='flex' flex='1 1 auto' marginTop='1.5em'>
          <Box style={{ paddingTop: 4, paddingRight: 8 }}>
            <Switch
              name='auto-verification-toggle'
              color='primary'
              checked={applicationApplyRoles}
              onChange={() => onRoleChange()}
              inputProps={{ 'aria-label': 'verification checkbox' }}
            />
          </Box>
          <Box>
            <Box ml={1}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#333',
                }}
              >
                Apply for Roles
              </Typography>
              <Typography style={{ fontSize: '13px', color: '#808080' }}>
                Applicants can apply for roles as part of the application workflow. <br />
                You can define appliable roles via the &apos;Roles&apos; tab. Applicants will need to complete relevant
                tasks before application approval.
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    </Box>
  );
};
