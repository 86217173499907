import React from 'react';
import { Typography, Box, Theme, Avatar, SvgIconProps } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';

type StatisticsProps = {
  value: number;
  title: string;
  subtitle?: string;
  icon: React.ComponentType<SvgIconProps>;
  color: string;
  simple?: boolean;
};

export const useStatisticsStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: '1.5rem',
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme?.color?.grey?.neutralBrand50,
      border: (props: StatisticsProps) => (!props.simple ? '' : 'none'),
      borderRadius: '8px',
    },
    title: {
      fontWeight: 900,
      fontSize: '1.25rem',
      lineHeight: 'unset',
    },
    subtitle: {
      color: theme.color.grey.neutral300,
      lineHeight: 'unset',
    },
    avatar: {
      width: 60,
      height: 60,
      borderRadius: 8,
      backgroundColor: (props: StatisticsProps) => props.color,
    },
    valueContainer: {
      fontSize: '1.5rem',
      fontWeight: 900,
      flex: 0.4,
      textAlign: 'right',
      marginRight: '2rem',
      color: (props: StatisticsProps) => props.color,
    },
    titleContainer: {
      flex: 0.6,
      textAlign: 'left',
      marginLeft: '2rem',
    },
  })
);

const Statistics = (props: StatisticsProps) => {
  const { value, title, subtitle, icon: Icon } = props;
  const classes = useStatisticsStyles(props);
  return (
    <Box className={classes.container}>
      <Typography variant='h3' className={classes.valueContainer}>
        {value}
      </Typography>
      <Avatar variant='rounded' classes={{ root: classes.avatar }}>
        <Icon fontSize='large' />
      </Avatar>
      <Box className={classes.titleContainer}>
        <Typography variant='h4' className={classes.title}>
          {title}
        </Typography>
        {subtitle ? (
          <Typography variant='body1' className={classes.subtitle}>
            {subtitle}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export { Statistics };
