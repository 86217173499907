import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { StringParam, useQueryParam } from 'use-query-params';
import { TaskMenuBarHeader } from '../TaskSideMenuBar';
import { SimpleAvailabilityComponent } from '../../../../../../../common/simple-availability-select/SimpleAvailabilitySelect';

interface Props {
  onClose: () => void;
}

export const SimpleAvailabilityView = ({ onClose }: Props) => {
  const [selectedVolunteerProfileId] = useQueryParam('volunteerProfileId', StringParam);

  return (
    <>
      <Box>
        <TaskMenuBarHeader onClose={onClose}>
          <Box>
            <Typography>Availability</Typography>
          </Box>
        </TaskMenuBarHeader>
      </Box>

      <Box
        flex={1}
        style={{ overflowY: 'scroll', backgroundColor: '#f6f8f9', width: '100%', paddingTop: '15px' }}
        display='flex'
      >
        <Box position='relative' display='flex' flex='1 1 auto' height={'auto'} minHeight={1} justifyContent={'center'}>
          {selectedVolunteerProfileId && (
            <SimpleAvailabilityComponent profileId={selectedVolunteerProfileId} editing={false} />
          )}
        </Box>
      </Box>
    </>
  );
};
