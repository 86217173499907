import { gql } from '@apollo/client';
import { RECURRENCE_FRAGMENT } from '../../../common/recurrence/recurrence-model.gql';

export const GET_ALL_APPLICANT_ACTIVITIES = gql`
  query GetAllApplicantActivities($userId: String!) {
    vm {
      application(userId: $userId) {
        applicationActivities {
            applicationActivityId
            activity {
                activityId
            }
            status
        }
      }
    }
}`;

export const INCOMING_ACTIVITY_APPLY_QUERY = gql`
  query GetIncomingActivityApply($userId: String!) {
    vm {
        profile(userId: $userId) {
          programs {
            programId
            name
            activities(includeInactiveActivities: false) {
              activityId
            }
          }
        }
    }
  }
`

export const INCOMING_ACTIVITY_DETAIL_QUERY = gql`
  query GetIncomingActivityDetail($activityIds: [String!]!, $from: Date, $until: Date) {
    vm {
      activities(activityIds: $activityIds) {
        __typename
        activityId
        isActive
        isRestrictedActivity
        isEnrollable
        startTime
        name
        endTime
        startDate
        endDate
        isSuspended
        isHidden
        hasOpenRoster
        description
        priority
        program {
          programId
          name
          allowCICO
        }
        sessions {
          sessionId
          name
          minVolunteers
          maxVolunteers
          dateRemoved
          description
          startTime
          endTime
          activityLocation {
            placesAddress {
              placesId
              description
              formatted
              latLong {
                lat
                long
              }
            }
          }
        }
        cancelledActivities {
          activityDate
        }
        nextX(x: 1)
        occurrencesBetween(from: $from, until: $until)
        activityLocation {
          activityLocationId
          placesAddress {
            placesId
            description
            formatted
            latLong {
              lat
              long
            }
          }
        }
        activityRemoteLocation {
          activityRemoteLocationId
          details
        }
        ... on VOLUNTEER_RecurringActivityType {
          schedule {
            recurrences {
              ...Recurrence
            }
          }
        }
        rosterTemplates {
          rosterDate
          placesAddress {
            formatted
          }
        }
      }
      }
    }
${RECURRENCE_FRAGMENT}
`

