import React, { useEffect, useMemo, useState } from 'react';
import { encodeDate } from '@campfire/hot-date';
import { Box } from '@material-ui/core';
import { DateTime } from 'luxon';
import { Route, withRouter } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { CampfireSwitch } from '../../../../content-blocks/common/CampfireSwitch';
import { useCampfireTheme } from '../../../../theme/useCampfireTheme';
import { getUpdateVolunteerActions } from '../../volunteer-common-profile/volunteer-profile-actions';
import { VolunteerCommonProfileProvider } from '../../volunteer-common-profile/VolunteerCommonProfileContext';
import { VolunteerCommonProfileDialog } from '../../volunteer-common-profile/VolunteerCommonProfileDialog';
import {
  GET_VOLUNTEER_DATABASE,
  GET_PROFILE_SETTING_VOLUNTEER_DATABASE,
  VolunteerDatabaseActions,
  VolunteerDatabaseState,
  VolunteerDatabaseVolunteer,
  GET_VOLUNTEER_DATABASE_TASKS,
} from './volunteer-database-model.gql';
import { VolunteerDatabaseDesktopTable } from './VolunteerDatabaseDesktopTable';
import { VolunteerDatabaseMobileTable } from './VolunteerDatabaseMobileTable';
import { GetVolunteerDatabase, GetVolunteerDatabaseVariables } from './__generated__/GetVolunteerDatabase';
import {
  GetProfileSettingVolunteerDatabase,
  GetProfileSettingVolunteerDatabaseVariables,
} from './__generated__/GetProfileSettingVolunteerDatabase';
import { GetVolunteerDatabaseTasks } from './__generated__/GetVolunteerDatabaseTasks';
import { useCampfireLazyQuery } from '../../../../global/network/useCampfireLazyQuery';
import { useUser } from '../../../../global/auth/useUser';
import { useCampfireQuery } from '../../../../global/network/useCampfireQuery';
import { useRequestExport } from '../../../../common/export/Export';

const initRequestVariables: GetVolunteerDatabaseVariables = {
  first: 25,
  last: null,
  before: null,
  after: null,
  startDate: encodeDate(DateTime.fromObject({ year: 1970, month: 1, day: 1 })),
  endDate: encodeDate(DateTime.local()),
  sortFilter: 'dateCreatedAsc',
  keywordsFilter: null,
  nameFilter: null,
  emailFilter: null,
  phoneFilter: null,
  privilegeFilter: null,
  commentsFilter: null,
  programsFilter: null,
  activitiesFilter: null,
  statusFilters: null,
  createdDateFilter: null,
  rolesFilter: null,
};

const VolunteerDatabaseScreenAdvanced = withRouter(({ match }) => {
  const { isMobile } = useCampfireTheme();
  const {
    maybeManagerIdentity: userManagerIdentity,
    user: { profileId },
  } = useUser();

  const programIds = userManagerIdentity ? userManagerIdentity.programManagerIdentities.map((i) => i.programId) : [];

  const [pageRef] = useState<React.RefObject<HTMLDivElement>>(React.createRef());

  const [selectedVolunteerId, setSelectedVolunteerId] = useQueryParam('volId', StringParam);
  const [volunteerDatabase, setVolunteerDatabase] = useState<VolunteerDatabaseState | undefined>();
  const [paginationState, setPaginationState] = useState({
    currentPage: 0,
    numberOfLoadedPages: -1,
    totalVolunteers: 0,
  });
  const [requestVariables, setRequestVariables] = useState<GetVolunteerDatabaseVariables>(initRequestVariables);
  const [getVolunteerDatabase, { data: response, loading: isLoading, refetch: reload }] = useCampfireLazyQuery<
    GetVolunteerDatabase,
    GetVolunteerDatabaseVariables
  >(GET_VOLUNTEER_DATABASE, {
    options: {
      variables: {
        ...initRequestVariables,
      },
    },
  });

  const { data: userProfileData, refetch: refetchUserProfile } = useCampfireQuery<
    GetProfileSettingVolunteerDatabase,
    GetProfileSettingVolunteerDatabaseVariables
  >(GET_PROFILE_SETTING_VOLUNTEER_DATABASE, {
    options: {
      variables: {
        profileId,
      },
    },
  });

  const { data: volunteerProfileTasks } = useCampfireQuery<GetVolunteerDatabaseTasks, undefined>(
    GET_VOLUNTEER_DATABASE_TASKS
  );

  const databaseTaskFields = userProfileData?.vm.profile?.userPreference?.databaseTaskFields || [];

  useEffect(() => {
    if (isMobile) {
      getVolunteerDatabase();
    }
  }, [isMobile]);

  useEffect(() => {
    if (response && !isLoading) {
      const { programActiveVolunteersConnection: connection } = response?.vm;
      if (connection) {
        const volunteers = connection.edges.map((edge) => edge.node);
        setPaginationState({
          ...paginationState,
          totalVolunteers: connection.totalCount ?? 0,
          numberOfLoadedPages: paginationState.numberOfLoadedPages + 1,
        });
        indexVolunteers(volunteers);
      }
    }
  }, [response]);

  function indexVolunteers(volunteers: VolunteerDatabaseVolunteer[]) {
    const state: VolunteerDatabaseState = {};

    for (let i = 0; i < volunteers.length; i++) {
      const volunteer = volunteers[i];
      state[volunteer.volunteerId] = volunteer;
    }

    const updatedState = { ...volunteerDatabase, ...state };
    setVolunteerDatabase(updatedState);
  }

  function getNextPage(cursor: string) {
    getVolunteerDatabase({
      variables: {
        ...requestVariables,
        after: cursor,
      },
    });
  }

  function handlePageChange(pageNumber: number) {
    setPaginationState({ ...paginationState, currentPage: pageNumber });
    if (pageNumber > paginationState.numberOfLoadedPages) {
      const cursor = Object.keys(volunteerDatabase ?? {}).pop();
      if (cursor) getNextPage(cursor);
    }
  }

  function handleNewRequest(filters: GetVolunteerDatabaseVariables) {
    setVolunteerDatabase(undefined);
    setPaginationState({
      ...paginationState,
      currentPage: 0,
      numberOfLoadedPages: -1,
    });
    setRequestVariables({
      ...requestVariables,
      ...filters,
    });
    getVolunteerDatabase({
      variables: {
        ...requestVariables,
        ...filters,
      },
    });
  }

  const campfireExport = useRequestExport();

  function handleCsvDownloadRequest() {
    campfireExport('volunteers', {
      filter: requestVariables,
      columns: ['name'],
    });
  }

  const updateVolunteer = (volunteerId: string, updatedVolunteer: any) => {
    if (!volunteerDatabase) return;
    setVolunteerDatabase({
      ...volunteerDatabase,
      [volunteerId]: updatedVolunteer,
    });
  };

  const retireVolunteer = (volunteer: VolunteerDatabaseVolunteer) => {
    updateVolunteer(volunteer.volunteerId, {
      ...volunteer,
      rosterStatus: 'retired',
      dateDeactivated: encodeDate(DateTime.local()) || null,
      flagging: null,
    });
  };

  const exitVolunteer = (volunteer: VolunteerDatabaseVolunteer) => {
    const date = DateTime.local();

    updateVolunteer(volunteer.volunteerId, {
      ...volunteer,
      rosterStatus: 'flagged',
      flagging: { __typename: 'VOLUNTEER_VolunteerFlaggingType', dateFlagged: encodeDate(date) },
      dateDeactivated: encodeDate(date),
    });
  };

  const reactivateVolunteer = (volunteer: VolunteerDatabaseVolunteer) => {
    updateVolunteer(volunteer.volunteerId, {
      ...volunteer,
      rosterStatus: 'previously',
    });
  };

  const volunteerDatabaseActions: VolunteerDatabaseActions = useMemo(
    () => ({
      retireVolunteer,
      exitVolunteer,
      reactivateVolunteer,
    }),
    []
  );

  const selectedVolunteer = useMemo(
    () => (volunteerDatabase && selectedVolunteerId ? volunteerDatabase[selectedVolunteerId] : undefined),
    [selectedVolunteerId]
  );

  return (
    <Box position='relative' display='flex' flexDirection='column' height={1} overflow='auto' bgcolor='#fffffff'>
      {isMobile ? (
        <VolunteerDatabaseMobileTable
          setSelectedVolunteerId={setSelectedVolunteerId}
          volunteerDatabaseState={volunteerDatabase ?? {}}
          volunteerDatabaseActions={volunteerDatabaseActions}
          reloadDatabase={reload}
          updateRequestVariables={handleNewRequest}
          requestVariables={requestVariables}
          totalVolunteers={paginationState.totalVolunteers}
        />
      ) : !isMobile ? (
        <VolunteerDatabaseDesktopTable
          isLoading={isLoading}
          setSelectedVolunteerId={setSelectedVolunteerId}
          activityRoles={response?.vm?.activityRoles ?? []}
          activities={response?.vm.programs.flatMap((p) => p.activities) ?? []}
          programs={response?.vm.programs ?? []}
          managedProgramIds={programIds}
          volunteerDatabaseState={volunteerDatabase}
          volunteerProfileTasks={volunteerProfileTasks?.vm.tasks ?? []}
          reloadDatabase={reload}
          pageRef={pageRef}
          handlePageChange={handlePageChange}
          paginationState={paginationState}
          updateRequestVariables={handleNewRequest}
          requestVariables={requestVariables}
          onRequestDownload={handleCsvDownloadRequest}
          databaseTaskFields={databaseTaskFields}
          refetchUserProfile={refetchUserProfile}
        />
      ) : null}
      <CampfireSwitch>
        <Route path={match.path}>
          {() => {
            return selectedVolunteer ? (
              <VolunteerCommonProfileProvider>
                <VolunteerCommonProfileDialog
                  open
                  onClose={() => setSelectedVolunteerId(undefined)}
                  profileId={selectedVolunteer.profile.profileId}
                  userId={selectedVolunteer.profile.userId}
                  updateVolunteerOnProfileActions={getUpdateVolunteerActions(updateVolunteer, selectedVolunteer)}
                />
              </VolunteerCommonProfileProvider>
            ) : null;
          }}
        </Route>
      </CampfireSwitch>
    </Box>
  );
});

export default VolunteerDatabaseScreenAdvanced;
